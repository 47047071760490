import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { useState } from "react";
import ReactLoading from "react-loading";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const ExpenseSummary = ({ data, filters, setFilters, loading }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const chartData = data?.expense_pie_chart || [];

  const filterOptions = [
    { id: "category", label: "Category" },
    { id: "payment_methods", label: "Payment Methods" },
  ];

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: "3px",
        boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #efefef",
        }}
      >
        <Box
          sx={{
            p: "9.6px 15px 9.6px 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              position: "relative",
              fontWeight: "500",
              "&::before": {
                content: "''",
                position: "absolute",
                height: "1rem",
                width: ".2rem",
                insetBlockStart: ".18rem",
                insetInlineStart: "-.60rem",
                background:
                  "linear-gradient(to bottom, #c1acef 50%, #90daf2 50%)",
                borderRadius: ".5rem",
              },
            }}
          >
            Expense Summary
          </Typography>
          {openFilter ? (
            <Button
              sx={{
                padding: "3.18px 10px",
              }}
              variant="text"
              onClick={() => setOpenFilter((pre) => !pre)}
            >
              Hide
            </Button>
          ) : (
            <Button
              sx={{
                padding: "3.18px 10px",
              }}
              variant="text"
              onClick={() => setOpenFilter((pre) => !pre)}
            >
              Filter by
            </Button>
          )}
        </Box>
        {openFilter && (
          <Box sx={{ bgcolor: "#f4f4f480", p: "10px" }}>
            <Stack direction="row" spacing={1}>
              {filterOptions?.map((o) => (
                <Chip
                  label={o.label}
                  color="primary"
                  variant={
                    filters?.expense_pie_chart_for === o.id
                      ? "filled"
                      : "outlined"
                  }
                  onClick={() => {
                    setFilters({
                      ...filters,
                      filterEntry: ["expense_summery"],
                      expense_pie_chart_for: o.id,
                    });
                  }}
                />
              ))}
            </Stack>
          </Box>
        )}
      </Box>
      <Box sx={{ height: "450px", pb: "20px" }}>
        {loading && filters.filterEntry.includes("expense_summery") ? (
          <Box
            sx={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ReactLoading
              type="cylon"
              color="#405189"
              height={"85px"}
              width={"100px"}
            />
          </Box>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                style={{ outline: "none" }}
                dataKey="value"
                isAnimationActive={false}
                data={chartData}
                cx="50%"
                cy="50%"
                outerRadius={80}
                innerRadius={60}
                fill="#8884d8"
                label
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        )}
      </Box>
    </Box>
  );
};

export default ExpenseSummary;
