import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import React, { useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import MainTableRow from "./MainTableRow";
import Note from "components/common/note";

const ExpandableRow = ({ context, item: row, ...restProps }) => {
  return <MainTableRow {...restProps} {...context} key={row.id} row={row} />;
};

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: ExpandableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const MainTable = ({
  setAllInvoiceBank,
  allInvoiceBank,
  handleDelete,
  setReload,
  pagination,
  setPagination,
  loading,
  permissions,
  bankInvoiceStatuses,
}) => {
  const [openNote, setOpenNote] = useState("");

  const rows = allInvoiceBank;
  const columns = [
    {
      id: "applicant_name",
      label: "Applicant name",
      style: { minWidth: "180px" },
    },
    { id: "account_name", label: "Account Name", style: { minWidth: "180px" } },
    { id: "bank_name", label: "Bank Name" },
    { id: "bank_deposit", label: "Bank Deposit" },
    {
      id: "followup_date",
      label: "Followup Date",
      style: { minWidth: "120px" },
    },
    { id: "last_note", label: "Last Note", style: { minWidth: "190px" } },
    { id: "loan_amount", label: "Loan Amount" },
    { id: "interest_amount", label: "Interest Rate" },
    { id: "total_paid", label: "Total Paid" },
    { id: "expense", label: "Expense Amount" },
    { id: "total_due", label: "Due Amount" },
    { id: "total_charge", label: "Total Charge" },
    { id: "reference_name", label: "Reference" },
    { id: "bank_invoice_status_id", label: "Status" },
    {
      id: "action",
      label: "Actions",
      style: { width: "170px" },
      align: "center",
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer sx={{ overflowX: "auto" }}>
          <TableVirtuoso
            style={{ height: "calc(100vh - 198px)" }}
            data={rows}
            components={TableComponents}
            context={{
              columns,
              handleDelete,
              setReload,
              allInvoiceBank,
              setAllInvoiceBank,
              permissions,
              bankInvoiceStatuses,
              openNote,
              setOpenNote,
            }}
            fixedHeaderContent={() => (
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 20px",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    sx={{ bgcolor: "#fff" }}
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          />
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 198px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
      {openNote && (
        <Note
          open={openNote}
          handleClose={() => setOpenNote(false)}
          id={openNote}
          related_id={openNote}
          related_to="bank_invoice"
          table_note_key="bank_invoice_notes"
          route="bank-invoice"
          allData={allInvoiceBank}
          setAllData={setAllInvoiceBank}
        />
      )}
    </Paper>
  );
};

export default MainTable;
