import { Box, Button } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { viewAllExpense } from "features/expense/api/expense";
import useApiCall from "hooks/useApiCall";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import AddExpense from "./AddExpense";
import ExpenseTable from "./ExpenseTable";

const Expense = ({ customer_id, project_id, reference_id }) => {
  const [addExpense, setAddExpense] = useState(false);
  const [currExpense, setCurrExpense] = useState({});
  const [allExpense, setAllExpense] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  const debouncedText = useDebounce(searchText, 1000);

  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    let finalPagination = { ...pagination };
    fetchListData(
      viewAllExpense,
      {
        body_data: { customer_id: [customer_id], project_id: [project_id] },
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}&search=${debouncedText}`,
      },
      false
    );
  }, [reload, pagination?.current_page, pagination?.per_page, debouncedText]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllExpense(allData);
      setPagination(rest);
    }
  }, [data]);

  return addExpense ? (
    <AddExpense
      setAddExpense={setAddExpense}
      currExpense={currExpense}
      setAllExpense={setAllExpense}
      allExpense={allExpense}
      customer_id={customer_id}
      project_id={project_id}
      reference_id={reference_id}
    />
  ) : (
    <Box sx={{ m: "-20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "10px",
        }}
      >
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in Expense.."}
        />
        <Button
          startIcon={<BiPlus />}
          variant="outlined"
          onClick={() => {
            setCurrExpense({});
            setAddExpense(true);
          }}
        >
          New Expense
        </Button>
      </Box>
      <ExpenseTable
        loading={loading}
        allExpense={allExpense}
        setReload={setReload}
        setAddExpense={setAddExpense}
        setCurrExpense={setCurrExpense}
        setAllExpense={setAllExpense}
        reload={reload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Box>
  );
};

export default Expense;
