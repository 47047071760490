import { Delete, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import UNIVERSAL from "config";
import moment from "moment";
import { TbListDetails } from "react-icons/tb";
import { updateKnowledgeStatus } from "../../api/knowledge";
import useQueries from "hooks/useQueries";

export function removeTagsAndWhitespace(html) {
  var cleanr = /<\/?[^>]+>|\s+/gi;
  var plainText = html.replace(cleanr, " ");
  return plainText.trim();
}

export const postStatus = [
  {
    id: 1,
    label: "Active",
    color: "#0db30d",
  },
  {
    id: 0,
    label: "Inactive",
    color: "#ff564a",
  },
];

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  setAllPost,
  allPost,
  handleTableCheckBox,
  selectedRows,
}) => {
  const { setQueries } = useQueries();
  const handleChangeStatus = async (value) => {
    await updateKnowledgeStatus({
      body_data: { id: row?.id, status: value },
    });
    setAllPost(
      allPost.map((post) =>
        post.id === row.id ? { ...row, status: value } : post
      )
    );
  };
  return (
    <TableRow
      hover
      role="checkbox"
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 16px",
        },
        "& .MuiTableCell-sizeMedium:first-of-type": {
          paddingLeft: "8px",
          paddingRight: "0px",
        },
      }}
      key={row.id}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRows?.includes(row.id)}
          onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
        />
      </TableCell>
      {columns.map((column, i) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.id === "description" ? (
              <Typography
                className={"clamped-text"}
                variant="body2"
                sx={{ width: "150px" }}
                dangerouslySetInnerHTML={{
                  __html: removeTagsAndWhitespace(value),
                }}
              ></Typography>
            ) : column.id === "status" ? (
              <TextField
                size="small"
                value={value}
                select
                sx={{
                  width: "100px",
                  color: postStatus?.find(
                    (singleStatus) => singleStatus?.id === value
                  )?.color,
                  textAlign: "left",
                  "& > div:before": { content: "none" },
                  "& div": {
                    color: postStatus?.find(
                      (singleStatus) => singleStatus?.id === value
                    )?.color,
                  },
                }}
                variant="standard"
                onChange={(e) => handleChangeStatus(e.target.value)}
              >
                {postStatus?.length > 0 &&
                  postStatus?.map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {status?.label}
                    </MenuItem>
                  ))}
              </TextField>
            ) : column.id === "created_at" ? (
              <span>{value ? moment(value).format("ll") : "-"}</span>
            ) : column.id === "created_by_image" ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Tooltip title={row?.created_by_name} placement="right">
                  <Avatar
                    sx={{ width: 28, height: 28, fontSize: 12 }}
                    alt={row?.created_by_name}
                    src={UNIVERSAL.BASEURL + row?.created_by_image}
                  />
                </Tooltip>
              </Box>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Details">
                  <IconButton onClick={() => setQueries(`id=${row?.id}&m=1`)}>
                    <TbListDetails />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton onClick={() => setQueries(`id=${row?.id}&m=0`)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(row.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
