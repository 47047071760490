import SetupCC from "components/setupCC";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useSelector } from "react-redux";
import * as yup from "yup";

const Projects = () => {
  const base_data = useSelector((state) => state?.base?.base_data);
  const projectStatuses = base_data?.projectStatuses || [];

  const initialValues = {
    name: "",
    project_status_id: "",
    start_date: null,
    end_date: null,
  };
  return (
    <SetupCC
      name="Project"
      baseRouteKey="projects"
      moduleKey="project"
      columns={[
        { id: "name", label: "Project Name", minWidth: 100 },
        { id: "embassy_fields", label: "Embassy's", minWidth: 100 },
        { id: "project_status", label: "Status", minWidth: 100 },
        { id: "start_date", label: "Start Date", minWidth: 100 },
        { id: "end_date", label: "End Date", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "180px" },
          align: "center",
        },
      ]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "embassy_fields"
              ? row["embassy_fields"]?.map((item) => item.field_name).join(", ")
              : value}
          </>
        );
      }}
      actions={["duplicate", "edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
        project_status_id: yup.string().required("Status is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Project Name" formik={formik} />
          <Select
            options={projectStatuses}
            label="Project Status"
            value={formik?.values?.project_status_id}
            onChange={(value) =>
              formik.setFieldValue("project_status_id", value)
            }
            selectProps={{ id: "id", label: "name" }}
          />
          <DateTimePicker
            name="start_date"
            label="Start Date"
            formik={formik}
          />
          <DateTimePicker name="end_date" label="End Date" formik={formik} />
        </>
      )}
    ></SetupCC>
  );
};

export default Projects;
