import { useSearchParams } from "react-router-dom";

const useQueries = () => {
  const [queries, setQueries] = useSearchParams();
  const row_id = queries.get("id");
  const mod_id = queries.get("m");
  const openAddEdit = mod_id === "0";
  const openView = !!Number(mod_id);

  return {
    queries,
    setQueries,
    row_id: Number(row_id),
    mod_id,
    openAddEdit,
    openView,
  };
};

export default useQueries;
