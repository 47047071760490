import Axios from "lib/Axios";

export const restoreCustomer = ({ id }) =>
  Axios.get(`/api/admin/customer/restore/${id}`).then((res) => res.data);


export const deleteCustomer = ({ id }) =>
  Axios.delete(`/api/admin/customer/delete/${id}`).then((res) => res.data);


export const viewAllCustomer = ({ body_data, params }) =>
  Axios.post(`/api/admin/customer/trash/list?${params}`, body_data).then(
    (res) => res.data
  );


export const customerBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/customer/bulk/action", body_data).then((res) => res.data);
