import { Box, Button } from "@mui/material";
import DateRangeField from "components/ui/dateRangeField";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import getInitialvalues from "features/payments/utils/filterInitialValues";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";

const MainScreenFilter = ({
  setReload,
  setFilters,
  setPagination,
  pagination,
  filters,
  isFiltering,
  invoices,
}) => {
  const [reset, setReset] = useState(false);
  const initialValues = getInitialvalues(filters);

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    setFilters({ ...filters, ...data });
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const resetForm = () => {
    const emptyFilter = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = null;
      }
    }
    formik.setValues(emptyFilter);
    setFilters({});
    setReload((pre) => !pre);
    setReset((pre) => !pre);
  };

  return (
    <Box
      p="15px 20px 5px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gap: "18px",
      }}
    >
      <Select
        options={invoices}
        label="Invoice"
        name="invoice_id"
        formik={formik}
        selectProps={{ id: "id", label: "name" }}
      />
      <DateRangeField
        label="Created Date"
        from="created_at_from"
        to="created_at_to"
        formik={formik}
        key={`${reset}`}
      />
      <DateRangeField
        label="Payment Date"
        from="payment_date_from"
        to="payment_date_to"
        formik={formik}
        key={`${reset}`}
      />
      <Box
        sx={{ display: "grid", gap: "10px", gridTemplateColumns: "1fr 1fr" }}
      >
        <InputField formik={formik} name="amount_from" label="Amount From" />
        <InputField formik={formik} name="amount_to" label="Amount To" />
      </Box>
      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          disabled={!formik.dirty}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          disabled={!isFiltering()}
          onClick={resetForm}
        >
          Reset Filter
        </Button>
      </Box>
    </Box>
  );
};

export default MainScreenFilter;
