import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import * as yup from "yup";

const Designations = () => {
  const [departments, setDepartments] = useState([]);
  const { fetchData } = useApi();

  useEffect(() => {
    (async () => {
      const { success, data } = await fetchData(
        {
          method: "get",
          url: "/api/admin/base/department",
        },
        false
      );
      if (success) setDepartments(data);
    })();
  }, []);

  const initialValues = {
    name: "",
    department_id: "",
  };
  return (
    <SetupCC
      name="Designation"
      baseRouteKey="designations"
      moduleKey="designation"
      columns={[
        { id: "name", label: "Designation Name", minWidth: 100 },
        { id: "department_name", label: "Department", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
        department_id: yup.string().required("Department Name required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Designation Name" formik={formik} />
          <Select
            options={departments}
            label="Select Department *"
            value={formik?.values?.department_id}
            onChange={(value) => formik.setFieldValue("department_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />
        </>
      )}
    ></SetupCC>
  );
};

export default Designations;
