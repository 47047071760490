import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import useBase from "hooks/useBase";
import * as yup from "yup";

const Subjects = () => {
  const {
    base: { faculties },
  } = useBase();
  const initialValues = {
    faculty_id: "",
    name: "",
  };

  return (
    <SetupCC
      name="Subject"
      baseRouteKey="subjects"
      moduleKey="subject"
      columns={[
        { id: "name", label: "Subject Name", minWidth: 150 },
        { id: "faculty_name", label: "Faculty", minWidth: 150 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <>
          <Select
            label="Select Faculty"
            name="faculty_id"
            options={faculties}
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
          <InputField name="name" label="Subject Name" formik={formik} />
        </>
      )}
    ></SetupCC>
  );
};

export default Subjects;
