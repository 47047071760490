import { Delete, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";
import NoteSkeleton from "components/skeletons/NoteSkeleton";
import InputField from "components/ui/inputField";
import LoadingBtn from "components/ui/loadingBtn";
import UNIVERSAL from "config";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import usePermissions from "hooks/usePermissions";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const Note = ({
  id,
  related_to,
  related_id,
  route,
  allData,
  setAllData,
  open,
  handleClose,
  isInvoiceNote,
}) => {
  const isLead = route === "lead";
  const curr_item = allData?.find((item) => item?.id === id);
  const { loading: listLoading, fetchData: fetchList } = useApi();
  const { fetchData: fetchDelete } = useApi();
  const { loading, fetchData } = useApi();
  const [notes, setNotes] = useState([]);
  const [editNote, setEditNote] = useState({});
  const permissions = usePermissions("note");

  useEffect(() => {
    const getNotes = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/${route}/note/${related_id}?limit=500`,
      };
      const result = await fetchList(endpoint, false);
      if (result.success) {
        const data = result?.data?.data;
        setNotes(data || []);
      }
    };
    getNotes();
  }, []);

  // Add Note
  const initialValues = {
    ...(isLead && { contact_status: 0 }),
    note: "",
    related_id: related_id,
    related_to,
  };
  const onSubmit = async (data) => {
    formik.resetForm();
    const endpoint = {
      method: "post",
      url: editNote?.id
        ? `/api/admin/note/update/${editNote?.id}`
        : "/api/admin/note/create",
      data,
    };
    try {
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const updatedNotes = editNote?.id
          ? notes.map((note) =>
              note?.id === result.data.id ? result.data : note
            )
          : [result.data, ...notes];
        setNotes(updatedNotes);
        allData &&
          setAllData(
            allData?.map((singleData) =>
              singleData?.id === id
                ? { ...singleData, last_note: updatedNotes?.[0]?.note }
                : singleData
            )
          );
        toast.success(editNote?.id ? "Note Updated!" : "Note added!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
    setEditNote({});
  };

  const handleEditNote = async (note) => {
    if (note.id !== editNote.id) {
      formik.setFieldValue("note", note.note);
      isLead && formik.setFieldValue("contact_status", note.contact_status);
      setEditNote(note);
    } else {
      formik.resetForm();
      setEditNote({});
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  // Delete Note
  const handleDeleteNote = async (note_id) => {
    const updatedNotes = notes.filter((note) => note.id !== note_id);
    setNotes(updatedNotes);
    allData &&
      setAllData(
        allData?.map((singleData) =>
          singleData?.id === id
            ? { ...singleData, last_note: updatedNotes?.[0]?.note }
            : singleData
        )
      );
    const endpoint = {
      method: "delete",
      url: `/api/admin/note/delete/${note_id}`,
    };
    await fetchDelete(endpoint, false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      formik.handleSubmit();
    }
  };

  return (
    <>
      {open ? (
        <Dialog open={open} onClose={handleClose} maxWidth="md">
          <Box>
            <Box
              sx={{
                width: "580px",
                margin: "0 auto",
              }}
            >
              <Box>
                <form onSubmit={formik.handleSubmit}>
                  <Box sx={{ padding: "15px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5">
                        {curr_item?.invoice_number ||
                          curr_item?.name ||
                          curr_item?.applicant_name ||
                          curr_item?.customer_name}
                      </Typography>
                      <Typography
                        sx={{ borderLeft: "1px solid #888", pl: "10px" }}
                      >
                        {curr_item?.phone || curr_item?.customer_phone}
                      </Typography>
                    </Box>
                    {isLead && (
                      <FormControlLabel
                        control={<Checkbox />}
                        label="Contact Status"
                        checked={
                          Number(formik?.values?.contact_status) ? true : false
                        }
                        onChange={(e) => {
                          formik.setFieldValue(
                            "contact_status",
                            e.target.checked ? 1 : 0
                          );
                        }}
                      />
                    )}
                    <InputField
                      rows={3}
                      multiline
                      sx={{ margin: "15px 0" }}
                      fullWidth
                      name="note"
                      label="Note"
                      formik={formik}
                      onKeyDown={handleKeyDown}
                      autoFocus
                    />

                    {loading ? (
                      <LoadingBtn />
                    ) : (
                      <Button
                        type="submit"
                        fullWidth
                        variant="solid"
                        disabled={!(formik.isValid && formik.dirty) || loading}
                      >
                        {editNote?.id ? "Update Note" : "Add Note"}
                      </Button>
                    )}
                  </Box>
                </form>
                {!listLoading ? (
                  <Box
                    sx={{
                      height: "250px",
                      padding: "0 15px 15px",
                      overflowY: "auto",
                      "& > div:first-of-type": {
                        bgcolor: "#F2A55D1A",
                        borderLeft: "4px solid #FF9E2C",
                        borderRadius: "5px",
                      },
                    }}
                  >
                    {notes.map((note) => (
                      <Box
                        key={note.id}
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "50px auto 30px",
                          padding: "10px",
                          borderTop: "1px dashed #ddd",
                        }}
                      >
                        <Box>
                          <Avatar
                            alt={note?.created_by_name}
                            src={UNIVERSAL.BASEURL + note?.created_by_image}
                          />
                        </Box>
                        <Box>
                          <Typography variant="h6">
                            {note?.created_by_name}{" "}
                            <span
                              style={{
                                color: "#00d640",
                                fontSize: "12px",
                              }}
                            >
                              {note?.contact_status &&
                              note?.contact_status === 1
                                ? `(Contacted)`
                                : ""}
                            </span>
                          </Typography>
                          <Box sx={{ fontSize: "12px" }}>
                            {moment(note?.created_at).format("lll")}
                          </Box>

                          <Box
                            sx={{
                              fontSize: "14px",
                              paddingTop: "10px",
                              whiteSpace: "pre-line",
                            }}
                          >
                            {note?.note}
                          </Box>
                        </Box>
                        <Box>
                          <IconButton
                            size="small"
                            disabled={!permissions?.update}
                            onClick={() => handleEditNote(note)}
                          >
                            <Edit />
                          </IconButton>
                          <IconButton
                            size="small"
                            disabled={!permissions?.delete || loading}
                            onClick={() => handleDeleteNote(note?.id)}
                          >
                            <Delete />
                          </IconButton>
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <NoteSkeleton />
                )}
              </Box>
            </Box>
          </Box>
        </Dialog>
      ) : (
        <Box>
          <Box>
            <form onSubmit={formik.handleSubmit}>
              <InputField
                rows={3}
                multiline
                sx={{ mb: "15px" }}
                fullWidth
                name="note"
                label="Note"
                formik={formik}
                onKeyDown={handleKeyDown}
                autoFocus
              />
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "auto 200px",
                  justifyContent: "space-between",
                  gap: "15px",
                  marginBottom: "15px",
                }}
              >
                <Box></Box>
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    variant="solid"
                    disabled={!(formik.isValid && formik.dirty) || loading}
                  >
                    {editNote?.id ? "Update Note" : "Add Note"}
                  </Button>
                )}
              </Box>
            </form>
          </Box>
          {!listLoading ? (
            <Box
              sx={{
                maxHeight: isInvoiceNote
                  ? "calc(100vh - 350px)"
                  : "calc(100vh - 314px)",
                overflowY: "auto",
                "& > div:first-of-type": {
                  bgcolor: "#F2A55D1A",
                  borderLeft: "4px solid #FF9E2C",
                  borderRadius: "5px",
                },
              }}
            >
              {notes?.length > 0 &&
                notes.map((note) => (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "50px auto 30px",
                      padding: "15px 20px 15px 15px",
                      borderTop: "1px dashed #ddd",
                    }}
                  >
                    <Box>
                      <Avatar
                        alt={note?.created_by_name}
                        src={UNIVERSAL.BASEURL + note?.created_by_image}
                      />
                    </Box>
                    <Box>
                      <Typography variant="h6">
                        {note?.created_by_name}
                      </Typography>
                      <Box sx={{ fontSize: "12px" }}>
                        {moment(note?.created_at).format("lll")}
                      </Box>
                      <Box
                        sx={{
                          fontSize: "14px",
                          paddingTop: "10px",
                          whiteSpace: "pre-line",
                        }}
                      >
                        {note?.note}
                      </Box>
                    </Box>
                    <Box>
                      <IconButton
                        disabled={!permissions?.update}
                        onClick={() => handleEditNote(note)}
                      >
                        <Edit />
                      </IconButton>
                      <IconButton
                        disabled={!permissions?.delete || loading}
                        onClick={() => handleDeleteNote(note?.id)}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Box>
                ))}
            </Box>
          ) : (
            <NoteSkeleton />
          )}
        </Box>
      )}
    </>
  );
};

export default Note;
