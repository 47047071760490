import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PopoverAG from "components/ui/popoverAG";
import useBase from "hooks/useBase";
import moment from "moment";
import React from "react";
import { MdDelete, MdRestore } from "react-icons/md";

const LeadsTableRow = ({
  row,
  columns,
  handleDelete,
  handleTableCheckBox,
  selectedRows,
  handleRestore,
  permissions,
}) => {
  const { base } = useBase();
  const { leadStatuses } = base;

  return (
    <TableRow
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 16px",
        },
        "& .MuiTableCell-sizeMedium:first-of-type": {
          paddingLeft: "8px",
          paddingRight: "0px",
        },
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          key={row.id}
          disabled={!permissions["bulk action"]}
          checked={selectedRows?.includes(row.id)}
          onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
        />
      </TableCell>
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.id === "lead_status" ? (
              <Box
                sx={{
                  bgcolor: `${value?.color}15`,
                  color: value?.color,
                  textAlign: "center",
                  padding: "3px 5px",
                  borderRadius: "20px",
                }}
              >
                {leadStatuses?.find((status) => status?.id === value?.id)?.name}
              </Box>
            ) : column.id === "last_note" ? (
              <Typography
                sx={{
                  overflow: "hidden",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  textOverflow: "ellipsis",
                  maxWidth: "150px",
                  maxHeight: "22px",
                  whiteSpace: "nowrap",
                }}
              >
                {value || "-"}
              </Typography>
            ) : column.id === "followup_date" ? (
              value ? (
                moment(value).format("ll")
              ) : (
                "-"
              )
            ) : column.id === "lead_assignees" ? (
              <PopoverAG items={value} />
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Resotre">
                  <IconButton
                    disabled={!permissions.restore}
                    onClick={() => handleRestore(row.id)}
                  >
                    <MdRestore />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete permanently">
                  <IconButton
                    disabled={!permissions.delete}
                    onClick={() => handleDelete(row.id)}
                  >
                    <MdDelete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default LeadsTableRow;
