/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "@mui/material";
import Box from "@mui/material/Box";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import handleError from "utils/handleError";
import AddSetupCC from "./AddSetupCC";
import SetupCCHeader from "./SetupCCHeader";
import SetupCCTable from "./SetupCCTable";

const SetupCC = (props) => {
  // Props
  const { updateBase } = useBase();
  const baseRouteKey = props?.baseRouteKey;
  const moduleKey = props?.moduleKey;
  const name = props?.name;
  const columns = props?.columns;
  const conditionalColumns = props?.conditionalColumns;
  const actions = props?.actions;
  const getInitialValues = props?.getInitialValues;
  const validationSchema = props?.validationSchema;
  const allFields = props?.allFields;
  const sidebarWidth = props?.sidebarWidth;
  const shouldReload = props?.shouldReload;
  // Props
  const permissions = usePermissions(moduleKey);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [viewSidebar, setViewSidebar] = useState(false);
  const [allData, setAllData] = useState([]);
  const [currData, setCurrData] = useState({});
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (openSidebar === false) {
      setCurrData({});
    }
  }, [openSidebar]);

  const { loading, fetchData } = useApi();
  const { fetchData: duplicateApi } = useApi();
  const { fetchData: deleteData } = useApi();

  useEffect(() => {
    (async () => {
      const params = `page=${pagination?.current_page}&limit=${pagination?.per_page}&search=${search}`;
      const endpoint = {
        method: "get",
        url: `/api/admin/${moduleKey}/list?${params}`,
      };
      const result = await fetchData(endpoint, false);
      const { success, data } = result;
      if (success) {
        const { data: allData, ...rest } = data;
        setAllData(allData);
        setPagination(rest);
      } else {
        const errors = handleError(result);
        errors.map((err) => toast.error(err));
      }
    })();
  }, [reload, pagination?.current_page, pagination?.per_page, search]);

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    const endpoint = {
      method: "delete",
      url: `/api/admin/${moduleKey}/delete/${openAlert}`,
    };
    const result = await deleteData(endpoint);
    if (result.success) {
      setAllData(allData.filter((singleData) => singleData.id !== openAlert));
      updateBase([baseRouteKey]);
      setReload((pre) => !pre);
    } else {
      toast.error(result?.message);
    }
  };

  const handleDuplicate = async (value) => {
    const endpoint = {
      method: "get",
      url: `/api/admin/project/duplicate/${value.id}`,
    };
    const result = await duplicateApi(endpoint, false);
    if (result.success) {
      setReload((pre) => !pre);
      updateBase([baseRouteKey]);
    }
  };

  const handleEdit = (value) => {
    setCurrData(value);
    setOpenSidebar(true);
  };

  const handleView = (value) => {
    setCurrData(value);
    setViewSidebar(true);
  };

  const all_props = {
    permissions,
    baseRouteKey,
    moduleKey,
    name,
    columns,
    conditionalColumns,
    actions,
    getInitialValues,
    validationSchema,
    allFields,
    openSidebar,
    setOpenSidebar,
    search,
    setSearch,
    allData,
    setAllData,
    handleDelete,
    handleDuplicate,
    handleEdit,
    handleView,
    currData,
    setCurrData,
    setReload,
    sidebarWidth,
    pagination,
    setPagination,
    loading,
    shouldReload,
  };

  return (
    <Box>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title={`Delete ${name}`}
          desc={
            <Box>
              Are you sure you want to delete this {name}? This will delete the{" "}
              {name} <strong>permanently</strong>!
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <SetupCCHeader {...all_props} />
      {permissions?.list ? <SetupCCTable {...all_props} /> : <NotAuthorized />}
      {openSidebar && <AddSetupCC {...all_props} />}
    </Box>
  );
};

export default SetupCC;
