import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import useBase from "hooks/useBase";
import * as yup from "yup";

const UniversityTargets = () => {
  const {
    base: { projects, universities },
  } = useBase();
  const initialValues = {
    project_id: "",
    university_id: "",
    target_student: "",
    // bonus_type: "",
    // bonus_amount: "",
    // total_bonus: "",
  };

  return (
    <SetupCC
      name="University Target"
      baseRouteKey=""
      moduleKey="university-target"
      columns={[
        { id: "project_name", label: "Project Name", minWidth: 100 },
        { id: "university_name", label: "University Name", minWidth: 100 },
        { id: "target_student", label: "Target Students", minWidth: 100 },
        // { id: "bonus_type", label: "Bonus Type", minWidth: 100 },
        // { id: "bonus_amount", label: "Bonus Amount", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        project_id: yup.string().required("Project is required!"),
        university_id: yup.string().required("University is required!"),
        target_student: yup.string().required("Target students is required!"),
        // bonus_type: yup.string().required("Bonus Type is required!"),
        // bonus_amount: yup.string().required("Bonus Amount is required!"),
        // total_bonus: yup.string().required("Total Bonus is required!"),
      })}
      allFields={(formik) => (
        <>
          <Select
            label="Select Project *"
            name="project_id"
            options={projects}
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
          <Select
            label="Select University *"
            name="university_id"
            options={universities}
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
          <InputField
            name="target_student"
            label="Target Students *"
            type="number"
            formik={formik}
          />
          {/* <Select
            label="Bonus Type"
            name="bonus_type"
            options={[
              { id: "flat", label: "Flat" },
              { id: "percentage", label: "Percentage" },
            ]}
            formik={formik}
          />
          <InputField
            name="bonus_amount"
            label="Bonus Amount"
            type="number"
            formik={formik}
          />
          <InputField
            name="total_bonus"
            label="Total Bonus"
            type="number"
            formik={formik}
          /> */}
        </>
      )}
    ></SetupCC>
  );
};

export default UniversityTargets;
