import Axios from "lib/Axios";

export const viewAllInvoice = ({ body_data, params }) =>
  Axios.post(`/api/admin/invoice/list?${params}`, body_data).then(
    (res) => res.data
  );

export const updateDueDate = ({ id, body_data }) =>
  Axios.post(`/api/admin/invoice/change/due-date/${id}`, body_data).then(
    (res) => res.data
  );

export const deleteInvoice = ({ id }) =>
  Axios.delete(`/api/admin/invoice/trash/${id}`).then((res) => res.data);

export const getProjects = (id) =>
  Axios.get(`/api/admin/base/project?customer_id=${id}`).then(
    (res) => res.data
  );

export const exportInvoice = ({ body_data }) =>
  Axios.post("/api/admin/invoice/export", body_data).then((res) => res.data);

export const importSample = () =>
  Axios.get("/api/admin/lead/sample?type=xlsx").then((res) => res.data);

export const restoreLead = ({ id }) =>
  Axios.get(`/api/admin/lead/restore/${id}`).then((res) => res.data);

export const viewLead = ({ id }) =>
  Axios.get(`/api/admin/lead/show/${id}`).then((res) => res.data);

export const allLeadUtils = () =>
  Axios.get("/api/admin/base/lead-utils").then((res) => res.data);

export const updateInvoiceStatus = ({ body_data, id }) =>
  Axios.post(`/api/admin/invoice/change/status/${id}`, body_data).then(
    (res) => res.data
  );

export const viewNote = ({ id }) =>
  Axios.get(`/api/admin/invoice/note/${id}`).then((res) => res.data);

export const createNote = ({ body_data }) =>
  Axios.post("/api/admin/note/create", body_data).then((res) => res.data);

export const deleteNote = ({ id }) =>
  Axios.delete(`/api/admin/note/delete/${id}`).then((res) => res.data);

export const viewAttachment = ({ id }) =>
  Axios.get(`api/admin/invoice/attachment/${id}`).then((res) => res.data);

export const uploadAttachment = ({ body_data, id, setProgress }) =>
  Axios.post(`/api/admin/invoice/attachment/upload/${id}`, body_data, {
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      setProgress(percentComplete);
    },
  }).then((res) => res.data);

export const deleteAttachment = ({ id }) =>
  Axios.delete(`/api/admin/attachment/delete/${id}`).then((res) => res.data);

export const viewActivity = ({ id }) =>
  Axios.get(`/api/admin/invoice/activity/${id}`).then((res) => res.data);

export const viewSummary = ({ body_data }) =>
  Axios.post("/api/admin/invoice/summery", body_data).then((res) => res.data);

export const importLeads = ({ body_data }) =>
  Axios.post("/api/admin/customer/import", body_data).then((res) => res.data);

export const invoiceBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/invoice/bulk/action", body_data).then(
    (res) => res.data
  );

export const viewTasks = ({ id, params }) =>
  Axios.post(`/api/admin/invoice/task/${id}?${params}`).then((res) => res.data);

export const addTask = ({ body_data }) =>
  Axios.post("/api/admin/task/create", body_data).then((res) => res.data);

export const updateTask = ({ id, body_data }) =>
  Axios.post(`/api/admin/task/update/${id}`, body_data).then((res) => res.data);

export const deleteTask = ({ id }) =>
  Axios.delete(`/api/admin/task/delete/${id}`).then((res) => res.data);

export const updateFollowupDate = ({ body_data }) =>
  Axios.post(`/api/admin/customer/followupdate`, body_data).then(
    (res) => res.data
  );

export const billingShippingAddress = ({ id, body_data }) =>
  Axios.post(`/api/admin/customer/billing-shipping/${id}`, body_data).then(
    (res) => res.data
  );

export const viewAddress = ({ id }) =>
  Axios.get(`/api/admin/base/customer-billing-shipping-address/${id}`).then(
    (res) => res.data
  );

export const viewAllPayment = ({ body_data, params }) =>
  Axios.post(`/api/admin/payment/list?${params}`, body_data).then(
    (res) => res.data
  );

export const createPayment = ({ body_data }) =>
  Axios.post("/api/admin/payment/create", body_data).then((res) => res.data);

export const updatePayment = ({ body_data, id }) =>
  Axios.post(`/api/admin/payment/update/${id}`, body_data).then(
    (res) => res.data
  );

export const deletePayment = ({ id }) =>
  Axios.delete(`/api/admin/payment/delete/${id}`).then((res) => res.data);

export const projectsList = ({ id }) =>
  Axios.get(`/api/admin/base/project?customer_id=${id}`).then(
    (res) => res.data
  );

const endpoints = {
  createInvoice: (data) => ({
    method: "post",
    url: "/api/admin/invoice/create",
    data,
  }),
  updateInvoice: (id, data) => ({
    method: "put",
    url: `/api/admin/invoice/update/${id}`,
    data,
  }),
};

export const { createInvoice, updateInvoice } = endpoints;
