import moment from "moment";
import useBase from "./useBase";

const useFormatter = () => {
  const { base } = useBase();
  const date_format = base?.settings?.app?.date_format;

  const formatDate = (date) => {
    const checkFormat = (date) => {
      if (date_format === "DD/MM/YYYY")
        return moment(date).format("DD/MM/YYYY");
      else if (date_format === "YYYY/MM/DD")
        return moment(date).format("YYYY/MM/DD");
      return moment(date).format("ll");
    };

    return date ? checkFormat(date) : "-";
  };

  const formatNumber = (number) => {
    const formatter = new Intl.NumberFormat("en-US", {
      notation: "compact",
      compactDisplay: "short",
    });
    return formatter.format(number || 0);
  };

  const formatCurrency = (number) => {
    const formatter = new Intl.NumberFormat("en-BD", {
      style: "currency",
      currency: "BDT",
      currencyDisplay: "narrowSymbol",
    });
    return formatter.format(number || 0);
  };

  return { formatDate, formatNumber, formatCurrency };
};

export default useFormatter;
