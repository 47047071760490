import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const KnowledgeBaseTable = ({
  allKnowLedgeBase,
  loading,
  pagination,
  setPagination,
  setReload,
}) => {
  const navigate = useNavigate();
  const rows = allKnowLedgeBase;
  const columns = [
    { id: "name", label: "Title" },

    {
      id: "knowledge_base_category_name",
      label: "Category",
      style: { width: "190px" },
    },

    { id: "created_at", label: "Created At", style: { width: "130px" } },
  ];

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 200px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, ...column.style }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <>
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          onClick={() => {
                            navigate(`${row.id}`);
                          }}
                          sx={{ cursor: "pointer" }}
                        >
                          {column.id === "created_at"
                            ? moment(value).format("ll")
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 200px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default KnowledgeBaseTable;
