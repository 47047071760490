import { Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import InputField from "components/ui/inputField";
import UNIVERSAL from "config";
import { useFormik } from "formik";
import useBase from "hooks/useBase";
import { useState } from "react";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { toast } from "react-toastify";
import * as yup from "yup";
import { updateGeneralSettings } from "../api/settings";

const General = ({ general }) => {
  const { updateBase } = useBase();
  const [files, setFiles] = useState({
    site_logo: {
      url: general?.site_logo ? UNIVERSAL.BASEURL + general?.site_logo : "",
      file: null,
    },
    site_favicon: {
      url: general?.site_favicon
        ? UNIVERSAL.BASEURL + general?.site_favicon
        : "",
      file: null,
    },
    site_footer_logo: {
      url: general?.site_footer_logo
        ? UNIVERSAL.BASEURL + general?.site_footer_logo
        : "",
      file: null,
    },
  });

  const initialValues = {
    name: general?.name || "",
    phone: general?.phone || "",
    site_url: general?.site_url || "",
    site_address: general?.site_address || "",
    email_address: general?.email_address || "",
    facebook: general?.facebook || "",
    twitter: general?.twitter || "",
    instagram: general?.instagram || "",
    linkedin: general?.linkedin || "",
  };

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    if (files?.site_logo?.file)
      formData.append("site_logo", files?.site_logo?.file);

    if (files?.site_favicon?.file)
      formData.append("site_favicon", files?.site_favicon?.file);

    if (files?.site_footer_logo?.file)
      formData.append("site_footer_logo", files?.site_footer_logo?.file);

    const result = await updateGeneralSettings({ body_data: formData });
    if (result.success) {
      toast.success(result.message);
      updateBase(["settings"]);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleAddImage = (e, type) => {
    const data = e.target.files[0];
    let objectURL = URL.createObjectURL(data);
    if (type === "site_logo") {
      setFiles({ ...files, site_logo: { url: objectURL, file: data } });
    } else if (type === "site_favicon") {
      setFiles({ ...files, site_favicon: { url: objectURL, file: data } });
    } else {
      setFiles({ ...files, site_footer_logo: { url: objectURL, file: data } });
    }
  };

  return (
    <Box
      sx={{
        width: "650px",
        margin: "0 auto",
        border: "1px solid #efefef",
        borderRadius: "5px",
        padding: "15px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography
          sx={{ color: (theme) => theme.palette.primary.main, mb: "8px" }}
        >
          Upload Site Logo
        </Typography>
        <Box
          sx={{
            position: "relative",
            height: "150px",
            backgroundColor: "#ddd",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "3px",
          }}
        >
          {files?.site_logo?.url && (
            <img
              src={files?.site_logo?.url}
              style={{ maxHeight: "100%", maxWidth: "100%" }}
              alt=""
            />
          )}
          <IconButton
            component="label"
            sx={{
              position: "absolute",
              backgroundColor: (theme) => theme.palette.primary.main,
              bottom: "3px",
              right: "3px",
              zIndex: "100",
              bgcolor: "#fff",
              boxShadow: "0 0 3px #ddd",
            }}
          >
            <input
              type="file"
              accept="image/png, image/gif, image/jpeg, image/svg+xml"
              hidden
              onChange={(e) => handleAddImage(e, "site_logo")}
            />
            <Edit
              fontSize="small"
              sx={{ color: (theme) => theme.palette.primary.main }}
            />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "20px auto",
            mt: "8px",
          }}
        >
          <AiOutlineInfoCircle
            style={{ fontSize: "16px", color: "#666", marginTop: "2px" }}
          />
          <Typography sx={{ fontSize: "14px", color: "#666" }}>
            These images are visible in login window and top-left corner of main
            crm window. Use 450x95 sizes images for perfect looking. And
            background should be transparent of this image.
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{ display: "grid", gridTemplateColumns: "200px auto", gap: "20px" }}
      >
        <Box>
          <Typography
            sx={{ color: (theme) => theme.palette.primary.main, mb: "8px" }}
          >
            Favicon
          </Typography>
          <Box
            sx={{
              position: "relative",
              height: "150px",
              backgroundColor: "#ddd",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "3px",
            }}
          >
            {files?.site_favicon?.url && (
              <img
                src={files?.site_favicon?.url}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
                alt=""
              />
            )}
            <IconButton
              component="label"
              sx={{
                position: "absolute",
                backgroundColor: (theme) => theme.palette.primary.main,
                bottom: "3px",
                right: "3px",
                zIndex: "100",
                bgcolor: "#fff",
                boxShadow: "0 0 3px #ddd",
              }}
            >
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg, image/svg+xml"
                hidden
                onChange={(e) => handleAddImage(e, "site_favicon")}
              />
              <Edit
                fontSize="small"
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "20px auto",
              mt: "8px",
            }}
          >
            <AiOutlineInfoCircle
              style={{ fontSize: "16px", color: "#666", marginTop: "2px" }}
            />
            <Typography sx={{ fontSize: "14px", color: "#666" }}>
              These images size should be 16x16
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography
            sx={{ color: (theme) => theme.palette.primary.main, mb: "8px" }}
          >
            Login page cover image
          </Typography>
          <Box
            sx={{
              position: "relative",
              height: "150px",
              backgroundColor: "#ddd",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "3px",
            }}
          >
            {files?.site_footer_logo?.url && (
              <img
                src={files?.site_footer_logo?.url}
                style={{ maxHeight: "100%", maxWidth: "100%" }}
                alt=""
              />
            )}
            <IconButton
              component="label"
              sx={{
                position: "absolute",
                backgroundColor: (theme) => theme.palette.primary.main,
                bottom: "3px",
                right: "3px",
                zIndex: "100",
                bgcolor: "#fff",
                boxShadow: "0 0 3px #ddd",
              }}
            >
              <input
                type="file"
                accept="image/png, image/gif, image/jpeg, image/svg+xml"
                hidden
                onChange={(e) => handleAddImage(e, "site_footer_logo")}
              />
              <Edit
                fontSize="small"
                sx={{ color: (theme) => theme.palette.primary.main }}
              />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{ color: (theme) => theme.palette.primary.main, mt: "8px" }}
      >
        General Informations
      </Typography>
      <InputField name="name" formik={formik} label="Company Name" />
      <InputField name="phone" formik={formik} label="Phone" />
      <InputField name="site_url" formik={formik} label="Site URL" />
      <InputField name="email_address" formik={formik} label="Email" />
      <InputField name="facebook" formik={formik} label="Facebook" />
      <InputField name="twitter" formik={formik} label="Twitter" />
      <InputField name="instagram" formik={formik} label="Instagram" />
      <InputField name="linkedin" formik={formik} label="Linkedin" />
      <Button
        variant="solid"
        onClick={formik.submitForm}
        fullWidth
        disabled={
          !(formik.dirty && formik.isValid) &&
          !(
            files?.site_logo?.file ||
            files?.site_favicon?.file ||
            files?.site_footer_logo?.file
          )
        }
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default General;
