import { Add } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
const Header = ({
  setOpenSidebar,
  setSearch,
  setPagination,
  pagination,
  setUpdateCount,
  updateCount,
}) => {
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useEffect(() => {
    setSearch(debouncedText);
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  return (
    <Grid container justifyContent="space-between" alignItems="center" p="20px">
      <Grid item>
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in table.."}
        />
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => {
            setOpenSidebar(true);
            updateCount && setUpdateCount(false);
          }}
          //   disabled={!permissions.create}
        >
          Add Complain/Complement
        </Button>
      </Box>
    </Grid>
  );
};

export default Header;
