import * as yup from "yup";

const validationSchema = yup.object({
  invoice_id: yup.string("").required("Invoice is Required").nullable(),
  payment_method_id: yup
    .string("")
    .required("Pay method is Required")
    .nullable(),
  payment_date: yup.string("").required("Pay date is Required").nullable(),
  currency_id: yup.string("").required("Currency is Required").nullable(),
  amount: yup.string("").required("Amount is Required").nullable(),
});

export default validationSchema;
