import { Box, Button, Dialog, TextField, Typography } from "@mui/material";
import LoadingBtn from "components/ui/loadingBtn";
import useApi from "hooks/useApi";
import { useState } from "react";
import { toast } from "react-toastify";

const AttRename = ({
  file_id,
  related_to,
  related_id,
  file_name,
  handleClose,
  open,
  setAttachments,
}) => {
  const { loading, fetchData } = useApi();
  const [renameText, setRenameText] = useState(file_name);

  const handleRename = async () => {
    setAttachments((pre) =>
      pre.map((att) =>
        att?.id === file_id ? { ...att, name: renameText } : att
      )
    );
    const endpoint = {
      method: "post",
      url: `/api/admin/attachment/update/${file_id}`,
      data: { related_to, related_id, name: renameText },
    };
    const result = await fetchData(endpoint, false);
    if (result.success) {
      toast.success("File renamed!");
      handleClose();
    } else {
      toast.error(result?.success);
    }
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey && file_name !== renameText) {
      e.preventDefault();
      e.stopPropagation();
      handleRename();
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Box sx={{ width: "100%", minWidth: "450px", p: "20px" }}>
        <Typography variant="h4" mb="15px">
          Rename
        </Typography>
        <TextField
          fullWidth
          autoFocus
          value={renameText}
          onChange={(e) => setRenameText(e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <Box sx={{ width: "80px", ml: "auto", mt: "15px" }}>
          {loading ? (
            <LoadingBtn isSmall />
          ) : (
            <Button
              fullWidth
              variant="solid"
              disabled={!renameText || file_name === renameText}
              onClick={handleRename}
            >
              Ok
            </Button>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default AttRename;
