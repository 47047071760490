import { Box, Button } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { viewInvoices } from "features/projectCustomers/api/projectCustomer";
import useApiCall from "hooks/useApiCall";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import AddInvoice from "./AddInvoice";
import InvoiceTable from "./InvoiceTable";
import MakePayment from "./MakePayment";
import ViewInvoice from "./viewInvoice/ViewInvoice";

const Invoice = ({
  customer_id,
  project_id,
  reference_id,
  user_id,
  assignees = [],
  intake_id = "",
}) => {
  const [addInvoice, setAddInvoice] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [currInvoice, setCurrInvoice] = useState({});
  const [allInvoice, setAllInvoice] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  const debouncedText = useDebounce(searchText, 1000);

  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    let finalPagination = { ...pagination };
    fetchListData(
      viewInvoices,
      {
        body_data: {
          customer_id: customer_id ? [customer_id] : [],
          project_id: project_id ? [project_id] : [],
          user_id: user_id ? [user_id] : [],
        },
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}&search=${debouncedText}`,
      },
      false
    );
  }, [reload, pagination?.current_page, pagination?.per_page, debouncedText]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllInvoice(allData);
      setPagination(rest);
    }
  }, [data]);

  return addInvoice ? (
    <AddInvoice
      setAddInvoice={setAddInvoice}
      setReload={setReload}
      currInvoice={currInvoice}
      customer_id={customer_id}
      project_id={project_id}
      reference_id={reference_id}
      user_id={user_id}
      defaultAssignees={assignees}
      defaultIntakeId={intake_id}
    />
  ) : makePayment ? (
    <MakePayment
      setMakePayment={setMakePayment}
      currInvoice={currInvoice}
      setReload={setReload}
    />
  ) : viewInvoice ? (
    <ViewInvoice invoice_id={viewInvoice} setViewInvoice={setViewInvoice} />
  ) : (
    <Box sx={{ m: "-20px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "10px",
        }}
      >
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in Invoice.."}
        />
        <Button
          startIcon={<BiPlus />}
          variant="outlined"
          onClick={() => {
            setCurrInvoice({});
            setAddInvoice(true);
          }}
        >
          New Invoice
        </Button>
      </Box>
      <InvoiceTable
        loading={loading}
        allInvoice={allInvoice}
        pagination={pagination}
        setPagination={setPagination}
        setReload={setReload}
        setAddInvoice={setAddInvoice}
        setCurrInvoice={setCurrInvoice}
        setAllInvoice={setAllInvoice}
        setMakePayment={setMakePayment}
        setViewInvoice={setViewInvoice}
      />
    </Box>
  );
};

export default Invoice;
