import { Box, Typography } from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useSelector } from "react-redux";
import * as yup from "yup";

const Currencies = () => {
  const { base_data } = useSelector((state) => state.base);
  const initialValues = {
    name: "",
    symbol: "",
    country_id: "",
  };
  return (
    <SetupCC
      name="Currency"
      baseRouteKey="currencies"
      moduleKey="currency"
      columns={[
        { id: "name", label: "Name", minWidth: 100 },
        { id: "symbol", label: "Symbol", minWidth: 100 },
        { id: "country", label: "Country", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "country" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img
                  loading="lazy"
                  width="20"
                  src={`https://flagcdn.com/w20/${value?.code?.toLowerCase()}.png`}
                  srcSet={`https://flagcdn.com/w40/${value?.code?.toLowerCase()}.png 2x`}
                  alt=""
                />
                <Typography>{value?.name}</Typography>
              </Box>
            ) : (
              value
            )}
          </>
        );
      }}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Name" formik={formik} />
          <InputField name="symbol" label="Symbol" formik={formik} />
          <Select
            isCountry={true}
            options={base_data?.countries || []}
            label="Country"
            value={formik?.values?.country_id}
            onChange={(value) => formik.setFieldValue("country_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />
        </>
      )}
    ></SetupCC>
  );
};

export default Currencies;
