import { Box, IconButton, Typography } from "@mui/material";
import UNIVERSAL from "config";
import getFileIcon from "../utils/getFileIcon";
import { BsThreeDotsVertical } from "react-icons/bs";

const SingleAtt = ({
  handleContextMenu,
  att,
  attDetails,
  isContextMenuOpen,
}) => {
  return (
    <Box
      sx={{
        bgcolor: "#fff",
        padding: "10px",
        borderRadius: "5px",
        boxShadow: "0 0 5px #efefef",
        cursor: "pointer",
        background: isContextMenuOpen ? "rgb(153 203 255 / 25%)" : "",
        border:
          attDetails?.id === att?.id
            ? "1px solid #405189"
            : "1px solid transparent",
      }}
    >
      <Box
        sx={{
          height: "146px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& img": {
            maxHeight: ["jpg", "jpeg", "png", "gif"].includes(att.extension)
              ? "90%"
              : "70%",
            maxWidth: "100%",
          },
        }}
      >
        <img src={getFileIcon(att)} alt="" />
      </Box>
      <Typography
        sx={{
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 1,
          overflow: "hidden",
          color: "#666",
          width: "calc(100% - 25px)",
          mb: "-2px",
        }}
      >
        {att.name}
      </Typography>
      <IconButton
        sx={{ position: "absolute", bottom: "6px", right: "6px" }}
        size="small"
        onClick={handleContextMenu}
      >
        <BsThreeDotsVertical />
      </IconButton>
    </Box>
  );
};

export default SingleAtt;
