import { Box } from "@mui/material";
import NotAuthorized from "components/notAuthorized";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import ActivityHeader from "./ActivityHeader";
import Filter from "./Filter";
import MainTable from "./table/MainTable";

const UniversityTargets = () => {
  const { base } = useBase();
  const { loading, fetchData } = useApi();
  const [universityTargets, setUniversityTargets] = useState([]);
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState();
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 30,
    to: 0,
    from: 0,
    total: 0,
  });

  useEffect(() => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    finalFilters.search = search;
    const getActivityList = async () => {
      const endpoint = {
        method: "post",
        url:
          "/api/admin/report/university/target?" +
          `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
        data: finalFilters,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data, ...rest } = result?.data;
        setUniversityTargets(data);
        setPagination(rest);
      }
    };
    getActivityList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, search]);

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const permissions = usePermissions("activity-log");

  return (
    <Box>
      <ActivityHeader
        {...{
          universityTargets,
          setSearch,
          setPagination,
          pagination,
          setFilters,
          search,
          filters,
          isFiltering,
          permissions,
          ...base,
        }}
      />

      {!!(permissions["list(own)"] || permissions["list(global)"]) && (
        <Filter
          {...{
            setPagination,
            pagination,
            setFilters,
            filters,
            isFiltering,
            setReload,
            ...base,
          }}
        />
      )}
      {permissions["list(own)"] || permissions["list(global)"] ? (
        <MainTable
          universityTargets={universityTargets}
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
          setReload={setReload}
          permissions={permissions}
        />
      ) : (
        <NotAuthorized />
      )}
    </Box>
  );
};

export default UniversityTargets;
