import { Add, DownloadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";

const StaffsHeader = ({
  setOpenSidebar,
  setSearch,
  setPagination,
  pagination,
  permissions,
  search,
}) => {
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);
  const { loading, fetchData } = useApi();

  useEffect(() => {
    setSearch(debouncedText || "");
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  const handleExport = async () => {
    const res = await fetchData(
      {
        method: "post",
        url: "/api/admin/staff/export",
        data: {
          type: "xlsx",
          trash_export: false,
          search,
        },
      },
      false
    );
    if (res.success) window.open(res?.data?.file);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        {!!permissions.list && (
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in table.."}
          />
        )}
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          startIcon={<DownloadOutlined />}
          onClick={handleExport}
          disabled={!permissions.export}
        >
          Export
        </LoadingButton>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => setOpenSidebar(true)}
          disabled={!permissions?.create}
        >
          New Staff
        </Button>
      </Box>
    </Grid>
  );
};

export default StaffsHeader;
