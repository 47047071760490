import moment from "moment";
import { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
// MUI
import DateRangeOutlined from "@mui/icons-material/DateRangeOutlined";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";

const DateRangeField = ({ from, to, label, formik, variant }) => {
  const dateFormat = "DD/MM/YYYY";
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState(formik?.values[from] || undefined);
  const [toDate, setToDate] = useState(formik?.values[to] || undefined);

  const onAdornmentClick = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  const onInputChange = (e) => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);

    setInputValue(inputValue);
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const onPopoverClose = (e, reason) => {
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    setFromDate(startDate);
    formik.setFieldValue(from, moment(startDate).format("YYYY-MM-DD"));
    setToDate(endDate);
    formik.setFieldValue(to, moment(endDate).format("YYYY-MM-DD"));
  };

  useEffect(() => {
    let inputValue = "";
    if (fromDate) inputValue += moment(fromDate).format(dateFormat);
    if (toDate) inputValue += " - " + moment(toDate).format(dateFormat);
    setInputValue(inputValue);
  }, [fromDate, toDate, formik]);

  const processInputValue = (value) => {
    let [fromDate, toDate] = value.split("-").map((elm) => elm.trim());

    fromDate = moment(fromDate, dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate, toDate };
  };

  return (
    <div>
      <TextField
        label={label || "Select Date Range"}
        variant={variant || "outlined"}
        fullWidth={true}
        size="small"
        value={inputValue}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={onAdornmentClick}>
                <DateRangeOutlined />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={onInputChange}
      />
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
      >
        <div>
          <DateRange
            ranges={[
              {
                startDate: moment(fromDate).toDate(),
                endDate: moment(toDate).toDate(),
                key: "selection",
              },
            ]}
            onChange={onSelectDateRanges}
            staticRanges={undefined}
            inputRanges={undefined}
            rangeColors={["#405189"]}
          />
        </div>
      </Popover>
    </div>
  );
};

export default DateRangeField;
