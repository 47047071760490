import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";

const SetupCCHeader = ({ setOpenSidebar, setSearch, name, permissions }) => {
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useEffect(() => {
    setSearch(debouncedText || "");
  }, [debouncedText]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        {!!permissions?.list && (
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in table.."}
          />
        )}
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          disabled={!permissions?.create}
          variant="outlined"
          startIcon={<Add />}
          onClick={() => setOpenSidebar(true)}
        >
          New {name}
        </Button>
      </Box>
    </Grid>
  );
};

export default SetupCCHeader;
