import { Box, Button, Dialog, Stack } from "@mui/material";
import Select from "components/ui/select";
import { useEffect } from "react";
import { TfiExchangeVertical } from "react-icons/tfi";

const ComfirmDelete = ({
  open,
  setOpen,
  setStaffDeleteData,
  staffDeleteData,
  setOpenAlert,
  assignees,
}) => {
  useEffect(() => {
    setStaffDeleteData({
      ...staffDeleteData,
      transfer_to_id: "",
    });
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          "& div": {
            borderRadius: "8px",
          },
        }}
      >
        <Box sx={{ width: "400px" }}>
          <Box
            sx={{
              paddingTop: "30px",
              paddingBottom: "5px",
              fontSize: "30px",
              fontWeight: "500",
              color: "#0cb367",
              textAlign: "center",
            }}
          >
            <TfiExchangeVertical />
          </Box>
          <Box
            sx={{
              marginTop: "-5px",
              paddingBottom: "25px",
              fontSize: "16px",
              fontWeight: "500",
              textAlign: "center",
            }}
          >
            Transfer Staff's Data
          </Box>
          <Box sx={{ backgroundColor: "#f8f8f8", paddingBottom: "5px" }}>
            <Box sx={{ padding: "15px 15px 0" }}>
              <Box sx={{ p: "5px 0 10px" }}>
                <Select
                  label="Select Staff"
                  name="type"
                  options={assignees}
                  selectProps={{ id: "user_id", label: "name" }}
                  value={staffDeleteData.transfer_to_id}
                  onChange={(value) =>
                    setStaffDeleteData({
                      ...staffDeleteData,
                      transfer_to_id: value || "",
                    })
                  }
                />
              </Box>
            </Box>
            <Stack
              // direction="row"
              gap="10px"
              justifyContent="center"
              padding="15px"
            >
              <Button
                fullWidth
                onClick={() => {
                  setOpen(false);
                  setOpenAlert(true);
                }}
                disabled={!staffDeleteData.transfer_to_id}
                variant="solid"
              >
                Transfer
              </Button>
              <Button
                fullWidth
                onClick={() => {
                  setStaffDeleteData({
                    ...staffDeleteData,
                    transfer_to_id: "",
                  });
                  setOpen(false);
                  setOpenAlert(true);
                }}
              >
                Skip
              </Button>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default ComfirmDelete;
