import { Box, Button, Typography } from "@mui/material";

const EditProfile = ({ id, setEditProfile }) => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pt: "25px",
          pb: "15px",
          borderBottom: "1px solid #ddd",
          alignItems: "center",
        }}
      >
        <Typography variant="h4">Edit Profile</Typography>
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant="solid"
            //   startIcon={<Edit />}
            onClick={() => setEditProfile(false)}
          >
            Update
          </Button>
          <Button
            variant="outlined"
            //   startIcon={<Edit />}
            onClick={() => setEditProfile(true)}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default EditProfile;
