const { default: Axios } = require("lib/Axios");

export const trashCustomersAPI = async () => {
  const { data } = await Axios.post("/api/admin/customer/trash/list", {});
  return data.data.data;
};

export const restoreAsyncTrashCustomer = async (id) => {
  const { data } = await Axios.get(`/api/admin/customer/restore/${id}`);

  return data;
};

export const deleteTrashCustomer = async (id) => {
  const { data } = await Axios.delete(`/api/admin/customer/delete/${id}`);

  return data;
};

export const filteredTrashCustomer = async (params, body_data) => {
  const { data } = await Axios.post(
    `/api/admin/customer/trash/list`,
    body_data
  );

  return data.data.data;
};
