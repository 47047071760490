import { Delete, UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  LinearProgress,
  TextField,
} from "@mui/material";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as yup from "yup";
import ReactLoading from "react-loading";
import { toast } from "react-toastify";

const UploadFileModal = ({
  open,
  related_to,
  handleClose,
  setAttachments,
  setReload,
}) => {
  const {
    base: { relatedToAttachments },
  } = useBase();

  const [progress, setProgress] = useState(0);
  const [currFile, setCurrFile] = useState(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const { fetchData } = useApi();
  const { fetchData: fetchRTData, loading: loadingRT } = useApi();

  useEffect(() => {
    if (acceptedFiles?.length) {
      setCurrFile(acceptedFiles[0]);
    }
  }, [acceptedFiles]);

  const [relatedToData, setRelatedToData] = useState([]);

  const initialValues = {
    related_to: relatedToAttachments.includes(related_to) ? related_to : "",
    related_id: "",
  };

  const validationSchema = yup.object({
    related_to: yup.string("").required("This field is Required").nullable(),
    related_id: yup.string("").required("This field is Required").nullable(),
  });

  const onSubmit = async (data) => {
    setUploading(true);
    const formData = new FormData();
    formData.append("file", currFile);
    Object.keys(data).forEach((item) => formData.append(item, data[item]));

    const endpoint = {
      method: "post",
      url: "/api/admin/attachment/create",
      data: formData,
      updateProgress: (value) => setProgress(value),
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      setAttachments((pre) => [result?.data, ...pre]);
      handleClose();
    } else {
      toast.error(result.message);
    }
    setUploading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const fetchRelatedToData = async (relatedTo) => {
    const endpoint = {
      method: "get",
      url: `/api/admin/base/related-module-data/${relatedTo}`,
    };
    const result = await fetchRTData(endpoint, false);
    if (result.success) setRelatedToData(result.data);
  };

  const convertToObjectOptions = (options) => {
    return options.map((option) => {
      return {
        id: option,
        label: option.charAt(0).toUpperCase() + option.slice(1),
      };
    });
  };

  useEffect(() => {
    if (relatedToAttachments.includes(related_to)) {
      fetchRelatedToData(related_to);
    }
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Box sx={{ position: "relative" }}>
        <Box sx={{ position: "absolute", top: "0", left: "0", width: "100%" }}>
          <LinearProgress
            variant="buffer"
            value={progress}
            sx={{
              height: "6px",
              "& span:last-child": {
                bgcolor: "#fff",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            width: "580px",
            margin: "0px auto",
          }}
        >
          <Box
            sx={{
              padding: "30px 22px 25px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                border: "2px dashed #ddd",
                borderRadius: "5px",
                height: "100px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                // mb: "15px",
              }}
              {...getRootProps({ className: "dropzone" })}
            >
              <input {...getInputProps()} />
              <p>Drag to attach file, or click to select</p>
            </Box>
            {!relatedToAttachments.includes(related_to) && (
              <Select
                options={convertToObjectOptions(relatedToAttachments || [])}
                label="Related to *"
                value={formik?.values?.related_to}
                onChange={(value) => {
                  fetchRelatedToData(value);
                  formik.setFieldValue("related_to", value);
                  formik.setFieldValue("related_id", "");
                }}
              />
            )}
            <Box sx={{ position: "relative" }}>
              {loadingRT && (
                <Box
                  sx={{
                    position: "absolute",
                    height: "30px",
                    top: "5px",
                    right: "6px",
                    background: "white",
                    zIndex: "10",
                  }}
                >
                  <ReactLoading
                    type="bubbles"
                    color="#405189"
                    height={"15px"}
                    width={"30px"}
                  />
                </Box>
              )}
              <Select
                disabled={!formik.values.related_to || loadingRT}
                options={relatedToData}
                label={
                  formik.values.related_to
                    ? "Select " + formik.values.related_to + " *"
                    : "Related to Item *"
                }
                value={formik?.values?.related_id}
                onChange={(value) => formik.setFieldValue("related_id", value)}
                selectProps={{ id: "related_id", label: "label" }}
              />
            </Box>
            <TextField
              fullWidth
              label="Attachment Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              size="small"
              multiline
              rows={2}
              disabled={!formik.values.related_id}
              sx={{ mb: "13px" }}
              InputLabelProps={{ shrink: true }}
            />
            <Box
              sx={{
                gap: "15px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {currFile && (
                <Chip
                  label={currFile?.name?.slice(0, 35)}
                  component="p"
                  variant="outlined"
                  clickable
                  deleteIcon={<Delete />}
                  onDelete={() => setCurrFile(null)}
                />
              )}
              <Button
                // sx={{ width: "250px" }}
                disabled={!currFile}
                onClick={formik.submitForm}
                startIcon={<UploadFile />}
                variant="solid"
              >
                Upload Attachment
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default UploadFileModal;
