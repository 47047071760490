import { Delete, Download } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import NotAuthorized from "components/notAuthorized";
import AttachmentsSkeleton from "components/skeletons/AttachmentsSkeleton";
import InputField from "components/ui/inputField";
import UNIVERSAL from "config";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaEye } from "react-icons/fa";
import { FcFile } from "react-icons/fc";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import * as yup from "yup";
import ViewAttachment from "./ViewAttachment";

const AttachmentUpload = () => {
  const permissions = usePermissions("attachment");
  let lead_id = useSelector((state) => state.auth.user_details?.user?.lead_id);
  const [viewAtt, setViewAtt] = useState(false);
  const [currFile, setCurrFile] = useState(null);

  const initialValues = {
    related_to: "customer",
    related_id: lead_id,
    file: "",
    description: "",
  };

  const [attachments, setAttachments] = useState([]);
  const { loading, fetchData } = useApi();
  const { fetchData: fetchReleteTodData } = useApi();

  useEffect(() => {
    const getAttachments = async () => {
      const endpoint = {
        method: "get",
        url: `/api/customer/attachment/list/${lead_id}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData } = result.data;
        setAttachments(allData || []);
      }
    };
    getAttachments();
  }, []);

  const handleFileView = (file) => {
    if (file?.mime_type.includes("image")) {
      setCurrFile(file);
      setViewAtt(true);
    } else {
      window.open(UNIVERSAL.BASEURL + file?.file, { replace: true });
    }
  };

  const handleDeleteAtt = async (attId) => {
    Swal.fire({
      title: "<strong>Are you sure?</strong>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteAtt = async () => {
          const endpoint = {
            method: "delete",
            url: `/api/customer/attachment/delete/${attId}`,
          };
          const result = await fetchData(endpoint, false);
          if (result.success) {
            setAttachments(attachments.filter((att) => att.id !== attId));
          }
        };
        deleteAtt();
      }
    });
  };

  const validationSchema = yup.object({
    file: yup.mixed().required(),
  });

  let onSubmit = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    const endpoint = {
      method: "post",
      url: `/api/customer/attachment/create`,
      data: formData,
    };
    const result = await fetchData(endpoint, false);
    if (result.success) {
      setAttachments([result.data, ...attachments]);
      formik.resetForm();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      formik.setFieldValue("file", acceptedFiles[0]);
    },
  });

  return (
    <Box pt="10px">
      {permissions?.list ? (
        <Box>
          <Box
            sx={{
              border: "2px dashed #ddd",
              borderRadius: "5px",
              height: "100px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
              mb: "15px",
            }}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            <p>Drag to attach file, or click to select</p>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              pb: "15px",
              gap: "15px",
            }}
          ></Box>
          <InputField
            rows={2}
            multiline
            sx={{ mb: "15px" }}
            fullWidth
            name="description"
            label="Attachment Description"
            formik={formik}
          />
          <Box
            sx={{
              gap: "15px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {formik.values.file ? (
              <Box>
                <Chip
                  label={formik.values.file.name}
                  deleteIcon={<DeleteIcon />}
                  variant="outlined"
                  onDelete={() => formik.setFieldValue("file", "")}
                />
              </Box>
            ) : (
              <></>
            )}
            <Button
              variant="solid"
              onClick={formik.submitForm}
              disabled={
                !(formik.dirty && formik.isValid) || !permissions?.create
              }
            >
              Upload Attachment
            </Button>
          </Box>
          <Stack
            sx={{
              height: "calc(100vh - 390px)",
              overflowY: "auto",
              mt: "10px",
            }}
            id="scrollableDiv"
          >
            {loading ? (
              <AttachmentsSkeleton />
            ) : (
              <>
                {attachments?.length > 0 &&
                  attachments?.map((attachment, key) => (
                    <Box
                      key={key}
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "50px auto 100px",
                        alignItems: "center",
                        borderBottom: "1px solid #ddd",
                        padding: "15px 10px",
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <FcFile style={{ fontSize: "40px" }} />
                      </Box>
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Typography variant="h6">
                            {attachment?.name}
                          </Typography>
                          {attachment?.description && (
                            <Typography>({attachment?.description})</Typography>
                          )}
                        </Box>
                        <Typography sx={{ fontSize: "12px" }}>
                          {(attachment?.file_size / 1024).toFixed(0)}KB
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          gap: "10px",
                        }}
                      >
                        {attachment?.mime_type?.includes("image") && (
                          <IconButton
                            onClick={() => handleFileView(attachment)}
                          >
                            <FaEye />
                          </IconButton>
                        )}
                        <a
                          href={UNIVERSAL.BASEURL + attachment?.file}
                          target="_blank"
                          rel="noreferrer"
                          download={attachment?.name}
                        >
                          <IconButton>
                            <Download />
                          </IconButton>
                        </a>
                        <IconButton
                          disabled={!permissions?.delete}
                          onClick={() => handleDeleteAtt(attachment?.id)}
                        >
                          <Delete />
                        </IconButton>
                      </Box>
                    </Box>
                  ))}
              </>
            )}
          </Stack>
          {viewAtt && (
            <ViewAttachment setViewAtt={setViewAtt} file={currFile} />
          )}
        </Box>
      ) : (
        <NotAuthorized />
      )}
    </Box>
  );
};

export default AttachmentUpload;
