import { Add } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useDispatch } from "react-redux";
import handleError from "utils/handleError";

const AddSetupCC = ({
  openSidebar,
  setOpenSidebar,
  currData,
  allData,
  setAllData,
  name,
  getInitialValues,
  validationSchema,
  allFields,
  sidebarWidth,
  baseRouteKey,
  moduleKey,
  setReload,
  shouldReload,
}) => {
  const isUpdating = currData?.id ? true : false;
  const { updateBase } = useBase();
  const dispatch = useDispatch();
  const { loading, fetchData } = useApi();

  const handleCreate = async (data) => {
    const endpoint = {
      method: "post",
      url: `/api/admin/${moduleKey}/create`,
      data,
    };
    return await fetchData(endpoint);
  };

  const handleUpdate = async (data) => {
    const endpoint = {
      method: "put",
      url: `/api/admin/${moduleKey}/update/${currData?.id}`,
      data,
    };
    return await fetchData(endpoint);
  };

  const onSubmit = async (data) => {
    const result = isUpdating
      ? await handleUpdate(data)
      : await handleCreate(data);
    if (result?.success) {
      if (shouldReload) {
        setReload((prev) => !prev);
      } else {
        isUpdating
          ? setAllData(
              allData.map((sd) =>
                sd.id === currData.id ? { ...currData, ...result?.data } : sd
              )
            )
          : setAllData([result?.data, ...allData]);
        updateBase([baseRouteKey]);
      }
      setOpenSidebar(false);
    } else {
      handleError(result, formik);
    }
    dispatch(setLoading(false));
  };

  const formik = useFormik({
    initialValues: getInitialValues(currData),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <RightDrawer
      {...{
        openDrawer: openSidebar,
        setOpenDrawer: setOpenSidebar,
        width: sidebarWidth ? sidebarWidth : "400px",
        headingLeft: (isUpdating ? "Edit " : "New ") + name,
        headingRight: "",
      }}
    >
      <Box p="20px" sx={{ "& svg": { fontSize: "20px" } }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack gap="17px">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              {allFields(formik)}
            </LocalizationProvider>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              fullWidth
              variant="solid"
              type="submit"
              disabled={!(formik.dirty && formik.isValid)}
              startIcon={!isUpdating && <Add />}
            >
              {isUpdating ? "Update" : "Add"}
            </LoadingButton>
          </Stack>
        </form>
      </Box>
    </RightDrawer>
  );
};

export default AddSetupCC;
