import { Box, Button } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import * as yup from "yup";
import Editor from "../../../../components/ui/editor/Editor";
import useBase from "hooks/useBase";
import useQueries from "hooks/useQueries";
import { useState } from "react";
import { useEffect } from "react";
import ReactLoading from "react-loading";

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  setAllPost,
  allPost,
}) => {
  const { row_id } = useQueries();
  const { loading, fetchData } = useApi();
  const [currPost, setCurrPost] = useState(row_id ? {} : persistedData);
  const isUpdating = row_id ? true : false;
  const { base } = useBase();
  const { knowledgeBaseCategories } = base;

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullPost = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/knowledge/details/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrPost(result?.data);
    };
    row_id && getFullPost();
  }, []);

  const initialValues = {
    title: currPost?.name || "",
    category: currPost?.knowledge_base_category_id || "",
    description: currPost?.description || "",
  };

  const validationSchema = yup.object({
    title: yup.string("").required("Title is Required").nullable(),
    category: yup.string("").required("This field is Required").nullable(),
    description: yup.string("").required("This field is Required").nullable(),
  });

  const onSubmit = async (data) => {
    let endpoint;
    if (isUpdating) {
      endpoint = {
        method: "put",
        url: `/api/admin/knowledge/update/${currPost.id}`,
        data: {
          name: data.title,
          knowledge_base_category_id: data.category,
          description: data.description,
        },
      };
    } else {
      endpoint = {
        method: "post",
        url: "/api/admin/knowledge/create",
        data: {
          name: data.title,
          knowledge_base_category_id: data.category,
          description: data.description,
        },
      };
    }

    const result = await fetchData(endpoint);
    if (result.success) {
      if (isUpdating) {
        setAllPost(
          allPost.map((post) => (post.id === currPost.id ? result.data : post))
        );
      } else {
        setAllPost([result?.data, ...allPost]);
      }
    }
    handleClose("reset");
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <RightDrawer
      openDrawer={openDrawer}
      setOpenDrawer={handleClose}
      headingLeft={isUpdating ? "Edit Knowledge Base" : "Add Knowledge Base"}
      headingRight={null}
      width="750px"
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box sx={{ p: "20px" }}>
          <Box p="20px 0">
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                gap: "20px",
              }}
            >
              <InputField
                name="title"
                label="Knowledge Base Title *"
                formik={formik}
              />

              <Select
                options={knowledgeBaseCategories}
                selectProps={{ id: "id", label: "name" }}
                label="Category *"
                value={formik?.values?.category}
                onChange={(value) => {
                  formik.setFieldValue("category", value);
                }}
              />

              <Box sx={{ gridColumn: "span 2" }}>
                <Editor formik={formik} initVal={currPost.description} />
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                mt: "20px",
              }}
            >
              <Button
                variant="solid"
                onClick={formik.submitForm}
                fullWidth
                disabled={!(formik.dirty && formik.isValid)}
              >
                {isUpdating ? "Update Knowledge Base" : "Save Knowledge Base"}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </RightDrawer>
  );
};

export default AddEdit;
