import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";

const NotesHeader = ({
  setOpenSidebar,
  setSearch,
  setPagination,
  pagination,
  permissions,
}) => {
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useEffect(() => {
    setSearch(debouncedText || "");
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        {!!(permissions["list(own)"] || permissions["list(global)"]) && (
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in table.."}
          />
        )}
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          variant={"outlined"}
          startIcon={<BiPlus />}
          onClick={() => setOpenSidebar(true)}
          disabled={!permissions.create}
        >
          New Note
        </Button>
      </Box>
    </Grid>
  );
};

export default NotesHeader;
