import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Avatar, Box, Typography } from "@mui/material";
import ActivitySkeleton from "components/skeletons/ActivitySkeleton";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";

const Activity = ({ activityList }) => {
  const { loading, fetchData } = useApi();
  const [activities, setActivities] = useState([]);

  useEffect(() => {
    const getActivityList = async () => {
      const result = await fetchData(activityList, false);
      if (result.success) setActivities(result?.data);
    };
    getActivityList();
  }, []);

  return (
    <Box
      sx={{
        height: "calc(100vh - 122px)",
        overflowY: "auto",
        m: "-20px",
        p: "15px",
      }}
    >
      {loading ? (
        <ActivitySkeleton />
      ) : (
        <Timeline
          sx={{
            [`& .${timelineItemClasses.root}:before`]: {
              flex: 0,
              padding: 0,
            },
            margin: "0px",
            padding: "0px 10px",
          }}
        >
          {activities?.map((activity, i) => (
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot
                  variant="outlined"
                  sx={{ borderColor: "#efefef", border: "none", p: "0" }}
                >
                  <Avatar
                    sx={{ width: "50px", height: "50px" }}
                    src={UNIVERSAL.BASEURL + activity?.created_by_image}
                  />
                </TimelineDot>
                {i < activities.length - 1 && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent sx={{ py: "5px", px: "10px", mb: "10px " }}>
                <Box
                  sx={{
                    border: "1px solid #f7f7f7",
                    borderRadius: "5px",
                    padding: "10px",
                  }}
                >
                  <Typography variant="h5">{activity?.title}</Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#444",
                      marginBottom: "10px",
                    }}
                  >
                    {moment(activity?.created_at).format("lll")}
                  </Typography>
                  <Typography>{activity?.description}</Typography>
                </Box>
              </TimelineContent>
            </TimelineItem>
          ))}
        </Timeline>
      )}
    </Box>
  );
};

export default Activity;
