import { Box, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";

const StaffSummary = ({ user_id }) => {
  const [summary, setSummary] = useState({});
  const { fetchData } = useApi();
  useEffect(() => {
    const getStaffSummary = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/staff/summery/${user_id}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        setSummary(result?.data);
      }
    };
    getStaffSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const formatTitle = (title) => {
    const modifiledTitle = title.split("_").join(" ");
    return modifiledTitle.charAt(0).toUpperCase() + modifiledTitle.slice(1);
  };

  return (
    <div>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
          gap: "20px",
          padding: "20px",
        }}
      >
        {summary &&
          Object.keys(summary).map((summaryKey, i) => (
            <Box
              key={i}
              sx={{
                bgcolor: "#fff",
                padding: "20px",
                borderRadius: "5px",
                boxShadow: "2px 1px 3px #e5e5e5",
              }}
            >
              <Typography mb="5px">{formatTitle(summaryKey)}</Typography>
              <Typography variant="h4">
                {!summary[summaryKey]
                  ? "-"
                  : summaryKey === "last_login" || summaryKey === "last_logout"
                  ? moment(summary[summaryKey]).format("lll")
                  : summaryKey === "today_checkin" ||
                    summaryKey === "today_checkout"
                  ? moment(summary[summaryKey], "h:mm:ss").format("LT")
                  : summaryKey === "today_working_hour" ||
                    summaryKey === "total_month_working_hour" ||
                    summaryKey === "total_week_working_hour"
                  ? summary[summaryKey] + " H"
                  : summary[summaryKey]}
              </Typography>
            </Box>
          ))}
      </Box>
    </div>
  );
};

export default StaffSummary;
