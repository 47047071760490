import { ArrowBack, Logout } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "@mui/material";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import assets from "assets";
import UNIVERSAL from "config";
import Axios from "lib/Axios";
import { logout } from "lib/Reducer/authSlice";
import React, { useState } from "react";
import { CgProfile } from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ClientNotification from "./ClientNotification";
import ClientChatList from "./ClientChatList";

const drawerWidth = 250;
const navItems = [
  {
    name: "Projects",
    path: "project",
  },
  {
    name: "Invoices",
    path: "invoice",
  },
  {
    name: "Payments",
    path: "payment",
  },
  {
    name: "Attachments",
    path: "attachments",
  },
  {
    name: "Chat",
    path: "chat",
  },
  {
    name: "Support",
    path: "ticket",
  },
  {
    name: "Knowledgebase",
    path: "knowledgebase",
  },
];
const ClientAppBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const user_details = useSelector((state) => state.auth.user_details);
  const site_logo = useSelector(
    (state) => state?.base?.base_data?.settings?.general?.site_logo
  );

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleLogout = async () => {
    try {
      (await auth?.user_details?.role) !== "Customer"
        ? Axios.post("/api/admin/logout")
        : Axios.post("/api/logout");
      dispatch(logout());
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const navigate = useNavigate();
  const location = useLocation();
  const currPath = location?.pathname?.split("/")[2];
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  return (
    <Box
      sx={{
        width: "100%",
        bgcolor: (theme) => theme.palette.white.main,
        boxShadow: "0 0 8px 5px #81818114",
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "1200px",
          height: "65px",
          margin: "0 auto",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0 10px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img
            src={site_logo ? UNIVERSAL.BASEURL + site_logo : assets.logo}
            alt=""
            width="200px"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("")}
          />
        </Box>

        <Box
          sx={{
            ml: "auto",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { md: "none" } }}
          >
            <MenuIcon sx={{ fontSize: "2rem", color: "#515151" }} />
          </IconButton>

          <Box sx={{ display: { xs: "none", md: "block" }, mr: 2 }}>
            {navItems.map((item) => (
              <Button
                sx={{ color: currPath !== item?.path && "#777" }}
                key={item?.name}
                onClick={() => {
                  navigate(item?.path);
                }}
              >
                {item?.name}
              </Button>
            ))}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <ClientChatList />
          <ClientNotification />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <IconButton
              onClick={handleClick}
              size="small"
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <Avatar
                sx={{ cursor: "pointer", border: "1px solid #ddd" }}
                src={""}
              >
                {user_details.user.name.charAt(0)}
              </Avatar>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 18,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate("");
                }}
              >
                <ListItemIcon>
                  <CgProfile style={{ fontSize: "20px" }} />
                </ListItemIcon>
                Profile
              </MenuItem>
              {/* <MenuItem
                onClick={() => {
                  handleClose();
                  navigate("chat");
                }}
              >
                <ListItemIcon>
                  <MdChatBubbleOutline style={{ fontSize: "20px" }} />
                </ListItemIcon>
                Chat
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate("ticket");
                }}
              >
                <ListItemIcon>
                  <BiSupport style={{ fontSize: "20px" }} />
                </ListItemIcon>
                Support
              </MenuItem>
              <Divider />
              <MenuItem
                onClick={() => {
                  handleClose();
                  navigate("knowledgebase");
                }}
              >
                <ListItemIcon>
                  <AiOutlineQuestionCircle style={{ fontSize: "20px" }} />
                </ListItemIcon>
                Knowledge Base
              </MenuItem> */}
              <MenuItem onClick={() => handleLogout()}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
      <Box component="nav">
        <Drawer
          anchor="right"
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          <Box>
            <Button
              onClick={handleDrawerToggle}
              sx={{
                height: "64px",
                width: "100%",
                borderBottom: "1px solid #ddd",
                display: "flex",
                justifyContent: "flex-start",
                pl: "15px",
              }}
            >
              <ArrowBack style={{ fontSize: "25px" }} />
            </Button>

            <List sx={{ p: "0" }}>
              {navItems.map((item) => (
                <ListItem
                  sx={{
                    borderBottom: "1px solid #ddd",
                    bgcolor: currPath === item?.path && "#efefef",
                    "&:hover": { bgcolor: "#efefef" },
                  }}
                  key={item?.name}
                  disablePadding
                  onClick={() => {
                    handleDrawerToggle();
                    navigate(item?.path);
                  }}
                >
                  <ListItemButton>
                    <ListItemText primary={item?.name} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default ClientAppBar;
