import { UploadFileSharp } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Chip, Divider } from "@mui/material";
import Editor from "components/ui/editor/Editor";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import * as yup from "yup";
const editorConfig = {
  buttons: [
    "bold",
    "italic",
    "underline",
    "|",
    "ul",
    "ol",
    "|",
    "align",
    "undo",
    "redo",
  ],
};
const validationSchema = yup.object({
  description: yup.string().notRequired(""),
});
const ReplyForm = ({ ticketId, setReplys }) => {
  const { loading: replyFormLoading, fetchData: addReply } = useApi();

  const initialValues = {
    ticket_id: ticketId || "",
    description: "",
    file: "",
  };
  const onSubmit = async (values, action) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    let endpoint = {
      method: "post",
      url: "/api/admin/ticket/reply",
      data: formData,
    };
    let result = await addReply(endpoint, false);
    if (result.success) {
      setReplys((prev) => [...prev, result.data]);
    }
    action.resetForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <>
      <Divider sx={{ my: 4 }} />

      <Editor formik={formik} configProp={editorConfig} />

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
          mt: 3,
        }}
      >
        {formik.values.file && (
          <Box>
            <Chip
              label={formik.values.file.name}
              deleteIcon={<DeleteIcon />}
              variant="outlined"
              onDelete={() => formik.setFieldValue("file", "")}
            />
          </Box>
        )}
        <Button
          component="label"
          startIcon={<UploadFileSharp />}
          variant="outlined"
        >
          <input
            name="file"
            type="file"
            hidden
            onChange={(e) => formik.setFieldValue("file", e.target.files[0])}
          />
          Attach File
        </Button>
        <Button
          variant="solid"
          onClick={formik.submitForm}
          disabled={!(formik.dirty && formik.isValid) || replyFormLoading}
        >
          Reply
        </Button>
      </Box>
    </>
  );
};

export default ReplyForm;
