import Axios from "lib/Axios";

export const deleteStaff = ({ id, transfer_to_id }) =>
  Axios.delete(`/api/admin/staff/delete/${id}?user_id=${transfer_to_id}`).then(
    (res) => res.data
  );

const endpoints = {
  createStaff: (data) => ({
    method: "post",
    url: "/api/admin/staff/create",
    data,
  }),
  updateStaff: (id, data) => ({
    method: "post",
    url: `/api/admin/staff/update/${id}`,
    data,
  }),
};

export const { createStaff, updateStaff } = endpoints;

export const viewAllStaffs = ({ params }) =>
  Axios.get(`/api/admin/staff/list?${params}`).then((res) => res.data);

export const allDepartments = () =>
  Axios.get("/api/admin/base/department").then((res) => res.data);

export const allDesignations = () =>
  Axios.get("/api/admin/base/designation").then((res) => res.data);

export const allRoles = () =>
  Axios.get("/api/admin/base/role").then((res) => res.data);

export const rolePermissions = () =>
  Axios.get("/api/admin/role/permission/module").then((res) => res.data);
