import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Alert from "components/alert";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import {
  deleteCustomer,
  updateProjectCustomerStatus,
} from "features/projectCustomers/api/projectCustomer";
import useApi from "hooks/useApi";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ProjectTable = ({
  allProject,
  loading,
  pagination,
  setPagination,
  setReload,
  setCurrProject,
  setAddProject,
  setAllProject,
  currProject,
}) => {
  const projectCustomerStatuses = useSelector(
    (state) => state.base.base_data.projectCustomerStatuses
  );

  const rows = allProject;
  const columns = [
    { id: "project_name", label: "Project" },
    { id: "created_at", label: "FO Date", style: { width: "130px" } },
    { id: "university_name", label: "University", style: { width: "140px" } },
    {
      id: "admission_status",
      label: "Update",
      style: { width: "100px" },
      align: "center",
    },
    { id: "intake_name", label: "Intake" },
    {
      id: "status_id",
      label: "Status",
      style: { width: "160px", align: "center" },
    },
    {
      id: "action",
      label: "Actions",
      style: { width: "150px" },
      align: "center",
    },
  ];

  const { fetchData } = useApi();

  const handleEditBtn = (value) => {
    setCurrProject(value);
    setAddProject(true);
  };

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    const result = await fetchData(deleteCustomer(openAlert));
    if (result.success) {
      setReload((pre) => !pre);
    }
  };

  return (
    <>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Project Customer"
          desc="Are you sure you want to delete? Don't worry you will find this Project Customer on Trash Menu"
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 245px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, ...column.style }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <ProjectTableRow
                  row={row}
                  key={row.id}
                  columns={columns}
                  setAllProject={setAllProject}
                  allProject={allProject}
                  projectCustomerStatuses={projectCustomerStatuses}
                  handleEditBtn={handleEditBtn}
                  handleDelete={handleDelete}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 245px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default ProjectTable;

const ProjectTableRow = ({
  columns,
  row,
  setAllProject,
  allProject,
  projectCustomerStatuses,
  handleEditBtn,
  handleDelete,
}) => {
  const handleChangeStatus = async (id) => {
    setAllProject(
      allProject.map((item) =>
        item.id === row.id
          ? {
              ...row,
              status_id: id,
              status_color: projectCustomerStatuses?.find(
                (pcs) => pcs?.id === id
              ).color,
            }
          : item
      )
    );
    const result = await updateProjectCustomerStatus({
      body_data: {
        project_customer_id: row?.id,
        project_customer_status_id: id,
      },
    });
    if (!result.success) {
      toast.error(result.message);
    }
  };

  return (
    <TableRow hover role="checkbox" key={row.id}>
      {columns.map((column, i) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.format && typeof value === "number" ? (
              column.format(value)
            ) : column.id === "created_at" ? (
              moment(value).format("ll")
            ) : column.id === "admission_status" ? (
              <Box
                sx={{
                  bgcolor: `${row?.admission_status_color}15`,
                  color: row?.admission_status_color,
                  textAlign: "center",
                  padding: "3px 5px",
                  borderRadius: "20px",
                  width: "100px",
                  mt: "5px",
                  fontSize: "13px",
                }}
              >
                {value}
              </Box>
            ) : column.id === "preferred_countries" ? (
              value.map((cn) => cn.name).join(", ") || "-"
            ) : column.id === "status_id" ? (
              <TextField
                size="small"
                value={value}
                select
                sx={{
                  width: "130px",
                  textAlign: "left",
                  "& > div:before": { content: "none" },
                  "& div": {
                    color: row?.status_color,
                  },
                  "& svg": {
                    color: row?.status_color,
                  },
                }}
                variant="standard"
                onChange={(e) => handleChangeStatus(e.target.value)}
              >
                {projectCustomerStatuses?.length > 0 &&
                  projectCustomerStatuses?.map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      <span
                        style={{
                          fontSize: "0.875rem",
                        }}
                      >
                        {status?.name}
                      </span>
                    </MenuItem>
                  ))}
              </TextField>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEditBtn(row)}>
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(row.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
