import { Delete, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import UNIVERSAL from "config";
import moment from "moment";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  handleEdit,
  permissions,
  ...restProps
}) => {
  const taskpriorities = [
    { id: 1, label: "Low", color: "#008000" },
    { id: 2, label: "Medium", color: "#FFA500" },
    { id: 3, label: "High", color: "#FF0000" },
    { id: 4, label: "Urgent", color: "#800080" },
  ];

  const getPriorityDetails = (value) =>
    taskpriorities?.find((p) => p.id === Number(value));

  return (
    <TableRow
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 20px",
        },
      }}
      {...restProps}
      hover
      key={row.id}
    >
      {columns.map((column, i) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.id === "created_at" ? (
              moment(value).format("lll")
            ) : column.id === "related_to" ? (
              <Typography variant="body2" textTransform="capitalize">
                {value}
              </Typography>
            ) : column.id === "created_by" ? (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Tooltip title={row?.created_by_name} placement="right">
                  <Avatar
                    sx={{ width: 28, height: 28, fontSize: 12 }}
                    alt={row?.created_by_name}
                    src={UNIVERSAL.BASEURL + row?.created_by_image}
                  />
                </Tooltip>
              </Box>
            ) : column.id === "priority" ? (
              <Box
                sx={{
                  backgroundColor: `${getPriorityDetails(value)?.color}15`,
                  color: getPriorityDetails(value)?.color,
                  textAlign: "center",
                  padding: "3px 5px",
                  borderRadius: "20px",
                  width: "80px",
                  mt: "5px",
                  fontSize: "13px",
                }}
              >
                {getPriorityDetails(value)?.label}
              </Box>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Edit">
                  <IconButton
                    disabled={!permissions.update}
                    onClick={() => handleEdit(row)}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    disabled={!permissions.delete}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
