import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import * as yup from "yup";

const References = () => {
  const initialValues = {
    name: "",
    description: "",
  };
  return (
    <SetupCC
      name="Reference"
      baseRouteKey="references"
      moduleKey="reference"
      columns={[
        { id: "name", label: "Name", minWidth: 100 },
        { id: "description", label: "Description", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Name*" formik={formik} />
          <InputField
            name="description"
            label="Description"
            formik={formik}
            multiline
            rows={4}
          />
        </>
      )}
    ></SetupCC>
  );
};

export default References;
