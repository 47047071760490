import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import PopoverAG from "components/ui/popoverAG";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import moment from "moment";
import React, { memo, useLayoutEffect, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import ViewAttachment from "./ViewAttachment";

const Conversation = ({
  msg,
  owner_id,
  lastMessage,
  isCustomer,
  setMsgs,
  setCurrChat,
  route,
}) => {
  const virtuosoRef = useRef(null);
  const [action, setAction] = useState(null);
  const [viewAtt, setViewAtt] = useState(false);

  useLayoutEffect(() => {
    let timerId = setTimeout(() => {
      virtuosoRef.current.scrollToIndex({
        index: msg?.length - 1,
        behavior: "smooth",
      });
    }, 1000);

    return () => {
      clearTimeout(timerId);
    };
  }, [msg?.length]);

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          flexGrow: 1,
          height: "100%",
          py: 2,
        }}
      >
        <Virtuoso
          ref={virtuosoRef}
          className="disable_bar pos_relative"
          data={msg}
          style={{ height: "100%" }}
          initialTopMostItemIndex={msg?.length - 1}
          itemContent={(_, message) => (
            <ChatItem
              key={message?.id}
              {...{
                owner_id,
                message,
                action,
                setAction,
                viewAtt,
                setViewAtt,
                isCustomer,
                setMsgs,
                setCurrChat,
                route,
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
};
const ChatItem = memo(
  ({
    owner_id,
    message,
    action,
    setAction,
    viewAtt,
    setViewAtt,
    isCustomer,
    setMsgs,
    setCurrChat,
    route,
  }) => {
    let getMsgOwner = message?.user_id ? message?.user : message?.customer;
    let checkMsgOwner = getMsgOwner?.id === owner_id;
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            gap: "10px",
            my: "10px",
            position: "relative",
            cursor: checkMsgOwner ? "pointer" : "initial",
            ...(checkMsgOwner && {
              justifyContent: "end",
            }),
          }}
          onClick={() => {
            setAction(message?.id);
          }}
        >
          {!checkMsgOwner && (
            <Avatar
              alt={getMsgOwner?.name}
              src={UNIVERSAL.BASEURL + getMsgOwner?.image}
              sx={{
                width: 32,
                height: 32,
                ...(checkMsgOwner && {
                  order: 2,
                }),
              }}
            />
          )}

          {checkMsgOwner && (
            <Box
              sx={{
                display: action === message?.id ? "block" : "none",
              }}
            >
              <LongMenu
                route={route}
                chat={message}
                setMsgs={setMsgs}
                setCurrChat={setCurrChat}
              />
            </Box>
          )}
          <Box
            sx={{
              borderRadius: "10px",
              ...(checkMsgOwner
                ? {
                    border: "1px solid #ddd",
                    borderTopRightRadius: "2px",
                  }
                : {
                    color: "#fff",
                    background: "#405189",
                    borderTopLeftRadius: "2px",
                  }),
              padding: "5px 10px",
              maxWidth: "20rem",
            }}
          >
            {message?.file &&
              (message?.mime_type?.includes("image") ? (
                <Box
                  role="button"
                  onClick={() => setViewAtt(message?.id)}
                  sx={{ cursor: "pointer" }}
                >
                  <img
                    src={UNIVERSAL.BASEURL + message?.file}
                    alt=""
                    style={{
                      maxWidth: "100%",
                    }}
                  />
                </Box>
              ) : (
                <Chip
                  onClick={() => window.open(UNIVERSAL.BASEURL + message?.file)}
                  label={message?.file_name}
                  sx={{
                    color: "#fff",
                    background: "#2a2d45 !important",
                    border: 0,
                    my: 1,
                    cursor: "pointer",
                  }}
                  variant="outlined"
                />
              ))}

            <Typography
              variant="body1"
              sx={{
                color: "inherit",
                fontSize: "14px",
                mb: 0.5,
                wordWrap: "break-word",
              }}
            >
              {message?.message}
            </Typography>

            <Typography
              variant="body1"
              sx={{
                color: "inherit",
                fontSize: "10px",
                textAlign: "right",
              }}
            >
              {moment(message.updated_at || message.created_at).format("lll")}
            </Typography>
          </Box>
        </Box>
        {viewAtt === message?.id && (
          <ViewAttachment setViewAtt={setViewAtt} file={message} />
        )}
        {
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {isCustomer ? (
              <PopoverAG items={message?.user_chat_seen_list || []} size={12} />
            ) : (
              <PopoverAG
                items={
                  [
                    ...(message?.customer_chat_seen_list || []),
                    ...(message?.user_chat_seen_list || []),
                  ].filter((items) => items?.user_id !== owner_id) || []
                }
                size={12}
              />
            )}
          </Box>
        }
      </>
    );
  }
);
export default memo(Conversation);
const options = ["Edit Chat", "Delete Chat"];

const ITEM_HEIGHT = 38;

function LongMenu({ route, chat, setCurrChat, setMsgs }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let handleEdit = () => {
    setCurrChat(chat);
  };
  let { fetchData } = useApi();
  let handleDelete = async () => {
    let endpoint = {
      method: "post",
      url: `/api/${route}/chat/action`,
      data: {
        chat_id: chat?.id,
        action: "delete",
      },
    };

    let result = await fetchData(endpoint, false);
    if (result?.success) {
      setMsgs((prev) => ({
        ...prev,
        data: prev.data.filter((item) => item.id !== chat?.id),
      }));
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option) => {
    if (option === options[0]) {
      handleEdit();
    } else if (option === options[1]) {
      handleDelete();
    }
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-label="more" id="long-button" onClick={handleClick}>
        <MoreVertIcon
          sx={{
            fontSize: "16px",
          }}
        />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "20ch",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={() => handleClose(option)}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
