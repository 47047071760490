import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { ticketPriorities, ticketStatus } from "../../table/MainTableRow";
import ReplyForm from "./ReplyForm";
import ReplyList from "./ReplyList";
import ReactLoading from "react-loading";

const Profile = ({ profile, loading, row_id }) => {
  let { created_at, project_name, customer_name, priority, status } = profile;
  let details = Object.keys({
    created_at,
    customer_name,
    project_name,
    priority,
    status,
  });
  let [replys, setReplys] = useState([]);
  const { loading: listReplayLoading, fetchData: listReply } = useApi();

  useEffect(() => {
    async function allReply() {
      const endpoint = {
        method: "get",
        url: `api/admin/ticket/reply/list/${row_id}`,
      };
      const result = await listReply(endpoint, false);
      setReplys(result.data);
    }
    allReply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [row_id]);


  return loading ? (
    <Box
      sx={{
        height: "calc(100vh - 143px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
          columnGap: "60px",
        }}
      >
        {details?.length > 0 &&
          details?.map((data, i) => (
            <Box
              key={i}
              className="colsBorder"
              sx={{
                width: "100%",
                minWidth: 0,
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                mb: 2,
              }}
            >
              <Typography
                variant="h5"
                className="clamped-text"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                {data.replace(/_/g, " ")}
                {": "}
              </Typography>

              <Typography
                variant="body1"
                className="clamped-text"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                {data === "created_at" ? (
                  moment(profile[data]).format("lll")
                ) : data === "status" ? (
                  <span
                    style={{
                      color: ticketStatus?.find(
                        (status) => status?.id === profile[data]
                      )?.color,
                    }}
                  >
                    {profile[data]}
                  </span>
                ) : data === "priority" ? (
                  <span
                    style={{
                      color: ticketPriorities?.find(
                        (priority) => priority?.id === profile[data]
                      )?.color,
                      backgroundColor:
                        ticketPriorities?.find(
                          (priority) => priority?.id === profile[data]
                        )?.color + "15",
                      textAlign: "center",
                      padding: "0 25px",
                      display: "inline-block",
                      borderRadius: "15px",
                    }}
                  >
                    {profile[data]}
                  </span>
                ) : (
                  profile[data]
                )}
              </Typography>
            </Box>
          ))}
      </Box>
      <Box marginTop={2}>
        <Typography
          dangerouslySetInnerHTML={{
            __html: profile?.description,
          }}
        ></Typography>
      </Box>
      <ReplyForm ticketId={profile.id} setReplys={setReplys} />
      <ReplyList
        setReplys={setReplys}
        loading={listReplayLoading}
        replys={replys}
      />
    </>
  );
};

export default Profile;
