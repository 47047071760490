import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";

const TableAdmissionList = ({ project_customer_id }) => {
  const [rows, setRows] = useState([]);

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getAdmissionInfo = async () => {
      const endpoint = {
        method: "get",
        url: `/api/customer/project/admission/info/${project_customer_id}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) setRows(result.data);
    };
    getAdmissionInfo();
  }, []);

  const columns = [
    { id: "country_name", label: "Country", minWidth: 100 },
    { id: "university_name", label: "University", minWidth: 100 },
    { id: "subject_name", label: "Subject" },
    { id: "student_id", label: "Student Id" },
    { id: "intake_name", label: "Session" },
    { id: "text", label: "Note" },
    { id: "status", label: "Status" },
  ];

  return (
    <>
      {!loading ? (
        <TableContainer sx={{ height: "250px", overflowX: "auto" }}>
          <Table stickyHeader>
            <TableBody>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: "500" }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
              {rows?.map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "status" ? (
                            <Box
                              sx={{
                                bgcolor: `${row?.color}15`,
                                color: row.color,
                                textAlign: "center",
                                padding: "3px 5px",
                                borderRadius: "20px",
                                width: "120px",
                                mt: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {value}
                            </Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 265px)" />
      )}
    </>
  );
};

export default TableAdmissionList;
