import { Button, Stack } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateRangeField from "components/ui/dateRangeField";
import MultiSelect from "components/ui/multiSelect";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import * as yup from "yup";

const Filter = ({
  openDrawer,
  setOpenDrawer,
  setReload,
  setFilters,
  filters,
  isFiltering,
  pagination,
  setPagination,
}) => {
  const assignees = useSelector((state) => state.base.base_data?.assignees);

  const getInitialvalues = (filters) => {
    return {
      user_id: filters.user_id || [],
      date_from: filters.date_from || null,
      date_to: filters.date_to || null,
    };
  };

  const initialValues = getInitialvalues(filters);
  const validationSchema = yup.object({});

  const onSubmit = async (data) => {
    setFilters({ ...filters, ...data });
    setOpenDrawer(false);
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const resetForm = () => {
    const emptyFilter = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = null;
      }
    }
    formik.setValues(emptyFilter);
    setFilters({});
    setReload((pre) => !pre);
    setOpenDrawer(false);
  };

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "400px",
        headingLeft: "Filters",
        headingRight: "",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack
          direction="column"
          gap="15px"
          p="20px"
          sx={{ height: "calc(100vh - 125px)", overflowY: "auto" }}
        >
          <MultiSelect
            options={assignees}
            label="Select Staff"
            value={formik?.values?.user_id}
            onChange={(value) => formik.setFieldValue("user_id", value)}
            selectProps={{ id: "user_id", label: "name" }}
          />
          <DateRangeField
            label="Date Between"
            from="date_from"
            to="date_to"
            formik={formik}
          />
        </Stack>
        <Stack
          direction="row"
          gap="15px"
          p="10px 20px"
          justifyContent="flex-end"
        >
          {isFiltering() ? (
            <Button variant="outlined" onClick={resetForm}>
              Reset Filter
            </Button>
          ) : (
            <Button variant="outlined" onClick={() => setOpenDrawer(false)}>
              Cancel
            </Button>
          )}
          <Button
            variant="solid"
            onClick={formik.submitForm}
            disabled={!formik.dirty}
          >
            Filter Attendance
          </Button>
        </Stack>
      </LocalizationProvider>
    </RightDrawer>
  );
};

export default Filter;
