const getInitialvalues = (filters) => {
  return {
    apply_through_id: filters.apply_through_id || "",
    status: filters.status || "",
    university_id: filters.university_id || "",
    intake_id: filters.intake_id || "",
    reference_id: filters.reference_id || "",
    project_id: filters.project_id || "",
    date_from: filters.due_date_from || null,
    date_to: filters.due_date_to || null,
    date_for: filters.date_for || "",
    filter_for: filters.filter_for || "",
    branches: filters.branches || "",
  };
};

export default getInitialvalues;
