import DateRangeOutlined from "@mui/icons-material/DateRangeOutlined";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Skeleton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import MultiSelect from "components/ui/multiSelect";
import UNIVERSAL from "config";
import useBase from "hooks/useBase";
import moment from "moment";
import { useState } from "react";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // Import the default styles
import "react-date-range/dist/theme/default.css";
const months = [
  { value: "01", name: "January" },
  { value: "02", name: "February" },
  { value: "03", name: "March" },
  { value: "04", name: "April" },
  { value: "05", name: "May" },
  { value: "06", name: "June" },
  { value: "07", name: "July" },
  { value: "08", name: "August" },
  { value: "09", name: "September" },
  { value: "10", name: "October" },
  { value: "11", name: "November" },
  { value: "12", name: "December" },
];

const dateValidatio = (date) => {
  let check = moment(date, "YYYY-MM-DD");

  if (check.isValid()) {
    return moment(date).format("YYYY-MM-DD");
  }

  return false;
};
const LeaderBoard = ({
  data,
  filters,
  setFilters,
  loading,
  name,
  year_filter,
  month_filter,
  staff_filter,
  title,
  data_name,
  start_date,
  end_date,
  isHide,
}) => {
  const initial_date = [
    {
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      key: "selection",
    },
  ];
  const { base } = useBase();
  const [openFilter, setOpenFilter] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const onAdornmentClick = (e) => {
    setIsOpen(true);
    setAnchorEl(e.currentTarget);
  };

  const [selectedDateRange, setSelectedDateRange] = useState(initial_date);

  const handleDateChange = (ranges) => {
    setSelectedDateRange([ranges.selection]);
    setFilters({
      ...filters,
      filterEntry: [name],
      [start_date]: dateValidatio(ranges.selection.startDate),
      [end_date]: dateValidatio(ranges.selection.endDate),
    });
  };

  const onPopoverClose = () => {
    setIsOpen(false);
    setAnchorEl(null);
  };

  const formattedDateRange =
    filters[end_date] && `${filters[start_date]} / ${filters[end_date]}`;
  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: "3px",
        boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
        padding: "15px",
        mb: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "12px",
        }}
      >
        <Typography
          sx={{
            fontWeight: "500",
          }}
        >
          {title}
        </Typography>
        {openFilter ? (
          <Button
            sx={{
              padding: "3.18px 10px",
            }}
            variant="text"
            onClick={() => setOpenFilter((pre) => !pre)}
          >
            Hide
          </Button>
        ) : (
          <Button
            sx={{
              padding: "3.18px 10px",
            }}
            variant="text"
            onClick={() => setOpenFilter((pre) => !pre)}
          >
            Filter by
          </Button>
        )}
      </Box>
      {openFilter && (
        <Box sx={{ bgcolor: "#f4f4f480", p: "10px", mb: "12px" }}>
          <Stack direction="row" spacing={1}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Box
                sx={{
                  width: "20rem",
                }}
              >
                <MultiSelect
                  options={base?.assignees}
                  label="Assignees"
                  value={filters[staff_filter]}
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      filterEntry: [name],
                      [staff_filter]: value,
                    })
                  }
                  selectProps={{
                    id: "user_id",
                    label: "name",
                  }}
                />
              </Box>

              {isHide ? (
                <>
                  <DatePicker
                    views={["year"]}
                    value={filters[year_filter]}
                    onChange={(newValue) =>
                      setFilters({
                        ...filters,
                        filterEntry: [name],
                        [year_filter]: moment(newValue).isValid()
                          ? moment(newValue).format("YYYY")
                          : null,
                      })
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select year"
                        size="small"
                        sx={{ width: "150px" }}
                      />
                    )}
                  />
                  <TextField
                    size="small"
                    label="Select month"
                    select
                    value={filters[month_filter]}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        filterEntry: [name],
                        [month_filter]: e.target.value,
                      })
                    }
                    sx={{ width: "150px" }}
                  >
                    {months.map(({ value, name }) => (
                      <MenuItem key={value} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                </>
              ) : (
                <Box>
                  <TextField
                    size="small"
                    label="Select Date Range"
                    variant="outlined"
                    value={formattedDateRange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={onAdornmentClick}>
                            <DateRangeOutlined />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Popover
                    open={isOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={onPopoverClose}
                  >
                    <div>
                      <DateRange
                        onChange={handleDateChange}
                        ranges={selectedDateRange}
                        staticRanges={undefined}
                        inputRanges={undefined}
                        rangeColors={["#405189"]}
                      />
                    </div>
                  </Popover>
                </Box>
              )}
              <Button
                variant="outlined"
                disabled={
                  !(
                    filters[start_date] ||
                    filters[end_date] ||
                    filters[month_filter] ||
                    filters[staff_filter].length ||
                    filters[year_filter]
                  )
                }
                onClick={() => {
                  setFilters({
                    ...filters,
                    filterEntry: [name],
                    [start_date]: "",
                    [end_date]: "",
                    [month_filter]: "",
                    [staff_filter]: [],
                    [year_filter]: null,
                  });

                  setSelectedDateRange(initial_date);
                }}
              >
                Reset Filter
              </Button>
            </LocalizationProvider>
          </Stack>
        </Box>
      )}

      {loading && filters.filterEntry.includes(name) ? (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gap: "10px",
          }}
        >
          {[...Array(10)]?.map((_, i) => (
            <Skeleton
              variant="rectangular"
              height={"45px"}
              key={i}
              sx={{
                borderRadius: "5px",
              }}
            />
          ))}
        </Box>
      ) : (
        <>
          {!data?.[data_name]?.length ? (
            <Typography
              textAlign={"center"}
              variant="h5"
              sx={{
                fontWeight: 400,
                color: "#999",
                pb: "25px",
              }}
            >
              Nothing here!
            </Typography>
          ) : (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
                gap: "10px",
              }}
            >
              {data?.[data_name]?.map((item) => (
                <Box
                  key={item.user_id}
                  sx={{
                    display: "flex",
                    gap: 3,
                    borderRadius: "5px",
                    p: " 8px 10px",
                    alignItems: "center",
                    border: "1px solid #ddd",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <>
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                        }}
                        alt={item?.user_name}
                        src={UNIVERSAL.BASEURL + item?.user_image}
                      />
                      <Typography variant="h5" fontSize={"14px"}>
                        {item?.user_name}
                      </Typography>
                    </>
                  </Box>
                  <Typography variant="h4">{item?.value}</Typography>
                </Box>
              ))}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default LeaderBoard;
