import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import * as yup from "yup";

const BankInvoiceBanks = () => {
  const initialValues = {
    name: "",
  };

  return (
    <SetupCC
      name="Bank-Invoice Bank"
      baseRouteKey="bankInvoiceBanks"
      moduleKey="bank-invoice-bank"
      columns={[
        { id: "name", label: "Bank-Invoice Bank Name", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Name" formik={formik} />
        </>
      )}
    ></SetupCC>
  );
};

export default BankInvoiceBanks;
