import { logout } from "lib/Reducer/authSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const useApiCall = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchData = async (api, apiData, showMessage) => {
    setLoading(true);
    try {
      const result = await api(apiData);
      if (result.success) {
        if (showMessage !== false) {
          toast.success(result.message);
        }
        setData(result.data);
      } else {
        if (showMessage !== false) {
          toast.error(result.message);
        }
        setData(null);
      }
    } catch (error) {
      if (error?.response?.data?.code === 401) {
        dispatch(logout());
      }
      setError(error?.response?.data);
      if (error?.response && showMessage) {
        toast.error(error?.response?.data?.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return { data, error, loading, fetchData };
};

export default useApiCall;
