import * as yup from "yup";

const validationSchema = (activeField) =>
  yup.object().shape({
    name: yup.string("").required("Name is Required").nullable(),
    jobs: yup
      .array()
      .of(
        yup.object().shape({
          company_name: yup
            .string("")
            .required("Company name is Required")
            .nullable(),
          title: yup.string("").required("Job title is Required").nullable(),
        })
      )
      .required("Invoice is Required"),
    phone: yup.string().required("Phone number is required").nullable(),
  });

export default validationSchema;
