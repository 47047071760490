import Axios from "lib/Axios";

export const updateGeneralSettings = ({ body_data }) =>
  Axios.post("/api/admin/settings/general", body_data).then((res) => res.data);

export const updateEmailSettings = ({ body_data }) =>
  Axios.post("/api/admin/settings/email", body_data).then((res) => res.data);

export const updateDateTimelSettings = ({ body_data }) =>
  Axios.post("/api/admin/settings/app", body_data).then((res) => res.data);

  export const updateSMSSettings = ({ body_data }) =>
  Axios.post("/api/admin/settings/sms", body_data).then((res) => res.data);