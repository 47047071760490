import { Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { memo } from "react";

const ELPCForms = ({ formik, i, courses }) => {
  return (
    <>
      <Box
        sx={{
          gridColumn: "1 / -1",
          m: "25px 0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Course {i + 1} :</Typography>
        <Close
          style={{ cursor: "pointer" }}
          onClick={() => {
            formik.setFieldValue(
              "courses",
              [...formik.values.courses].filter((course, index) => index !== i)
            );
          }}
        />
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
          gap: "15px",
        }}
      >
        <Select
          sx={{ gridColumn: "1 / -1" }}
          options={courses}
          label="Select course type"
          value={formik?.values?.courses[i].course_id}
          onChange={(value) =>
            formik.setFieldValue(`courses[${i}].course_id`, value)
          }
          selectProps={{ id: "id", label: "name" }}
        />
        {formik?.values?.courses[i]?.course_id && (
          <>
            {courses
              .find(
                (course) =>
                  course?.id === Number(formik?.values?.courses[i]?.course_id)
              )
              ?.fields?.map((fildName) => (
                <InputField
                  isComplex={true}
                  name={`courses[${i}].${fildName.toLowerCase()}`}
                  label={fildName === "Overall" ? fildName + " *" : fildName}
                  formik={formik}
                  sx={{
                    gridColumn: fildName === "Overall" ? "1 / -1" : "span 1",
                  }}
                />
              ))}
          </>
        )}
      </Box>
    </>
  );
};

export default memo(ELPCForms);
