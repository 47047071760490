import { Box, Button } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import LoadingBtn from "components/ui/loadingBtn";
import Select from "components/ui/select";
import {
  createPayment,
  updatePayment,
} from "features/invoices/invoices/api/invoices";
import { useFormik } from "formik";
import useApiCall from "hooks/useApiCall";
import useBase from "hooks/useBase";
import moment from "moment";
import { useEffect } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import * as yup from "yup";

const AddPayment = ({
  id,
  setAddPayment,
  currPayment,
  setReload,
  currency_id,
}) => {
  const { base, updateBase } = useBase();
  const { invoices, paymentMethods, currencies, banks } = base;

  useEffect(() => {
    updateBase(["banks"]);
  }, []);

  const { data: updatedResult, loading, fetchData } = useApiCall();

  const onSubmit = async (data) => {
    if (currPayment?.id) {
      fetchData(updatePayment, {
        body_data: data,
        id: currPayment?.id,
      });
    } else {
      fetchData(createPayment, { body_data: data });
    }
  };

  useEffect(() => {
    if (updatedResult && !loading) {
      setReload((pre) => !pre);
      setAddPayment(false);
    }
  }, [updatedResult]);

  const initialValues = {
    invoice_id: id,
    bank_id: currPayment?.bank_id || "",
    payment_method_id: currPayment?.payment_method_id || "",
    payment_date: currPayment?.payment_date || moment().format("YYYY-MM-DD"),
    due_date: currPayment?.due_date || null,
    currency_id: currPayment?.currency_id || currency_id || "",
    amount: currPayment?.amount || "",
    transaction_id: currPayment?.transaction_id || "",
    note: currPayment?.note || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      payment_method_id: yup.string("").required().nullable(),
      currency_id: yup.string("").required().nullable(),
      amount: yup.string("").required().nullable(),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddPayment(false)}
        >
          View Payments
        </Button>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "15px",
          pb: "15px",
        }}
      >
        <Select
          options={invoices}
          label="Invoice"
          value={formik?.values?.invoice_id}
          onChange={(value) => formik.setFieldValue("invoice_id", value)}
          selectProps={{ id: "id", label: "name" }}
          disabled
        />
        <InputField
          label="Transaction ID"
          name="transaction_id"
          formik={formik}
        />
        <DateTimePicker
          name="payment_date"
          label="Payment Date *"
          type="date"
          formik={formik}
        />
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "15px",
          }}
        >
          <Select
            options={paymentMethods}
            label="Pay Method *"
            name="payment_method_id"
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
          <Select
            options={banks}
            label="Banks"
            value={formik?.values?.bank_id}
            onChange={(value) => formik.setFieldValue("bank_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />
        </Box>
        <Box
          sx={{
            gridColumn: "span 2",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "15px",
          }}
        >
          <Select
            options={currencies}
            label="Currency *"
            name="currency_id"
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
          <InputField label="Amount *" name="amount" formik={formik} />
          <DateTimePicker name="due_date" label="Due Date" formik={formik} />
        </Box>
        <InputField
          sx={{ gridColumn: "span 2" }}
          name="note"
          label="Note"
          multiline
          rows={2}
          formik={formik}
        />
      </Box>
      {loading ? (
        <LoadingBtn />
      ) : (
        <Button
          sx={{ gridColumn: "span 2" }}
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={!(formik.dirty && formik.isValid)}
        >
          {currPayment?.id ? "Update Payment" : "Save Payment"}
        </Button>
      )}
    </Box>
  );
};

export default AddPayment;
