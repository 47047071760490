import Axios from "lib/Axios";

export const exportLead = ({ body_data }) =>
  Axios.post("/api/admin/lead/export", body_data).then((res) => res.data);

export const importSample = () =>
  Axios.get("/api/admin/lead/sample?type=xlsx").then((res) => res.data);

export const addNewLead = ({ body_data }) =>
  Axios.post("/api/admin/lead/create", body_data).then((res) => res.data);

export const editLead = ({ body_data, id }) =>
  Axios.post(`/api/admin/lead/update/${id}`, body_data).then((res) => res.data);

export const restoreLead = ({ id }) =>
  Axios.get(`/api/admin/lead/restore/${id}`).then((res) => res.data);

export const deleteLead = ({ id }) =>
  Axios.delete(`/api/admin/lead/trash/${id}`).then((res) => res.data);

export const viewAllLeads = ({ body_data, params }) =>
  Axios.post(`/api/admin/lead/list?${params}`, body_data).then(
    (res) => res.data
  );

export const viewLead = ({ id }) =>
  Axios.get(`/api/admin/lead/show/${id}`).then((res) => res.data);

export const updateLeadStatus = ({ body_data }) =>
  Axios.post("/api/admin/lead/change/status", body_data).then(
    (res) => res.data
  );

export const viewLeadSummary = ({ body_data }) =>
  Axios.post(`api/admin/lead/summery`, body_data).then((res) => res.data);

export const importLeads = ({ body_data }) =>
  Axios.post("/api/admin/lead/import", body_data).then((res) => res.data);

export const leadBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/lead/bulk/action", body_data).then((res) => res.data);

export const updateFollowupDate = ({ body_data }) =>
  Axios.post(`/api/admin/lead/followupdate`, body_data).then((res) => res.data);

const endpoints = {
  createLead: (data) => ({
    method: "post",
    url: "/api/admin/lead/create",
    data,
  }),
  updateLead: (id, data) => ({
    method: "post",
    url: `/api/admin/lead/update/${id}`,
    data,
  }),
  deleteCustomer: (id) => ({
    method: "delete",
    url: `/api/admin/project-customer/trash/${id}`,
  }),
};

export const { createLead, updateLead, deleteCustomer } = endpoints;
