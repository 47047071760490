import { PrintRounded } from "@mui/icons-material";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import assets from "assets";
import useApi from "hooks/useApi";
import useWindowSize from "hooks/useWindowSize";
import moment from "moment";
import { useRef, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiUser } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { toast } from "react-toastify";
import handleError from "utils/handleError";
import ReactLoading from "react-loading";

const Profile = ({ profile, setOpenDrawer, loading }) => {
  const { isSuperMini } = useWindowSize();
  const [password, setPassword] = useState(false);
  const [openPass, setOpenPass] = useState(false);
  const { fetchData } = useApi();
  const navigate = useNavigate();

  const handleCovertCustomer = async () => {
    const endpoint = {
      method: "post",
      url: "/api/admin/lead/convert/customer",
      data: {
        lead_id: profile?.id,
        password,
      },
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      navigate("/customers");
    } else {
      const errors = handleError(result);
      errors.map((err) => toast.error(err));
    }
  };
  const printableContentRef = useRef();

  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              position: "absolute",
              right: "20px",
              top: "20px",
            }}
          >
            {openPass ? (
              <>
                <Box sx={{ display: "flex", gap: "5px", position: "relative" }}>
                  <TextField
                    sx={{ width: "260px" }}
                    size="small"
                    label="Give a password"
                    placeholder=""
                    helperText="Pass length sould be more than 8 letters"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    disabled={!(password?.length >= 8)}
                    variant="solid"
                    sx={{
                      minWidth: "25px",
                      position: "absolute",
                      height: "39px",
                      right: "0",
                      top: "0",
                    }}
                    onClick={handleCovertCustomer}
                  >
                    <AiOutlineCheck />
                  </Button>
                </Box>
                <Button
                  variant="outlined"
                  sx={{ minWidth: "25px", marginLeft: "-5px" }}
                  onClick={() => setOpenPass(false)}
                >
                  <AiOutlineClose />
                </Button>
              </>
            ) : (
              <Button
                variant="solid"
                startIcon={<BiUser />}
                onClick={() => setOpenPass(true)}
              >
                Convert to Customer
              </Button>
            )}
            {!openPass && (
              <ReactToPrint
                documentTitle={profile?.name}
                trigger={() => (
                  <Button variant="outlined" startIcon={<PrintRounded />}>
                    Print
                  </Button>
                )}
                content={() => printableContentRef.current}
              />
            )}
          </Box>
          <Box ref={printableContentRef} p={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                paddingBottom: "1.5rem",
              }}
            >
              <Typography variant="h4" mt={isSuperMini ? "55px" : "5px"}>
                Personal information
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: isSuperMini ? "1fr" : "1fr 3fr",
                gap: "10px",
                padding: "20px 0 40px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: "10px",
                  gap: "10px",
                }}
              >
                <img src={assets.userPlaceholder} alt="" width={160} />
                <Box
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    textTransform: "capitalize",
                    textAlign: "center",
                  }}
                >
                  {profile?.name || "-"}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(220px, 1fr))",
                  gap: "10px",
                }}
              >
                <Stack gap="15px">
                  <Box>
                    <Typography variant="h5">E-mail</Typography>
                    <Typography>{profile?.email || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Phone</Typography>
                    <Typography>{profile?.phone || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Address</Typography>
                    <Typography>{profile?.address || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Create at</Typography>
                    <Typography>
                      {profile?.created_at
                        ? moment(profile?.created_at).format("ll")
                        : "-"}
                    </Typography>
                  </Box>
                </Stack>
                <Stack gap="15px">
                  <Box>
                    <Typography variant="h5">City</Typography>
                    <Typography>{profile?.city || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Division</Typography>
                    <Typography>{profile?.state || "-"} </Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Country</Typography>
                    <Typography>{profile?.country?.name || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Status</Typography>
                    <Box
                      sx={{
                        bgcolor: `${profile?.lead_status?.color}15`,
                        color: profile?.lead_status?.color,
                        textAlign: "center",
                        padding: "3px 5px",
                        borderRadius: "20px",
                        width: "120px",
                        mt: "5px",
                        fontSize: "13px",
                      }}
                    >
                      {profile?.lead_status?.name}
                    </Box>
                  </Box>
                </Stack>
                <Stack gap="15px">
                  <Box>
                    <Typography variant="h5">Source</Typography>
                    <Typography>{profile?.source?.name || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Assigned</Typography>
                    {profile?.lead_assignees?.map((assignee) => (
                      <Typography>{assignee?.name}</Typography>
                    ))}
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box sx={{ pt: "15px", pb: "35px" }}>
              <Typography
                variant="h4"
                sx={{ borderBottom: "1px solid #ddd", pb: "15px", mb: "15px" }}
              >
                Additional Information
              </Typography>
              <Box>{profile?.description || "-"}</Box>
            </Box>
            <Box mt="20px">
              <Typography variant="h4">Educational Information</Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                  rowGap: "20px",
                  padding: "20px 0",
                  margin: "20px 0",
                  borderTop: "1px solid #ddd",
                  "& > div:nth-of-type(4), & > div:nth-of-type(5), & > div:nth-of-type(6)":
                    {
                      borderTop: "1px solid #ddd",
                      paddingTop: "20px",
                    },
                }}
              >
                {profile?.lead_s_s_c && (
                  <Stack
                    gap="20px"
                    sx={{ padding: "0 10px", borderLeft: "1px solid #405189" }}
                  >
                    <Box>
                      <Typography variant="h5">SSC Group</Typography>
                      <Typography>{profile?.lead_s_s_c?.group}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Board</Typography>
                      <Typography>{profile?.lead_s_s_c?.board}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Examination Year</Typography>
                      <Typography>
                        {profile?.lead_s_s_c?.passing_year}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">GPA</Typography>
                      <Typography>{profile?.lead_s_s_c?.gpa}</Typography>
                    </Box>
                  </Stack>
                )}
                {profile?.lead_o_level && (
                  <Stack
                    gap="20px"
                    sx={{ padding: "0 10px", borderLeft: "1px solid #405189" }}
                  >
                    <Box>
                      <Typography variant="h5">O Level Institute</Typography>
                      <Typography>
                        {profile?.lead_o_level?.institute_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Examination Year</Typography>
                      <Typography>
                        {profile?.lead_o_level?.passing_year}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Grade</Typography>
                      <Typography>{profile?.lead_o_level?.grade}</Typography>
                    </Box>
                  </Stack>
                )}
                {profile?.lead_h_s_c && (
                  <Stack
                    gap="20px"
                    sx={{ padding: "0 10px", borderLeft: "1px solid #405189" }}
                  >
                    <Box>
                      <Typography variant="h5">HSC Group</Typography>
                      <Typography>{profile?.lead_h_s_c?.group}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Board</Typography>
                      <Typography>{profile?.lead_h_s_c?.board}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Examination Year</Typography>
                      <Typography>
                        {profile?.lead_h_s_c?.passing_year}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">GPA</Typography>
                      <Typography>{profile?.lead_h_s_c?.gpa}</Typography>
                    </Box>
                  </Stack>
                )}

                {profile?.lead_diploma && (
                  <Stack
                    gap="20px"
                    sx={{ padding: "0 10px", borderLeft: "1px solid #405189" }}
                  >
                    <Box>
                      <Typography variant="h5">Diploma Subject</Typography>
                      <Typography>
                        {profile?.lead_diploma?.subject_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Diploma Group</Typography>
                      <Typography>{profile?.lead_diploma?.group}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Board</Typography>
                      <Typography>{profile?.lead_diploma?.board}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Examination Year</Typography>
                      <Typography>
                        {profile?.lead_diploma?.passing_year}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">GPA</Typography>
                      <Typography>{profile?.lead_diploma?.gpa}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Institute</Typography>
                      <Typography>
                        {profile?.lead_diploma?.institute_name}
                      </Typography>
                    </Box>
                  </Stack>
                )}

                {profile?.lead_a_level && (
                  <Stack
                    gap="20px"
                    sx={{ padding: "0 10px", borderLeft: "1px solid #405189" }}
                  >
                    <Box>
                      <Typography variant="h5">A Level Institute</Typography>
                      <Typography>
                        {profile?.lead_a_level?.institute_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Examination Year</Typography>
                      <Typography>
                        {profile?.lead_a_level?.passing_year}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Grade</Typography>
                      <Typography>{profile?.lead_a_level?.grade}</Typography>
                    </Box>
                  </Stack>
                )}
                {profile?.lead_bachelor && (
                  <Stack
                    gap="20px"
                    sx={{ padding: "0 10px", borderLeft: "1px solid #405189" }}
                  >
                    <Box>
                      <Typography variant="h5">Bachelor Subject</Typography>
                      <Typography>
                        {profile?.lead_bachelor?.subject_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">University Name</Typography>
                      <Typography>
                        {profile?.lead_bachelor?.university_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Examination Year</Typography>
                      <Typography>
                        {profile?.lead_bachelor?.passing_year}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">CGPA</Typography>
                      <Typography>{profile?.lead_bachelor?.gpa}</Typography>
                    </Box>
                  </Stack>
                )}
                {profile?.lead_master && (
                  <Stack
                    gap="20px"
                    sx={{ padding: "0 10px", borderLeft: "1px solid #405189" }}
                  >
                    <Box>
                      <Typography variant="h5">Masters Subject</Typography>
                      <Typography>
                        {profile?.lead_master?.subject_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">University Name</Typography>
                      <Typography>
                        {profile?.lead_master?.university_name}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">Examination Year</Typography>
                      <Typography>
                        {profile?.lead_master?.passing_year}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="h5">CGPA</Typography>
                      <Typography>{profile?.lead_master?.gpa}</Typography>
                    </Box>
                  </Stack>
                )}
              </Box>
            </Box>

            <Box pt="40px">
              <Typography variant="h4">English Language Course</Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                  padding: "20px 0",
                  rowGap: "20px",
                  margin: "20px 0",
                  borderTop: "1px solid #ddd",
                }}
              >
                {profile?.lead_courses?.map((course) => (
                  <Box sx={{ padding: "0 10px" }}>
                    <Box
                      sx={{
                        "& table": {
                          borderCollapse: "collapse",
                          width: "100%",
                          marginTop: "10px",
                        },
                        "& td": { border: "1px solid #ddd", padding: "10px" },
                        "& td:first-of-type": {
                          fontWeight: "500",
                        },
                        "& tr:nth-of-type(5) td:last-child": {
                          color: "#d14a22",
                        },
                      }}
                    >
                      <Typography variant="h5" align="center">
                        {course?.course_name}
                      </Typography>
                      <table>
                        <tbody>
                          {course?.course_name === "DUOLINGO" ? (
                            <>
                              <tr>
                                <td>Literacy</td>
                                <td>{course?.literacy}</td>
                              </tr>
                              <tr>
                                <td>Comprehension</td>
                                <td>{course?.comprehension}</td>
                              </tr>
                              <tr>
                                <td>Conversation</td>
                                <td>{course?.conversation}</td>
                              </tr>
                              <tr>
                                <td>Production</td>
                                <td>{course?.production}</td>
                              </tr>
                              <tr>
                                <td>Overall</td>
                                <td>{course?.overall}</td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr>
                                <td>Listening</td>
                                <td>{course?.listening}</td>
                              </tr>
                              <tr>
                                <td>Writing</td>
                                <td>{course?.writing}</td>
                              </tr>
                              <tr>
                                <td>Reading</td>
                                <td>{course?.reading}</td>
                              </tr>
                              <tr>
                                <td>Speaking</td>
                                <td>{course?.speaking}</td>
                              </tr>
                              <tr>
                                <td>Overall</td>
                                <td>{course?.overall}</td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>

            {profile?.lead_jobs?.length > 0 && (
              <Box mt="20px">
                <Typography variant="h4">Job Information</Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
                    rowGap: "20px",
                    padding: "20px 0",
                    margin: "20px 0",
                    borderTop: "1px solid #ddd",
                    "& > div:nth-of-type(4), & > div:nth-of-type(5), & > div:nth-of-type(6)":
                      {
                        borderTop: "1px solid #ddd",
                        paddingTop: "20px",
                      },
                  }}
                >
                  {profile?.lead_jobs?.map((job) => (
                    <Stack
                      gap="20px"
                      sx={{
                        padding: "0 10px",
                        borderLeft: "1px solid #405189",
                      }}
                    >
                      <Box>
                        <Typography variant="h5">Title</Typography>
                        <Typography>{job?.title || "-"}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">Company Name</Typography>
                        <Typography>{job?.company_name || "-"}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">Company Address</Typography>
                        <Typography>{job?.company_address || "-"}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">Join Date</Typography>
                        <Typography>
                          {job?.join_date
                            ? moment(job?.join_date).format("ll")
                            : "-"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">End Date</Typography>
                        <Typography>
                          {job?.end_date
                            ? moment(job?.end_date).format("ll")
                            : "-"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">Duration</Typography>
                        <Typography>{job?.duration || "-"}</Typography>
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Box>
            )}

            <Box pt="40px">
              <Typography variant="h4">Preferred Countries</Typography>
              <Box
                sx={{
                  padding: "20px 0",
                  margin: "20px 0",
                  borderTop: "1px solid #ddd",
                }}
              >
                <Box
                  gap="20px"
                  sx={{
                    display: "flex",
                    flexFlow: "wrap",
                    gap: "10px",
                    "& h6": {
                      backgroundColor: "#efefef",
                      padding: "5px 10px",
                      borderRadius: "5px",
                      fontWeight: "400",
                    },
                  }}
                >
                  {profile?.lead_preferred_countries?.map(({ name }) => (
                    <Typography variant="h6">{name}</Typography>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Profile;
