import { Box, Button, Typography } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import {
  createInvoice,
  projectsList,
  updateInvoice,
} from "features/invoices/invoices/api/invoices";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import handleError from "utils/handleError";
import validationSchema from "../../schema";
import InvoicePriceTable from "./InvoicePriceTable";
import useQueries from "hooks/useQueries";

export let checkePaidAmount = (values) =>
  Number(values.paid_amount) >= Number(values.total) &&
  Number(values.total) !== 0 &&
  Number(values.paid_amount) !== 0;

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  references,
  setAllInvoices,
  allInvoices,
  paymentMethods,
}) => {
  const { row_id } = useQueries();
  const { loading, fetchData } = useApi();
  const [currInvoice, setCurrInvoice] = useState(row_id ? {} : persistedData);
  const isUpdating = row_id ? true : false;
  const { base, updateBase } = useBase();
  const {
    assignees,
    customers,
    intakes,
    fees,
    currencies,
    settings,
    branches,
  } = base;
  const [projects, setProjects] = useState([]);
  const currency_id = settings?.app?.currency_id;
  const currency_symbol = currency_id
    ? currencies.find((c) => c.id === currency_id).symbol
    : "";

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullInvoice = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/invoice/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrInvoice(result?.data);
    };
    row_id && getFullInvoice();
  }, []);

  const onSubmit = async (data) => {
    const result = await fetchData(
      isUpdating ? updateInvoice(currInvoice?.id, data) : createInvoice(data)
    );
    if (result?.success) {
      if (isUpdating) {
        setAllInvoices(
          allInvoices.map((invoice) =>
            invoice.id === currInvoice.id
              ? { ...currInvoice, ...result?.data }
              : invoice
          )
        );
      } else {
        setAllInvoices([result?.data, ...allInvoices]);
      }
      updateBase(["invoices"]);
      handleClose("reset");
    } else {
      handleError(result, formik);
    }
  };

  const copyMatchingData = (schemaObj, sourceObj) => {
    const obj = {};
    for (const key in schemaObj) {
      if (sourceObj.hasOwnProperty(key)) {
        obj[key] = sourceObj[key];
      }
    }
    return obj;
  };

  // initial values depending on edit mode or create mode
  const initialValues = {
    customer_id: currInvoice?.customer_id || "",
    project_id: currInvoice?.project_id || "",
    reference_id: currInvoice?.reference_id || "",
    due_date: currInvoice?.due_date || null,
    tax: currInvoice?.tax || 0,
    discount: currInvoice?.discount || 0,
    subtotal: currInvoice?.subtotal || 0,
    total: currInvoice?.total || 0,
    note: currInvoice?.note || "",
    status: currInvoice?.status || "unpaid",
    currency_id: currInvoice?.currency_id || currency_id || "",
    paid_amount: currInvoice?.paid_amount || 0,
    intake_id: currInvoice?.intake_id || "",
    branch_id: currInvoice?.branch_id || "",
    payment_method_id: currInvoice?.payment_method_id || "",
    tags: currInvoice?.tags || [],
    assignees: currInvoice?.assignees?.map((user) => user?.user_id) || [],
    invoice_details:
      currInvoice?.invoice_details?.map((invoice) =>
        copyMatchingData(
          {
            fee_id: 3,
            tax: 10,
            discount: 0,
            subtotal: 1000,
            total: 1010,
          },
          invoice
        )
      ) || [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const getProjects = async () => {
      const result = await projectsList({
        id: formik.values.customer_id || currInvoice.customer_id || "",
      });
      if (result.success) setProjects(result.data);
    };
    if (currInvoice.customer_id || formik.values.customer_id) getProjects();
  }, [formik.values.customer_id]);

  useEffect(() => {
    formik.dirty &&
      checkePaidAmount(formik.values) &&
      formik.setFieldValue("due_date", null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.total, formik.values.paid_amount, formik.dirty]);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: isUpdating ? "Edit Invoice" : "New Invoice",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box
          p="20px"
          sx={{
            height: "calc(100vh - 143px)",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
            }}
          >
            <Select
              sx={{ gridColumn: "span 3" }}
              name="branch_id"
              formik={formik}
              options={branches}
              label="Branch"
              selectProps={{ id: "id", label: "name" }}
            />
            <Box
              sx={{
                gridColumn: "span 3",
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "10px",
              }}
            >
              <Select
                options={customers}
                value={formik?.values?.customer_id}
                onChange={(newValue) => {
                  formik.setFieldValue("customer_id", newValue);
                  formik.setFieldValue("project_id", "");
                }}
                label="Customer *"
                selectProps={{ id: "id", label: "name" }}
              />
              <Select
                disabled={!formik.values.customer_id}
                options={projects}
                label="Project"
                name="project_id"
                formik={formik}
                selectProps={{ id: "id", label: "name" }}
              />
            </Box>
            <MultiSelect
              options={assignees}
              label="Assignees *"
              name="assignees"
              formik={formik}
              selectProps={{
                id: "user_id",
                label: "name",
              }}
            />
            <Select
              options={references}
              label="Reference"
              name="reference_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={intakes}
              label="Intake"
              name="intake_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              name="payment_method_id"
              options={paymentMethods}
              label="Select Payment Method"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={currencies}
              label="Currency *"
              name="currency_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            <InputField
              name="paid_amount"
              label="Paid Amount"
              formik={formik}
            />
            {checkePaidAmount(formik.values) ? null : (
              <DateTimePicker
                name="due_date"
                label="Due Date *"
                type="date"
                formik={formik}
              />
            )}

            <InputField
              sx={{ gridColumn: "span 3" }}
              name="note"
              label="Note"
              multiline
              rows={2}
              formik={formik}
            />
          </Box>
          <Box
            sx={{
              borderTop: "1px dashed #ddd",
              borderBottom: "1px dashed #ddd",
              mt: "15px",
              py: "15px",
              mx: "-20px",
            }}
          >
            <InvoicePriceTable formik={formik} fees={fees} />
          </Box>
          <Box>
            <Box
              sx={{
                width: "300px",
                marginLeft: "auto",
                mt: "5px",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "100px auto",
                }}
              >
                <Typography align="right">Sub Total</Typography>
                <Typography align="right" sx={{ fontWeight: 700 }}>
                  {currency_symbol}
                  {formik.values.subtotal}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "100px auto",
                }}
              >
                <Typography align="right">Tax</Typography>
                <Typography align="right" sx={{ fontWeight: 700 }}>
                  {currency_symbol}
                  {formik.values.tax}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "100px auto",
                }}
              >
                <Typography align="right">Discount</Typography>
                <Typography align="right" sx={{ fontWeight: 700 }}>
                  -{currency_symbol}
                  {formik.values.discount}
                </Typography>
              </Box>
              <Box
                sx={{
                  borderTop: "1px solid #cbcbcb",
                  display: "grid",
                  gridTemplateColumns: "100px auto",
                  pt: "5px",
                  mt: "5px",
                }}
              >
                <Typography align="right" fontSize="16px">
                  Total
                </Typography>
                <Typography
                  align="right"
                  fontSize="16px"
                  sx={{ fontWeight: 700 }}
                >
                  {currency_symbol}
                  {formik.values.total}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          padding: "20px",
        }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={
            !(formik.dirty && formik.isValid) ||
            loading ||
            (checkePaidAmount(formik.values) ? false : !formik.values.due_date)
          }
        >
          {isUpdating ? "Update Invoice" : "Save Invoice"}
        </Button>
      </Box>
    </RightDrawer>
  );
};

export default AddEdit;
