import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useState } from "react";
import processNestedOptions from "utils/processNestedOptions";

const Select = (props) => {
  const isGroup = props?.isGroup;
  const variant = props.variant;
  const type = isGroup
    ? "object"
    : (props?.options && typeof props?.options[0]) || "string";
  const selectProps = props?.selectProps || { id: "id", label: "label" };
  const value = props.value || props.defaultValue || null;
  const size = props?.size;
  const label = props?.label;
  const onChange = props?.onChange;
  const options = isGroup
    ? processNestedOptions(props.options || [])
    : props.options || [];
  const isCountry = props.isCountry || false;
  const disableClearable = props.disableClearable || false;
  const formik = props.formik;
  const name = props.name;

  const [inputValue, setInputValue] = useState("");
  const isComplex = props.isComplex;

  const getFormikValue = () => {
    if (!isComplex) return formik.values[name];
    // eslint-disable-next-line no-useless-escape
    else
      return name.split(/[\.\[\]]+/).reduce((acc, key) => {
        if (Array.isArray(acc)) {
          return acc[parseInt(key)];
        }
        return acc[key];
      }, formik.values);
  };

  return formik ? (
    <>
      {isCountry ? (
        <Autocomplete
          {...props}
          size={size || "small"}
          value={
            type !== "object" && getFormikValue()
              ? getFormikValue() || null
              : options.find(
                  (o) =>
                    o[selectProps.id]?.toString() ===
                    getFormikValue()?.toString()
                ) || null
          }
          onChange={(event, newValue, reason) => {
            if (reason === "clear") {
              formik?.setFieldTouched(name, true, true);
            }

            if (event.target.nodeName === "LI") {
              formik.setFieldValue(
                name,
                type !== "object" ? newValue : newValue[selectProps.id]
              );
            } else {
              formik.setFieldValue(name, "");
            }
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option?.[selectProps?.id]}
            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option[selectProps.label]} ({option.code})
            </Box>
          )}
          getOptionLabel={(option) => option[selectProps.label]}
          disableClearable={disableClearable}
          fullWidth
          renderInput={(params) => (
            <TextField
              variant={variant || "outlined"}
              {...params}
              label={label || ""}
            />
          )}
        />
      ) : (
        <Autocomplete
          {...props}
          disableClearable={disableClearable}
          size={size || "small"}
          value={
            type !== "object" && getFormikValue()
              ? getFormikValue() || null
              : options.find(
                  (o) =>
                    o[selectProps.id]?.toString() ===
                    getFormikValue()?.toString()
                ) || null
          }
          onChange={(event, newValue, reason) => {
            if (reason === "clear") {
              formik?.setFieldTouched(name, true, true);
            }
            if (event.target.nodeName === "LI") {
              formik.setFieldValue(
                name,
                type !== "object" ? newValue : newValue[selectProps.id]
              );
            } else {
              formik.setFieldValue(name, "");
            }
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                "& > img": { mr: 2, flexShrink: 0 },
                fontWeight: option?.isGroupTitle && "500",
                pointerEvents: option?.isGroupTitle ? "none" : "all",
                "&:hover": {
                  pointerEvents: option?.isGroupTitle ? "none" : "all",
                },
              }}
              {...props}
              key={option?.[selectProps?.id]}
            >
              {option.color && (
                <Box
                  sx={{
                    height: "12px",
                    width: "12px",
                    borderRadius: "2px",
                    backgroundColor: option.color,
                    marginRight: "5px",
                    pointerEvents: "none",
                  }}
                ></Box>
              )}
              {
                <Box
                  sx={{ pl: isGroup && !option?.isGroupTitle ? "20px" : "5px" }}
                ></Box>
              }
              {type === "object" ? option[selectProps.label] : option}
              {option.symbol && (
                <span style={{ marginLeft: "5px", pointerEvents: "none" }}>
                  ({option.symbol})
                </span>
              )}
              {option.invoice_number && (
                <span style={{ marginLeft: "5px", pointerEvents: "none" }}>
                  ({option.customer_name}{" "}
                  {option.project_name && `, ${option.project_name}`})
                </span>
              )}
            </Box>
          )}
          getOptionLabel={(option) => {
            return type !== "object" ? option : option[selectProps.label];
          }}
          fullWidth
          renderInput={(params) => (
            <TextField
              variant={variant || "outlined"}
              sx={{ color: "red" }}
              {...params}
              label={label || ""}
              error={formik.touched[name] && formik.errors[name]}
              helperText={formik.touched[name] && formik.errors[name]}
            />
          )}
        />
      )}
    </>
  ) : (
    <>
      {isCountry ? (
        <Autocomplete
          {...props}
          size={size || "small"}
          value={
            type !== "object" && value
              ? value || null
              : options.find(
                  (o) => o[selectProps.id]?.toString() === value?.toString()
                ) || null
          }
          onChange={(event, newValue) => {
            if (event.target.nodeName === "LI") {
              type !== "object"
                ? onChange(newValue)
                : onChange(newValue[selectProps.id]);
            } else {
              onChange(null);
            }
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
              {...props}
              key={option?.[selectProps?.id]}

            >
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                alt=""
              />
              {option[selectProps.label]} ({option.code})
            </Box>
          )}
          getOptionLabel={(option) => option[selectProps.label]}
          disableClearable={disableClearable}
          fullWidth
          renderInput={(params) => (
            <TextField
              variant={variant || "outlined"}
              {...params}
              label={label || ""}
              {...props}
            />
          )}
        />
      ) : (
        <Autocomplete
          {...props}
          disableClearable={disableClearable}
          size={size || "small"}
          value={
            type !== "object"
              ? value || null
              : options.find(
                  (o) => o[selectProps.id]?.toString() === value?.toString()
                ) || null
          }
          onChange={(event, newValue) => {
            if (event.target.nodeName === "LI") {
              type !== "object"
                ? onChange(newValue)
                : onChange(newValue[selectProps.id]);
            } else {
              onChange(null);
            }
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          renderOption={(props, option) => (
            <Box
              component="li"
              sx={{
                "& > img": { mr: 2, flexShrink: 0 },
                fontWeight: option?.isGroupTitle && "500",
                pointerEvents: option?.isGroupTitle ? "none" : "all",
                "&:hover": {
                  pointerEvents: option?.isGroupTitle ? "none" : "all",
                },
              }}
              {...props}
            >
              {option.color && (
                <Box
                  sx={{
                    height: "12px",
                    width: "12px",
                    borderRadius: "2px",
                    backgroundColor: option.color,
                    marginRight: "5px",
                    pointerEvents: "none",
                  }}
                ></Box>
              )}
              {
                <Box
                  sx={{ pl: isGroup && !option?.isGroupTitle ? "20px" : "5px" }}
                ></Box>
              }
              {type === "object" ? option[selectProps.label] : option}
              {option.symbol && (
                <span style={{ marginLeft: "5px", pointerEvents: "none" }}>
                  ({option.symbol})
                </span>
              )}
              {option.invoice_number && (
                <span style={{ marginLeft: "5px", pointerEvents: "none" }}>
                  ({option.customer_name}{" "}
                  {option.project_name && `, ${option.project_name}`})
                </span>
              )}
            </Box>
          )}
          getOptionLabel={(option) =>
            type !== "object" ? option : option[selectProps.label]
          }
          // disableClearable={true}
          fullWidth
          renderInput={(params) => (
            <TextField
              variant={variant || "outlined"}
              sx={{ color: "red" }}
              {...params}
              label={label || ""}
              error={props.error}
              helperText={props.helperText}
            />
          )}
        />
      )}
    </>
  );
};

export default Select;
