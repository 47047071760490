import { Box, Button } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useQueries from "hooks/useQueries";
import { useState } from "react";
import { useEffect } from "react";
import handleError from "utils/handleError";
import ReactLoading from "react-loading";
import * as yup from "yup";

export let monthsInformat = [
  {
    id: "january",
    name: "January",
  },
  {
    id: "february",
    name: "February",
  },
  {
    id: "march",
    name: "March",
  },
  {
    id: "april",
    name: "April",
  },
  {
    id: "may",
    name: "May",
  },
  {
    id: "june",
    name: "June",
  },
  {
    id: "july",
    name: "July",
  },
  {
    id: "august",
    name: "August",
  },
  {
    id: "september",
    name: "September",
  },
  {
    id: "october",
    name: "October",
  },
  {
    id: "november",
    name: "November",
  },
  {
    id: "december",
    name: "December",
  },
];

let validationSchema = yup.object({
  bank_invoice_status_id: yup.string("").required().nullable(),
  installation_fee: yup.number().required("Required Field"),
  charge: yup.number().required("Required Field"),
});

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  setAllSales,
  allSales,
  customers,
  references,
  bankInvoiceStatuses,
  banks,
}) => {
  const { row_id } = useQueries();
  const isUpdating = row_id ? true : false;
  const { loading, fetchData } = useApi();
  const [currSale, setCurrSale] = useState(row_id ? {} : persistedData);

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullSale = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/sales/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrSale(result?.data);
    };
    row_id && getFullSale();
  }, []);

  const onSubmit = async (data) => {
    let endpoint;
    if (isUpdating) {
      endpoint = {
        method: "put",
        url: `/api/admin/sales/update/${row_id}`,
        data: data,
      };
    } else {
      endpoint = {
        method: "post",
        url: `/api/admin/sales/store`,
        data,
      };
    }

    const result = await fetchData(endpoint);
    if (result?.success) {
      if (isUpdating) {
        setAllSales(
          allSales.map((item) =>
            item.id === row_id ? { ...currSale, ...result.data } : item
          )
        );
      } else {
        setAllSales([result.data, ...allSales]);
      }
      handleClose("reset");
    } else {
      handleError(result, formik);
    }
  };

  // initial values depending on edit mode or create mode
  const initialValues = {
    customer_id: currSale?.customer_id || null,
    reference_id: currSale?.reference_id || null,
    contact_name: currSale?.contact_name || "",
    contact_number: currSale?.contact_number || "",
    bank_id: currSale?.bank_id || null,
    bank_invoice_status_id: currSale?.bank_invoice_status_id || null,
    account_open_date: currSale?.account_open_date || "",
    account_close_date: currSale?.account_close_date || "",
    followup_date: currSale?.followup_date || "",
    note: currSale?.note || "",
    installation_fee: currSale?.installation_fee || "",
    charge: currSale?.charge || "",
    expense: currSale?.expense || "",
    expected_payment_id: currSale?.expected_payment_id || null,
    payment_id: currSale?.payment_id || null,
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  useEffect(() => {
    if (formik.values.expected_payment_type === "charge") {
      formik.setFieldValue("year", currSale.year);
      formik.setFieldValue("month", currSale.month);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.expected_payment_type]);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: isUpdating ? "Edit Sale Invoice" : "New Sale Invoice",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <>
          <Box
            sx={{
              height: "calc(100vh - 145px)",
              overflowY: "auto",
              p: "20px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
                pb: "15px",
              }}
            >
              <Select
                options={customers}
                label="Customer"
                name="customer_id"
                selectProps={{ id: "id", label: "name" }}
                formik={formik}
              />

              <Select
                options={banks}
                label="Banks"
                name="bank_id"
                selectProps={{ id: "id", label: "name" }}
                formik={formik}
              />
              <Select
                options={bankInvoiceStatuses}
                label="Bank Invoice Status *"
                name="bank_invoice_status_id"
                selectProps={{ id: "id", label: "name" }}
                formik={formik}
              />

              <Select
                options={references}
                label="Reference"
                name="reference_id"
                formik={formik}
                selectProps={{ id: "id", label: "name" }}
              />
              <DateTimePicker
                name="followup_date"
                label="Followup Date "
                formik={formik}
              />
              <InputField
                name="contact_name"
                label="Contact Name"
                formik={formik}
              />
              <InputField
                name="contact_number"
                label="Contact Number"
                formik={formik}
              />

              <InputField
                name="installation_fee"
                label="Installation Fee *"
                type="number"
                formik={formik}
              />
              <InputField
                name="charge"
                label="Subscription Fee *"
                type="number"
                formik={formik}
              />

              <InputField
                name="expense"
                label="Expense"
                type="number"
                formik={formik}
              />
              <DateTimePicker
                name="account_open_date"
                label="Confirmation Date"
                formik={formik}
              />
              <DateTimePicker
                name="account_close_date"
                label="Account Close Date "
                formik={formik}
              />
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                gap: "10px",
                py: "15px",
              }}
            >
              <InputField
                name="note"
                label="Additional Information"
                multiline
                rows={3}
                formik={formik}
              />
            </Box>
          </Box>
          <Box sx={{ p: "20px" }}>
            <Button
              variant="solid"
              onClick={formik.submitForm}
              fullWidth
              disabled={!(formik.dirty && formik.isValid) || loading}
            >
              {isUpdating ? "Update Sale" : "Save Sale"}
            </Button>
          </Box>
        </>
      )}
    </RightDrawer>
  );
};

export default AddEdit;
