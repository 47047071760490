import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import PopoverAG from "components/ui/popoverAG";
import useBase from "hooks/useBase";
import moment from "moment";

const LatestTasks = ({ data }) => {
  const rows = data.tasks || [];
  const columns = [
    { id: "related_to", label: "Related" },
    { id: "name", label: "Task Name", minWidth: 150 },
    { id: "priority", label: "Priority" },
    { id: "assignees", label: "Assigness", align: "center" },
    { id: "status", label: "Status" },
    { id: "date_added", label: "Start Date", minWidth: 100 },
    { id: "due_date", label: "Due Date" },
  ];

  const taskpriorities = [
    { id: 1, label: "Low", color: "#008000" },
    { id: 2, label: "Medium", color: "#FFA500" },
    { id: 3, label: "High", color: "#FF0000" },
    { id: 4, label: "Urgent", color: "#800080" },
  ];

  const { base } = useBase();
  const { taskStatuses } = base;
  const getTaskstatus = (statusId) =>
    taskStatuses?.find((p) => p.id === statusId);

  const getPriorityDetails = (value) =>
    taskpriorities?.find((p) => p.id === Number(value));

  return (
    <div>
      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "3px",
          boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
        }}
      >
        <Typography sx={{ borderBottom: "1px solid #efefef", p: "10px 15px" }}>
          Todays's Due Tasks
        </Typography>
        <Box sx={{ height: "550px", overflowY: "auto" }}>
          <TableContainer sx={{ height: "100%", overflowX: "auto" }}>
            <Table
              stickyHeader
              sx={{
                "& th:first-of-type": { pl: "20px", pr: "0px" },
                "& th:last-child": { pr: "20px" },
                "& td:first-of-type": { pl: "20px", pr: "0px" },
                "& td:last-child": { pr: "20px" },
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map((row) => (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "status" ? (
                            <Box
                              sx={{
                                bgcolor: `${
                                  getTaskstatus(row?.task_status_id)?.color
                                }15`,
                                color: getTaskstatus(row?.task_status_id)
                                  ?.color,
                                textAlign: "center",
                                padding: "3px 5px",
                                borderRadius: "20px",
                                width: "100px",
                                mt: "5px",
                                fontSize: "13px",
                                textTransform: "capitalize",
                              }}
                            >
                              {getTaskstatus(row?.task_status_id)?.name}
                            </Box>
                          ) : column.id === "related_to" ? (
                            <Typography
                              variant="body2"
                              textTransform="capitalize"
                            >
                              {value}
                            </Typography>
                          ) : column.id === "date_added" ? (
                            value ? (
                              moment(value).format("ll")
                            ) : (
                              "-"
                            )
                          ) : column.id === "due_date" ? (
                            value ? (
                              moment(value).format("ll")
                            ) : (
                              "-"
                            )
                          ) : column.id === "assignees" ||
                            column.id === "followers" ? (
                            <PopoverAG items={value} />
                          ) : column.id === "priority" ? (
                            <Box
                              sx={{
                                backgroundColor: `${
                                  getPriorityDetails(value)?.color
                                }15`,
                                color: getPriorityDetails(value)?.color,
                                textAlign: "center",
                                padding: "3px 5px",
                                borderRadius: "20px",
                                width: "80px",
                                mt: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {getPriorityDetails(value)?.label}
                            </Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </div>
  );
};

export default LatestTasks;
