import {
  deleteTrashCustomer,
  filteredTrashCustomer,
  restoreAsyncTrashCustomer,
  trashCustomersAPI,
} from "./trashCustomersAPI";

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

const initialState = {
  isLoading: false,
  isError: false,
  customers: [],
  search: "",
  restoreStatus: false,
  deleteStatus: false,
  error: "",
};

// fetch trash customers
export const fetchCustomers = createAsyncThunk(
  "trashCustomer/fetchCustomers",
  async () => {
    const response = await trashCustomersAPI();

    return response;
  }
);

export const removeTrashCustomer = createAsyncThunk(
  "trashCustomer/removeCustomer",
  async (id) => {
    const response = await deleteTrashCustomer(id);

    return response;
  }
);

export const restoreTrashCustomer = createAsyncThunk(
  "trashCustomer/restoreCustomer",
  async (id) => {
    const response = await restoreAsyncTrashCustomer(id);

    return response;
  }
);

export const asyncTrashCustomerFilter = createAsyncThunk(
  "trashCustomer/filterCustomers",
  async ({ params, body_data }) => {
    const response = await filteredTrashCustomer(params, body_data);

    return response;
  }
);

const trashCustomersSlice = createSlice({
  name: "trashCustomer",
  initialState,
  reducers: {
    searchFile: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: (builder) => {
    // fetching customers case
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.customers = action.payload;
        state.error = "";
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.customers = [];
        state.error = action.error.message;
      });
    // fetching customers case
    builder
      .addCase(removeTrashCustomer.pending, (state) => {
        state.deleteStatus = false;
      })
      .addCase(removeTrashCustomer.fulfilled, (state, action) => {
        state.isError = false;
        state.customers = state.customers.filter(
          (customer) => customer.id !== action.meta.arg
        );
        state.deleteStatus = true;
        state.error = "";
      })
      .addCase(removeTrashCustomer.rejected, (state, action) => {
        state.deleteStatus = false;
        state.isError = true;
        state.error = action.error.message;
      });
    // restore customers case
    builder
      .addCase(restoreTrashCustomer.pending, (state) => {
        state.restoreStatus = false;
      })
      .addCase(restoreTrashCustomer.fulfilled, (state, action) => {
        state.isError = false;
        state.customers = state.customers.filter(
          (customer) => customer.id !== action.meta.arg
        );
        state.restoreStatus = true;
        state.error = "";
      })
      .addCase(restoreTrashCustomer.rejected, (state, action) => {
        state.restoreStatus = false;
        state.isError = true;
        state.error = action.error.message;
      });
    // filter customers case
    builder
      .addCase(asyncTrashCustomerFilter.fulfilled, (state, action) => {
        state.isError = false;
        state.customers = action.payload;
        state.error = "";
      })
      .addCase(asyncTrashCustomerFilter.rejected, (state, action) => {
        state.isError = true;
        state.error = action.error.message;
      });
  },
});

export default trashCustomersSlice.reducer;
export const { searchFile } = trashCustomersSlice.actions;
