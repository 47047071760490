import Skeleton from "@mui/material/Skeleton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const TableSkeleton = ({ rows, columns, tableHeight }) => {
  return (
    <TableContainer
      sx={{ height: tableHeight || "calc(100vh - 210px)", overflowX: "auto" }}
    >
      <Table
        stickyHeader
        sx={{
          "& th:first-of-type": { pl: "20px" },
          "& th:last-child": { pr: "20px" },
          "& td:first-of-type": { pl: "20px" },
          "& td:last-child": { pr: "20px" },
        }}
      >
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.id}
                align={column.align}
                style={column.style}
              >
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", maxWidth: "250px" }}
                />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(rows || 11)]?.map((row, i) => {
            return (
              <TableRow hover role="checkbox" key={i}>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    <Skeleton
                      variant="text"
                      sx={{ fontSize: "1rem", maxWidth: "250px" }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
