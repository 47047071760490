import {
  Checkbox,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PopoverAG from "components/ui/popoverAG";
import moment from "moment";
import React from "react";
import { MdDelete, MdRestore } from "react-icons/md";
import { toast } from "react-toastify";
import { updateProjectCustomerStatus } from "../../api/projectCustomer";
import useBase from "hooks/useBase";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  handleTableCheckBox,
  selectedRows,
  allCustomers,
  setAllCustomers,
  permissions,
  handleRestore,
}) => {
  const { base } = useBase();
  const { projectCustomerStatuses } = base;

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& .MuiTableCell-sizeMedium": {
            padding: "10px 0px 10px 16px",
          },
          "& .MuiTableCell-sizeMedium:first-of-type": {
            paddingLeft: "8px",
            paddingRight: "0px",
          },
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            disabled={!permissions["bulk action"]}
            checked={selectedRows?.includes(row.id)}
            onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
          />
        </TableCell>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={column.style}
            >
              {column.id === "created_at" ? (
                moment(value).format("ll")
              ) : column.id === "status_id" ? (
                <Box
                  sx={{
                    bgcolor: `${row?.status_color}15`,
                    color: row?.status_color,
                    textAlign: "center",
                    padding: "3px 5px",
                    borderRadius: "20px",
                  }}
                >
                  {
                    projectCustomerStatuses?.find(
                      (status) => status?.id === value
                    )?.name
                  }
                </Box>
              ) : column.id === "note" ? (
                <Typography
                  sx={{
                    overflow: "hidden",
                    WebkitLineClamp: "1",
                    WebkitBoxOrient: "vertical",
                    textOverflow: "ellipsis",
                    maxWidth: "150px",
                    maxHeight: "22px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {row?.notes?.length > 0
                    ? row?.notes[0]?.note
                    : "No notes created!"}
                </Typography>
              ) : column.id === "followup_date" ? (
                value ? (
                  moment(value).format("ll")
                ) : (
                  "-"
                )
              ) : column.id === "assignees" ? (
                <PopoverAG items={value} />
              ) : column.id === "action" ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Resotre">
                    <IconButton
                      disabled={!permissions.restore}
                      onClick={() => handleRestore(row.id)}
                    >
                      <MdRestore />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete permanently">
                    <IconButton
                      disabled={!permissions.delete}
                      onClick={() => handleDelete(row.id)}
                    >
                      <MdDelete />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                value || "-"
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </React.Fragment>
  );
};

export default MainTableRow;
