import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useDate } from "hooks/useDate";

const DateTime = () => {
  const { date, time } = useDate();

  return (
    <Box
      sx={{
        textAlign: "right",
        borderLeft: "1px solid #ddd",
        p: "0 15px",
        marginLeft: "10px",
      }}
    >
      <Typography sx={{ fontSize: "13px" }}>{date}</Typography>
      <Typography sx={{ fontSize: "14px" }}>{time}</Typography>
    </Box>
  );
};

export default DateTime;
