import Axios from "lib/Axios";

export const exportLead = ({ body_data }) =>
  Axios.post("/api/admin/lead/export", body_data).then((res) => res.data);

export const importSample = () =>
  Axios.get("/api/admin/lead/sample?type=xlsx").then((res) => res.data);

export const addNewLead = ({ body_data }) =>
  Axios.post("/api/admin/lead/create", body_data).then((res) => res.data);

export const editLead = ({ body_data, id }) =>
  Axios.post(`/api/admin/lead/update/${id}`, body_data).then((res) => res.data);

export const deleteLead = ({ id }) =>
  Axios.delete(`/api/admin/lead/delete/${id}`).then((res) => res.data);

export const viewAllLeads = ({ body_data, params }) =>
  Axios.post(`/api/admin/lead/trash/list?${params}`, body_data).then(
    (res) => res.data
  );

export const viewLead = ({ id }) =>
  Axios.get(`/api/admin/lead/show/${id}`).then((res) => res.data);

export const allLeadUtils = () =>
  Axios.get("/api/admin/base/lead-utils").then((res) => res.data);

export const updateLeadStatus = ({ body_data }) =>
  Axios.post("/api/admin/lead/change/status", body_data).then(
    (res) => res.data
  );

export const viewNote = ({ id }) =>
  Axios.get(`/api/admin/lead/note/${id}`).then((res) => res.data);

export const createNote = ({ body_data }) =>
  Axios.post("/api/admin/note/create", body_data).then((res) => res.data);

export const deleteNote = ({ id }) =>
  Axios.delete(`/api/admin/note/delete/${id}`).then((res) => res.data);

export const viewAttachment = ({ id }) =>
  Axios.get(`api/admin/lead/attachment/${id}`).then((res) => res.data);

export const uploadAttachment = ({ body_data, id, setProgress }) =>
  Axios.post(`/api/admin/lead/attachment/upload/${id}`, body_data, {
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      setProgress(percentComplete);
    },
  }).then((res) => res.data);

export const deleteAttachment = ({ id }) =>
  Axios.delete(`/api/admin/attachment/delete/${id}`).then((res) => res.data);

export const viewLeadSummary = () =>
  Axios.get(`api/admin/lead/summery`).then((res) => res.data);

export const importLeads = ({ body_data }) =>
  Axios.post("/api/admin/lead/import", body_data).then((res) => res.data);

export const leadBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/lead/bulk/action", body_data).then((res) => res.data);

export const viewTasks = ({ id, params }) =>
  Axios.get(`/api/admin/lead/task/${id}?${params}`).then((res) => res.data);

export const addTask = ({ body_data }) =>
  Axios.post("/api/admin/task/create", body_data).then((res) => res.data);

export const updateTask = ({ id }) =>
  Axios.post(`/api/admin/task/update/${id}`).then((res) => res.data);

export const deleteTask = ({ id }) =>
  Axios.delete(`/api/admin/task/delete/${id}`).then((res) => res.data);

export const restoreLead = ({ id }) =>
  Axios.get(`/api/admin/lead/restore/${id}`).then((res) => res.data);
