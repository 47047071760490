import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const ProjectTable = ({ user_id, customer_id }) => {
  const projectCustomerStatuses = useSelector(
    (state) => state.base.base_data.projectCustomerStatuses
  );
  const [allProject, setAllProject] = useState([]);
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 30,
    to: 0,
    from: 0,
    total: 0,
  });

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getCustomers = async () => {
      let finalPagination = { ...pagination };
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/project-customer/list?${params}`,
        data: user_id
          ? { user_id: [user_id] }
          : customer_id
          ? { customer_id: [customer_id] }
          : null,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllProject(allData || []);
        setPagination(rest);
      }
    };
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page]);

  const columns = [
    { id: "customer_name", label: "Customer" },
    { id: "project_name", label: "Project" },
    { id: "created_at", label: "FO Date", style: { width: "130px" } },
    { id: "followup_date", label: "Followup Date", style: { width: "150px" } },
    { id: "university_name", label: "University", style: { width: "190px" } },
    {
      id: "admission_status",
      label: "Update",
      style: { width: "100px" },
      align: "center",
    },
    { id: "intake_name", label: "Intake" },
    {
      id: "project_customer_status_id",
      label: "Status",
      style: { width: "160px", align: "center" },
    },
  ];

  return (
    <Box sx={{ m: "-20px" }}>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        {!loading ? (
          <TableContainer
            sx={{ height: "calc(100vh - 188px)", overflowX: "auto" }}
          >
            <Table
              stickyHeader
              sx={{
                "& th:first-of-type": { pl: "20px", pr: "0px" },
                "& th:last-child": { pr: "20px" },
                "& td:first-of-type": { pl: "20px", pr: "0px" },
                "& td:last-child": { pr: "20px" },
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, ...column.style }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allProject?.map((row) => (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : column.id === "created_at" ? (
                            moment(value).format("ll")
                          ) : column.id === "admission_status" ? (
                            <Box
                              sx={{
                                bgcolor: `${row?.admission_status_color}15`,
                                color: row?.admission_status_color,
                                textAlign: "center",
                                padding: "3px 5px",
                                borderRadius: "20px",
                                width: "100px",
                                mt: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {value}
                            </Box>
                          ) : column.id === "preferred_countries" ? (
                            value.map((cn) => cn.name).join(", ") || "-"
                          ) : column.id === "project_customer_status_id" ? (
                            <Box
                              sx={{
                                bgcolor: `${
                                  projectCustomerStatuses?.find(
                                    (pcs) =>
                                      pcs?.id ===
                                      row?.project_customer_status_id
                                  )?.color
                                }15`,
                                color: projectCustomerStatuses?.find(
                                  (pcs) =>
                                    pcs?.id === row?.project_customer_status_id
                                )?.color,
                                p: "4px",
                                borderRadius: "25px",
                                textAlign: "center",
                              }}
                            >
                              {
                                projectCustomerStatuses?.find(
                                  (pc) =>
                                    pc?.id === row?.project_customer_status_id
                                )?.name
                              }
                            </Box>
                          ) : column.id === "followup_date" ? (
                            value ? (
                              moment(value).format("ll")
                            ) : (
                              "-"
                            )
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableSkeleton columns={columns} tableHeight="calc(100vh - 188px)" />
        )}
        <TablePagination
          span={columns?.length}
          setReload={setReload}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Paper>
    </Box>
  );
};

export default ProjectTable;
