import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import SetupCC from "components/setupCC";
import Editor from "components/ui/editor/Editor";
import InputField from "components/ui/inputField";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import * as yup from "yup";
export function removeTagsAndWhitespace(html) {
  const cleanr = /<\/?[^>]+>|\s+/gi;
  const plainText = html?.replace(cleanr, " ");
  return plainText?.trim();
}
const NoticeBoard = () => {
  const initialValues = {
    title: "",
    description: "",
    status: 0,
  };

  return (
    <SetupCC
      shouldReload
      sidebarWidth="700px"
      name="Notice Board"
      baseRouteKey=""
      moduleKey="notice"
      columns={[
        { id: "title", label: "Title", minWidth: 100 },
        { id: "description", label: "Description", width: "180px" },
        { id: "status", label: "Status", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "description" ? (
              <Typography
                className={"clamped-text"}
                variant="body2"
                sx={{ width: "180px" }}
                dangerouslySetInnerHTML={{
                  __html: removeTagsAndWhitespace(value),
                }}
              ></Typography>
            ) : column?.id === "status" ? (
              <Box>
                {value ? (
                  <Box
                    sx={{
                      color: "green",
                      fontSize: "16px",
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <span>Active</span> <HiOutlineCheckBadge />
                  </Box>
                ) : (
                  <span>Inactive</span>
                )}
              </Box>
            ) : (
              value
            )}
          </>
        );
      }}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        title: yup.string().required("Title is required!"),
        description: yup
          .string()
          .test("name-validation", "Description is required!", (value) =>
            Boolean(removeTagsAndWhitespace(value))
          )
          .required("Description is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="title" label="Title *" formik={formik} />
          <Editor formik={formik} name="description" isResetNeed={false} />
          <FormControlLabel
            control={<Checkbox />}
            label="Want to Active?"
            checked={formik.values.status}
            onChange={(e) =>
              formik.setFieldValue("status", e.target.checked ? 1 : 0)
            }
          />
        </>
      )}
    ></SetupCC>
  );
};

export default NoticeBoard;
