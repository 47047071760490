import { Delete, Edit } from "@mui/icons-material";
import {
  Avatar,
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import UNIVERSAL from "config";
import { TbListDetails } from "react-icons/tb";

const StaffsTable = ({
  allStaffs,
  handleDelete,
  handleEdit,
  pagination,
  setPagination,
  loading,
  handleView,
  setReload,
  permissions,
}) => {
  const rows = allStaffs;

  const columns = [
    { id: "image", label: "Image" },
    { id: "name", label: "Name" },
    { id: "phone", label: "Phone" },
    { id: "email", label: "Email" },
    { id: "role", label: "Role" },
    { id: "designation_name", label: "Designation" },
    { id: "department_name", label: "Department" },
    {
      id: "action",
      label: "Actions",
      style: { width: "180px" },
      align: "center",
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 198px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 20px",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={row.code}
                    sx={{
                      "& .MuiTableCell-sizeMedium": {
                        padding: "13px 0px 13px 20px",
                      },
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={column.style}
                        >
                          {column.id === "image" ? (
                            <Avatar src={UNIVERSAL.BASEURL + value} />
                          ) : column.id === "action" ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "center",
                              }}
                            >
                              <Tooltip title="Details">
                                <IconButton onClick={() => handleView(row)}>
                                  <TbListDetails />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Edit">
                                <IconButton
                                  disabled={!permissions?.update}
                                  onClick={() => handleEdit(row)}
                                >
                                  <Edit style={{ fontSize: "22px" }} />
                                </IconButton>
                              </Tooltip>

                              <Tooltip title="Delete">
                                <IconButton
                                  disabled={!permissions?.delete}
                                  onClick={() => handleDelete(row.id)}
                                >
                                  <Delete style={{ fontSize: "22px" }} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 198px)" />
      )}
      <TablePagination
        pagination={pagination}
        setPagination={setPagination}
        setReload={setReload}
      />
    </Paper>
  );
};

export default StaffsTable;
