import { Box, Button, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useQueries from "hooks/useQueries";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import handleError from "utils/handleError";

import MultiSelect from "components/ui/multiSelect";
import { toast } from "react-toastify";
import {
  createReference,
  getAllSemesters,
  updateReference
} from "../../api/commissions";
import validationSchema from "../../schema";
import moment from "moment";
const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  setAllReference,
  allReference,
  intakes,
  references,
  universities,
  currencies,
  branches,
  applyThroughs,
  projects,
}) => {
  const [semesters, setSemesters] = useState([]);
  const { row_id } = useQueries();
  const [currReference, setCurrReference] = useState(
    row_id ? {} : persistedData
  );
  const isUpdating = row_id ? true : false;

  const { loading, fetchData } = useApi();

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullReference = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/invoice/university-reference/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrReference(result?.data);
    };
    row_id && getFullReference();
  }, []);
  useEffect(() => {
    const getSemesters = async () => {
      const result = await getAllSemesters();
      if (result?.data?.data) {
        setSemesters(result?.data?.data);
      }
    };
    getSemesters();
  }, []);
  const statuses = [
    { id: "paid", name: "Paid" },
    { id: "unpaid", name: "Unpaid" },
    { id: "partial", name: "Partial" },
    { id: "cancelled", name: "Cancelled" },
    { id: "unsent", name: "Unsent" },
  ];

  const onSubmit = async (data) => {
    const {
      commission,
      student_fees_paid_amount,
      student_commission_status,
      student_fees_paid_date,
      student_fees_due_date,
      university_commission_date,
      university_commission_amount,
      university_commission_due_date,
      university_commission_status,
      ...rest
    } = data;
    const formattedData = commission
      ? commission.map((name) => ({
          semester_id: name,
          student_fees_paid_amount: student_fees_paid_amount[name],
          student_fees_paid_date: student_fees_paid_date
            ? student_fees_paid_date[name]
            : null,
          student_commission_status: student_commission_status
            ? student_commission_status[name]
            : null,
          student_fees_due_date: student_fees_due_date
            ? student_fees_due_date[name]
            : null,
          university_commission_date: university_commission_date
            ? university_commission_date[name]
            : null,
          university_commission_amount: university_commission_amount[name],
          university_commission_due_date: university_commission_due_date
            ? university_commission_due_date[name]
            : null,
          university_commission_status: university_commission_status
            ? university_commission_status[name]
            : null,
        }))
      : [];

    let finalData = {
      ...rest,
      commission: formattedData || [],
    };

    const result = await fetchData(
      isUpdating
        ? updateReference(row_id, finalData)
        : createReference(finalData)
    );
    if (result?.success) {
      if (isUpdating) {
        setAllReference(
          allReference.map((reference) =>
            reference.id === currReference.id
              ? { ...currReference, ...result?.data }
              : reference
          )
        );
      } else {
        setAllReference([result?.data, ...allReference]);
      }
      // updateBase(["invoices"]);
      handleClose("reset");
    } else {
      const erros = handleError(result);
      erros?.map((err, i) => toast?.error(err));
    }
  };

  const initialValues = {
    customer_name: "",
    project_customer_id: "",
    reference_id: "",
    student_id: "",
    course_name: "",
    university_id: "",
    intake_id: "",
    branch_id: "",
    currency_id: "",
    note: "",
    status: "",
    apply_through_id: "",
    commission: [],

    student_fees_paid_amount: Object.fromEntries(
      semesters.map((semester) => [semester.id, 0])
    ),
    student_fees_paid_date: null,
    student_fees_due_date: null,
    student_commission_status: "",

    university_commission_date: null,
    university_commission_amount: Object.fromEntries(
      semesters.map((semester) => [semester.id, 0])
    ),
    university_commission_due_date: null,
    university_commission_status: "",
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let tempinitialValues = {
        customer_name: data?.customer_name,
        project_customer_id: data?.project_customer_id,
        reference_id: data?.reference_id,
        student_id: data?.student_id,
        course_name: data?.course_name,
        university_id: data?.university_id,
        intake_id: data?.intake_id,

        branch_id: data?.branch_id,
        currency_id: data?.currency_id,
        note: data?.note,
        status: data?.status,
        apply_through_id: data?.apply_through_id,

        commission: data?.semester_commissions?.map(
          (commission) => commission.semester_id
        ),

        student_fees_paid_amount: Object.fromEntries(
          data?.semester_commissions.map((commission) => [
            commission.semester_id,
            commission.student_fees_paid_amount,
          ])
        ),

        student_fees_paid_date: Object.fromEntries(
          data?.semester_commissions.map((commission) => [
            commission.semester_id,
            commission.student_fees_paid_date,
          ])
        ),
        student_fees_due_date: Object.fromEntries(
          data?.semester_commissions.map((commission) => [
            commission.semester_id,
            commission.student_fees_due_date,
          ])
        ),
        student_commission_status: Object.fromEntries(
          data?.semester_commissions.map((commission) => [
            commission.semester_id,
            commission.student_commission_status,
          ])
        ),
        university_commission_amount: Object.fromEntries(
          data?.university_commissions.map((commission) => [
            commission.semester_id,
            commission.university_commission_amount,
          ])
        ),
        university_commission_date: Object.fromEntries(
          data?.university_commissions.map((commission) => [
            commission.semester_id,
            commission.university_commission_date,
          ])
        ),
        university_commission_due_date: Object.fromEntries(
          data?.university_commissions.map((commission) => [
            commission.semester_id,
            commission.university_commission_due_date,
          ])
        ),
        university_commission_status: Object.fromEntries(
          data?.university_commissions.map((commission) => [
            commission.semester_id,
            commission.university_commission_status,
          ])
        ),
      };
      return tempinitialValues;
    } else return initialValues;
  };

  const formik = useFormik({
    initialValues: getInitialValues(currReference),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

 
  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: currReference?.id ? "Edit Commision" : "New Commision",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box p="20px">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
            }}
          >
            <InputField
              name="student_id"
              label="Student ID"
              multiline
              formik={formik}
            />
            <InputField
              name="course_name"
              label="Course Name"
              multiline
              formik={formik}
            />
            <InputField
              label="Customer Name *"
              name="customer_name"
              formik={formik}
            />
            <Select
              options={projects}
              label="Project"
              value={formik?.values?.project_customer_id}
              onChange={(value) =>
                formik.setFieldValue("project_customer_id", value)
              }
              selectProps={{ id: "id", label: "name" }}
            />

            <Select
              options={references}
              label="References"
              value={formik?.values?.reference_id}
              onChange={(value) => formik.setFieldValue("reference_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={universities}
              label="University *"
              value={formik?.values?.university_id}
              onChange={(value) => formik.setFieldValue("university_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={intakes}
              label="Intake"
              value={formik?.values?.intake_id}
              onChange={(value) => formik.setFieldValue("intake_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />

            <Select
              options={branches}
              label="Branch Name"
              name="branch_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />

            <Select
              options={currencies}
              label="Currency *"
              name="currency_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />

            <Select
              options={statuses}
              label="Status *"
              name="status"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={applyThroughs}
              label="Apply Through"
              name="apply_through_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
            <Box sx={{ gridColumn: "span 3" }}>
              <TextField
                fullWidth
                id="outlined-multiline-static"
                label="Note"
                multiline
                rows={3}
                variant="outlined"
                name="note"
                value={formik.values.note}
                onChange={formik.handleChange}
              />
            </Box>
          </Box>
          <Box mt={3}>
            <MultiSelect
              disableRemoveOption
              name="commission"
              label="Select Semesters"
              formik={formik}
              options={semesters}
              selectProps={{
                id: "id",
                label: "name",
              }}
            />
            {formik.values.commission?.length > 0 &&
              formik.values.commission.map((name) => (
                <Box
                  key={name}
                  sx={{
                    p: "10px 20px",
                    mt: "20px",
                    mx: "-20px",
                    px: "20px",
                  }}
                >
                  {semesters.map((semester) => (
                    <h3
                      key={semester.id}
                      style={{
                        textAlign: "center",
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#000",
                      }}
                    >
                      {semester.id == name ? semester.name : ""}
                    </h3>
                  ))}

                  <Box>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "20px",
                          pb: "20px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Tuition Fees Paid Amount"
                          variant="outlined"
                          size="small"
                          type="number"
                          name={`student_fees_paid_amount.${name}`}
                          value={
                            formik.values?.student_fees_paid_amount
                              ? formik.values.student_fees_paid_amount[name]
                              : 0
                          }
                          onChange={formik.handleChange}
                        />
                        <DatePicker
                          label="Tuition Fees Paid Date"
                          name={`student_fees_paid_date.${name}`}
                          value={
                            formik.values.student_fees_paid_date
                              ? formik.values.student_fees_paid_date[name]
                                ? formik.values.student_fees_paid_date[name]
                                : null
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `student_fees_paid_date.${name}`,
                              value ? moment(value).format("YYYY-MM-DD") :""
                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          inputFormat={"DD/MM/YYYY"}
                        />
                        <Select
                          options={[
                            { id: "paid", name: "Paid", color: "#008000" },
                            {
                              id: "pending",
                              name: "Pending",
                              color: "#FF0000",
                            },
                          ]}
                          label="Tuition Status *"
                          name={`student_commission_status.${name}`}
                          selectProps={{ id: "id", label: "name" }}
                          value={
                            formik.values.student_commission_status
                              ? formik.values.student_commission_status[name]
                                ? formik.values.student_commission_status[name]
                                : null
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `student_commission_status.${name}`,
                              value
                            )
                          }
                        />
                        <DatePicker
                          label="Tuition Fees Due Date"
                          name={`student_fees_due_date.${name}`}
                          value={
                            formik.values.student_fees_due_date
                              ? formik.values.student_fees_due_date[name]
                                ? formik.values.student_fees_due_date[name]
                                : null
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `student_fees_due_date.${name}`,
                              value ? moment(value).format("YYYY-MM-DD") :""

                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          inputFormat={"DD/MM/YYYY"}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "10px",
                          pb: "20px",
                        }}
                      >
                        <TextField
                          id="outlined-basic"
                          label="Commission Amount"
                          variant="outlined"
                          size="small"
                          type="number"
                          name={`university_commission_amount.${name}`}
                          value={
                            formik.values.university_commission_amount
                              ? formik.values.university_commission_amount[name]
                              : ""
                          }
                          onChange={formik.handleChange}
                        />
                        <DatePicker
                          label="Commission Date"
                          name={`university_commission_date.${name}`}
                          value={
                            formik.values.university_commission_date
                              ? formik.values.university_commission_date[name]
                                ? formik.values.university_commission_date[name]
                                : null
                              : null
                          }
                          onChange={(value) =>
                          { 
                            formik.setFieldValue(
                              `university_commission_date.${name}`,
                              value ? moment(value).format("YYYY-MM-DD") :""

                            )}
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          inputFormat={"DD/MM/YYYY"}
                        />
                        <DatePicker
                          label="Commission Due Date"
                          name={`university_commission_due_date.${name}`}
                          value={
                            formik.values.university_commission_due_date
                              ? formik.values.university_commission_due_date[
                                  name
                                ]
                                ? formik.values.university_commission_due_date[
                                    name
                                  ]
                                : null
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `university_commission_due_date.${name}`,
                              value ? moment(value).format("YYYY-MM-DD") :""

                            )
                          }
                          renderInput={(params) => (
                            <TextField size="small" {...params} />
                          )}
                          inputFormat={"DD/MM/YYYY"}
                        />

                        <Select
                          options={[
                            { id: "paid", name: "Paid", color: "#008000" },
                            {
                              id: "pending",
                              name: "Pending",
                              color: "#FF0000",
                            },
                          ]}
                          label="Commission Status *"
                          name={`university_commission_status.${name}`}
                          selectProps={{ id: "id", label: "name" }}
                          value={
                            formik.values.university_commission_status
                              ? formik.values.university_commission_status[name]
                                ? formik.values.university_commission_status[
                                    name
                                  ]
                                : null
                              : null
                          }
                          onChange={(value) =>
                            formik.setFieldValue(
                              `university_commission_status.${name}`,
                              value
                            )
                          }
                        />
                      </Box>
                    </LocalizationProvider>
                  </Box>
                </Box>
              ))}
          </Box>
          <Button
            variant="solid"
            onClick={formik.submitForm}
            fullWidth
            disabled={!(formik.dirty && formik.isValid) || loading}
            sx={{ gridColumn: "span 3", mt: "20px" }}
          >
            {currReference?.id ? "Update Commision" : "Save Commision"}
          </Button>
        </Box>
      )}
    </RightDrawer>
  );
};

export default AddEdit;
