import { Box, Button } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import {
  createMakePayment
} from "features/invoices/sales/api/sales";

import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { useEffect } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import * as yup from "yup";

const Payments = ({
  setMakePayment,
  currPayment,
  id,
  setReloadMain,
  setReload,
}) => {
  let paymentMethods = useSelector(
    (state) => state?.base?.base_data?.paymentMethods
  );
  const { loading, fetchData } = useApi();

  const initialValues = {
    installation_invoice_id: id,
    payment_method_id: "",
    expected_payment_id: currPayment?.id,
    payment_date: null,
    total_paid:currPayment.total_due,
    note: "",
    payble_amount: currPayment.total_due,
  };

  // const getInitialValues = (data) => {
  //   if (data?.id) {
  //     let obj = {};
  //     for (const key in initialValues) {
  //       obj[key] = data[key];
  //     }
  //     return obj;
  //   } else return initialValues;
  // };
  const onSubmit = async ({ payble_amount, ...data }) => {
    let result = await fetchData(createMakePayment(data));
    if (result?.success) {
      setReload((pre) => !pre);
      setReloadMain((pre) => !pre);
      setMakePayment(false);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      total_paid: yup
        .number()
        .positive("Required Field")
        .required("Required Field"),
      payment_method_id: yup.string("").required("Required Field").nullable(),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  
  return (
    <Box sx={{ m: "-10px" }}>
      <Box>
        <Button
          sx={{
            mb: "20px",
          }}
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setMakePayment(false)}
        >
          View Installment
        </Button>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "15px",
            pb: "15px",
          }}
        >
          <InputField
            name="payble_amount"
            label="Payable Amount"
            type="number"
            disabled
            formik={formik}
          />
          <Select
            name="payment_method_id"
            options={paymentMethods}
            label="Select Payment Method *"
            formik={formik}
            selectProps={{ id: "id", label: "name" }}
          />

          <InputField
            name="total_paid"
            label="Amount *"
            type="number"
            formik={formik}
          />
          <DateTimePicker
            name="payment_date"
            label="Payment Date"
            type="date"
            formik={formik}
          />

          <Box sx={{ gridColumn: "span 2" }}>
            <InputField
              fullWidth
              name="note"
              label="Note"
              multiline
              rows={3}
              formik={formik}
            />
          </Box>
          <Button
            sx={{ gridColumn: "span 2" }}
            variant="solid"
            onClick={formik.submitForm}
            fullWidth
            disabled={!(formik.dirty && formik.isValid) || loading}
          >
            Create Payment
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Payments;
