import { useState } from "react";

function useCopyToClipboard() {
  const copy = async (text) => {
    try {
      if (!navigator?.clipboard) {
        console.warn("Clipboard not supported");
        return false;
      }
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      return false;
    }
  };
  return copy;
}
const useCopy = (data) => {
  const [copied, setCopied] = useState(false);
  const copy = useCopyToClipboard();
  let handleCopy = () => {
    copy(data);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return [copied, handleCopy];
};
export default useCopy;
