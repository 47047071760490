import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { viewSummary } from "../api/payments";

const Summary = ({ search, filters }) => {
  const [paySummary, setPaySummary] = useState([]);

  useEffect(() => {
    const getPaymentSummary = async () => {
      const summaryData = await viewSummary({
        body_data: { ...filters, search },
      });
      setPaySummary(summaryData?.data);
    };
    getPaymentSummary();
  }, [search, filters]);

  return (
    <Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gap: "20px",
          padding: "10px 20px",
        }}
      >
        <Box
          sx={{
            bgcolor: "#fff",
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "2px 1px 3px #e5e5e5",
          }}
        >
          <Typography>Total Payment</Typography>
          <Typography variant="h3">{paySummary?.total_payment}</Typography>
        </Box>
      </Box>

      {paySummary?.payment_method_summery?.length && (
        <>
          <Typography
            variant="h5"
            sx={{ p: "10px 20px", borderBottom: "1px solid #ddd" }}
          >
            Amount based on Payment Method
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "20px",
              padding: "20px 20px",
            }}
          >
            {paySummary?.payment_method_summery?.map((pms) => (
              <Box
                sx={{
                  bgcolor: "#fff",
                  padding: "20px",
                  borderRadius: "5px",
                  boxShadow: "2px 1px 3px #e5e5e5",
                }}
              >
                <Typography textTransform="capitalize">
                  {pms.payment_method}
                </Typography>
                <Typography variant="h3">{pms.total}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
      {paySummary?.invoice_summery?.length && (
        <>
          <Typography
            variant="h5"
            sx={{ p: "10px 20px", borderBottom: "1px solid #ddd" }}
          >
            Amount based on Invoice
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
              gap: "20px",
              padding: "20px 20px",
            }}
          >
            {paySummary?.invoice_summery?.map((is) => (
              <Box
                sx={{
                  bgcolor: "#fff",
                  padding: "20px",
                  borderRadius: "5px",
                  boxShadow: "2px 1px 3px #e5e5e5",
                }}
              >
                <Typography>{is.invoice_number}</Typography>
                <Typography variant="h3">{is.total}</Typography>
              </Box>
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Summary;
