import * as yup from "yup";

const validationSchema = yup.object({
  branch_id: yup.string("").notRequired().nullable(),
  project_id: yup.string("").required("Project is Required").nullable(),
  customer_id: yup.string("").required("Customer is Required").nullable(),
  project_customer_status_id: yup
    .string("")
    .required("Status is Required")
    .nullable(),
  intake_id: yup.string("").required("Intake is Required").nullable(),
  assignees: yup.array().required("Assignee is Required").min(1),
});

export default validationSchema;
