import { Edit } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import useApi from "hooks/useApi";
import { decreament } from "lib/Reducer/features/todo/todoSlice";
import { MdDelete } from "react-icons/md";
import { useDispatch } from "react-redux";

const TodoActions = ({ todo: data, setTodos, setOpenEdit }) => {
  const { fetchData } = useApi();
  let dispatch = useDispatch();

  let handleDelete = async () => {
    if (!data.is_finished) dispatch(decreament());
    setTodos((todos) => todos?.filter((todo) => todo?.id !== data?.id));

    let endpoint = {
      method: "delete",
      url: `/api/admin/todo/delete/${data?.id}`,
    };
    fetchData(endpoint, false);
  };

  return (
    <Box
      sx={{
        zIndex: 12,
        backgroundImage: "linear-gradient(to right, transparent 10%, #fff 90%)",
        position: "absolute",
        right: 0,
        top: 0,
        height: "100%",
        display: "none",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "5px",
        px: "7px",
        width: "300px",
      }}
    >
      <Tooltip title="Edit">
        <IconButton onClick={() => setOpenEdit(data.id)}>
          <Edit />
        </IconButton>
      </Tooltip>

      <Tooltip title="Delete">
        <IconButton onClick={handleDelete}>
          <MdDelete />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default TodoActions;
