import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useState } from "react";
import ReactLoading from "react-loading";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const InvoiceAmount = ({ data, filters, setFilters, loading }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const chartData = data?.invoice_line_chart || [];

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: "3px",
        boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #efefef",
        }}
      >
        <Box
          sx={{
            p: "9.6px 15px 9.6px 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              position: "relative",
              fontWeight: "500",
              "&::before": {
                content: "''",
                position: "absolute",
                height: "1rem",
                width: ".2rem",
                insetBlockStart: ".18rem",
                insetInlineStart: "-.60rem",
                background:
                  "linear-gradient(to bottom, #c1acef 50%, #90daf2 50%)",
                borderRadius: ".5rem",
              },
            }}
          >
            Total invoice amount
          </Typography>
          <Box sx={{ display: "flex", gap: "10px" }}>
            {loading && filters.filterEntry.includes("invoice_amount") && (
              <ReactLoading
                type="bubbles"
                color="#405189"
                height={"15px"}
                width={"30px"}
              />
            )}
            <ToggleButtonGroup
              size="small"
              color="primary"
              value={filters?.invoice_line_chart_for}
              exclusive
              onChange={(e, value) => {
                if (value !== null && value !== "filter_by") {
                  setFilters({
                    ...filters,
                    filterEntry:["invoice_amount"],
                    invoice_line_chart_for: value,
                  });
                } else if (value === "filter_by") {
                  setOpenFilter((pre) => !pre);
                }
              }}
              sx={{
                "& button": {
                  padding: "3px 10px",
                },
              }}
            >
              <ToggleButton value="week">Weekly</ToggleButton>
              <ToggleButton value="month">Monthly</ToggleButton>
              <ToggleButton value="filter_by">Filter By</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Box>
        {openFilter && (
          <Box sx={{ bgcolor: "#f4f4f480", p: "10px" }}>
            <Stack direction="row" spacing={1}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  views={["year"]}
                  value={filters.year_invoice_line_chart}
                  onChange={(newValue) =>
                    setFilters({
                      ...filters,
                      filterEntry:["invoice_amount"],

                      year_invoice_line_chart: moment(newValue).isValid()
                        ? moment(newValue).format("YYYY")
                        : null,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select year"
                      size="small"
                      sx={{ width: "150px" }}
                    />
                  )}
                />
                {filters?.invoice_line_chart_for === "week" && (
                  <>
                    <TextField
                      size="small"
                      label="Select month"
                      select
                      value={filters.month_invoice_line_chart}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          filterEntry:["invoice_amount"],

                          month_invoice_line_chart: e.target.value,
                        })
                      }
                      sx={{ width: "150px" }}
                    >
                      <MenuItem value={"01"}>January</MenuItem>
                      <MenuItem value={"02"}>February</MenuItem>
                      <MenuItem value={"03"}>March</MenuItem>
                      <MenuItem value={"04"}>April</MenuItem>
                      <MenuItem value={"05"}>May</MenuItem>
                      <MenuItem value={"06"}>June</MenuItem>
                      <MenuItem value={"07"}>July</MenuItem>
                      <MenuItem value={"08"}>August</MenuItem>
                      <MenuItem value={"09"}>September</MenuItem>
                      <MenuItem value={"10"}>October</MenuItem>
                      <MenuItem value={"11"}>November</MenuItem>
                      <MenuItem value={"12"}>December</MenuItem>
                    </TextField>
                    <TextField
                      size="small"
                      label="Select week"
                      select
                      value={filters.week_invoice_line_chart}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          filterEntry:["invoice_amount"],

                          week_invoice_line_chart: e.target.value,
                        })
                      }
                      sx={{ width: "150px" }}
                    >
                      <MenuItem value={"current"}>Current</MenuItem>
                      <MenuItem value={"week1"}>Week 1</MenuItem>
                      <MenuItem value={"week2"}>Week 2</MenuItem>
                      <MenuItem value={"week3"}>Week 3</MenuItem>
                      <MenuItem value={"week4"}>Week 4</MenuItem>
                      <MenuItem value={"week5"}>Week 5</MenuItem>
                    </TextField>
                  </>
                )}

                <Button
                  variant="outlined"
                  disabled={
                    !(
                      filters.week_invoice_line_chart ||
                      filters.month_invoice_line_chart ||
                      filters.year_invoice_line_chart
                    )
                  }
                  onClick={() => {
                    setFilters({
                      ...filters,
                      filterEntry:["invoice_amount"],
                      week_invoice_line_chart: "",
                      month_invoice_line_chart: "",
                      year_invoice_line_chart: null,
                    });
                  }}
                >
                  Reset Filter
                </Button>
              </LocalizationProvider>
            </Stack>
          </Box>
        )}
      </Box>
      <Box sx={{ height: "480px", p: "20px 15px 20px 0" }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Line
              type="monotone"
              dataKey="amount"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default InvoiceAmount;
