import { NotificationsActive } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Chip, Typography } from "@mui/material";
import InputField from "components/ui/inputField";
import UNIVERSAL from "config";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import { MdAudiotrack } from "react-icons/md";
import { toast } from "react-toastify";
import * as yup from "yup";

const Notification = ({ puserCreds }) => {
  const { updateBase } = useBase();
  let { loading, fetchData } = useApi();
  const initialValues = {
    pusher_app_id: puserCreds?.pusher_app_id || "",
    pusher_key: puserCreds?.pusher_key || "",
    pusher_secret: puserCreds?.pusher_secret || "",
    pusher_cluster: puserCreds?.pusher_cluster || "",
    notification_sound: null,
  };

  const validationSchema = yup.object({
    notification_sound: yup
      .mixed()
      .notRequired()
      .test("fileSize", "File too large max size 1MB", (value) => {
        return value ? value.size <= 1000000 : true; // 1 MB
      })
      .test("fileType", "Only mp3 is supported", (value) => {
        return value ? ["audio/mpeg"].includes(value.type) : true;
      })
      .test(
        "fileDuration",
        "Audio file duration should be 3 seconds or less",
        async function (value) {
          try {
            const audio = new Audio();
            audio.src = URL.createObjectURL(value);
            await new Promise((resolve) => (audio.onloadedmetadata = resolve));
            return audio.duration <= 3;
          } catch (error) {
            return true;
          }
        }
      ),
  });
  const onSubmit = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    const endpoint = {
      method: "post",
      url: `/api/admin/settings/notification`,
      data: formData,
    };
    const result = await fetchData(endpoint, false);
    if (result.success) {
      toast.success(result.message);
      updateBase(["settings"]);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  let audioSoundForm = formik?.values?.notification_sound
    ? new Audio(URL.createObjectURL(formik?.values?.notification_sound))
    : null;

  let audioSoundApi = new Audio(
    UNIVERSAL.BASEURL + puserCreds?.notification_sound
  );

  return (
    <Box
      sx={{
        width: "650px",
        margin: "0 auto",
        border: "1px solid #efefef",
        borderRadius: "5px",
        padding: "15px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5">Pusher Credentials</Typography>
      <InputField name="pusher_app_id" formik={formik} label="Pusher ID" />
      <InputField name="pusher_key" formik={formik} label="Pusher Key" />
      <InputField name="pusher_secret" formik={formik} label="Pusher Secret" />
      <InputField
        name="pusher_cluster"
        formik={formik}
        label="Pusher Cluster"
      />
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Button
          component="label"
          startIcon={<MdAudiotrack />}
          variant="outlined"
          fullWidth
        >
          <input
            name="notification_sound"
            type="file"
            hidden
            accept="audio/mpeg"
            onChange={(e) =>
              formik.setFieldValue("notification_sound", e.target.files[0])
            }
          />
          Upload Notification Sound
        </Button>
        {formik.errors.notification_sound && (
          <Typography
            variant="body1"
            sx={{
              color: "#ec1818",
              fontSize: "12px",
            }}
          >
            {formik.errors.notification_sound}
          </Typography>
        )}
      </Box>
      <Box>
        {formik.values.notification_sound ? (
          <Chip
            label={formik.values.notification_sound.name}
            icon={
              <NotificationsActive
                sx={{
                  cursor: "pointer",
                  fill: "#00000040",
                  "&:hover": {
                    fill: "#00000066",
                  },
                }}
                onClick={() => {
                  audioSoundForm.play();
                }}
              />
            }
            deleteIcon={<DeleteIcon />}
            variant="outlined"
            onDelete={() => {
              formik.setFieldValue("notification_sound", null);
              audioSoundForm.pause();
            }}
          />
        ) : (
          puserCreds?.notification_sound && (
            <Chip
              label={puserCreds?.notification_sound.split("/")[2]}
              deleteIcon={<NotificationsActive />}
              variant={"outlined"}
              onDelete={() => {
                audioSoundApi.play();
              }}
            />
          )
        )}
      </Box>

      <Button
        variant="solid"
        onClick={formik.submitForm}
        fullWidth
        disabled={!(formik.dirty && formik.isValid) || loading}
      >
        Save Credentials
      </Button>
    </Box>
  );
};

export default Notification;
