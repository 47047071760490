import { Box, Typography } from "@mui/material";
import assets from "assets";
import useApi from "hooks/useApi";
import { useEffect, useRef, useState } from "react";
import AddTodo from "./AddTodo";
import TodoItem from "./TodoItem";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import ReactLoading from "react-loading";
import { BiFilter } from "react-icons/bi";
import TodoFilter from "./TodoFilter";

const Todo = () => {
  const user_type = useSelector((state) => state.auth.user_details?.role);
  const user_id = useSelector((state) => state.auth.user_details.user.id);
  const divScrollRef = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const initialFIlters = {
    search: "",
    user_id: user_id,
    is_finished: "",
    date_from: null,
    date_to: null,
  };
  const [todos, setTodos] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [filters, setFilters] = useState({
    ...initialFIlters,
    is_finished: false,
  });
  const [activeMenu, setActiveMenu] = useState("Active");
  const [openEdit, setOpenEdit] = useState("");

  const { loading, fetchData } = useApi();

  const updateData = (dataWithPaginaiton) => {
    const { data: todos, ...rest } = dataWithPaginaiton;
    setTodos((oldTodos) =>
      rest?.current_page === 1 ? todos : [...oldTodos, ...todos]
    );
    setPagination(rest);
  };

  const getTodos = async (page) => {
    const params = `page=${page}&limit=15`;
    const endpoint = {
      method: "post",
      url: `/api/admin/todo/list?${params}`,
      data: filters,
    };
    const result = await fetchData(endpoint, false);
    if (result?.success) {
      updateData(result.data);
    }
  };

  useEffect(() => {
    getTodos(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  const handleTodoMenus = (item) => {
    if (item === activeMenu) return;
    const filteredItems = {};
    if (item === "All") {
      filteredItems.is_finished = "";
    } else if (item === "Active") {
      filteredItems.is_finished = false;
    } else if (item === "Completed") {
      filteredItems.is_finished = true;
    }
    setActiveMenu(item);
    setFilters({ ...filters, ...filteredItems });
  };

  let handleDeleteCompletedTodos = async () => {
    setTodos([]);

    let endpoint = {
      method: "delete",
      url: `/api/admin/todo/complete/delete`,
    };
    fetchData(endpoint, false);
  };
  const isFiltering = false;

  return (
    <Box>
      <Box
        sx={{
          background:
            "rgb(116,109,205) linear-gradient(90deg, rgba(116,109,205,1) 35%, rgba(78,137,211,1) 100%)",
          backgroundImage: `url(${assets.todoBg})`,
          height: "200px",
          backgroundSize: "cover",
          backgroundPositionY: "bottom",
        }}
      ></Box>
      <Box sx={{ zIndex: 10 }}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "700px",
            margin: "0 auto",
            mt: "-180px",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              fontWeight: 500,
              fontSize: "26px",
              letterSpacing: "5px",
              mb: "20px",
              ml: "10px",
            }}
          >
            TODO
          </Typography>
          <AddTodo
            {...{
              setActiveMenu,
              setTodos,
              setFilters,
              activeMenu,
              initialFIlters,
            }}
          />
          <Box
            sx={{
              bgcolor: "#fff",
            }}
          >
            <Box
              sx={{
                borderBottom: "1px solid #ddd",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: "10px 15px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "12px",
                }}
              >
                <Box
                  onClick={() => setOpenFilter((pre) => !pre)}
                  sx={{
                    userSelect: "none",
                    px: "3px",
                    cursor: "pointer",
                    fontSize: "13px",
                    fontWeight: 500,
                    color: isFiltering ? "#658CF6" : "#777",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  <BiFilter fontSize="18px" />
                  <span>Filter</span>
                </Box>
                {["All", "Active", "Completed"].map((item) => (
                  <Box
                    onClick={() => handleTodoMenus(item)}
                    sx={{
                      userSelect: "none",
                      px: "3px",
                      cursor: "pointer",
                      fontSize: "13px",
                      fontWeight: 500,
                      color: item === activeMenu ? "#658CF6" : "#777",
                    }}
                  >
                    {item}
                  </Box>
                ))}
                {loading && (
                  <Box sx={{ mt: "-13px" }}>
                    <ReactLoading
                      type="bubbles"
                      color="#405189"
                      height={"15px"}
                      width={"30px"}
                    />
                  </Box>
                )}
              </Box>
              {activeMenu === "Completed" && (
                <Typography
                  onClick={handleDeleteCompletedTodos}
                  sx={{
                    px: "3px",
                    cursor: "pointer",
                    fontSize: "13px",
                    fontWeight: 500,
                    color: "#777",
                    userSelect: "none",
                  }}
                >
                  Clear Completed
                </Typography>
              )}
            </Box>
            <Box sx={{ display: openFilter ? "block" : "none" }}>
              <TodoFilter
                open={openFilter}
                handleClose={() => setOpenFilter(false)}
                filters={filters}
                setFilters={setFilters}
              />
            </Box>
            <Box
              sx={{
                height: openFilter
                  ? "calc(100vh - 360px)"
                  : "calc(100vh - 290px)",
                overflowY: "auto",
              }}
              id="scrollableDiv"
              ref={divScrollRef}
            >
              {!!todos?.length && (
                <InfiniteScroll
                  dataLength={todos?.length}
                  next={() => getTodos(pagination.current_page + 1)}
                  hasMore={
                    pagination.current_page === pagination?.last_page
                      ? false
                      : true
                  }
                  loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {todos?.map((todo) => (
                    <TodoItem
                      {...{
                        todo,
                        activeMenu,
                        setTodos,
                        openEdit,
                        setOpenEdit,
                      }}
                    />
                  ))}
                </InfiniteScroll>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Todo;
