import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {
  Avatar,
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { decreament, increament } from "lib/Reducer/features/todo/todoSlice";
import moment from "moment";
import { useDispatch } from "react-redux";
import TodoActions from "./TodoActions";
import { Close } from "@mui/icons-material";
import UNIVERSAL from "config";
import { useSelector } from "react-redux";

const TodoItem = ({ todo, setTodos, openEdit, setOpenEdit, activeMenu }) => {
  const { loading: stausLoading, fetchData: updateStatus } = useApi();
  const user_type = useSelector((state) => state.auth.user_details?.role);
  const dispatch = useDispatch();

  const handleCheckBox = async (todo) => {
    const endpoint = {
      method: "post",
      url: `/api/admin/todo/status`,
      data: {
        id: todo?.id,
        is_finished: Boolean(todo?.is_finished) ? 0 : 1,
      },
    };
    const result = await updateStatus(endpoint, false);
    if (result?.success) {
      handleUpdateResult(result.data, result.data.is_finished);
    }
  };

  const handleUpdateResult = (data, isFinished) => {
    isFinished ? dispatch(decreament()) : dispatch(increament());
    if (activeMenu === "All") {
      setTodos((todos) =>
        todos?.map((todo) => (todo?.id === data?.id ? data : todo))
      );
    } else {
      setTodos((todos) => todos?.filter((todo) => todo?.id !== data?.id));
    }
  };

  // Edit Item
  const initialValues = {
    text: todo?.text || "",
    date: todo?.date
      ? moment(todo?.date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD"),
  };

  const { fetchData } = useApi();

  const onSubmit = async (data) => {
    let endpoint = {
      method: "put",
      url: `/api/admin/todo/update/${todo.id}`,
      data,
    };
    const result = await fetchData(endpoint, false);
    if (result.success) {
      setTodos((todos) =>
        todos?.map((todo) =>
          todo?.id === openEdit ? { ...todo, ...result?.data } : todo
        )
      );
      formik.resetForm();
      setOpenEdit("");
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  return openEdit !== todo?.id ? (
    <Box
      sx={{
        p: "15px",
        borderBottom: "1px solid #ddd",
        backgroundColor: "#fff",
        position: "relative",
        paddingLeft: "55px",
        transition: ".2s",
        "&:hover": {
          bgcolor: "#efefef",
          "& div:last-child": {
            display: "flex",
          },
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: "13px",
          top: "14px",
          width: "32px",
          height: "32px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {stausLoading ? (
          <CircularProgress size={20} />
        ) : (
          <Checkbox
            checked={Boolean(todo?.is_finished)}
            icon={<RadioButtonUncheckedIcon />}
            checkedIcon={
              <TaskAltIcon
                sx={{
                  fill: "#00cf3e",
                }}
              />
            }
            onChange={() => handleCheckBox(todo)}
          />
        )}
      </Box>
      <Box sx={{ display: "grid", gridTemplateColumns: "auto 80px" }}>
        <Typography
          variant="body1"
          lineHeight="29px"
          sx={{
            textDecoration: todo?.is_finished ? "line-through" : "none",
            color: todo?.is_finished ? "#58585880" : "inherit",
          }}
        >
          {todo?.text}
        </Typography>
        {user_type !== "Admin" && (
          <Typography
            sx={{
              fontSize: "12px",
              color: "#777",
              fontWeight: 500,
              textAlign: "right",
              lineHeight: "29px",
            }}
          >
            {moment(todo?.date).format("ll")}
          </Typography>
        )}
      </Box>
      {user_type === "Admin" && (
        <Box sx={{ display: "flex", gap: "8px", pt: "8px" }}>
          <Avatar
            src={UNIVERSAL.BASEURL + todo?.user_image}
            alt=""
            sx={{ height: "16px", width: "16px" }}
          />
          <Typography
            sx={{
              fontSize: "12px",
              color: "#777",
              fontWeight: 500,
              pr: "8px",
              borderRight: "1px solid #ddd",
            }}
          >
            {todo?.user_name}
          </Typography>
          <Typography
            sx={{
              fontSize: "12px",
              color: "#777",
            }}
          >
            {moment(todo?.date).format("ll")}
          </Typography>
        </Box>
      )}
      <TodoActions {...{ todo, setTodos, setOpenEdit }} />
    </Box>
  ) : (
    <Box
      sx={{
        p: "15px",
        borderBottom: "1px solid #405189",
        backgroundColor: "#fff",
        position: "relative",
        paddingLeft: "55px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: "13px",
          top: "13px",
          width: "32px",
          height: "32px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <IconButton size="small" onClick={() => setOpenEdit("")}>
          <Close />
        </IconButton>
      </Box>
      <Box
        sx={{
          background: "#fff",
          borderRadius: "5px",
          display: "grid",
          gridTemplateColumns: "auto 135px",
          gap: "15px",
        }}
      >
        <InputBase
          placeholder="Create a new todo..."
          autoFocus
          sx={{ color: "#555" }}
          value={formik.values.text}
          onChange={(e) => formik.setFieldValue("text", e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && formik.submitForm()}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
          }}
        >
          <span>{moment(formik.values.date).format("ll")}</span>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              label="Custom input"
              value={formik.values.date}
              onChange={(newValue) => {
                formik.setFieldValue(
                  "date",
                  moment(newValue).format("YYYY-MM-DD")
                );
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  {InputProps?.endAdornment}
                  <input
                    style={{
                      width: "0px",
                      height: "0px",
                      opacity: 0,
                    }}
                    ref={inputRef}
                    {...inputProps}
                  />
                </Box>
              )}
            />
          </LocalizationProvider>
        </Box>
      </Box>
    </Box>
  );
};

export default TodoItem;
