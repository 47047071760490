import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Dialog, IconButton, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import Comments from "../ViewTask/comment/Comments";
import { taskpriorities } from "../table/MainTableRow";

let CreateSection = ({ handleClose, setReload }) => {
  let { loading, fetchData } = useApi();
  const initialValues = {
    name: "",
    color: "#000000",
    is_finished: false,
  };

  let onSubmit = async ({ name, color, is_finished }) => {
    const endpoint = {
      method: "post",
      url: "/api/admin/task-status/create",
      data: { name, color, is_finished },
    };
    const result = await fetchData(endpoint, false);
    if (result.success) {
      handleClose();
      setReload((prev) => !prev);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      name: yup.string().required("Name is required!"),
    }),
    onSubmit,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      <Typography variant="h3" mb={1.5}>
        Add a New Section
      </Typography>
      <InputField name="name" label="Name" formik={formik} />
      <InputField
        label="Color"
        name="color"
        type="color"
        formik={formik}
        InputLabelProps={{ shrink: true }}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          mt: 3,
        }}
      >
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={formik.submitForm}
          variant="solid"
          disabled={!(formik.dirty && formik.isValid) || loading}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
};

let DeleteSection = ({ handleClose, sectionId, setData }) => {
  let { loading, fetchData } = useApi();
  let handleDelete = async () => {
    const endpoint = {
      method: "delete",
      url: `/api/admin/task-status/delete/${sectionId}`,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      setData((prev) => prev.filter((e) => e.id !== sectionId));
      handleClose();
    }
  };
  return (
    <>
      <Typography variant="h4" mb={1.5}>
        Are you sure you want to delete this Section?
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          mt: 3,
        }}
      >
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="solid" disabled={loading}>
          Delete
        </Button>
      </Box>
    </>
  );
};

let DeleteTask = ({ handleClose, setData, data }) => {
  let { loading, fetchData } = useApi();
  let handleDelete = async (e) => {
    const endpoint = {
      method: "delete",
      url: `/api/admin/task/delete/${data?.taskId}`,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      setData((prevData) => {
        const newData = [...prevData];
        const sectionIndex = newData.findIndex((e) => e.id === data?.sectionId);
        const taskIndex = newData[sectionIndex].tasks.findIndex(
          (e) => e.id === data?.taskId
        );
        newData[sectionIndex].tasks.splice(taskIndex, 1);
        return newData;
      });
      handleClose();
    }
  };
  return (
    <>
      <Typography variant="h4" mb={1.5}>
        Are you sure you want to delete this Task?
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          gap: "10px",
          mt: 3,
        }}
      >
        <Button autoFocus onClick={handleClose}>
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="solid" disabled={loading}>
          Delete
        </Button>
      </Box>
    </>
  );
};

let ViewTask = ({ handleClose, profile }) => {
  let { created_at, name, task_priority } = profile || {};
  let details =
    Object.keys({
      created_at,
      name,
      task_priority,
    }) || {};
  return (
    <>
      <Typography
        variant="h3"
        sx={{ borderBottom: "1px solid #ddd", pb: "10px" }}
      >
        Task Information
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          pt: "20px",
        }}
      >
        {details?.length > 0 &&
          details?.map((data, i) => (
            <Box
              key={i}
              sx={{
                width: "100%",
                minWidth: 0,
                display: "flex",
                gap: "10px",
                justifyContent: "space-between",
                mb: 1,
              }}
            >
              <Typography
                variant="h5"
                className="clamped-text"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                {data.replace(/_/g, " ")}
                {": "}
              </Typography>

              <Typography
                variant="body1"
                className="clamped-text"
                sx={{
                  textTransform: "capitalize",
                }}
              >
                {data === "created_at" ? (
                  moment(profile?.[data])?.format("lll")
                ) : data === "task_priority" ? (
                  <span
                    style={{
                      color: taskpriorities?.find(
                        (priority) => priority?.label === profile?.[data]
                      )?.color,
                      backgroundColor:
                        taskpriorities?.find(
                          (priority) => priority?.label === profile?.[data]
                        )?.color + "15",
                      textAlign: "center",
                      padding: "0 25px",
                      display: "inline-block",
                      borderRadius: "15px",
                    }}
                  >
                    {profile?.[data]}
                  </span>
                ) : (
                  profile?.[data]
                )}
              </Typography>
            </Box>
          ))}
      </Box>
      <Box marginTop={1}>
        <Typography>{profile?.description}</Typography>
      </Box>
    </>
  );
};

let AddEditTask = ({ handleClose, currTask, setData, sectionId }) => {
  const user_id = useSelector((state) => state?.auth?.user_details?.user?.id);
  const { base } = useBase();
  const { assignees, relatedToForTasks } = base;
  const [relatedToData, setRelatedToData] = useState([]);

  const initialValues = {
    related_to: currTask?.related_to || "",
    related_id: currTask?.related_id || "",
    name: currTask?.name || "",
    date_added: currTask?.date_added || moment().format("YYYY-MM-DD"),
    due_date: currTask?.due_date || "",
    date_finished: currTask?.date_finished || "",
    assignees: currTask?.assignees?.map((assignee) => assignee?.user_id) || [],
    followers: currTask?.followers?.map((follower) => follower?.user_id) || [],
    priority: currTask?.priority || 2,
    task_status_id: sectionId || "",
    description: currTask?.description || "",
  };

  const validationSchema = yup.object({
    name: yup.string("").required("Name is Required").nullable(),
    priority: yup.string("").required("Priority is Required").nullable(),
    date_added: yup.string("").required("Date added is Required").nullable(),
    assignees: yup.array().required("Assignee is Required").min(1),
    description: yup
      .string("")
      .max(250, "Description Should be less than 250 letter"),
  });

  const { loading, fetchData } = useApi();
  const onSubmit = async (data) => {
    let endpoint;
    if (currTask?.id) {
      endpoint = {
        method: "post",
        url: `/api/admin/task/update/${currTask.id}`,
        data,
      };
    } else {
      endpoint = {
        method: "post",
        url: "/api/admin/task/create",
        data,
      };
    }
    const result = await fetchData(endpoint);
    if (result.success) {
      if (currTask?.id) {
        setData((prevData) => {
          const newData = [...prevData];
          const sectionIndex = newData.findIndex((e) => e.id === sectionId);
          const taskIndex = newData[sectionIndex].tasks.findIndex(
            (e) => e.id === currTask?.id
          );
          newData[sectionIndex].tasks[taskIndex] = result?.data;
          return newData;
        });
      } else {
        try {
          setData((prevData) => {
            const newData = [...prevData];
            const index = newData.findIndex((e) => e.id === sectionId);
            newData[index].tasks.push(result?.data);
            return newData;
          });
        } catch (err) {
          alert(err);
        }
      }
      handleClose(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const fetchRelatedToData = async (relatedTo) => {
    const endpoint = {
      method: "get",
      url: `/api/admin/base/related-module-data/${relatedTo}`,
    };
    const result = await fetchData(endpoint, false);
    if (result.success) setRelatedToData(result.data);
  };

  useEffect(() => {
    if (currTask?.id) {
      fetchRelatedToData(formik.values.related_to);
    }
  }, []);

  const convertToObjectOptions = (options) => {
    return options.map((option) => {
      return {
        id: option,
        label: option.charAt(0).toUpperCase() + option.slice(1),
      };
    });
  };
  return (
    <>
      <Typography variant="h3" sx={{ pl: "5px", pt: "5px" }}>
        {currTask?.id ? "Edit Task" : "Add Task"}
      </Typography>
      <Box sx={{ p: "5px" }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "15px",
                py: "15px",
                borderBottom: "1px solid #ddd",
                mb: "15px",
              }}
            >
              <Select
                options={convertToObjectOptions(relatedToForTasks || [])}
                label="Related to"
                value={formik?.values?.related_to}
                onChange={(value) => {
                  fetchRelatedToData(value);
                  formik.setFieldValue("related_to", value);
                  formik.setFieldValue("related_id", "");
                }}
              />
              <Select
                disabled={!formik.values.related_to}
                options={relatedToData}
                label={
                  formik.values.related_to
                    ? "Select " + formik.values.related_to
                    : "Related to Item"
                }
                value={formik?.values?.related_id}
                onChange={(value) => formik.setFieldValue("related_id", value)}
                selectProps={{ id: "related_id", label: "label" }}
              />
              <InputField
                sx={{ gridColumn: "span 2" }}
                name="name"
                label="Task Name *"
                formik={formik}
              />
              <InputField
                sx={{ gridColumn: "span 2" }}
                name="description"
                label="Task Description"
                formik={formik}
                multiline
                rows={4}
              />
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
              }}
            >
              <MultiSelect
                sx={{ gridColumn: "span 2" }}
                options={assignees}
                label="Assigned To *"
                value={formik?.values?.assignees}
                onChange={(value) => formik.setFieldValue("assignees", value)}
                selectProps={{
                  id: "user_id",
                  label: "name",
                }}
              />
              <MultiSelect
                sx={{ gridColumn: "span 2" }}
                options={assignees}
                label="Followers"
                value={formik?.values?.followers}
                onChange={(value) => formik.setFieldValue("followers", value)}
                selectProps={{
                  id: "user_id",
                  label: "name",
                }}
              />
              <Select
                options={taskpriorities}
                label="Priority *"
                value={formik?.values?.priority}
                onChange={(value) => formik.setFieldValue("priority", value)}
              />
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "15px",
                gridColumn: "span 2",
                pt: "15px",
              }}
            >
              <DateTimePicker
                name="date_added"
                label="Start Date *"
                formik={formik}
              />
              <DateTimePicker
                name="due_date"
                label="Due Date"
                formik={formik}
              />
              <DateTimePicker
                name="date_finished"
                label="Finished Date"
                formik={formik}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                mt: "20px",
              }}
            >
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>

              <Button
                variant="solid"
                onClick={formik.submitForm}
                disabled={!(formik.dirty && formik.isValid) || loading}
              >
                {currTask?.id ? "Update Task" : "Save Task"}
              </Button>
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
    </>
  );
};

let TaskComment = ({ post, handleClose }) => (
  <>
    <IconButton onClick={handleClose}>
      <ArrowBack style={{ fontSize: "20px" }} />
    </IconButton>

    <Comments post={post} />
  </>
);

const Modal = ({ handleClose, openModal, modalList, setReload, setData }) => {
  const modalArray = Object.entries(modalList).map(([key]) => ({ name: key }));
  return modalArray.map((modal, i) => (
    <Dialog
      key={i}
      open={modal.name === openModal?.modalName}
      onClose={handleClose}
    >
      <>
        <Box sx={{ minWidth: "600px", p: 2 }}>
          {modal.name === modalList.createSection && (
            <CreateSection handleClose={handleClose} setReload={setReload} />
          )}
          {modal.name === modalList.deleteSection && (
            <DeleteSection
              handleClose={handleClose}
              sectionId={openModal?.data?.sectionId}
              setData={setData}
            />
          )}
          {modal.name === modalList.deleteTask && (
            <DeleteTask
              handleClose={handleClose}
              setData={setData}
              data={openModal.data}
            />
          )}
          {modal.name === modalList.viewTask && (
            <ViewTask handleClose={handleClose} profile={openModal.data} />
          )}
          {modal.name === modalList.createTask && (
            <AddEditTask
              setData={setData}
              handleClose={handleClose}
              sectionId={openModal?.data?.sectionId}
            />
          )}
          {modal.name === modalList.updateTask && (
            <AddEditTask
              setData={setData}
              handleClose={handleClose}
              currTask={openModal.data?.task}
              sectionId={openModal?.data?.sectionId}
            />
          )}

          {modal.name === modalList.taskComments && (
            <TaskComment post={openModal.data} handleClose={handleClose} />
          )}
        </Box>
      </>
    </Dialog>
  ));
};

export default Modal;
