import { Download } from "@mui/icons-material";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import UNIVERSAL from "config";
import useFormatter from "hooks/useFormatter";
import { GoCircle } from "react-icons/go";
import { FcFile } from "react-icons/fc";
import ReactLoading from "react-loading";
import { CgDetailsMore } from "react-icons/cg";
import { SlCalender } from "react-icons/sl";
import { MdTaskAlt } from "react-icons/md";
import { RiFlag2Line } from "react-icons/ri";
import { HiOutlineUsers } from "react-icons/hi";
import { GrAttachment } from "react-icons/gr";

const taskPriorities = [
  { id: 1, label: "Low", color: "#008000" },
  { id: 2, label: "Medium", color: "#FFA500" },
  { id: 3, label: "High", color: "#FF0000" },
  { id: 4, label: "Urgent", color: "#800080" },
];
const Profile = ({ profile: task, loading }) => {
  const attachments = task?.attachments;
  const { formatDate } = useFormatter();

  return loading ? (
    <Box
      sx={{
        height: "calc(100vh - 143px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          height: "calc(100vh - 125px)",
          overflowY: "auto",
          m: "-20px",
          p: "20px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "30px auto",
            rowGap: "5px",
          }}
        >
          <Box>
            <GoCircle fontSize={20} />
          </Box>
          <Box>
            <Typography variant="h5" mb="2px">
              Task Name
            </Typography>
            <Typography mb="15px">{task?.name || "-"}</Typography>
          </Box>
          <Box>
            <CgDetailsMore fontSize={20} />
          </Box>
          <Box>
            <Typography variant="h5" mb="2px">
              Description
            </Typography>
            <Typography mb="15px">{task?.description || "-"}</Typography>
          </Box>
          <Box>
            <RiFlag2Line fontSize={20} />
          </Box>
          <Box>
            <Typography variant="h5" mb="2px">
              Task Priority
            </Typography>
            <Typography
              mb="15px"
              color={
                taskPriorities?.find((tp) => tp.label === task?.task_priority)
                  ?.color || "inherit"
              }
            >
              {task?.task_priority}
            </Typography>
          </Box>
          <Box>
            <MdTaskAlt fontSize={20} />
          </Box>
          <Box>
            <Typography variant="h5" mb="2px">
              Task Status
            </Typography>
            <Typography mb="15px" color={task?.status_color}>
              {task?.status_name}
            </Typography>
          </Box>
          <Box mt="1">
            <SlCalender fontSize={18} />
          </Box>
          <Box>
            <Typography variant="h5" mb="2px">
              Due Date
            </Typography>
            <Typography mb="15px" color={task?.status_color}>
              {formatDate(task?.due_date)}
            </Typography>
          </Box>
          <Box>
            <HiOutlineUsers fontSize={20} />
          </Box>
          <Box>
            <Typography variant="h5" mb="2px">
              Assignees
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "25px auto",
                padding: "10px 0",
                alignItems: "center",
                gap: "10px",
                "& .MuiAvatar-root": {
                  width: 28,
                  height: 28,
                  fontSize: 12,
                },
              }}
            >
              {!!task?.assignees?.length
                ? task?.assignees?.map((item) => (
                    <>
                      <Avatar
                        key={item.name}
                        alt={item?.name}
                        src={UNIVERSAL.BASEURL + item?.image}
                      />
                      <span key={item.name}>{item?.name}</span>
                    </>
                  ))
                : "-"}
            </Box>
          </Box>
          <Box>
            <HiOutlineUsers fontSize={20} />
          </Box>
          <Box>
            <Typography variant="h5" mb="2px">
              Followers
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "25px auto",
                padding: "10px 0",
                alignItems: "center",
                gap: "10px",
                "& .MuiAvatar-root": {
                  width: 28,
                  height: 28,
                  fontSize: 12,
                },
              }}
            >
              {!!task?.followers?.length
                ? task?.followers?.map((item) => (
                    <>
                      <Avatar
                        key={item.name}
                        alt={item?.name}
                        src={UNIVERSAL.BASEURL + item?.image}
                      />
                      <span key={item.name}>{item?.name}</span>
                    </>
                  ))
                : "-"}
            </Box>
          </Box>
          <Box>
            <GrAttachment fontSize={20} />
          </Box>
          <Box>
            <Typography variant="h5" mb="2px">
              Attachments
            </Typography>
            {attachments?.map((attachment, key) => (
              <Box
                key={key}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "50px auto 100px",
                  alignItems: "center",
                  borderBottom: "1px solid #ddd",
                  padding: "15px 0",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FcFile style={{ fontSize: "40px" }} />
                </Box>
                <Box>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <Typography variant="h6">{attachment?.name}</Typography>
                    {attachment?.description && (
                      <Typography>({attachment?.description})</Typography>
                    )}
                  </Box>
                  <Typography sx={{ fontSize: "12px" }}>
                    {(attachment?.file_size / 1024).toFixed(0)}KB
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                >
                  <a
                    href={UNIVERSAL.BASEURL + attachment?.file}
                    target="_blank"
                    rel="noreferrer"
                    download={attachment?.name}
                  >
                    <IconButton>
                      <Download />
                    </IconButton>
                  </a>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Profile;
