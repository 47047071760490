import { ArrowBack, Delete, Download, UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import AttachmentsSkeleton from "components/skeletons/AttachmentsSkeleton";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { FcFile } from "react-icons/fc";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
const AddAttachment = ({ openDrawer, setOpenDrawer, ticketId }) => {
  return (
    <>
      {" "}
      {openDrawer && (
        <Box
          sx={{
            padding: 3,
          }}
        >
          <Button
            variant="text"
            startIcon={<ArrowBack />}
            sx={{
              mb: 3,
            }}
            onClick={() => setOpenDrawer(false)}
          >
            Back
          </Button>

          <Attachment id={ticketId} route="ticket" />
        </Box>
      )}
    </>
  );
};

export default AddAttachment;

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const Attachment = ({ id, route }) => {
  const [progress, setProgress] = useState(0);
  const [currFileName, setCurrFileName] = useState("");
  const [reload, setReload] = useState(false);
  const [viewAtt, setViewAtt] = useState(false);
  const [currFile, setCurrFile] = useState({});
  const dispatch = useDispatch();

  // Fetch Attachments
  const [attachments, setAttachments] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getAttachments = async () => {
      const endpoint = {
        method: "get",
        url: `/api/${route}/attachment/${id}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAttachments(allData || []);
        setPagination(rest);
      }
    };
    getAttachments();
  }, [reload]);

  const handleUploadAttachment = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setCurrFileName(e.target.files[0].name);

      const formData = new FormData();
      formData.append("file", file);

      const endpoint = {
        method: "post",
        url: `/api/${route}/attachment/upload/${id}`,
        data: formData,
        updateProgress: (value) => setProgress(value),
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        setAttachments([result.data, ...attachments]);
        setCurrFileName("");
      }
    }
  };

  const handleFileView = (file) => {
    if (file?.mime_type.includes("image")) {
      setCurrFile(file);
      setViewAtt(true);
    } else {
      window.open(UNIVERSAL.BASEURL + file?.file, { replace: true });
    }
  };

  const handleDeleteAtt = async (attId) => {
    Swal.fire({
      title: "<strong>Are you sure?</strong>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteAtt = async () => {
          const endpoint = {
            method: "delete",
            url: `/api/ticket/attachment/delete/${attId}`,
          };
          const result = await fetchData(endpoint, false);
          if (result.success) {
            setAttachments(attachments.filter((att) => att.id !== attId));
          }
        };
        deleteAtt();
      }
    });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Typography variant="h4">Documents</Typography>
        <Button component="label" startIcon={<UploadFile />} variant="outlined">
          <input
            type="file"
            hidden
            onChange={(e) => handleUploadAttachment(e)}
          />
          Upload File
        </Button>
      </Box>
      <Stack sx={{ height: "calc(100vh - 235px)", overflowY: "auto" }}>
        {currFileName && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "50px auto 60px",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              padding: "15px 10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FcFile style={{ fontSize: "40px" }} />
            </Box>
            <Box>
              <Typography variant="h6">{currFileName}</Typography>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </Box>
        )}
        {loading ? (
          <AttachmentsSkeleton />
        ) : (
          attachments?.length > 0 &&
          attachments?.map((attachment, key) => (
            <Box
              key={key}
              sx={{
                display: "grid",
                gridTemplateColumns: "50px auto 100px",
                alignItems: "center",
                borderBottom: "1px solid #ddd",
                padding: "15px 10px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FcFile style={{ fontSize: "40px" }} />
              </Box>
              <Box>
                <Typography variant="h6">{attachment?.name}</Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {(attachment?.file_size / 1024).toFixed(0)}KB
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                {attachment?.mime_type?.includes("image") && (
                  <IconButton onClick={() => handleFileView(attachment)}>
                    <FaEye />
                  </IconButton>
                )}
                <a
                  href={UNIVERSAL.BASEURL + attachment?.file}
                  target="_blank"
                  rel="noreferrer"
                  download={attachment?.name}
                >
                  <IconButton>
                    <Download />
                  </IconButton>
                </a>
                <IconButton onClick={() => handleDeleteAtt(attachment?.id)}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ))
        )}
      </Stack>
      {viewAtt && <ViewAttachment setViewAtt={setViewAtt} file={currFile} />}
    </>
  );
};

const ViewAttachment = ({ setViewAtt, file }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        zIndex: "100",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        backgroundColor: "#00000094",
      }}
      onClick={() => setViewAtt(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          "& img": {
            maxHeight: "calc(100% - 40px)",
            maxWidth: "calc(100% - 40px)",
          },
        }}
      >
        {file?.mime_type.includes("image") && (
          <img
            src={UNIVERSAL.BASEURL + file?.file}
            alt=""
            onClick={(e) => e.stopPropagation()}
          />
        )}
      </Box>
    </Box>
  );
};
