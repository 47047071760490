import {
  Box,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import * as yup from "yup";

const Roles = () => {
  // Setup common component format
  const [permissionModules, setPermissionModules] = useState([]);
  const { fetchData } = useApi();

  useEffect(() => {
    (async () => {
      const { success, data } = await fetchData(
        {
          method: "get",
          url: "/api/admin/role/permission/module",
        },
        false
      );
      if (success) setPermissionModules(data);
    })();
  }, []);

  const initialValues = {
    name: "",
    role_permissions: permissionModules || [],
  };

  return (
    <SetupCC
      sidebarWidth="600px"
      name="Role"
      baseRouteKey="roles"
      moduleKey="role"
      columns={[
        { id: "name", label: "Role Name", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Role Name" formik={formik} />
          <Table
            sx={{
              border: "1px solid #ddd",
              "& td": {
                border: "1px solid #ddd",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>Features</TableCell>
                <TableCell>Permissons</TableCell>
              </TableRow>
              {formik?.values?.role_permissions?.map(
                (singleModule, firstIndex) => (
                  <TableRow>
                    <TableCell>{singleModule?.module}</TableCell>
                    <TableCell>
                      {singleModule?.permission?.map(
                        (singlePermission, secondIndex) => (
                          <Box>
                            <FormControlLabel
                              control={<Checkbox />}
                              label={singlePermission?.operation}
                              checked={
                                formik?.values?.role_permissions[firstIndex]
                                  ?.permission[secondIndex]?.is_permit
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `role_permissions[${firstIndex}].permission[${secondIndex}].is_permit`,
                                  e.target.checked ? 1 : 0
                                );
                              }}
                            />
                          </Box>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </>
      )}
    ></SetupCC>
  );
};

export default Roles;
