import Search from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import InputBase from "@mui/material/InputBase";
import useWindowSize from "hooks/useWindowSize";

const SearchBox = ({
  isFullWidth,
  handleSearch,
  width = "300px",
  placeholder = "Search here..",
}) => {
  const { isMini } = useWindowSize();

  return (
    <Box sx={{ position: "relative" }}>
      <InputBase
        sx={{
          border: "1px solid #ddd",
          padding: "0 36px 0 15px",
          height: "38px",
          display: "flex",
          alignItems: "center",
          borderRadius: "3px",
          width: isFullWidth ? "100%" : isMini ? "190px" : "230px",
          transition: ".5s",
          "&.Mui-focused": {
            width: isFullWidth ? "100%" : isMini ? "230px" : width,
            borderColor: (theme) => theme.palette.primary.main,
          },
        }}
        onChange={(e) => handleSearch(e.target.value)}
        placeholder={placeholder}
      />
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          right: "7px",
          transform: "translate(0, -50%)",
          display: "flex",
          alignItem: "center",
        }}
      >
        <Search style={{ fontSize: "22px", color: "#888" }} />
      </Box>
    </Box>
  );
};

export default SearchBox;
