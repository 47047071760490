import { Box } from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import * as yup from "yup";

const BankInvoiceStatuses = () => {
  const initialValues = {
    name: "",
  };
  return (
    <SetupCC
      name="Bank Invoice Status"
      baseRouteKey="bankInvoiceStatuses"
      moduleKey="bank-invoice-status"
      columns={[
        { id: "name", label: "Name", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "color" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: value,
                    height: "20px",
                    width: "20px",
                    borderRadius: "5px",
                  }}
                ></Box>
              </Box>
            ) : column?.id === "is_finished" ? (
              <>{value ? "Yes" : "No"}</>
            ) : column?.id === "is_open" ? (
              <>{value ? "Yes" : "No"}</>
            ) : (
              value
            )}
          </>
        );
      }}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }

          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <InputField name="name" label="Name" formik={formik} />
      )}
    ></SetupCC>
  );
};

export default BankInvoiceStatuses;
