import TextField from "@mui/material/TextField";

const InputField = (props) => {
  const { formik, name, size, variant } = props;
  const isComplex = props.isComplex;

  const getFormikErrors = () => {
    if (!isComplex) return formik?.errors[name];
    // eslint-disable-next-line no-useless-escape
    else
      return name?.split(/[\.\[\]]+/)?.reduce((acc, key) => {
        if (Array.isArray(acc)) {
          return acc[parseInt(key)];
        }
        return acc?.[key];
      }, formik?.errors);
  };

  const getFormikTouchStatus = () => {
    if (!isComplex) return formik?.touched[name];
    // eslint-disable-next-line no-useless-escape
    else
      return name?.split(/[\.\[\]]+/)?.reduce((acc, key) => {
        if (Array.isArray(acc)) {
          return acc[parseInt(key)];
        }
        return acc?.[key];
      }, formik?.touched);
  };

  return (
    <TextField
      variant={variant || "outlined"}
      size={size || "small"}
      {...props}
      {...formik.getFieldProps(name)}
      error={getFormikTouchStatus() && getFormikErrors()}
      helperText={getFormikTouchStatus() && getFormikErrors()}
    />
  );
};

export default InputField;
