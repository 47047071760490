import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import InputField from "components/ui/inputField";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import SmsValueskeleton from "./SmsValueskeleton";

const validationSchema = yup.object({
  title: yup.string().required("Title is Required"),
  body: yup.string().required("Message is Required"),
});
const AddTemplate = ({
  setOpenDrawer,
  openDrawer,
  currTemplate,
  setAllTemplate,
  allTemplate,
}) => {
  const { updateBase } = useBase();

  const textareaRef = useRef(null);
  let [data, setData] = useState([]);
  const { loading: listLoading, fetchData: listSMSValue } = useApi();

  function insertTextAtCursor(text, formik) {
    const textarea = textareaRef.current;
    if (!textarea) return;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const originalValue = textarea.value;
    const newValue =
      originalValue.substring(0, start) + text + originalValue.substring(end);
    const numOfLineBreaks = (newValue.match(/\n/g) || []).length;
    const newCursorPos = start + text.length + numOfLineBreaks;
    formik.setFieldValue("body", newValue);
    requestAnimationFrame(() => {
      textarea.selectionStart = newCursorPos;
      textarea.selectionEnd = newCursorPos;
      textarea.focus();
    });
  }

  useEffect(() => {
    async function listSMSValueCall() {
      const endpoint = {
        method: "post",
        url: `/api/admin/sms/key-value/list/`,
      };
      const result = await listSMSValue(endpoint, false);
      if (result.success) {
        setData(result.data);
      }
    }
    listSMSValueCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    title: currTemplate?.title || "",
    body: currTemplate?.body || "",
    is_default: currTemplate?.is_default || false,
  };
  const { loadingTemplete, fetchData: templateApi } = useApi();
  const onSubmit = async (data) => {
    let endpoint;
    if (currTemplate?.id) {
      endpoint = {
        method: "put",
        url: `/api/admin/sms-template/update/${currTemplate.id}`,
        data,
      };
    } else {
      endpoint = {
        method: "post",
        url: "/api/admin/sms-template/create",
        data,
      };
    }
    const result = await templateApi(endpoint);
    if (result.success) {
      if (currTemplate?.id) {
        setAllTemplate(
          allTemplate.map((tmplt) =>
            tmplt.id === currTemplate.id ? result.data : tmplt
          )
        );
      } else {
        setAllTemplate([result?.data, ...allTemplate]);
      }
      setOpenDrawer(false);
      updateBase(["smsTemplates"]);
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "850px",
        headingLeft: "Add SMS Template",
        headingRight: "",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <form onSubmit={formik.handleSubmit}>
          <Stack spacing={3} direction={"column"}>
            <InputField name="title" label="Title *" formik={formik} />

            <InputField
              formik={formik}
              inputRef={textareaRef}
              name="body"
              label="Message *"
              multiline
              rows={10}
            />

            <FormControlLabel
              control={<Checkbox />}
              label="Default"
              checked={formik.values.is_default}
              onChange={(e) =>
                formik.setFieldValue("is_default", e.target.checked)
              }
            />

            <div>
              <Button
                sx={{
                  display: "block",
                  ml: "auto",
                }}
                variant="contained"
                type="submit"
                disabled={!(formik.dirty && formik.isValid) || loadingTemplete}
              >
                {currTemplate?.id ? "Update " : "Add "}
                Message
              </Button>
            </div>
          </Stack>
        </form>

        <SmsValueskeleton loading={listLoading} />

        <Box
          sx={{
            mt: "1.5rem",
            display: "grid",
            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
            columnGap: "41px",
          }}
        >
          {data?.length > 0 &&
            data?.map((data) => (
              <Button
                key={data.id}
                className="colsBorder"
                sx={{
                  width: "100%",
                  minWidth: 0,
                  display: "flex",
                  gap: "10px",
                  justifyContent: "space-between",
                }}
                onClick={() => insertTextAtCursor(`{${data.key}}`, formik)}
              >
                <Typography
                  variant="span"
                  className="clamped-text"
                  sx={{ color: "#2f74ca" }}
                >
                  <>{`{${data.key}} : `}</>
                </Typography>{" "}
                <Typography
                  variant="span"
                  className="clamped-text"
                  sx={{ color: "#2f74ca" }}
                >
                  <>{data.value}</>
                </Typography>
              </Button>
            ))}
        </Box>
      </Box>
    </RightDrawer>
  );
};

export default AddTemplate;
