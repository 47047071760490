import { Box, Button } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { createExpense, updateExpense } from "features/expense/api/expense";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import moment from "moment";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import handleError from "utils/handleError";
import * as yup from "yup";

const AddExpense = ({
  setAddExpense,
  currExpense,
  setAllExpense,
  allExpense,
  customer_id,
  project_id,
  reference_id,
}) => {
  const { base } = useBase();
  const {
    projects,
    customers,
    paymentMethods,
    currencies,
    expenseCategories,
    references,
  } = base;
  const isUpdating = currExpense?.id ? true : false;
  const { loading, fetchData } = useApi();
  const currency_id = useSelector(
    (state) => state?.base?.base_data?.settings?.app?.currency_id
  );

  const validationSchema = yup.object({
    currency_id: yup.string("").required("Currency is Required").nullable(),
    expense_category_id: yup
      .string("")
      .required("Expense Category is Required")
      .nullable(),
    payment_method_id: yup
      .string("")
      .required("Payment Method is Required")
      .nullable(),
    name: yup.string("").required("Name is Required").nullable(),
  });

  const onSubmit = async (data) => {
    const result = await fetchData(
      isUpdating ? updateExpense(currExpense?.id, data) : createExpense(data)
    );
    if (result?.success) {
      if (isUpdating) {
        setAllExpense(
          allExpense.map((expense) =>
            expense.id === currExpense.id
              ? { ...currExpense, ...result.data }
              : expense
          )
        );
      } else {
        setAllExpense([result.data, ...allExpense]);
      }
      setAddExpense(false);
    } else {
      handleError(result, formik);
    }
  };

  // initial values depending on edit mode or create mode
  const initialValues = {
    currency_id: currency_id || "",
    expense_category_id: "",
    payment_method_id: "",
    customer_id: customer_id,
    project_id: project_id,
    name: "",
    reference_id: reference_id || "",
    expense_date: moment().format("YYYY-MM-DD"),
    tax: 0,
    discount: 0,
    total: 0,
    note: "",
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let obj = {};
      for (const key in initialValues) {
        obj[key] = data[key];
      }
      return obj;
    } else return initialValues;
  };

  const formik = useFormik({
    initialValues: getInitialValues(currExpense),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box sx={{ m: "-10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddExpense(false)}
        >
          View Expenses
        </Button>
      </Box>

      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
            pt: "15px",
          }}
        >
          <Select
            disabled
            options={customers}
            label="Customer"
            name="customer_id"
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
          <Select
            disabled
            options={projects}
            label="Project"
            name="project_id"
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
            pt: "15px",
            pb: "15px",
          }}
        >
          <InputField
            name="name"
            label="Expense Name *"
            multiline
            formik={formik}
          />
          <Select
            options={references}
            label="Reference"
            name="reference_id"
            formik={formik}
            selectProps={{ id: "id", label: "name" }}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "10px",
            pb: "15px",
          }}
        >
          <Select
            options={expenseCategories}
            label="Expense Category *"
            name="expense_category_id"
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
          <DateTimePicker
            name="expense_date"
            label="Expense Date *"
            formik={formik}
          />
          <Select
            options={paymentMethods}
            label="Payment Method *"
            name="payment_method_id"
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
        </Box>
        <Box
          sx={{
            borderTop: "1px solid #ddd",
            pt: "15px",
            display: "grid",
            gridTemplateColumns: "repeat(3, 1fr)",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
            }}
          >
            <InputField name="tax" label="Tax" type="number" formik={formik} />
            <InputField
              name="discount"
              label="Discount"
              type="number"
              formik={formik}
            />
          </Box>
          <InputField
            name="total"
            label="Total"
            type="number"
            formik={formik}
          />
          <Select
            options={currencies}
            label="Currency *"
            name="currency_id"
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
          <InputField
            sx={{ gridColumn: "span 3" }}
            name="note"
            label="Note"
            multiline
            rows={3}
            formik={formik}
          />
          <Button
            variant="solid"
            onClick={formik.submitForm}
            fullWidth
            disabled={!(formik.dirty && formik.isValid) || loading}
            sx={{ gridColumn: "span 3" }}
          >
            {currExpense?.id ? "Update Expense" : "Save Expense"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddExpense;
