import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import useNotificationQuery from "hooks/useNotificationQuery";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddEdit from "./AddEdit";
import Filter from "./Filter";
import TasksHeader from "./TasksHeader";
import ViewTask from "./ViewTask/ViewTask";
import Kanban from "./kanban";
import MainTable from "./table/MainTable";
import useQueries from "hooks/useQueries";
import { toast } from "react-toastify";

const Tasks = () => {
  const { setQueries, openAddEdit, openView } = useQueries();
  const [persistedData, setPersistedData] = useState({});
  const permissions = usePermissions("task");
  const { updateBase } = useBase();
  const [kanban, setKanban] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState();
  const { base_data } = useSelector((state) => state.base);
  const [allTask, setAllTask] = useState([]);
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getTasks = async () => {
      let finalFilters = { ...filters };
      let finalPagination = { ...pagination };
      finalFilters.search = search;
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/task/list?${params}`,
        data: finalFilters,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllTask(allData || []);
        setPagination(rest);
      }
    };
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, search, kanban]);

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = (id) => {
    setOpenAlert(id);
  };
  const { fetchData: deleteApi } = useApi();

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    const endpoint = {
      method: "delete",
      url: `/api/admin/task/delete/${openAlert}`,
    };
    const result = await deleteApi(endpoint);
    if (result.success) {
      if (allTask?.length > 1) {
        setAllTask(allTask.filter((task) => task?.id !== openAlert));
      } else {
        setReload((pre) => !pre);
      }
    } else {
      toast.error(result?.message);
    }
  };

  const all_props = {
    ...base_data,
    persistedData,
    setPersistedData,
    setReload,
    filters,
    setFilters,
    isFiltering,
    pagination,
    setPagination,
    allTask,
    setAllTask,
    permissions,
  };

  useEffect(() => {
    updateBase(["taskStatuses", "relatedToForTasks", "assignees"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Box>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Task"
          desc={
            <Box>
              Are you sure you want to delete this Task? This will delete the
              Task permanently! <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <TasksHeader
        {...{
          allTask,
          setSearch,
          setPagination,
          pagination,
          isFiltering,
          kanban,
          setKanban,
          ...all_props,
        }}
      />
      {openAddEdit && (
        <AddEdit
          {...{
            openDrawer: openAddEdit,
            setOpenDrawer: () => setQueries(""),
            ...all_props,
          }}
        />
      )}
      {openView && (
        <ViewTask
          openDrawer={openView}
          setOpenDrawer={() => setQueries("")}
          {...all_props}
        />
      )}
      {!kanban &&
        !!(permissions["list(own)"] || permissions["list(global)"]) && (
          <Filter {...all_props} />
        )}
      {permissions["list(own)"] || permissions["list(global)"] ? (
        kanban ? (
          <Kanban permissions={permissions} />
        ) : (
          <MainTable
            allTask={allTask}
            handleDelete={handleDelete}
            pagination={pagination}
            setPagination={setPagination}
            loading={loading}
            setReload={setReload}
            {...all_props}
          />
        )
      ) : (
        <NotAuthorized />
      )}
    </Box>
  );
};

export default Tasks;
