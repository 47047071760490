import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateRangeField from "components/ui/dateRangeField";
import MultiSelect from "components/ui/multiSelect";
import RangeSlider from "components/ui/rangeSlider/RangeSlider";
import Select from "components/ui/select";
import getInitialvalues from "features/projectCustomers/utils/filterInitialValues";
import { useFormik } from "formik";
import { exam_boards, exam_groups, grades } from "utils/constants";
import * as yup from "yup";

const AdvanceFilter = ({
  openDrawer,
  setOpenDrawer,
  setReload,
  setFilters,
  filters,
  isFiltering,
  customers,
  universities,
  references,
  branches,
  courses,
  subjects,
  institutes,
  pagination,
  setPagination,
  assignees
}) => {
  const initialValues = getInitialvalues(filters);
  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    setFilters({ ...filters, ...data });
    setOpenDrawer(false);
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const resetForm = () => {
    const emptyFilter = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = null;
      }
    }
    formik.setValues(emptyFilter);
    setFilters({});
    setReload((pre) => !pre);
    setOpenDrawer(false);
  };


  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "400px",
        headingLeft: "Advanced Filters",
        headingRight: "",
      }}
    >
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Stack
          direction="column"
          gap="15px"
          p="20px"
          sx={{ height: "calc(100vh - 125px)", overflowY: "auto" }}
        >
          <MultiSelect
            options={branches}
            label="Branch"
            value={formik?.values?.branch_id}
            onChange={(value) => formik.setFieldValue("branch_id", value)}
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
          <MultiSelect
            options={references}
            label="Reference"
            value={formik?.values?.reference_id}
            onChange={(value) => formik.setFieldValue("reference_id", value)}
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
          <Select
            name="reference_type"
            options={[
              { id: "sub", label: "Sub" },
              { id: "master", label: "Master" },
            ]}
            label="Reference Type"
            formik={formik}
          />
          <MultiSelect
            options={universities}
            label="Universities"
            value={formik?.values?.university_id}
            onChange={(value) => formik.setFieldValue("university_id", value)}
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
          <Select
            name="source"
            options={[
              { id: "own", label: "Own" },
              { id: "loan", label: "Loan" },
            ]}
            label="Source of Fund"
            formik={formik}
          />
          <MultiSelect
            options={customers}
            label="Customers"
            value={formik?.values?.customer_id}
            onChange={(value) => formik.setFieldValue("customer_id", value)}
            selectProps={{
              id: "id",
              label: "name",
            }}
          />
          <DateRangeField
            label="Followup Date"
            from="followup_date_from"
            to="followup_date_to"
            formik={formik}
          />
          <DateRangeField
            label="Created at"
            from="created_at_from"
            to="created_at_to"
            formik={formik}
          />

          <DateRangeField
            label="Assignee Changed Date"
            from="assignee_changed_date_from"
            to="assignee_changed_date_to"
            formik={formik}
          />

          <MultiSelect
            options={assignees}
            label="Assignees Changed by"
            name="changed_by"
            selectProps={{
              id: "user_id",
              label: "name",
            }}
            formik={formik}
          />

          {/* SSC Filter */}
          <Typography variant="h5">Filter educations (SSC)</Typography>
          <DateRangeField
            label="SSC Examination Year"
            from="ssc_passing_year_from"
            to="ssc_passing_year_to"
            formik={formik}
          />
          <RangeSlider
            from="ssc_gpa_from"
            to="ssc_gpa_to"
            minmax={[0, 5]}
            type="GPA"
            formik={formik}
          />
          <TextField
            size="small"
            label="SSC Group"
            select
            {...formik.getFieldProps("ssc_group")}
          >
            {exam_groups?.map((group) => (
              <MenuItem key={group} value={group}>
                {group}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            label="SSC Board"
            select
            {...formik.getFieldProps("ssc_board")}
          >
            {exam_boards?.map((board) => (
              <MenuItem key={board} value={board}>
                {board}
              </MenuItem>
            ))}
          </TextField>

          {/* HSC Filter */}
          <Typography variant="h5">Filter educations (HSC)</Typography>
          <DateRangeField
            label="HSC Examination Year"
            from="hsc_passing_year_from"
            to="hsc_passing_year_to"
            formik={formik}
          />
          <RangeSlider
            from="hsc_gpa_from"
            to="hsc_gpa_to"
            minmax={[0, 5]}
            type="GPA"
            formik={formik}
          />
          <TextField
            size="small"
            label="HSC Group"
            select
            {...formik.getFieldProps("hsc_group")}
          >
            {exam_groups?.map((group) => (
              <MenuItem key={group} value={group}>
                {group}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            size="small"
            label="HSC Board"
            select
            {...formik.getFieldProps("hsc_board")}
          >
            {exam_boards?.map((board) => (
              <MenuItem key={board} value={board}>
                {board}
              </MenuItem>
            ))}
          </TextField>
          {/* O Level Filter */}
          <Typography variant="h5">Filter educations (O Level)</Typography>
          <DateRangeField
            label="O Level Examination Year"
            from="o_level_passing_year_from"
            to="o_level_passing_year_to"
            formik={formik}
          />
          <MultiSelect
            formik={formik}
            label="O Level Grades"
            options={grades}
            name="o_level_grade"
            selectProps={{ label: "name", id: "id" }}
          />
          <MultiSelect
            name="o_level_institute_id"
            formik={formik}
            label="O Level institutes"
            options={institutes}
            selectProps={{ label: "name", id: "id" }}
          />
          {/* A Level Filter */}
          <Typography variant="h5">Filter educations (A Level)</Typography>
          <DateRangeField
            label="A Level Examination Year"
            from="a_level_passing_year_from"
            to="a_level_passing_year_to"
            formik={formik}
          />
          <MultiSelect
            name="a_level_grade"
            formik={formik}
            label="A Level Grades"
            options={grades}
            selectProps={{ label: "name", id: "name" }}
          />
          <MultiSelect
            name="a_level_institute_id"
            formik={formik}
            label="A Level institutes"
            options={institutes}
            selectProps={{ label: "name", id: "id" }}
          />
          {/* Bachelor Filter */}
          <Typography variant="h5">Filter educations (Bachelor)</Typography>
          <DateRangeField
            label="Bachelor Examination Year"
            from="bachelor_passing_year_from"
            to="bachelor_passing_year_to"
            formik={formik}
          />
          <RangeSlider
            from="bachelor_gpa_from"
            to="bachelor_gpa_to"
            minmax={[0, 4]}
            type="CGPA"
            formik={formik}
          />
          <MultiSelect
            name="bachelor_subject_id"
            formik={formik}
            label="Bachelor Subjects"
            options={subjects}
            selectProps={{ label: "name", id: "id" }}
          />
          <MultiSelect
            name="bachelor_university_id"
            formik={formik}
            label="Bachelor University"
            options={universities}
            selectProps={{ label: "name", id: "id" }}
          />
          {/* Masters Filter */}
          <Typography variant="h5">Filter educations (Masters)</Typography>
          <DateRangeField
            label="Masters Examination Year"
            from="master_passing_year_from"
            to="master_passing_year_to"
            formik={formik}
          />
          <RangeSlider
            from="master_gpa_from"
            to="master_gpa_to"
            minmax={[0, 4]}
            type="CGPA"
            formik={formik}
          />
          <MultiSelect
            name="master_subject_id"
            formik={formik}
            label="Masters Subjects"
            options={subjects}
            selectProps={{ label: "name", id: "id" }}
          />
          <MultiSelect
            name="master_university_id"
            formik={formik}
            label="Masters University"
            options={universities}
            selectProps={{ label: "name", id: "id" }}
          />
          {/* English language Proficiency  Filter */}
          <Typography variant="h5">Filter English Language Course</Typography>
          <TextField
            size="small"
            label="Language Course"
            select
            {...formik.getFieldProps("course_id")}
          >
            {courses?.map((course) => (
              <MenuItem key={course.id} value={course?.id}>
                {course?.name}
              </MenuItem>
            ))}
          </TextField>

          {!formik.values.course_id ? (
            <></>
          ) : courses.find((course) => course.id === formik.values.course_id)
              .name === "DUOLINGO" ? (
            <>
              <RangeSlider
                from="overall_from"
                to="overall_to"
                minmax={[0, 10]}
                type="Overall"
                formik={formik}
              />
              <RangeSlider
                from="literacy_from"
                to="literacy_to"
                minmax={[0, 10]}
                type="Literacy"
                formik={formik}
              />
              <RangeSlider
                from="comprehension_from"
                to="comprehension_to"
                minmax={[0, 10]}
                type="Comprehension"
                formik={formik}
              />
              <RangeSlider
                from="conversation_from"
                to="conversation_to"
                minmax={[0, 10]}
                type="Conversation"
                formik={formik}
              />
              <RangeSlider
                from="production_from"
                to="production_to"
                minmax={[0, 10]}
                type="Production"
                formik={formik}
              />
            </>
          ) : (
            <>
              <RangeSlider
                from="overall_from"
                to="overall_to"
                minmax={[0, 10]}
                type="Overall"
                formik={formik}
              />
              <RangeSlider
                from="listening_from"
                to="listening_to"
                minmax={[0, 10]}
                type="Listening"
                formik={formik}
              />
              <RangeSlider
                from="reading_from"
                to="reading_to"
                minmax={[0, 10]}
                type="Reading"
                formik={formik}
              />
              <RangeSlider
                from="writing_from"
                to="writing_to"
                minmax={[0, 10]}
                type="Writing"
                formik={formik}
              />
              <RangeSlider
                from="speaking_from"
                to="speaking_to"
                minmax={[0, 10]}
                type="Speaking"
                formik={formik}
              />
            </>
          )}
        </Stack>

        <Stack
          direction="row"
          gap="15px"
          p="10px 20px"
          justifyContent="flex-end"
        >
          {isFiltering() ? (
            <Button variant="outlined" onClick={resetForm}>
              Reset Filter
            </Button>
          ) : (
            <Button variant="outlined" onClick={() => setOpenDrawer(false)}>
              Cancel
            </Button>
          )}
          <Button
            variant="solid"
            onClick={formik.submitForm}
            disabled={!formik.dirty}
          >
            Filter Project Customers
          </Button>
        </Stack>
      </LocalizationProvider>
    </RightDrawer>
  );
};

export default AdvanceFilter;
