import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import Activity from "components/common/activity";
import Attachment from "components/common/attachment";
import NotAuthorized from "components/notAuthorized";
import RightDrawer from "components/rightDrawer/RightDrawer";
import { useState } from "react";
import Profile from "./profile/Profile";
import useQueries from "hooks/useQueries";
import useApi from "hooks/useApi";
import { useEffect } from "react";

const ViewTicket = ({ openDrawer, setOpenDrawer, permissions }) => {
  const { row_id, mod_id, setQueries } = useQueries();
  const { fetchData, loading } = useApi();
  const [profile, setProfile] = useState({});
  const [value, setValue] = useState(mod_id || "1");

  const handleChange = (event, newValue) => {
    setQueries(`id=${row_id}&m=${newValue}`);
    setValue(newValue);
  };
  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/ticket/show/${row_id}`,
      };
      const { success, data } = await fetchData(endpoint, false);
      if (success) setProfile(data);
    };
    getProfile();
  }, []);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "1200px",
        headingLeft:
          "#" + row_id + `${profile?.title ? ` - ${profile?.title}` : ""}`,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", overflowX: "hidden" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="View Ticket" value="1" />
              <Tab label="Attachment" value="2" />
              <Tab label="Activity" value="3" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1">
              <Profile profile={profile} loading={loading} row_id={row_id} />
            </TabPanel>

            <TabPanel value="2">
              {permissions.attachment ? (
                <Attachment id={row_id} route="ticket" />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="3">
              {permissions.activity ? (
                <Activity
                  activityList={{
                    method: "get",
                    url: `/api/admin/ticket/activity/${row_id}`,
                  }}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default ViewTicket;
