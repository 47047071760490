import { UploadFileSharp } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Chip, Stack } from "@mui/material";
import InputField from "components/ui/inputField";
import { useFormik } from "formik";
import * as yup from "yup";
const validationSchema = yup.object({
  comment: yup.string().required(""),
});
const CommentForm = ({
  postId,
  placeholder,
  btnText,
  onSubmit,
  loading,
  data,
}) => {
  const initialValues = {
    comment: data?.comment || "",
    task_id: postId,
    file: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box marginBottom={2}>
      <Box my={1}>
        <InputField
          name="comment"
          label={placeholder}
          formik={formik}
          multiline
          rows={3}
          fullWidth
        />
      </Box>

      <Stack
        spacing={1}
        direction={"row"}
        justifyContent={"end"}
        alignItems={"center"}
        marginTop={2}
      >
        {formik.values.file ? (
          <Box>
            <Chip
              label={formik.values.file.name}
              deleteIcon={<DeleteIcon />}
              variant="outlined"
              onDelete={() => formik.setFieldValue("file", "")}
            />
          </Box>
        ) : (
          data?.file && (
            <Box>
              <Chip label={data?.attachment_name} variant="outlined" />
            </Box>
          )
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Button
            component="label"
            startIcon={<UploadFileSharp />}
            variant="outlined"
          >
            <input
              name="file"
              type="file"
              hidden
              onChange={(e) => formik.setFieldValue("file", e.target.files[0])}
            />
            Attach File
          </Button>
        </Box>

        <Button
          variant="solid"
          onClick={formik.submitForm}
          disabled={!(formik.dirty && formik.isValid) || loading}
        >
          {btnText}
        </Button>
      </Stack>
    </Box>
  );
};

export default CommentForm;
