import * as yup from "yup";

const validationSchema = yup.object({
  currency_id: yup.string("").required("Currency is Required").nullable(),
  expense_category_id: yup
    .string("")
    .required("Expense Category is Required")
    .nullable(),
  payment_method_id: yup
    .string("")
    .required("Payment Method is Required")
    .nullable(),
  name: yup.string("").required("Name is Required").nullable(),
});

export default validationSchema;
