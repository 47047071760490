const processNestedOptions = (data) => {
  const plainOptions = [];
  const addedKeys = [];

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      const values = data[key];
      if (!addedKeys.includes(key)) {
        plainOptions.push({ name: key, id: key, isGroupTitle: true });
        addedKeys.push(key); // Add the key to the addedKeys array
      }
      // Push the values for the current key
      plainOptions.push(...values);
    }
  }
  return plainOptions;
};

export default processNestedOptions;
