import { Add, FilterAltOutlined, TimelineOutlined } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import useQueries from "hooks/useQueries";
import { useEffect, useState } from "react";

const Header = ({
  activeMenu,
  setActiveMenu,
  setOpenFilter,
  setSearch,
  setPagination,
  pagination,
  permissions,
}) => {
  const { setQueries } = useQueries();
  const [searchText, setSearchText] = useState("");
  const debouncedText = useDebounce(searchText, 1000);

  useEffect(() => {
    setSearch(debouncedText);
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in table.."}
        />
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          disabled={!permissions.summery}
          variant={activeMenu === "summary" ? "solid" : "outlined"}
          startIcon={<TimelineOutlined />}
          onClick={() =>
            setActiveMenu(activeMenu === "summary" ? "" : "summary")
          }
        >
          Summary
        </Button>
        <Button
          variant={"outlined"}
          startIcon={<FilterAltOutlined />}
          onClick={() => setOpenFilter(true)}
        >
          Advanced Filters
        </Button>

        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => setQueries("m=0")}
          disabled={!permissions.create}
        >
          New Commission
        </Button>
      </Box>
    </Grid>
  );
};

export default Header;
