import { Box, Checkbox, FormControlLabel } from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import { HiOutlineCheckBadge } from "react-icons/hi2";
import * as yup from "yup";

const ProjectCustomerStatuses = () => {
  const initialValues = {
    name: "",
    color: "",
    is_final: false,
  };
  return (
    <SetupCC
      shouldReload
      name="Customer Status"
      baseRouteKey="projectCustomerStatuses"
      moduleKey="project-customer-status"
      columns={[
        { id: "name", label: "Name", minWidth: 100 },
        { id: "is_final", label: "Is Final", align: "center" },
        { id: "color", label: "Status Color", align: "center" },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "color" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: value,
                    height: "20px",
                    width: "20px",
                    borderRadius: "5px",
                  }}
                ></Box>
              </Box>
            ) : column?.id === "name" ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                {value}
                {!!row.is_final && (
                  <Box sx={{ color: "green", fontSize: "15px" }}>
                    <HiOutlineCheckBadge />
                  </Box>
                )}
              </Box>
            ) : column?.id === "is_final" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "5px",
                }}
              >
                {value === 1 ? "Yes" : "No"}
              </Box>
            ) : (
              value
            )}
          </>
        );
      }}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
        color: yup.string().required("Status color required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Status Name" formik={formik} />
          <InputField
            label="Status Color"
            name="color"
            type="color"
            formik={formik}
            InputLabelProps={{ shrink: true }}
          />
          <FormControlLabel
            control={<Checkbox />}
            label="Is Final?"
            checked={formik.values.is_final}
            onChange={(e) => formik.setFieldValue("is_final", e.target.checked)}
          />
        </>
      )}
    ></SetupCC>
  );
};

export default ProjectCustomerStatuses;
