import SetupCC from "components/setupCC";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import * as yup from "yup";

const Universities = () => {
  const [countries, setCountries] = useState([]);
  const { fetchData } = useApi();

  const initialValues = {
    name: "",
    description: "",
    address: "",
    start_year: null,
    country_id: "",
    commission: "",
    commission_type: "",
  };

  useEffect(() => {
    (async () => {
      const { success, data } = await fetchData(
        {
          method: "get",
          url: "/api/admin/base/country",
        },
        false
      );
      if (success) setCountries(data);
    })();
  }, []);

  return (
    <SetupCC
      name="University"
      baseRouteKey="universities"
      moduleKey="university"
      columns={[
        { id: "name", label: "University Name", minWidth: 100 },

        { id: "commission", label: "University Commission", minWidth: 50 },
        { id: "commission_type", label: "Commission Type", minWidth: 50 },
        {
          id: "description",
          label: "Description",
          minWidth: 100,
        },
        {
          id: "start_year",
          label: "Established",
          minWidth: 100,
          align: "center",
        },
        { id: "country_name", label: "Country", minWidth: 200 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
        country_id: yup.string().required("Country is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="University Name *" formik={formik} />
          <Select
            isCountry={true}
            options={countries}
            label="Country *"
            value={formik?.values?.country_id}
            onChange={(value) => formik.setFieldValue("country_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />
          <InputField
            name="address"
            label="Address"
            rows={2}
            multiline
            formik={formik}
          />
          <InputField
            name="description"
            label="Description"
            rows={2}
            multiline
            formik={formik}
          />
          <DateTimePicker
            views={["year"]}
            name="start_year"
            label="Start Year"
            formik={formik}
          />


          <InputField
            name="commission"
            label="University Commition"
            multiline
            formik={formik}
          />

          <Select
            label="University Commission Type"
            name="commission_type"
            options={[
              { id: "fixed", name: "Fixed" },
              { id: "percentage", name: "Percentage" },
            ]}
            formik={formik}
            selectProps={{ id: "id", label: "name" }}
          />
        </>
      )}
    ></SetupCC>
  );
};

export default Universities;
