import { Button } from "@mui/material";
import { Box } from "@mui/system";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApiCall from "hooks/useApiCall";
import useNotificationQuery from "hooks/useNotificationQuery";
import usePermissions from "hooks/usePermissions";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteCustomer,
  restoreCustomer,
  viewAllCustomer,
} from "../api/customer";
import CustomerBulkActions from "./CustomerBulkActions";
import CustomerHeader from "./CustomerHeader";
import MainScreenFilter from "./filter/MainScreenFilter";
import CustomerTable from "./table/CustomerTable";

const CustomerTrash = () => {
  const dispatch = useDispatch();
  const permissions = usePermissions("customer");
  const [activeMenu, setActiveMenu] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [allCustomers, setallCustomers] = useState([]);
  // Helper states
  const { base_data } = useSelector((state) => state.base);
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const { data, loading, fetchData: fetchListData } = useApiCall();
  let getParamsId = useNotificationQuery(reload);

  useEffect(() => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    finalFilters.search = search;
    finalFilters.id = getParamsId;

    fetchListData(
      viewAllCustomer,
      {
        body_data: finalFilters,
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reload,
    search,
    pagination?.current_page,
    pagination?.per_page,
    getParamsId,
  ]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setallCustomers(allData);
      setPagination(rest);
      setSelectedRows([]);
    }
  }, [data]);

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    try {
      if (allCustomers?.length > 1) {
        setallCustomers(
          allCustomers.filter((customer) => customer?.id !== openAlert)
        );
        setOpenAlert(false);
        const result = await deleteCustomer({ id: openAlert });
        if (result.success) {
          toast.success(result?.message);
        } else {
          toast.error(result?.message);
        }
      } else {
        setOpenAlert(false);
        const result = await deleteCustomer({ id: openAlert });
        if (result.success) {
          toast.success(result?.message);
        } else {
          toast.error(result?.message);
        }
        setReload((pre) => !pre);
      }
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleRestore = async (id) => {
    dispatch(setLoading(true));
    try {
      const result = await restoreCustomer({ id });
      if (result.success) {
        toast.success(result?.message);
      } else {
        toast.error(result?.message);
      }
      setReload((pre) => !pre);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  const all_props = {
    ...base_data,
    setReload,
    filters,
    setFilters,
    isFiltering,
    pagination,
    setPagination,
    allCustomers,
    setallCustomers,
    handleRestore,
    permissions,
  };

  // Bulk options
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTableCheckBox = (id, checked) => {
    if (id === "all") {
      if (checked) {
        setSelectedRows(allCustomers?.map((customer) => customer?.id));
      } else {
        setSelectedRows([]);
      }
    } else {
      if (checked) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows(selectedRows?.filter((customer) => customer !== id));
      }
    }
  };

  return (
    <div>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete customer"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this customer
              permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <CustomerHeader
        {...{
          activeMenu,
          setActiveMenu,
          openSidebar,
          setOpenSidebar,
          setSearch,
          isFiltering,
          setPagination,
          pagination,
          setReload,
          allCustomers,
          permissions,
          search,
          filters,
        }}
      />
      {!activeMenu &&
        !selectedRows?.length > 0 &&
        !!(
          permissions["trash list(own)"] || permissions["trash list(global)"]
        ) && <MainScreenFilter {...all_props} />}
      {selectedRows?.length > 0 && (
        <CustomerBulkActions
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          {...all_props}
        />
      )}
      {!activeMenu &&
        (permissions["trash list(own)"] || permissions["trash list(global)"] ? (
          <CustomerTable
            handleDelete={handleDelete}
            loading={loading}
            handleTableCheckBox={handleTableCheckBox}
            selectedRows={selectedRows}
            {...all_props}
          />
        ) : (
          <NotAuthorized />
        ))}
    </div>
  );
};

export default CustomerTrash;
