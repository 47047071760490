import RightDrawer from "components/rightDrawer/RightDrawer";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileDetails from "./ProfileDetails";
// MUI
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Permissions from "components/common/permissions";
import Timesheet from "components/common/timesheet/Timesheet";
import usePermissions from "hooks/usePermissions";
import NotAuthorized from "components/notAuthorized";

const Profile = ({ openDrawer, setOpenDrawer, index_to_open }) => {
  const permissions = usePermissions("staff");
  const user = useSelector((state) => state?.auth?.user_details?.user);
  const { loading, fetchData } = useApi();
  const [profile, setProfile] = useState({});
  const [value, setValue] = useState(index_to_open || "1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProfileDetails = async () => {
    const endpoint = {
      url: `/api/admin/staff/profile/${user.id}`,
      method: "get",
    };
    const result = await fetchData(endpoint, false);
    if (result.success) setProfile(result?.data);
  };

  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "900px",
        headingLeft: user?.name,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Profile" value="1" />
              <Tab label="Permissions" value="2" />
              <Tab label="Timesheet" value="3" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1">
              <ProfileDetails
                profile={profile}
                setOpenDrawer={setOpenDrawer}
                loading={loading}
              />
            </TabPanel>
            <TabPanel value="2">
              <Permissions
                role_permissions={profile?.rolePermissions}
                loading={loading}
                isAdmin={
                  profile?.role?.toLowerCase() === "admin" ? true : false
                }
              />
            </TabPanel>
            <TabPanel value="3">
              {permissions["timesheet"] ? (
                <Timesheet id={user.id} />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default Profile;
