import { Avatar, Box, TableCell, TableRow } from "@mui/material";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  selectedRows,
  handleTableCheckBox,
  permissions,
  ...restProps
}) => {
  return (
    <TableRow
      {...restProps}
      hover
      role="checkbox"
      key={row.id}
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 20px",
        },
      }}
    >
      {columns.map((column, i) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.id === "name" ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <Avatar src={row?.image} />
                <span>{value}</span>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
