import { PrintRounded } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import UNIVERSAL from "config";
import useFormatter from "hooks/useFormatter";
import React from "react";
import { useRef } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import { TableData, TableDetails } from "../Details";
import { viewSinglePayment } from "features/invoices/bank/api/invoiceBank";

const ViewInstallment = ({ setViewPayment, profile, symbol, currPayment }) => {
  const { formatDate, formatCurrency } = useFormatter();
  const site_logo = useSelector(
    (state) => state?.base?.base_data?.settings?.general?.site_logo
  );

  const printableContentRef = useRef();
  return (
    <>
      <Button
        sx={{
          mb: "20px",
        }}
        startIcon={<BiLeftArrowAlt />}
        onClick={() => setViewPayment(false)}
      >
        View Installment
      </Button>

      <ReactToPrint
        documentTitle={profile?.invoice_number}
        trigger={() => (
          <Button
            variant="outlined"
            startIcon={<PrintRounded />}
            sx={{
              position: "absolute",
              right: "20px",
              top: "20px",
            }}
          >
            Print
          </Button>
        )}
        content={() => printableContentRef.current}
      />

      <Box ref={printableContentRef} p={2}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <img
            src={UNIVERSAL.BASEURL + site_logo}
            alt=""
            style={{
              maxHeight: "100px",
              maxWidth: "240px",
              marginLeft: "-15px",
            }}
          />
        </Box>
        <Box
          sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "50px" }}
        >
          <Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
              <Typography textTransform="uppercase">Invoice Number:</Typography>
              <Typography> {profile?.invoice_number} </Typography>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
              <Typography textTransform="uppercase">Customer Name:</Typography>
              <Typography>{profile?.customer_name}</Typography>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
              <Typography textTransform="uppercase">Bank Name:</Typography>
              <Typography>{profile?.bank_name}</Typography>
            </Box>

            <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
              <Typography textTransform="uppercase">Reference Name:</Typography>
              <Typography>{profile?.reference_name}</Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
              <Typography textTransform="uppercase">Open Date:</Typography>
              <Typography>{formatDate(profile?.account_open_date)}</Typography>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
              <Typography textTransform="uppercase">Close Date:</Typography>
              <Typography>{formatDate(profile?.account_close_date)}</Typography>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
              <Typography textTransform="uppercase">Contact Name:</Typography>
              <Typography>{profile?.contact_name}</Typography>
            </Box>
            <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
              <Typography textTransform="uppercase">Contact Number:</Typography>
              <Typography>{profile?.contact_number}</Typography>
            </Box>
          </Box>
        </Box>

        <TableData
          rows={[currPayment]}
          formatDate={formatDate}
          symbol={symbol}
          columns={[
            { id: "month", label: "Month" },
            { id: "year", label: "Year" },
            { id: "expected_payment_type", label: "Installment" },
            { id: "status", label: "Status" },
            { id: "amount", label: "Amount" },
            { id: "total_due", label: "Due" },
            { id: "total_paid", label: "Total Paid" },
          ]}
        />

        <TableDetails
          formatDate={formatDate}
          symbol={symbol}
          title={"Payment History"}
          profile={currPayment}
          apiCall={viewSinglePayment}
          columns={[
            {
              id: "payment_method",
              label: "Payment Method",
            },
            // { id: "expected_payment_type", label: "Installment" },

            {
              id: "total_paid",
              label: "Total Paid",
            },
            { id: "payment_date", label: "Payment Date" },
          ]}
        />
        <Box
          sx={{
            margin: "20px 0 0 auto",
            padding: "20px 30px",
            border: "1px solid #caddff",
            bgcolor: "#fbfcff",
            width: "300px",
            mt: "30px",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "100px auto",
            }}
          >
            <Typography align="right">Payable</Typography>
            <Typography align="right">
              {symbol + formatCurrency(currPayment?.amount)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "100px auto",
            }}
          >
            <Typography align="right">Due Amount</Typography>
            <Typography align="right">
              {symbol + formatCurrency(currPayment?.total_due)}
            </Typography>
          </Box>

          <Box
            sx={{
              borderTop: "1px solid #cbcbcb",
              display: "grid",
              gridTemplateColumns: "100px auto",
              pt: "5px",
              mt: "5px",
            }}
          >
            <Typography align="right" fontSize="16px">
              Total Paid
            </Typography>
            <Typography align="right" fontSize="16px">
              {symbol + formatCurrency(currPayment?.total_paid)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ViewInstallment;
