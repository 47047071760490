import { Box, Button } from "@mui/material";
import MultiSelect from "components/ui/multiSelect";
import getInitialvalues from "features/projectCustomers/utils/filterInitialValues";
import { useFormik } from "formik";
import * as yup from "yup";

const MainScreenFilter = ({
  setReload,
  setFilters,
  setPagination,
  pagination,
  filters,
  isFiltering,
  projects,
  assignees,
  projectCustomerStatuses,
  intakes,
}) => {
  const initialValues = getInitialvalues(filters);

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    setFilters({ ...filters, ...data });
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const resetForm = () => {
    const emptyFilter = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = null;
      }
    }
    formik.setValues(emptyFilter);
    setFilters({});
    setReload((pre) => !pre);
  };

  return (
    <Box
      p="15px 20px 5px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gap: "18px",
      }}
    >
      <MultiSelect
        options={projects}
        label="Projects"
        name="project_id"
        value={formik?.values?.project_id}
        selectProps={{
          id: "id",
          label: "name",
        }}
        onChange={(value) => formik.setFieldValue("project_id", value)}
      />
      <MultiSelect
        options={projectCustomerStatuses}
        label="Statuses"
        value={formik?.values?.project_customer_status_id}
        name="project_customer_status_id"
        selectProps={{
          id: "id",
          label: "name",
        }}
        onChange={(value) =>
          formik.setFieldValue("project_customer_status_id", value)
        }
      />
      <MultiSelect
        options={assignees}
        label="Assignees"
        name="user_id"
        value={formik?.values?.user_id}
        selectProps={{
          id: "user_id",
          label: "name",
        }}
        onChange={(value) => formik.setFieldValue("user_id", value)}
      />
      <MultiSelect
        options={intakes}
        label="Intakes"
        value={formik?.values?.intake_id}
        name="intake_id"
        selectProps={{
          id: "id",
          label: "name",
        }}
        onChange={(value) => formik.setFieldValue("intake_id", value)}
      />
      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          disabled={!formik.dirty}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          disabled={!isFiltering()}
          onClick={resetForm}
        >
          Reset Filter
        </Button>
      </Box>
    </Box>
  );
};

export default MainScreenFilter;
