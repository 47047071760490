import {
  Box,
  Button,
  MenuItem,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import { useState } from "react";
import ReactLoading from "react-loading";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const LCPCombo = ({ data, filters, setFilters, loading }) => {
  const [openFilter, setOpenFilter] = useState(false);
  const chartData = data?.lead_customer_project_bar_chart || [];

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: "3px",
        boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #efefef",
        }}
      >
        <Box
          sx={{
            p: "9.6px 15px 9.6px 20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              position: "relative",
              fontWeight: "500",
              "&::before": {
                content: "''",
                position: "absolute",
                height: "1rem",
                width: ".2rem",
                insetBlockStart: ".18rem",
                insetInlineStart: "-.60rem",
                background:
                  "linear-gradient(to bottom, #c1acef 50%, #90daf2 50%)",
                borderRadius: ".5rem",
              },
            }}
          >
            Total lead, customer, project count
          </Typography>
          <Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              {loading && filters.filterEntry.includes("lcpCombo") && (
                <ReactLoading
                  type="bubbles"
                  color="#405189"
                  height={"15px"}
                  width={"30px"}
                />
              )}
              <ToggleButtonGroup
                size="small"
                color="primary"
                value={filters?.lead_customer_project_default_chart}
                exclusive
                onChange={(e, value) => {
                  if (value !== null && value !== "filter_by") {
                    setFilters({
                      ...filters,
                      filterEntry: ["lcpCombo"],
                      lead_customer_project_default_chart: value,
                    });
                  } else if (value === "filter_by") {
                    setOpenFilter((pre) => !pre);
                  }
                }}
                sx={{
                  "& button": {
                    padding: "3px 10px",
                  },
                }}
              >
                <ToggleButton value="week">Weekly</ToggleButton>
                <ToggleButton value="month">Monthly</ToggleButton>
                <ToggleButton value="filter_by">Filter By</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
        </Box>
        {openFilter && (
          <Box sx={{ bgcolor: "#f4f4f480", p: "10px" }}>
            <Stack direction="row" spacing={1}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  views={["year"]}
                  value={filters.year_for_lead_customer_project}
                  onChange={(newValue) =>
                    setFilters({
                      ...filters,
                      filterEntry: ["lcpCombo"],
                      year_for_lead_customer_project: moment(newValue).isValid()
                        ? moment(newValue).format("YYYY")
                        : null,
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select year"
                      size="small"
                      sx={{ width: "150px" }}
                    />
                  )}
                />
                {filters?.lead_customer_project_default_chart === "week" && (
                  <>
                    <TextField
                      size="small"
                      label="Select month"
                      select
                      value={filters.month_for_lead_customer_project}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          filterEntry: ["lcpCombo"],
                          month_for_lead_customer_project: e.target.value,
                        })
                      }
                      sx={{ width: "150px" }}
                    >
                      <MenuItem value={"01"}>January</MenuItem>
                      <MenuItem value={"02"}>February</MenuItem>
                      <MenuItem value={"03"}>March</MenuItem>
                      <MenuItem value={"04"}>April</MenuItem>
                      <MenuItem value={"05"}>May</MenuItem>
                      <MenuItem value={"06"}>June</MenuItem>
                      <MenuItem value={"07"}>July</MenuItem>
                      <MenuItem value={"08"}>August</MenuItem>
                      <MenuItem value={"09"}>September</MenuItem>
                      <MenuItem value={"10"}>October</MenuItem>
                      <MenuItem value={"11"}>November</MenuItem>
                      <MenuItem value={"12"}>December</MenuItem>
                    </TextField>
                    <TextField
                      size="small"
                      label="Select week"
                      select
                      value={filters.week_for_lead_customer_project}
                      onChange={(e) =>
                        setFilters({
                          ...filters,
                          filterEntry: ["lcpCombo"],
                          week_for_lead_customer_project: e.target.value,
                        })
                      }
                      sx={{ width: "150px" }}
                    >
                      <MenuItem value={"current"}>Current</MenuItem>
                      <MenuItem value={"week1"}>Week 1</MenuItem>
                      <MenuItem value={"week2"}>Week 2</MenuItem>
                      <MenuItem value={"week3"}>Week 3</MenuItem>
                      <MenuItem value={"week4"}>Week 4</MenuItem>
                      <MenuItem value={"week5"}>Week 5</MenuItem>
                    </TextField>
                  </>
                )}
                <Button
                  variant="outlined"
                  disabled={
                    !(
                      filters.week_for_lead_customer_project !== "current" ||
                      filters.month_for_lead_customer_project ||
                      filters.year_for_lead_customer_project
                    )
                  }
                  onClick={() => {
                    setFilters({
                      ...filters,
                      filterEntry: ["lcpCombo"],
                      week_for_lead_customer_project: "current",
                      month_for_lead_customer_project: "",
                      year_for_lead_customer_project: null,
                    });
                  }}
                >
                  Reset Filter
                </Button>
              </LocalizationProvider>
            </Stack>
          </Box>
        )}
      </Box>
      <Box sx={{ height: "480px", p: "20px 15px 20px 0" }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            width={500}
            height={300}
            data={chartData}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Bar dataKey="lead" fill="#8884d8" />
            <Bar dataKey="customer" fill="#82ca9d" />
            <Bar dataKey="project" fill="#ffc658" />
            <Legend />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default LCPCombo;
