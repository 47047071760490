import { Backdrop, CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import Router from "./router";

function App() {
  const { status } = useSelector((state) => state.loading);

  return (
    <>
      <Router />
      <Backdrop sx={{ color: "#fff", zIndex: 9999 }} open={status}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default App;
