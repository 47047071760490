import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Alert from "components/alert";
import TableSkeleton from "components/skeletons/TableSkeleton";
import { deleteAdmission } from "features/projectCustomers/api/projectCustomer";
import moment from "moment";
import { Fragment, useState } from "react";
import { toast } from "react-toastify";

const AdmissionTable = ({
  loading,
  allAdmission,
  setAllAdmission,
  setCurrAdmission,
  setAddAdmission,
}) => {
  const rows = allAdmission;
  const columns = [
    { id: "country_name", label: "Country", minWidth: 100 },
    { id: "university_name", label: "University", minWidth: 100 },
    { id: "subject_name", label: "Subject" },
    { id: "student_id", label: "Student Id" },
    { id: "start_class", label: "Start Class" },
    { id: "education_level", label: "Edu Level" },
    { id: "status_date", label: "Date" },
    { id: "text", label: "Note" },
    { id: "status", label: "Status", align: "center" },
    { id: "action", label: "Actions", minWidth: 70, align: "center" },
  ];

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    const result = await deleteAdmission({ id: openAlert });
    if (result.success) {
      toast.success(result.message);
      setAllAdmission(
        allAdmission.filter((admission) => admission.id !== openAlert)
      );
    } else {
      toast.error(result.message);
    }
    setOpenAlert(false);
  };

  const handleEditBtn = (value) => {
    setCurrAdmission(value);
    setAddAdmission(true);
  };

  return (
    <Fragment>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Admission Details"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this Admission
              Details permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 256px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "status" ? (
                            <Box
                              sx={{
                                bgcolor: `${row?.color}15`,
                                color: row.color,
                                textAlign: "center",
                                padding: "3px 5px",
                                borderRadius: "20px",
                                width: "100px",
                                mt: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {value}
                            </Box>
                          ) : column.id === "start_class" ||
                            column.id === "status_date" ? (
                            value ? (
                              moment(value).format("ll")
                            ) : (
                              "-"
                            )
                          ) : column.id === "action" ? (
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                justifyContent: "center",
                              }}
                            >
                              <IconButton onClick={() => handleEditBtn(row)}>
                                <Edit />
                              </IconButton>
                              <IconButton onClick={() => handleDelete(row.id)}>
                                <Delete />
                              </IconButton>
                            </Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 265px)" />
      )}
      {/* <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      /> */}
    </Fragment>
  );
};

export default AdmissionTable;
