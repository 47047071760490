const removeHtml = (html, trimSize) => {
  const cleanr = /<\/?[^>]+>|\s+/gi;
  const plainText = html.replace(cleanr, " ");
  const trimedText = plainText.trim();
  if (trimSize)
    return trimedText.length > trimSize
      ? trimedText.slice(0, trimSize) + "..."
      : trimedText;
  else return trimedText;
};

export default removeHtml;
