import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import * as yup from "yup";

const Semesters = () => {
  const initialValues = {
    name: "",
    year: "",
  };

  return (
    <SetupCC
      sidebarWidth="600px"
      name="Semester"
      moduleKey="semester"
      columns={[
        { id: "name", label: "Name", minWidth: 100 },
        { id: "year", label: "Year", minWidth: 100 },
        { id: "created_by_name", label: "Created By", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
        year: yup.string().required("Year is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Semester Name" formik={formik} />
          <InputField name="year" label="Year" formik={formik} />
        </>
      )}
    ></SetupCC>
  );
};

export default Semesters;
