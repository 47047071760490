import Chat from "components/common/chat";
import { useSelector } from "react-redux";

const CustomerChat = () => {
  const customer_id = useSelector((state) => state.auth.user_details?.user?.id);

  return (
    <Chat
      route={"customer"}
      isCustomer
      customer_id={customer_id}
      owner_id={customer_id}
    />
  );
};

export default CustomerChat;
