import { Box, Button } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { BiLeftArrowAlt } from "react-icons/bi";
import * as yup from "yup";
import { createMakePayment, updateMakePayment } from "../../../api/invoiceBank";
import { useSelector } from "react-redux";

const AddEditMakePayments = ({
  id,
  setAddPayment,
  currPayment,
  setReload,
  profile,
  setReloadMain,
}) => {
  let paymentMethods = useSelector(
    (state) => state?.base.base_data?.paymentMethods
  );
  const { loading, fetchData } = useApi();

  const initialValues = {
    bank_invoice_id: profile?.id,
    total_paid: "",
    payment_method_id: "",
    payment_date: null,
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let obj = {};
      for (const key in initialValues) {
        obj[key] = data[key];
      }
      return obj;
    } else return initialValues;
  };

  const onSubmit = async (data) => {
    let result = await fetchData(
      currPayment?.id
        ? updateMakePayment(data, currPayment?.id)
        : createMakePayment(data)
    );
    if (result?.success) {
      setReload((pre) => !pre);
      // setReloadMain((pre) => !pre);
      setAddPayment(false);
    }
  };
  const formik = useFormik({
    initialValues: getInitialValues(currPayment),
    validationSchema: yup.object({
      total_paid: yup.number().required("Required Field"),
      payment_method_id: yup.string("").required().nullable(),
    }),
    onSubmit,
    enableReinitialize: true,
  });
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddPayment(false)}
        >
          View Payments
        </Button>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "15px",
          pb: "15px",
        }}
      >
        <Select
          name="payment_method_id"
          options={paymentMethods}
          label="Select Payment Method *"
          formik={formik}
          selectProps={{ id: "id", label: "name" }}
        />

        <InputField
          name="total_paid"
          label="Total Paid *"
          type="number"
          formik={formik}
        />
        <DateTimePicker
          name="payment_date"
          label="Payment Date"
          type="date"
          formik={formik}
        />
        <Button
          sx={{ gridColumn: "span 2" }}
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={
            (!(formik.dirty && formik.isValid) && !currPayment?.id) || loading
          }
        >
          {currPayment?.id ? "Update Payment" : "Save Payment"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddEditMakePayments;
