import { createSlice } from "@reduxjs/toolkit";
import { ATTENDANCE_STATUS } from "utils/dataKeys";
import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "utils/manageLocalStorage";

const localData = loadFromLocalStorage(ATTENDANCE_STATUS) || {};

const initialState = {
  status: localData.status || 1,
  attendance_id: localData.attendance_id,
};

const baseSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    saveAttStatus: (state, action) => {
      state.status = action.payload.status;
      state.attendance_id = action.payload.attendance_id;
      saveToLocalStorage(ATTENDANCE_STATUS, action.payload);
    },
  },
});

export const { saveAttStatus } = baseSlice.actions;
export default baseSlice.reducer;
