import { Box, Typography } from "@mui/material";
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

const PaymentSummary = ({ data }) => {
  const chartData = data?.payment_pie_chart || [];

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: "3px",
        boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #efefef",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "13px 15px 13px 20px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              position: "relative",
              fontWeight: "500",
              "&::before": {
                content: "''",
                position: "absolute",
                height: "1rem",
                width: ".2rem",
                insetBlockStart: ".18rem",
                insetInlineStart: "-.60rem",
                background:
                  "linear-gradient(to bottom, #c1acef 50%, #90daf2 50%)",
                borderRadius: ".5rem",
              },
            }}
          >
            Payment Summary
          </Typography>
        </Box>
      </Box>
      <Box sx={{ height: "450px", pb: "20px" }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={400} height={400}>
            <Pie
              style={{ outline: "none" }}
              dataKey="value"
              isAnimationActive={false}
              data={chartData}
              cx="50%"
              cy="50%"
              outerRadius={80}
              innerRadius={60}
              fill="#8884d8"
              label
            >
              {chartData.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            <Tooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default PaymentSummary;
