import { Box, LinearProgress, Skeleton, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import useFormatter from "hooks/useFormatter";
import { useEffect, useState } from "react";
import { MdOutlineStorage } from "react-icons/md";
import { RiFileList3Line } from "react-icons/ri";
import { TbMessage, TbReport, TbUserSearch, TbUsers } from "react-icons/tb";

const QuickStats = ({ data, loading, filters }) => {
  const { formatNumber, formatCurrency } = useFormatter();
  let [smsValue, setsmsValue] = useState(0);
  let [storageValue, setstorageValue] = useState({
    total_size: 0,
    total_project_size: 0,
  });
  let { loading: loadingSMS, fetchData: getSMSValue } = useApi();

  let { loading: loadingStoarge, fetchData: getStorageValue } = useApi();

  useEffect(() => {
    async function callApi() {
      let endpointStotage = {
        method: "get",
        url: "/api/admin/base/project-total-size",
      };
      let resultStorage = await getStorageValue(endpointStotage, false);
      if (resultStorage.success) {
        let { total_given_size, total_project_size } = resultStorage.data;
        setstorageValue({
          total_size: (total_given_size / 1024).toFixed(2),
          total_project_size: (total_project_size / 1024).toFixed(2),
        });
      }
    }

    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function callApi() {
      let endpointSMS = {
        method: "get",
        url: "/api/admin/base/sms-balance",
      };

      let resultSms = await getSMSValue(endpointSMS, false);
      if (resultSms.success) {
        setsmsValue(resultSms.data.balance);
      }
    }

    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let totalAmount = 20000; // 20,000 taka
  let percentage = (smsValue / totalAmount) * 100;
  let formattedPercentage =
    percentage.toFixed(2) >= 100 ? 100 : percentage.toFixed(2);

  let percentageStorage =
    (storageValue.total_project_size / storageValue.total_size) * 100;
  let formattedPercentageStorage =
    percentageStorage.toFixed(2) >= 100 ? 100 : percentageStorage.toFixed(2);
  return (
    <>
      <Box
        sx={{
          pb: "20px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(375px, 1fr))",
          gap: "20px",
          "& > div": {
            bgcolor: "#fff",
            borderRadius: "3px",
            boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
            p: "15px",
            "& > div": {
              display: "flex",
              alignItems: "center",
              gap: "5px",
              mb: "10px",
            },
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
              color:
                storageValue.total_project_size > 0 &&
                storageValue.total_project_size >= storageValue.total_size - 2
                  ? "#fa0000"
                  : "",
            }}
          >
            <MdOutlineStorage style={{ fontSize: "18px", color: "#555" }} />
            <Typography>Storage amount </Typography>
            {loadingStoarge ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "130px", ml: "auto" }}
              />
            ) : (
              <Typography
                sx={{
                  ml: "auto",
                  fontWeight: 600,
                }}
              >
                {storageValue.total_project_size}
                GB/{storageValue.total_size}GB
              </Typography>
            )}
          </Box>
          <LinearProgress
            color="secondary"
            value={formattedPercentageStorage}
            variant="determinate"
          />
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
              color: smsValue > 0 && smsValue >= totalAmount ? "#fa0000" : "",
            }}
          >
            <TbMessage style={{ fontSize: "18px", color: "#555" }} />
            <Typography>SMS Balance</Typography>
            {loadingSMS ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "170px", ml: "auto" }}
              />
            ) : (
              <Typography
                sx={{
                  ml: "auto",
                  flexGrow: 0,
                  fontWeight: 600,
                }}
              >
                {formatCurrency(smsValue)}/{formatCurrency(totalAmount)}
              </Typography>
            )}
          </Box>
          <LinearProgress
            color="secondary"
            value={formattedPercentage}
            variant="determinate"
          />
        </Box>
      </Box>

      <Box
        sx={{
          pb: "20px",
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(375px, 1fr))",
          gap: "20px",
          "& > div": {
            bgcolor: "#fff",
            borderRadius: "3px",
            boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
            p: "15px",
            "& > div": {
              mb: "10px",
              "& > p:first-of-type": {
                backgroundColor: "#efefef",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "26px",
                width: "26px",
                borderRadius: "3px",
                fontSize: "17px",
                color: "#666",
              },
              "& > p:nth-of-type(3)": {
                textAlign: "right",
              },
            },
          },
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Typography>
              <TbUserSearch />
            </Typography>
            <Typography>Converted Leads</Typography>
            {loading && filters.filterEntry.includes("count") ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "80px", ml: "auto" }}
              />
            ) : (
              <Typography sx={{ ml: "auto" }}>
                {formatNumber(data?.lead_count?.converted_leads)}/
                {formatNumber(data?.lead_count?.total_leads)}
              </Typography>
            )}
          </Box>
          <LinearProgress
            color="red"
            value={
              (data?.lead_count?.converted_leads /
                data?.lead_count?.total_leads) *
              100
            }
            variant="determinate"
          />
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Typography>
              <TbUsers />
            </Typography>
            <Typography>Active Customers</Typography>
            {loading && filters.filterEntry.includes("count") ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "80px", ml: "auto" }}
              />
            ) : (
              <Typography sx={{ ml: "auto" }}>
                {formatNumber(data?.customer_count?.active_customers)}/
                {formatNumber(data?.customer_count?.total_customers)}
              </Typography>
            )}
          </Box>
          <LinearProgress
            color="success"
            value={
              (data?.customer_count?.active_customers /
                data?.customer_count?.total_customers) *
              100
            }
            variant="determinate"
          />
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Typography>
              <RiFileList3Line />
            </Typography>
            <Typography>Invoice Paid</Typography>
            {loading && filters.filterEntry.includes("count") ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "80px", ml: "auto" }}
              />
            ) : (
              <Typography sx={{ ml: "auto" }}>
                ৳{formatNumber(data?.invoice_amount?.total_invoice_paid_amount)}
                / ৳{formatNumber(data?.invoice_amount?.total_invoice_amount)}
              </Typography>
            )}
          </Box>
          <LinearProgress
            color="blue"
            value={
              (data?.invoice_amount?.total_invoice_paid_amount /
                data?.invoice_amount?.total_invoice_amount) *
              100
            }
            variant="determinate"
          />
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "15px",
              alignItems: "center",
            }}
          >
            <Typography>
              <TbReport />
            </Typography>
            <Typography>Tasks Finished</Typography>
            {loading && filters.filterEntry.includes("count") ? (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", width: "80px", ml: "auto" }}
              />
            ) : (
              <Typography sx={{ ml: "auto" }}>
                {formatNumber(data?.task_count?.completed_tasks)}/
                {formatNumber(data?.task_count?.total_tasks)}
              </Typography>
            )}
          </Box>
          <LinearProgress
            color="primary"
            value={
              (data?.task_count?.completed_tasks /
                data?.task_count?.total_tasks) *
              100
            }
            variant="determinate"
          />
        </Box>
      </Box>
    </>
  );
};

export default QuickStats;
