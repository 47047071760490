import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApiCall from "hooks/useApiCall";
import useBase from "hooks/useBase";
import usePermissions from "hooks/usePermissions";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  checkOut,
  deleteAttendence,
  viewAllAttendence,
} from "../api/attendence";
import AttendenceHeader from "./AttendanceHeader";
import Filter from "./Filter";
import Checkin from "./checkin/Checkin";
import MainTable from "./table/MainTable";

const Attendance = () => {
  const { updateBase } = useBase();

  const permissions = usePermissions("attendance");
  // API Calls
  const { data, loading, fetchData: fetchAllAttendence } = useApiCall();
  const assignees = useSelector((state) => state?.base?.base_data?.assignees);
  const attStatus = useSelector((state) => state?.attendance?.status); // 1==="Not checked In", 2==="Checked In", 3==="Checked Out"
  const [openFilter, setOpenFilter] = useState(false);
  const [openSidebar, setOpenSidebar] = useState(false);
  const [allAttendence, setAllAttendence] = useState([]);
  const [currAttendence, setCurrAttendence] = useState({});
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState();
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  useEffect(() => {
    if (openSidebar === false) {
      setCurrAttendence({});
    }
  }, [openSidebar]);

  useEffect(() => {
    updateBase(["assignees"]);
  }, [reload]);

  useEffect(() => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    finalFilters.search = search;
    fetchAllAttendence(
      viewAllAttendence,
      {
        body_data: finalFilters,
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reload,
    search,
    pagination?.current_page,
    pagination?.per_page,
    attStatus,
  ]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllAttendence(allData);
      setPagination(rest);
    }
  }, [data]);

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const dispatch = useDispatch();

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    if (allAttendence?.length > 1) {
      setAllAttendence(
        allAttendence.filter((staff) => staff?.id !== openAlert)
      );
      setOpenAlert(false);
      const result = await deleteAttendence({ id: openAlert });
      if (result.success) {
        toast.success(result?.message);
      } else {
        toast.error(result?.message);
      }
    } else {
      setOpenAlert(false);
      const result = await deleteAttendence({ id: openAlert });
      if (result.success) {
        toast.success(result?.message);
      } else {
        toast.error(result?.message);
      }
    }
  };

  const handleEdit = (value) => {
    setCurrAttendence(value);
    setOpenSidebar(true);
  };

  const handleCheckOut = async (id) => {
    dispatch(setLoading(true));
    try {
      const result = await checkOut({ id });
      if (result.success) {
        toast.success(result.message);
        setAllAttendence(
          allAttendence.map((attendence) =>
            attendence.id === id
              ? { ...attendence, ...result.data }
              : attendence
          )
        );
      } else {
        toast.error(result.message);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Box>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Attendence"
          desc={
            <Box>
              Are you sure you want to delete this attendence? This will delete
              the Attendence permanently!{" "}
              <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <AttendenceHeader
        {...{
          permissions,
          setOpenSidebar,
          allAttendence,
          handleCheckOut,
          search,
          setSearch,
          setPagination,
          pagination,
          setOpenFilter,
          isFiltering,
          filters,
        }}
      />
      {openFilter && (
        <Filter
          openDrawer={openFilter}
          setOpenDrawer={setOpenFilter}
          isFiltering={isFiltering}
          filters={filters}
          setFilters={setFilters}
          pagination={pagination}
          setPagination={setPagination}
          setReload={setReload}
        />
      )}
      {openSidebar && (
        <Checkin
          {...{
            openDrawer: openSidebar,
            setOpenDrawer: setOpenSidebar,
            setReload,
            setAllAttendence,
            allAttendence,
            currAttendence,
            staffs: assignees,
          }}
        />
      )}
      {permissions["list(own)"] || permissions["list(global)"] ? (
        <MainTable
          allAttendence={allAttendence}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
          handleCheckOut={handleCheckOut}
          setReload={setReload}
          permissions={permissions}
        />
      ) : (
        <NotAuthorized />
      )}
    </Box>
  );
};

export default Attendance;
