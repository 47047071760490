import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import { useState } from "react";
import MainTableRow from "./MainTableRow";

const MainTable = ({
  setAllInvoices,
  allInvoices,
  handleDelete,
  setReload,
  pagination,
  setPagination,
  loading,
  handleTableCheckBox,
  selectedRows,
  permissions,
  handleRestore,
}) => {
  const [openNote, setOpenNote] = useState("");
  const rows = allInvoices;
  const columns = [
    { id: "expense_number", label: "Expense No." },
    { id: "customer_name", label: "Customer" },
    { id: "project_name", label: "Project" },
    { id: "expense_category_name", label: "Category" },
    { id: "payment_method_name", label: "Payment Method" },
    { id: "name", label: "Name" },
    { id: "reference", label: "Reference" },
    { id: "expense_date", label: "Expense Date" },
    { id: "tax", label: "Tax", align: "right" },
    { id: "discount", label: "Discount", align: "right" },
    { id: "total", label: "Total", align: "right" },
    {
      id: "action",
      label: "Actions",
      style: { width: "150px" },
      align: "center",
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 257px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 16px",
                  },
                  "& .MuiTableCell-sizeMedium:first-of-type": {
                    paddingLeft: "8px",
                    paddingRight: "0px",
                  },
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    disabled={!permissions["bulk action"]}
                    checked={
                      selectedRows?.length === allInvoices?.length &&
                      allInvoices?.length
                    }
                    onChange={(e) =>
                      handleTableCheckBox("all", e.target.checked)
                    }
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <MainTableRow
                  key={row.id}
                  row={row}
                  columns={columns}
                  span={columns?.length}
                  handleDelete={handleDelete}
                  handleRestore={handleRestore}
                  setReload={setReload}
                  setOpenNote={setOpenNote}
                  openNote={openNote}
                  handleTableCheckBox={handleTableCheckBox}
                  selectedRows={selectedRows}
                  allInvoices={allInvoices}
                  setAllInvoices={setAllInvoices}
                  permissions={permissions}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 257px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Paper>
  );
};

export default MainTable;
