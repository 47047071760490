import { Box, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import {
  createStuCom,
  getAllSemesters,
  updateStuCom,
} from "features/invoices/commissions/api/commissions";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import handleError from "utils/handleError";
import * as yup from "yup";

const AddEditCommission = ({
  profile,
  allCommission,
  setAddCommission,
  currCommission,
  setAllCommission,
  setReload,
}) => {
  const row_id = currCommission?.id;

  const isUpdating = row_id ? true : false;

  const { loading, fetchData } = useApi();

  const [semesters, setSemesters] = useState([]);

  useEffect(() => {
    const getSemesters = async () => {
      const result = await getAllSemesters();
      if (result?.data?.data) {
        setSemesters(result?.data?.data);
      }
    };
    getSemesters();
  }, []);
  const onSubmit = async (data) => {
    const result = await fetchData(
      isUpdating ? updateStuCom(row_id, {uni_reference_invoices:row_id,...data}) : createStuCom({uni_reference_invoices:row_id,...data})
    );

    if (result?.success) {
      if (isUpdating) {
        setAllCommission(
          allCommission.map((item) =>
            item.id === currCommission.id
              ? { ...currCommission, ...result?.data }
              : item
          )
        );
      } else {
        setAllCommission([result?.data, ...allCommission]);
      }
      setAddCommission(false);
      setReload((prev) => !prev);
    } else {
      handleError(result,formik);
    }
  };

  const initialValues = {
    uni_reference_invoices: profile?.id,
    semester_id: "",

    student_fees_paid_amount: "",
    // student_fees_due_amount: "",
    student_fees_paid_date: null,
    student_fees_due_date: null,
    status: "",
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let obj = {};
      for (const key in initialValues) {
        obj[key] = data[key];
      }
      return obj;
    } else return initialValues;
  };

  const formik = useFormik({
    initialValues: getInitialValues(currCommission),
    onSubmit,
    enableReinitialize: true,
    validationSchema: yup.object({
      semester_id: yup.string("").required("Semister is Required"),
      student_fees_paid_amount: yup.string("").required("Amount is Required"),
      status: yup.string("").required("Status is Required"),
    }),
  });

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddCommission(false)}
        >
          View Commission
        </Button>
      </Box>

      <Box>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              pt: "10px",
            }}
          >
            <Select
              label="Select Semesters *"
              name="semester_id"
              formik={formik}
              options={semesters}
              selectProps={{
                id: "id",
                label: "name",
              }}
            />
            <Select
              options={[
                { id: "paid", name: "Paid", color: "#008000" },
                { id: "pending", name: "Pending", color: "#FF0000" },
              ]}
              label="Status *"
              name="status"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <InputField
              label="Total Amount *"
              type="number"
              name={`student_fees_paid_amount`}
              formik={formik}
            />

            {/* <InputField
              label="Due Amount"
              type="number"
              name={`student_fees_due_amount`}
              formik={formik}
            /> */}

            <DateTimePicker
              name="student_fees_paid_date"
              label="Paid Date"
              type="date"
              formik={formik}
            />
            <DateTimePicker
              name="student_fees_due_date"
              label="Due Date"
              type="date"
              formik={formik}
            />
          </Box>
        </LocalizationProvider>
      </Box>

      <Button
        variant="solid"
        onClick={formik.submitForm}
        fullWidth
        disabled={!(formik.dirty && formik.isValid) || loading}
        sx={{ gridColumn: "span 3", mt: "20px" }}
      >
        {row_id ? "Update Commision" : "Save Commision"}
      </Button>
    </Box>
  );
};

export default AddEditCommission;
