const { useSelector } = require("react-redux");

const usePermissions = (moduleName) => {
  const { role_permissions, role } = useSelector(
    (state) => state?.auth?.user_details
  );

  const permissions = {};
  role_permissions
    ?.find((rp) => rp.module === moduleName)
    ?.permission?.map(
      (p) => (permissions[p.operation] = role === "Admin" ? 1 : p.is_permit)
    );
  permissions.isAdmin = role === "Admin" ? 1 : 0;
  return permissions;
};

export default usePermissions;
