import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import moment from "moment";

const PaymentTable = ({
  allPayment,
  loading,
  pagination,
  setPagination,
  setReload,
}) => {
  const rows = allPayment;
  const columns = [
    { id: "created_at", label: "Created Date" },
    { id: "transaction_id", label: "TrxID" },
    {
      id: "payment_method_name",
      label: "Pay Method",
      style: { width: "120px" },
    },
    { id: "invoice_number", label: "Invoice Number" },
    { id: "payment_date", label: "Payment Date", style: { width: "130px" } },
    { id: "note", label: "Note" },
    { id: "amount", label: "Amount", align: "right" },
  ];

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 200px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, ...column.style }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <TableRow hover role="checkbox" key={row.id}>
                  {columns.map((column, i) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === "number" ? (
                          column.format(value)
                        ) : column.id === "payment_date" ||
                          column.id === "created_at" ? (
                          moment(value).format("ll")
                        ) : column.id === "amount" ? (
                          <Typography variant="body2">
                            {row.symbol + value}
                          </Typography>
                        ) : (
                          value
                        )}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 200px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default PaymentTable;
