import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import moment from "moment";
import { useState } from "react";
import { BsChevronExpand } from "react-icons/bs";
import { useSelector } from "react-redux";
import TableAdmissionList from "./TableAdmissionList";

const ProjectTable = ({
  allProject,
  loading,
  pagination,
  setPagination,
  setReload,
}) => {
  const [openAdmission, setOpenAdmission] = useState(false);
  const projectCustomerStatuses = useSelector(
    (state) => state.base.base_data.projectCustomerStatuses
  );

  const rows = allProject;
  const columns = [
    { id: "project_name", label: "Project" },
    { id: "created_at", label: "FO Date", style: { width: "130px" } },
    { id: "university_name", label: "University", style: { width: "190px" } },
    {
      id: "admission_status",
      label: "Update",
      style: { width: "100px" },
      align: "center",
    },
    { id: "intake_name", label: "Intake" },
    {
      id: "project_customer_status_id",
      label: "Status",
      style: { width: "160px", align: "center" },
    },
  ];

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 200px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth, ...column.style }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <>
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : column.id === "created_at" ? (
                            moment(value).format("ll")
                          ) : column.id === "university_name" ? (
                            value ? (
                              <Box
                                sx={{
                                  position: "relative",
                                  cursor: "pointer",
                                  "&:hover": {
                                    "& svg": {
                                      color: (theme) =>
                                        theme.palette.primary.main,
                                    },
                                  },
                                  "& svg": {
                                    position: "absolute",
                                    color:
                                      openAdmission === row?.id
                                        ? (theme) => theme.palette.primary.main
                                        : "#777",
                                    top: "0px",
                                    right: "0px",
                                    cursor: "pointer",
                                    fontSize: "17px",
                                  },
                                }}
                                onClick={() => {
                                  setOpenAdmission(
                                    openAdmission === row?.id ? "" : row?.id
                                  );
                                }}
                              >
                                <Typography
                                  sx={{
                                    overflow: "hidden",
                                    WebkitLineClamp: "1",
                                    WebkitBoxOrient: "vertical",
                                    textOverflow: "ellipsis",
                                    maxWidth: "150px",
                                    maxHeight: "22px",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {value}
                                </Typography>
                                <BsChevronExpand />
                              </Box>
                            ) : (
                              "-"
                            )
                          ) : column.id === "admission_status" ? (
                            <Box
                              sx={{
                                bgcolor: `${row?.admission_status_color}15`,
                                color: row?.admission_status_color,
                                textAlign: "center",
                                padding: "3px 5px",
                                borderRadius: "20px",
                                width: "100px",
                                mt: "5px",
                                fontSize: "13px",
                              }}
                            >
                              {value}
                            </Box>
                          ) : column.id === "preferred_countries" ? (
                            value.map((cn) => cn.name).join(", ") || "-"
                          ) : column.id === "project_customer_status_id" ? (
                            <Box
                              sx={{
                                bgcolor: `${
                                  projectCustomerStatuses?.find(
                                    (pcs) =>
                                      pcs?.id ===
                                      row?.project_customer_status_id
                                  )?.color
                                }15`,
                                color: projectCustomerStatuses?.find(
                                  (pcs) =>
                                    pcs?.id === row?.project_customer_status_id
                                )?.color,
                                p: "4px",
                                borderRadius: "25px",
                                textAlign: "center",
                              }}
                            >
                              {
                                projectCustomerStatuses?.find(
                                  (pc) =>
                                    pc?.id === row?.project_customer_status_id
                                )?.name
                              }
                            </Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={columns?.length + 1}
                    >
                      <Collapse
                        in={openAdmission === row?.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 1 }}>
                          <Box
                            sx={{
                              margin: "0 auto",
                              maxWidth: "880px",
                              zIndex: "-1",
                            }}
                          >
                            <TableAdmissionList project_customer_id={row.id} />
                          </Box>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 200px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default ProjectTable;
