import { Box, Button } from "@mui/material";
import MultiSelect from "components/ui/multiSelect";
import { useFormik } from "formik";
import * as yup from "yup";
import getInitialvalues from "../utils/filterInitialValues";
import useBase from "hooks/useBase";

const Filter = ({
  setReload,
  setFilters,
  setPagination,
  pagination,
  filters,
  isFiltering,
}) => {
  const { base } = useBase();
  const { customers } = base;
  const initialValues = getInitialvalues(filters);

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    setFilters({ ...filters, ...data });
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const resetForm = () => {
    const emptyFilter = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = null;
      }
    }
    formik.setValues(emptyFilter);
    setFilters({});
    setReload((pre) => !pre);
  };

  return (
    <Box
      p="15px 20px 5px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gap: "18px",
      }}
    >
      <MultiSelect
        options={customers}
        label="Customer"
        value={formik?.values?.customer_id}
        onChange={(value) => formik.setFieldValue("customer_id", value)}
        selectProps={{
          id: "id",
          label: "name",
        }}
      />

      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          disabled={!formik.dirty}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          disabled={!isFiltering()}
          onClick={resetForm}
        >
          Reset Filter
        </Button>
      </Box>
    </Box>
  );
};

export default Filter;
