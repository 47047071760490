import { Box, Button, CircularProgress } from "@mui/material";
import { DragDropContext } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import BoardSection from "./BoardSection";

const Board = ({
  loading,
  onDragEnd,
  data,
  createTask,
  deleteSection,
  onUpdateTask,
  onDeleteTask,
  onViewTask,
  onTaskComment,
  permissions,
  createSection,
}) => {
  const user_id = useSelector((state) => state?.auth.user_details.user.id);

  return (
    <Box
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill,minmax(300px,1fr))",
        gap: "20px",
        padding: "20px",
      }}
      className="kanban"
    >
      {loading ? (
        [...Array(4)].map((i) => (
          <Box
            key={i}
            sx={{
              borderRadius: "4px",
              background: "#F4F5F7",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 12rem)",
              minWidth: "300px",
            }}
          >
            <CircularProgress />
          </Box>
        ))
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          {data.map((section) => (
            <BoardSection
              key={section?.id}
              {...{
                user_id,
                section,
                createTask,
                deleteSection,
                onUpdateTask,
                onDeleteTask,
                onViewTask,
                onTaskComment,
                permissions,
              }}
            />
          ))}
        </DragDropContext>
      )}
      <Button
        sx={{
          fontSize: "18px",
          border: "1px solid #ddd",
          height: "50px",
        }}
        onClick={createSection}
      >
        Create Section +
      </Button>
    </Box>
  );
};

export default Board;
