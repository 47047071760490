import { Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";

const JobFroms = ({ formik, i }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        sx={{
          gridColumn: "1 / -1",
          m: "25px 0 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Job Exprience {i + 1} :</Typography>
        <Close
          style={{ cursor: "pointer" }}
          onClick={() => {
            formik.setFieldValue(
              "jobs",
              [...formik.values.jobs].filter((job, index) => index !== i)
            );
          }}
        />
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(270px, 1fr))",
          gap: "15px",
        }}
      >
        <InputField
          sx={{ gridColumn: "1 / -1" }}
          name={`jobs[${i}].company_name`}
          label="Company Name *"
          formik={formik}
          isComplex={true}
        />
        <InputField
          name={`jobs[${i}].title`}
          label="Job Title *"
          formik={formik}
          isComplex={true}
        />
        <InputField
          name={`jobs[${i}].duration`}
          label="Duration"
          formik={formik}
          isComplex={true}
        />
        <DateTimePicker
          isComplex={true}
          name={`jobs[${i}].join_date`}
          label="Joining Date"
          formik={formik}
        />
        <DateTimePicker
          isComplex={true}
          name={`jobs[${i}].end_date`}
          label="End Date"
          formik={formik}
        />
        <InputField
          name={`jobs[${i}].company_address`}
          label="Company Address"
          formik={formik}
          isComplex={true}
          sx={{ gridColumn: "1 / -1" }}
          multiline
          rows={2}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default JobFroms;
