import { Box, Button, Stack, Typography } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import InputField from "components/ui/inputField";
import { FieldArray, Form, Formik } from "formik";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import * as yup from "yup";
import EmailValueskeleton from "./EmailValueskeleton";

let validationSchemaEmailchema = yup.object().shape({
  emailValue: yup.array().of(
    yup.object().shape({
      key: yup.string().max(225, "Text too long").required("Required field"),
      value: yup.string().max(225, "Text too long").required("Required field"),
    })
  ),
});
let validationSchemaEditValue = yup.object().shape({
  value: yup.string().required("Required field"),
});

let emailIntValue = { key: "", value: "" };
const AddEmailValue = ({ setOpenDrawer, openDrawer }) => {
  let [data, setData] = useState([]);
  let [edit, setEdit] = useState("");
  const { loading: listLoading, fetchData: listEmailValue } = useApi();
  const { loading: createLoading, fetchData: createEmailValue } = useApi();
  const { loading: updateLoading, fetchData: updateEmailValue } = useApi();
  const { loading: deleteLoading, fetchData: deleteEmailvalue } = useApi();

  const initialValues = { emailValue: [emailIntValue] };
  let onSubmit = async (values, action) => {
    const endpoint = {
      method: "post",
      url: "/api/admin/email/key-value/create",
      data: { objects: values.emailValue },
    };
    const result = await createEmailValue(endpoint);
    if (result.success) {
      setData((prev) => [...prev, ...result.data]);
    }
    action.resetForm();
  };
  let handleDelete = async (id) => {
    const endpoint = {
      method: "delete",
      url: `/api/admin/email/key-value/destroy/${id}`,
    };
    const result = await deleteEmailvalue(endpoint, false);
    if (result.success) {
      setData((prev) => prev.filter((data) => data.id !== id));
    }
  };

  let handleEditSubmit = async (value, action, id) => {
    const endpoint = {
      method: "put",
      url: `/api/admin/email/key-value/update/${id}`,
      data: value,
    };
    const result = await updateEmailValue(endpoint, false);
    if (result.success) {
      setData((prev) =>
        prev.map((data) =>
          data.id === id ? { ...data, value: result.data.value } : data
        )
      );
      setEdit("");
    }
  };

  useEffect(() => {
    async function listEmailValueCall() {
      const endpoint = {
        method: "post",
        url: "/api/admin/email/key-value/list",
      };
      const result = await listEmailValue(endpoint, false);
      if (result.success) {
        setData(result.data);
      }
    }
    listEmailValueCall();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "850px",
        headingLeft: "Email Value",
        headingRight: "",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchemaEmailchema}
        >
          {(formik) => (
            <Form>
              <FieldArray name="emailValue">
                {({ _, remove, push }) => (
                  <>
                    {formik.values.emailValue.length > 0 &&
                      formik.values.emailValue.map((_, index) => (
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "15px",
                            mb: "1rem",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "15px",
                            }}
                          >
                            <InputField
                              name={`emailValue.${index}.key`}
                              label="Key *"
                              fullWidth
                              formik={formik}
                            />

                            <InputField
                              name={`emailValue.${index}.value`}
                              label="Value *"
                              fullWidth
                              formik={formik}
                            />
                          </Box>

                          <div>
                            {formik.values.emailValue.length > 1 && (
                              <Button
                                sx={{
                                  marginLeft: "auto",
                                  display: "block",
                                }}
                                type="button"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </Button>
                            )}
                          </div>
                        </Box>
                      ))}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "between",
                      }}
                    >
                      {formik.values.emailValue.length < 10 && (
                        <Button
                          type="button"
                          onClick={() => push(emailIntValue)}
                        >
                          Add More
                        </Button>
                      )}
                      <Button
                        sx={{
                          marginLeft: "auto",
                          display: "block",
                        }}
                        disabled={
                          !(formik.dirty && formik.isValid) || createLoading
                        }
                        variant="contained"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </>
                )}
              </FieldArray>
            </Form>
          )}
        </Formik>
      </Box>

      <Box sx={{ padding: "20px" }}>
        <EmailValueskeleton loading={listLoading} />
        {data?.length > 0 &&
          data?.map((data, i) => (
            <Box sx={{ mb: "1.5rem" }} key={data.id}>
              <Stack
                spacing={1}
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems={"center"}
                  sx={{ minWidth: 0 }}
                >
                  <Typography variant="h3" className="clamped-text">
                    {data.key}:{" "}
                  </Typography>
                  <Typography variant="body1" className="clamped-text">
                    {data.value}
                  </Typography>
                </Stack>
                <Stack
                  spacing={1}
                  direction={"row"}
                  alignItems={"center"}
                  flexShrink={0}
                >
                  <Button
                    sx={{
                      marginLeft: "auto",
                      display: "block",
                    }}
                    type="button"
                    disabled={deleteLoading}
                    onClick={() => handleDelete(data.id)}
                  >
                    Delete
                  </Button>
                  <Button
                    sx={{
                      marginLeft: "auto",
                      display: "block",
                    }}
                    type="button"
                    onClick={() => setEdit(data.id)}
                  >
                    Edit Value
                  </Button>
                </Stack>
              </Stack>
              {edit === data.id && (
                <Formik
                  initialValues={{ value: data.value }}
                  onSubmit={(value, action) =>
                    handleEditSubmit(value, action, data.id)
                  }
                  validationSchema={validationSchemaEditValue}
                >
                  {(formikNested) => (
                    <Form>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "15px",
                          mt: "1rem",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "15px",
                          }}
                        >
                          <InputField
                            name={`value`}
                            label="Value *"
                            fullWidth
                            formik={formikNested}
                          />
                          <Button
                            sx={{
                              marginLeft: "auto",
                              display: "block",
                            }}
                            disabled={
                              !(formikNested.dirty && formikNested.isValid) ||
                              updateLoading
                            }
                            variant="contained"
                            type="submit"
                          >
                            Update
                          </Button>
                        </Box>
                      </Box>
                    </Form>
                  )}
                </Formik>
              )}
            </Box>
          ))}
      </Box>
    </RightDrawer>
  );
};

export default AddEmailValue;
