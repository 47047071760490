import Axios from "lib/Axios";
import { saveBase } from "lib/Reducer/baseSlice";
import { useDispatch, useSelector } from "react-redux";

const useBase = () => {
  const dispatch = useDispatch();
  const base = useSelector((state) => state.base.base_data) || {};
  const updateBase = async (filter) => {
    try {
      const res = await Axios.post("/api/admin/base/all", {
        filter,
      });
      if (res?.data?.success) {
        dispatch(
          saveBase({ data: { ...base, ...res?.data?.data }, updated: false })
        );
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return { base, updateBase };
};

export default useBase;

const baseRouteKeys = {
  roles: "role",
  roleWisePermissions: "role-wise-permission",
  departments: "department",
  designations: "designation",
  courses: "course",
  universities: "university",
  leadStatuses: "lead-status",
  sources: "source",
  assignees: "assignee",
  tags: "tag",
  customFields: "custom-fields",
  timezones: "timezones",
  relatedTos: "related-to",
  relatedToAttachments: "related-to-attachment",
  relatedToNotes: "related-to-note",
  relatedToTasks: "related-to-task",
  relatedModuleDatas: "related-module-data",
  countries: "country",
  courseFields: "course-fields",
  institutes: "institute",
  subjects: "subject",
  facultyWiseSubjects: "faculty-wise-subject",
  leadColumns: "lead-column",
  projectTotalSizes: "project-total-size",
  projectStatuses: "project-status",
  projectCustomerStatuses: "project-customer-status",
  bankInvoiceStatuses: "bank-invoice-status",
  projects: "project",
  customers: "customer",
  intakes: "intake",
  fees: "fee",
  currencies: "currency",
  paymentMethods: "payment-method",
  expenseCategories: "expense-category",
  customerBillingShippingAddresses: "customer-billing-shipping-address",
  todayAttendanceStatuses: "today-attendance-status",
  admissionStatuses: "admission-status",
  projectCustomerSponsorJobs: "project-customer-sponsor-job",
  projectCustomerSponsorJobFields: "project-customer-sponsor-job-field",
  embassyFields: "embassy-field",
  knowledgeBaseCategories: "knowledge-base-category",
  invoices: "invoice",
  unpaidInvoices: "invoice-unpaid",
  references: "reference",
  smsTemplates: "sms-template",
  smsBalances: "sms-balance",
  emailTemplateLists: "email-template",
  emailModuleSettings: "email-module-settings",
  permission_module: "permission-module",
  branches: "branch",
  banks: "bank",
  faculties: "faculty",
  bankInvoiceBanks: "bank-invoice-bank",
  taskStatuses: "task-status",
  settings: "settings",
};
