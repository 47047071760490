import {
  Box,
  Checkbox,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PopoverAG from "components/ui/popoverAG";
import moment from "moment";
import React from "react";
import { MdDelete, MdRestore } from "react-icons/md";
import { updateKnowledgeStatus } from "../../api/knowledge";

function removeTagsAndWhitespace(html) {
  var cleanr = /<\/?[^>]+>|\s+/gi;
  var plainText = html.replace(cleanr, " ");
  return plainText.trim();
}

export const postStatus = [
  {
    id: 1,
    label: "Active",
    color: "#0db30d",
  },
  {
    id: 0,
    label: "Inactive",
    color: "#ff564a",
  },
];

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  handleEdit,
  handleView,
  setAllPost,
  allPost,
  handleTableCheckBox,
  selectedRows,
  permissions,
  handleRestore,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const openPO = Boolean(anchorEl);
  const handleChangeStatus = async (value) => {
    await updateKnowledgeStatus({
      body_data: { id: row?.id, status: value },
    });
    setAllPost(
      allPost.map((post) =>
        post.id === row.id ? { ...row, status: value } : post
      )
    );
  };
  return (
    <TableRow
      hover
      role="checkbox"
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 16px", // <-- arbitrary value
        },
      }}
      key={row.id}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRows?.includes(row.id)}
          onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
          // disabled={!permissions["bulk action"]}
        />
      </TableCell>
      {columns.map((column, i) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.id === "description" ? (
              <Typography
                className={"clamped-text"}
                variant="body2"
                sx={{ width: "150px" }}
                dangerouslySetInnerHTML={{
                  __html: removeTagsAndWhitespace(value),
                }}
              ></Typography>
            ) : column.id === "status" ? (
              <TextField
                size="small"
                value={value}
                select
                sx={{
                  width: "100px",
                  color: postStatus?.find(
                    (singleStatus) => singleStatus?.id === value
                  )?.color,
                  textAlign: "left",
                  "& > div:before": { content: "none" },
                  "& div": {
                    color: postStatus?.find(
                      (singleStatus) => singleStatus?.id === value
                    )?.color,
                  },
                }}
                variant="standard"
                onChange={(e) => handleChangeStatus(e.target.value)}
              >
                {postStatus?.length > 0 &&
                  postStatus?.map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {status?.label}
                    </MenuItem>
                  ))}
              </TextField>
            ) : column.id === "created_at" ? (
              <span>{value ? moment(value).format("ll") : "-"}</span>
            ) : column.id === "created_by_image" ? (
              <PopoverAG items={value} />
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Resotre">
                  <IconButton onClick={() => handleRestore(row.id)}>
                    <MdRestore />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete permanently">
                  <IconButton onClick={() => handleDelete(row.id)}>
                    <MdDelete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
