import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, IconButton, Typography } from "@mui/material";
import PopoverAG from "components/ui/popoverAG";
import { memo } from "react";
import { taskpriorities } from "../table/MainTableRow";

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}
const BoardItem = ({
  provided,
  user_id,
  item,
  section,
  onUpdateTask,
  onDeleteTask,
  onViewTask,
  onTaskComment,
  permissions,
  snapshot,
  style,
}) => {
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        userSelect: "none",
        backgroundColor: "#fff",
        borderRadius: "6px",
        padding: "10px",
        margin: "0 0 12px 0",
        boxShadow: "0px 1px 1px 0 #dddddd",
        border: snapshot?.isDragging
          ? `1px solid ${section.color}`
          : "1px solid transparent",
        cursor: "default",
        ...getStyle(provided, style),
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "self-start",
          justifyContent: "space-between",
          gap: "5px",
        }}
      >
        <Typography
          variant="h6"
          style={{
            display: "flex",
            minWidth: 0,
            alignItems: "center",
          }}
        >
          <span className="truncate">{item?.name}</span>
          <span
            style={{
              color: taskpriorities?.find(
                (priority) => priority?.label === item?.task_priority
              )?.color,
              backgroundColor:
                taskpriorities?.find(
                  (priority) => priority?.label === item?.task_priority
                )?.color + "15",
              textAlign: "center",
              padding: "0 10px",
              display: "inline-block",
              borderRadius: "12px",
              fontSize: "10px",
              fontWeight: "normal",
              marginLeft: "5px",
            }}
          >
            {item?.task_priority}
          </span>
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton size="small" onClick={() => onViewTask(item)}>
            <VisibilityIcon fontSize="12px" />
          </IconButton>
          <IconButton
            disabled={
              (item.created_by !== user_id && !permissions.isAdmin) ||
              !permissions.update
            }
            size="small"
            onClick={() => onUpdateTask(item, section.id)}
          >
            <ModeEditIcon fontSize="12px" />
          </IconButton>
          <IconButton
            size="small"
            disabled={!permissions.delete}
            onClick={() => onDeleteTask(item, section.id)}
          >
            <DeleteOutlineIcon fontSize="12px" />
          </IconButton>
        </Box>
      </Box>

      <Typography variant="body2" className="truncate" mt="5px">
        {item.description}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <PopoverAG items={item?.assignees} size={18} />
        <button
          style={{
            all: "unset",
            fontSize: "10px",
            textDecoration: "underline",
            marginTop: "10px",
            cursor: "pointer",
          }}
          onClick={() => onTaskComment(item)}
        >
          Comments
        </button>
      </Box>
    </div>
  );
};

export default memo(BoardItem);
