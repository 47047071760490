import { Add } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";

const Header = ({
  setOpenSMSvalue,
  setOpenTemplate,
  setSearch,
  setPagination,
  pagination,
}) => {
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useEffect(() => {
    setSearch(debouncedText || "");
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in table.."}
        />
      </Grid>

      <Box sx={{ display: "flex", gap: "10px", justifyContent: "end" }}>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => setOpenSMSvalue(true)}
        >
          SMS Value
        </Button>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => setOpenTemplate(true)}
        >
          Add SMS Template
        </Button>
      </Box>
    </Grid>
  );
};

export default Header;
