import {
  Box,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import { viewAdmissions } from "features/projectCustomers/api/projectCustomer";
import useApiCall from "hooks/useApiCall";
import moment from "moment";
import { useEffect, useState } from "react";

const TableAdmissionList = ({ id, open, handleClose }) => {
  const [rows, setRows] = useState([]);

  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    fetchListData(
      viewAdmissions,
      {
        id,
      },
      false
    );
  }, []);

  useEffect(() => {
    if (data) {
      setRows(data);
    }
  }, [data]);

  const columns = [
    { id: "country_name", label: "Country", width: 100 },
    { id: "university_name", label: "University", width: 100 },
    { id: "subject_name", label: "Subject" },
    { id: "student_id", label: "Student Id" },
    { id: "start_class", label: "Start Class", width: 120 },
    { id: "education_level", label: "Edu Level", width: 100 },
    { id: "status_date", label: "Date" },
    { id: "text", label: "Note" },
    { id: "status", label: "Status" },
  ];

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      <Box>
        <Box
          sx={{
            width: "900px",
            margin: "10px auto",
            height: "450px",
            padding: "10px",
          }}
        >
          {!loading ? (
            <TableContainer sx={{ height: "450px", overflowX: "auto" }}>
              <Table size="small" stickyHeader>
                <TableBody>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        sx={{ fontWeight: "500" }}
                        key={column.id}
                        align={column.align}
                        style={{ width: column.width }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                  {rows?.map((row) => {
                    return (
                      <TableRow hover role="checkbox" key={row.id}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "status" ? (
                                <Box
                                  sx={{
                                    bgcolor: `${row?.color}15`,
                                    color: row.color,
                                    textAlign: "center",
                                    padding: "3px 5px",
                                    borderRadius: "20px",
                                    width: "120px",
                                    mt: "5px",
                                    fontSize: "13px",
                                  }}
                                >
                                  {value}
                                </Box>
                              ) : column.id === "start_class" ||
                                column.id === "status_date" ? (
                                value ? (
                                  moment(value).format("ll")
                                ) : (
                                  "-"
                                )
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <TableSkeleton columns={columns} tableHeight="450px" />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default TableAdmissionList;
