import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab, Typography } from "@mui/material";
import { useState } from "react";
import AttDetails from "./AttDetails";
import AttFolders from "./AttFolders";
import AttachmentsGrid from "./AttachmentsGrid";
import FileTypes from "./FileTypes";
import OnlyFilesScreen from "./OnlyFilesScreen";

const Attachments = () => {
  const [onlyFilesScreen, setOnlyFilesScreen] = useState(false);
  const [attDetails, setAttDetails] = useState(null);
  const [tabValue, setTabValue] = useState("1");
  const [filters, setFilters] = useState("1");

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: attDetails ? "auto 400px" : "auto",
        gap: "5px",
      }}
    >
      <Box
        className="att-scroller"
        sx={{
          padding: "20px",
          height: "calc(100vh - 65px)",
          overflowY: "auto",
        }}
      >
        {!onlyFilesScreen ? (
          <Box>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={(e, newValue) => setTabValue(newValue)}
                  variant="scrollable"
                  allowScrollButtonsMobile
                >
                  <Tab label="Folders" value="1" />
                  <Tab label="File Types" value="2" />
                </TabList>
              </Box>
              <Box
                sx={{
                  paddingTop: "30px",
                }}
              >
                <TabPanel value="1" sx={{ p: 0 }}>
                  <AttFolders {...{ setOnlyFilesScreen, setFilters }} />
                </TabPanel>
                <TabPanel value="2" sx={{ p: 0 }}>
                  <FileTypes {...{ setOnlyFilesScreen, setFilters }} />
                </TabPanel>
              </Box>
            </TabContext>
            <Box sx={{ pt: "45px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{ fontWeight: "500", fontSize: "18px", pb: "15px" }}
                >
                  Recent Files
                </Typography>
                <Typography
                  onClick={() => {
                    setFilters({});
                    setOnlyFilesScreen("All");
                  }}
                  sx={{
                    pb: "15px",
                    color: "#405189",
                    cursor: "pointer",
                    fontWeight: 500,
                  }}
                >
                  View All
                </Typography>
              </Box>
              <AttachmentsGrid
                {...{ type: "recents", attDetails, setAttDetails }}
              />
            </Box>
          </Box>
        ) : (
          <OnlyFilesScreen
            {...{
              onlyFilesScreen,
              setOnlyFilesScreen,
              attDetails,
              setAttDetails,
              filters,
              setFilters,
            }}
          />
        )}
      </Box>
      {attDetails && (
        <AttDetails {...{ attDetails, setAttDetails, filters, setFilters }} />
      )}
    </Box>
  );
};

export default Attachments;
