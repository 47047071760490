import {
  Timeline,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const ActivitySkeleton = () => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        margin: "0px",
        padding: "0px 10px",
      }}
    >
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot
            variant="outlined"
            sx={{ borderColor: "#efefef", border: "none", p: "0" }}
          >
            <Skeleton variant="circular" width={50} height={50} />
          </TimelineDot>
        </TimelineSeparator>
        <TimelineContent sx={{ py: "5px", px: "10px", mb: "10px " }}>
          <Box
            sx={{
              border: "1px solid #f7f7f7",
              borderRadius: "5px",
              padding: "10px",
            }}
          >
            <Skeleton
              variant="text"
              sx={{ fontSize: ".8rem", maxWidth: "250px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: ".6rem", maxWidth: "150px" }}
            />
            <Skeleton
              variant="text"
              sx={{ fontSize: ".8rem", maxWidth: "400px", mt: "10px" }}
            />
          </Box>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
};

export default ActivitySkeleton;
