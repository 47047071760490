import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

const Summary = ({ filters, search }) => {
  const { loading, fetchData } = useApi();
  const [projectCustomerSummery, setProjectCustomerSummery] = useState(null);

  useEffect(() => {
    const getCustomerSummary = async () => {
      const endpoint = {
        method: "post",
        url: "/api/admin/project-customer/summery",
        data: { ...filters, search },
      };
      const result = await fetchData(endpoint, false);
      setProjectCustomerSummery(result?.data || []);
    };
    getCustomerSummary();
  }, [search, filters]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 145px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "20px",
              padding: "20px 20px",
            }}
          >
            {projectCustomerSummery?.length > 0 &&
              projectCustomerSummery.map((singleSummery) => (
                <Box
                  key={singleSummery?.project_customer_status_id}
                  sx={{
                    bgcolor: `${singleSummery?.color}27`,
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "2px 1px 3px #e5e5e5",
                  }}
                >
                  <Typography>{singleSummery?.status}</Typography>
                  <Typography variant="h3">
                    {singleSummery?.total_project_customer}
                  </Typography>
                </Box>
              ))}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Summary;
