import { Add, Close } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import useBase from "hooks/useBase";
import { useEffect, useState } from "react";
import { education_fields, exam_boards, exam_groups } from "utils/constants";

const allEduOptions = [
  "SSC",
  "HSC",
  "Bachelor",
  "Diploma",
  "Master",
  "O Level",
  "A Level",
];

const EduFields = ({ formik, subjects, institutes, universities }) => {
  const {
    base: { facultyWiseSubjects },
  } = useBase();
  const key = "educations";
  const values = formik.values.educations;
  const [isAddEduActive, setIsAddEduActive] = useState(false);
  const [eduOptions, setEduOptions] = useState(allEduOptions);

  useEffect(() => {
    const existingEdus = values?.map((singleEdu) => singleEdu.name);
    setEduOptions(allEduOptions.filter((eo) => !existingEdus.includes(eo)));
  }, [values?.length]);

  const handleEduTypeSelect = (value) => {
    if (value) {
      setIsAddEduActive(false);
      const currEdu = education_fields?.find((field) => field.name === value);
      formik.setFieldValue("educations", [
        currEdu,
        ...formik?.values?.educations,
      ]);
    } else {
      setIsAddEduActive(false);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          gridColumn: "span 2",
          borderBottom: "1px solid #ddd",
          pb: "10px",
          m: "35px 0 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h5" color={(theme) => theme.palette.primary.main}>
          Educational Info
        </Typography>

        {/* Add course button */}
        <Button
          disabled={isAddEduActive}
          startIcon={<Add />}
          onClick={() => setIsAddEduActive(true)}
        >
          {formik.values?.educations?.length
            ? "Add another education"
            : "Add education"}
        </Button>
      </Box>
      {isAddEduActive && (
        <Select
          label="Select Education Type"
          options={eduOptions}
          onChange={(value) => handleEduTypeSelect(value)}
        />
      )}
      {/* {selectedEdu && getCurrFields()} */}
      {values &&
        values.map((singleEdu, i) => (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
              gap: "15px",
              margin: "10px 0 20px",
            }}
          >
            <Box
              sx={{
                gridColumn: "1 / -1",
                m: "10px 0 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h6">{singleEdu.name} :</Typography>
              <Close
                style={{ cursor: "pointer" }}
                onClick={() => {
                  formik.setFieldValue(
                    key,
                    [...formik.values[key]].filter((edu, index) => index !== i)
                  );
                }}
              />
            </Box>

            {singleEdu.hasOwnProperty("group") && (
              <Select
                options={exam_groups}
                value={formik.values.educations[i].group}
                onChange={(newValue) =>
                  formik.setFieldValue(`educations[${i}].group`, newValue)
                }
                label="Group"
              />
            )}
            {singleEdu.hasOwnProperty("board") && (
              <Select
                options={exam_boards}
                value={formik.values.educations[i].board}
                onChange={(newValue) =>
                  formik.setFieldValue(`educations[${i}].board`, newValue)
                }
                label="Board"
              />
            )}
            {singleEdu.hasOwnProperty("subject_id") && (
              <Select
                isGroup
                options={facultyWiseSubjects}
                value={formik.values.educations[i].subject_id}
                onChange={(newValue) =>
                  formik.setFieldValue(`educations[${i}].subject_id`, newValue)
                }
                label="Subject"
                selectProps={{ id: "id", label: "name" }}
              />
            )}
            {singleEdu.hasOwnProperty("institute_id") && (
              <Select
                options={institutes}
                value={formik.values.educations[i].institute_id}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    `educations[${i}].institute_id`,
                    newValue
                  )
                }
                label="Institute"
                selectProps={{ id: "id", label: "name" }}
              />
            )}
            {singleEdu.hasOwnProperty("university_id") && (
              <Select
                disabled={!formik.values.country_id}
                options={
                  formik.values.country_id
                    ? universities?.filter(
                        (university) =>
                          university?.country_id === formik.values.country_id
                      )
                    : universities
                }
                value={formik.values.educations[i].university_id}
                onChange={(newValue) =>
                  formik.setFieldValue(
                    `educations[${i}].university_id`,
                    newValue
                  )
                }
                label="University"
                selectProps={{ id: "id", label: "name" }}
              />
            )}
            {singleEdu.hasOwnProperty("gpa") && (
              <InputField
                name={`educations[${i}].gpa`}
                label="GPA"
                formik={formik}
              />
            )}
            {singleEdu.hasOwnProperty("grade") && (
              <InputField
                name={`educations[${i}].grade`}
                label="Grade"
                formik={formik}
              />
            )}
            {singleEdu.hasOwnProperty("passing_year") && (
              <DateTimePicker
                isComplex={true}
                views={["year"]}
                name={`educations[${i}].passing_year`}
                label="Examination year"
                formik={formik}
              />
            )}
          </Box>
        ))}
    </Box>
  );
};

export default EduFields;
