import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import useApi from "hooks/useApi";
import usePermissions from "hooks/usePermissions";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteKnowledge, restoreKnowledge } from "../api/knowledge";
import BulkActions from "./BulkActions";
import Header from "./Header";
import MainScreenFilter from "./filter/MainScreenFilter";
import MainTable from "./table/MainTable";

const KnowledgeBaseCategory = () => {
  let dispatch = useDispatch();
  const [openSidebar, setOpenSidebar] = useState(false);
  const { base_data } = useSelector((state) => state.base);
  const [openProfile, setOpenProfile] = useState(false);

  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState();
  const [currPost, setCurrPost] = useState({});
  const [allPost, setAllPost] = useState([]);
  const [reload, setReload] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  useEffect(() => {
    if (openSidebar === false) {
      setCurrPost({});
    }
  }, [openSidebar]);

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getPost = async () => {
      let finalFilters = { ...filters };
      let finalPagination = { ...pagination };
      finalFilters.search = search;
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/knowledge/trash/list?${params}`,
        data: finalFilters,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllPost(allData || []);
        setPagination(rest);
      }
    };
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, search]);

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    setSelectedRows([]);
    const result = await fetchData(deleteKnowledge(openAlert));
    if (result.success) {
      if (allPost?.length > 1) {
        setAllPost(allPost.filter((post) => post?.id !== openAlert));
      }
    } else {
      setReload((pre) => !pre);
    }
  };

  const handleEdit = (value) => {
    setCurrPost(value);
    setOpenSidebar(true);
  };
  const handleView = async (value) => {
    setOpenProfile(true);
    setCurrPost(value);
  };
  const permissions = usePermissions("knowledge");
  const all_props = {
    ...base_data,
    setReload,
    filters,
    setFilters,
    isFiltering,
    pagination,
    setPagination,
    allPost,
    setAllPost,
    permissions,
  };

  // Bulk options

  const handleTableCheckBox = (id, checked) => {
    if (id === "all") {
      if (checked) {
        setSelectedRows(allPost?.map((post) => post?.id));
      } else {
        setSelectedRows([]);
      }
    } else {
      if (checked) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows(selectedRows?.filter((post) => post !== id));
      }
    }
  };
  const handleRestore = async (id) => {
    dispatch(setLoading(true));
    try {
      const result = await restoreKnowledge({ id });
      if (result.success) {
        toast.success(result?.message);
      } else {
        toast.error(result?.message);
      }
      setReload((pre) => !pre);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };
  return (
    <Box>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Post"
          desc={
            <Box>
              Are you sure you want to delete this Post? This will delete the
              Post permanently! <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Header
        setOpenSidebar={setOpenSidebar}
        setSearch={setSearch}
        setPagination={setPagination}
        pagination={pagination}
        allPost={allPost}
      />

      {selectedRows?.length > 0 && (
        <BulkActions
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          {...all_props}
        />
      )}

      {!selectedRows?.length > 0 && <MainScreenFilter {...all_props} />}

      <MainTable
        allPost={allPost}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        handleView={handleView}
        setReload={setReload}
        handleTableCheckBox={handleTableCheckBox}
        selectedRows={selectedRows}
        handleRestore={handleRestore}
        {...all_props}
      />
    </Box>
  );
};

export default KnowledgeBaseCategory;
