import { Delete, Download, UploadFile } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  IconButton,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AttachmentsSkeleton from "components/skeletons/AttachmentsSkeleton";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { FaEye } from "react-icons/fa";
import { FcFile } from "react-icons/fc";
import Swal from "sweetalert2";
import ViewAttachment from "./ViewAttachment";
import downloader from "utils/downloader";

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const Attachment = ({ id, route }) => {
  const [progress, setProgress] = useState(0);
  const [viewAtt, setViewAtt] = useState(false);
  const [currFile, setCurrFile] = useState(null);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  const [currFileName, setCurrFileName] = useState("");
  const [description, setDescription] = useState("");
  const [uploading, setUploading] = useState(false);
  const [selectedAttachments, setSelectedAttachments] = useState(new Set());

  // Fetch Attachments
  const [attachments, setAttachments] = useState([]);
  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getAttachments = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/${route}/attachment/${id}?limit=500`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const data = result?.data?.data;
        setAttachments(data || []);
      }
    };
    getAttachments();
  }, []);

  const handleUploadAttachment = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append("description", description);
    formData.append("file", currFile);

    try {
      setCurrFile(null);
      setDescription("");
      const endpoint = {
        method: "post",
        url: `/api/admin/${route}/attachment/upload/${id}`,
        data: formData,
        updateProgress: (value) => setProgress(value),
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        setAttachments([result.data, ...attachments]);
      }
    } finally {
      setUploading(false);
    }
  };

  const handleFileView = (file) => {
    if (file?.mime_type.includes("image")) {
      setCurrFile(file);
      setViewAtt(true);
    } else {
      window.open(UNIVERSAL.BASEURL + file?.file, { replace: true });
    }
  };

  const handleDeleteAtt = async (attId) => {
    Swal.fire({
      title: "<strong>Are you sure?</strong>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        const deleteAtt = async () => {
          const endpoint = {
            method: "delete",
            url: `/api/admin/attachment/delete/${attId}`,
          };
          const result = await fetchData(endpoint, false);
          if (result.success) {
            setAttachments(attachments.filter((att) => att.id !== attId));
            setSelectedAttachments(new Set());
          }
        };
        deleteAtt();
      }
    });
  };

  useEffect(() => {
    if (acceptedFiles?.length) {
      setCurrFile(acceptedFiles[0]);
      setCurrFileName(acceptedFiles[0]?.name);
    }
  }, [acceptedFiles]);

  const handleSelectAll = useCallback(
    (event) => {
      if (event.target.checked) {
        setSelectedAttachments(new Set(attachments?.map((att) => att?.id)));
      } else {
        setSelectedAttachments(new Set());
      }
    },
    [attachments]
  );

  const handleSelectAttachment = useCallback((id) => {
    setSelectedAttachments((prev) => {
      const newSet = new Set(prev);
      if (newSet?.has(id)) {
        newSet?.delete(id);
      } else {
        newSet?.add(id);
      }
      return newSet;
    });
  }, []);

  const handleDownloadSelected = useCallback(() => {
    attachments
      .filter((att) => selectedAttachments?.has(att?.id))
      .forEach((file) => {
        downloader({
          fileUrl: `${UNIVERSAL.BASEURL}${file?.file}`,
          fileName: file?.name,
        });
      });
  }, [attachments, selectedAttachments]);

  const handleDownloadSingle = useCallback((attachment) => {
    downloader({
      fileUrl: `${UNIVERSAL.BASEURL}${attachment?.file}`,
      fileName: attachment?.name,
    });
  }, []);

  return (
    <Box>
      <Box
        sx={{
          border: "2px dashed #ddd",
          borderRadius: "5px",
          height: "100px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          mb: "15px",
        }}
        {...getRootProps({ className: "dropzone" })}
      >
        <input {...getInputProps()} />
        <p>Drag to attach file, or click to select</p>
      </Box>
      <TextField
        fullWidth
        label="Attachment Description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        size="small"
        multiline
        rows={2}
        disabled={!currFile}
        sx={{ mb: "13px" }}
        InputLabelProps={{ shrink: true }}
      />
      <Box
        sx={{
          gap: "15px",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        {currFile && (
          <Chip
            label={currFile?.name?.slice(0, 15)}
            component="p"
            variant="outlined"
            clickable
            deleteIcon={<Delete />}
            onDelete={() => setCurrFile(null)}
          />
        )}
        <Button
          disabled={!currFile}
          onClick={handleUploadAttachment}
          startIcon={<UploadFile />}
          variant="solid"
        >
          Upload Attachment
        </Button>
      </Box>
      <Stack
        sx={{ height: "calc(100vh - 400px)", overflowY: "auto", mt: "10px" }}
      >
        {!!selectedAttachments.size && (
          <Box
            p="10px 20px 10px"
            sx={{
              borderTop: "1px solid #efefef",
              bgcolor: "#fff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "18px",
              backgroundColor: "#e3eefa",
            }}
          >
            <Typography variant="h5">
              {selectedAttachments.size} Selected
            </Typography>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}
            >
              <Button
                variant="solid"
                startIcon={<Download />}
                onClick={handleDownloadSelected}
                disabled={selectedAttachments.size === 0}
              >
                Download
              </Button>
            </Box>
          </Box>
        )}

        {!!attachments.length && (
          <Box sx={{ padding: "10px" }}>
            <Checkbox
              checked={selectedAttachments.size === attachments.length}
              onChange={handleSelectAll}
            />{" "}
            {selectedAttachments.size === attachments.length
              ? "Deselect All"
              : "Select All"}
          </Box>
        )}

        {uploading && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "50px auto 60px",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              padding: "15px 10px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FcFile style={{ fontSize: "40px" }} />
            </Box>
            <Box>
              <Typography variant="h6">{currFileName}</Typography>
              <LinearProgressWithLabel value={progress} />
            </Box>
          </Box>
        )}

        {loading && !uploading ? (
          <AttachmentsSkeleton />
        ) : (
          attachments?.length > 0 &&
          attachments?.map((attachment, key) => (
            <Box
              key={key}
              sx={{
                display: "grid",
                gridTemplateColumns: "40px 50px auto 100px",
                alignItems: "center",
                borderBottom: "1px solid #ddd",
                padding: "15px 10px",
              }}
            >
              <Checkbox
                checked={selectedAttachments.has(attachment.id)}
                onChange={() => handleSelectAttachment(attachment.id)}
              />

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FcFile style={{ fontSize: "40px" }} />
              </Box>

              <Box>
                <Box
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  <Typography variant="h6">{attachment?.name}</Typography>
                  {attachment?.description && (
                    <Typography>({attachment?.description})</Typography>
                  )}
                </Box>
                <Typography sx={{ fontSize: "12px" }}>
                  {(attachment?.file_size / 1024).toFixed(0)}KB
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                {attachment?.mime_type?.includes("image") && (
                  <IconButton onClick={() => handleFileView(attachment)}>
                    <FaEye />
                  </IconButton>
                )}

                <IconButton onClick={() => handleDownloadSingle(attachment)}>
                  <Download />
                </IconButton>
                <IconButton onClick={() => handleDeleteAtt(attachment?.id)}>
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ))
        )}
      </Stack>
      {viewAtt && <ViewAttachment setViewAtt={setViewAtt} file={currFile} />}
    </Box>
  );
};

export default Attachment;
