import { Edit } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddEmail from "./AddEmail";
import EmailTable from "./EmailTable";
import ViewEmail from "./ViewEmail";

const Email = ({ email_addresses, related_to, related_id }) => {
  const user_id = useSelector((state) => state?.auth?.user_details?.user?.id);
  const [addEmail, setAddEmail] = useState(false);
  const [viewEmail, setViewEmail] = useState({ data: null, open: false });
  const [allEmail, setAllEmail] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  const debouncedText = useDebounce(searchText, 1000);

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getEmails = async () => {
      const params = `page=${pagination?.current_page}&limit=${pagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/email/list?${params}`,
        data: {
          user_id,
          related_to,
          related_id,
          subject: debouncedText,
        },
      };
      const result = await fetchData(endpoint, false);
      if (result?.success) {
        const { data: allData, ...rest } = result.data;
        setAllEmail(allData || []);
        setPagination(rest);
      }
    };
    getEmails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, debouncedText]);

  return (
    <Box sx={{ m: "-20px" }}>
      {addEmail ? (
        <AddEmail
          {...{
            email_addresses,
            setAddEmail,
            related_to,
            setReload,
            related_id,
          }}
        />
      ) : viewEmail?.open ? (
        <ViewEmail {...{ setViewEmail, viewEmail }} />
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "15px",
            }}
          >
            <SearchBox
              handleSearch={(text) => setSearchText(text)}
              placeholder={"Search in table ..."}
            />
            <Button
              startIcon={<Edit />}
              variant="outlined"
              onClick={() => setAddEmail(true)}
            >
              Compose
            </Button>
          </Box>
          <EmailTable
            loading={loading}
            allEmail={allEmail}
            pagination={pagination}
            setPagination={setPagination}
            setReload={setReload}
            setAllEmail={setAllEmail}
            setViewEmail={setViewEmail}
          />
        </Box>
      )}
    </Box>
  );
};

export default Email;
