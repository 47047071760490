import { Delete, UploadFile } from "@mui/icons-material";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import SuccessAnimation from "components/ui/successAnimation";
import { importLeads, importSample } from "features/leads/api/leads";
import { useFormik } from "formik";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useState } from "react";
import { FcFile } from "react-icons/fc";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import call from "utils/callApi";
import * as yup from "yup";

const Import = ({
  openDrawer,
  setOpenDrawer,
  leadStatuses,
  sources,
  assignees,
}) => {
  const [selectedFile, setSlectedFile] = useState(null);
  const [totalImported, setTotalImported] = useState(null);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const handleDownloadSample = async () => {
    const res = await call(importSample);
    if (res) window.open(res?.file);
  };
  const handleUploadAttachment = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setSlectedFile(file);
      setError("");
    }
  };
  const initialValues = {
    status: "",
    source: "",
    assignee: [],
  };

  const validationSchema = yup.object({
    status: yup.string().required(),
    source: yup.string().required(),
    assignee: yup.array().required().min(1),
  });

  const onSubmit = async (data) => {
    dispatch(setLoading(true));
    const formData = new FormData();

    formData.append("status", data.status);
    formData.append("source", data.source);
    data.assignee.map((singleAssignee) =>
      formData.append("assignee[]", Number(singleAssignee))
    );
    formData.append("file", selectedFile);

    try {
      const res = await importLeads({
        body_data: formData,
      });
      if (res.success) {
        setTotalImported(res.data.total_upload);
      } else {
        toast.error(res.message);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const formatFileName = (text) => {
    if (text.length > 20) {
      const textArr = text.split(".");
      const modifiedText =
        textArr[0].substring(0, 20) + "..." + textArr[textArr.length - 1];
      return modifiedText;
    } else return text;
  };

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "400px",
        headingLeft: "Import Lead",
        headingRight: "",
      }}
    >
      {totalImported === null ? (
        <Stack gap="20px" p="20px">
          <Stack
            direction="row"
            justifyContent="flex-end"
            borderBottom="1px solid #ddd"
            pb="15px"
          >
            <Button variant="outlined" onClick={handleDownloadSample}>
              Download sample file
            </Button>
          </Stack>
          <Select
            options={leadStatuses}
            label="Status *"
            value={formik?.values?.status}
            onChange={(value) => formik.setFieldValue("status", value)}
            selectProps={{ id: "id", label: "name" }}
          />
          <Select
            options={sources}
            label="Source *"
            value={formik?.values?.source}
            onChange={(value) => formik.setFieldValue("source", value)}
            selectProps={{ id: "id", label: "name" }}
          />
          <MultiSelect
            options={assignees}
            label="Assignees *"
            value={formik?.values?.assignee}
            onChange={(value) => formik.setFieldValue("assignee", value)}
            selectProps={{
              id: "user_id",
              label: "name",
            }}
          />
          {selectedFile ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "50px auto 100px",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FcFile style={{ fontSize: "40px" }} />
              </Box>
              <Box>
                <Typography variant="h6">
                  {formatFileName(selectedFile?.name)}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {(selectedFile?.size / 1024).toFixed(0)}KB
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                }}
              >
                <IconButton
                  onClick={() => {
                    setError("");
                    setSlectedFile(null);
                  }}
                >
                  <Delete />
                </IconButton>
              </Box>
            </Box>
          ) : (
            <Button
              component="label"
              variant="outlined"
              disabled={!(formik.isValid && formik.dirty)}
            >
              <input
                type="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                hidden
                onChange={(e) => handleUploadAttachment(e)}
              />
              Select File
            </Button>
          )}
          <Box>
            {error && (
              <Typography sx={{ color: "red", mb: "7px" }}>{error}</Typography>
            )}
            <Button
              fullWidth
              startIcon={<UploadFile />}
              variant="solid"
              disabled={!(formik.isValid && formik.dirty) || !selectedFile}
              onClick={formik.submitForm}
            >
              Import
            </Button>
          </Box>
        </Stack>
      ) : (
        <Stack sx={{ padding: "20px" }} gap="10px" alignItems="center">
          <SuccessAnimation />
          <Typography variant="h5" mb="20px">
            Total {totalImported} data imported
          </Typography>
          <Button
            fullWidth
            variant="solid"
            onClick={() => setTotalImported(null)}
          >
            Import More
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => setOpenDrawer(false)}
          >
            Close
          </Button>
        </Stack>
      )}
    </RightDrawer>
  );
};

export default Import;
