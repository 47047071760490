import Loader from "components/loader";
import SettingUp from "components/settingUp";
import UNIVERSAL from "config";
import Axios from "lib/Axios";
import { saveUser } from "lib/Reducer/authSlice";
import { saveBase, saveSiteInfo } from "lib/Reducer/baseSlice";
import { useEffect, useState } from "react";
import Favicon from "react-favicon";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { BASE, CREDENTIALS } from "utils/dataKeys";
import { loadFromLocalStorage } from "utils/manageLocalStorage";
import * as Features from "./features";
import AdminLayout from "./layout/AdminLayout";
import ClientLayout from "./layout/ClientLayout";
import PrivateRoute from "./routeModel/PrivateRoute";

const Router = () => {
  const fav_url = useSelector((state) => state?.base?.site_info?.site_favicon);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const base = useSelector((state) => state.base);
  const [displaySettinUp, setDisplaySettingUp] = useState(false);
  const userDetails = loadFromLocalStorage(CREDENTIALS);

  const updatePermissions = () => {
    if (auth?.user_details?.role === "Customer") {
      Axios.get(
        `/api/admin/base/customer/permission/list/${auth?.user_details?.user?.id}`
      ).then((res) => {
        const newUserDetails = {
          ...userDetails,
          role_permissions: res?.data?.data,
        };
        dispatch(saveUser(newUserDetails));
      });
    } else {
      Axios.get(
        `/api/admin/base/${
          auth?.user_details?.role === "Customer" ? "customer" : "staff"
        }/permission/list/${auth?.user_details?.user?.id}`
      ).then((res) => {
        const newUserDetails = {
          ...userDetails,
          role_permissions: res?.data?.data,
        };
        dispatch(saveUser(newUserDetails));
      });
    }
  };

  const updateFeatureLists = () => {
    if (auth?.user_details?.role !== "Customer") {
      Axios.get("/api/admin/feature-list/list").then(({ data }) => {
        dispatch(
          saveBase({
            data: { ...base?.base_data, featureLists: data?.data },
            updated: false,
          })
        );
      });
    }
  };

  const updateSiteInfo = () => {
    Axios.get("/api/admin/site-info").then((res) => {
      if (res?.data?.success) dispatch(saveSiteInfo(res.data?.data));
    });
  };

  useEffect(() => {
    const updateSite = async () => {
      updateSiteInfo();
      updateFeatureLists();
      if (auth?.user_details?.token) {
        updatePermissions();
        if (!base?.base_data || !base?.base_data?.have_all) {
          setDisplaySettingUp(true);
          await Axios.post("/api/admin/base/all").then((res) => {
            dispatch(
              saveBase({
                data: { ...res?.data?.data, have_all: true },
                updated: false,
              })
            );
            setDisplaySettingUp(false);
          });
        } else {
          dispatch(
            saveBase({ data: loadFromLocalStorage(BASE), updated: false })
          );
        }
      }
    };
    updateSite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth?.user_details?.token, base?.base_data?.length, base.updated]);

  let permission = auth?.user_details?.role_permissions?.find(
    (role) => role?.module === "dashboard"
  )?.permission?.[0]?.is_permit;
  const role = useSelector((state) => state?.auth?.user_details?.role);

  return (
    <BrowserRouter>
      {fav_url && <Favicon url={UNIVERSAL.BASEURL + fav_url} />}
      <Loader />
      {displaySettinUp && (
        <SettingUp setDisplaySettingUp={setDisplaySettingUp} />
      )}
      <Routes>
        <Route
          path="/client"
          element={
            <PrivateRoute>
              <ClientLayout />
            </PrivateRoute>
          }
        >
          <Route index element={<Features.CustomerProfile />} />
          <Route path="project" element={<Features.CustomerProject />} />
          <Route path="invoice" element={<Features.CustomerInvoice />} />
          <Route path="payment" element={<Features.CustomerPayment />} />
          <Route path="attachments" element={<Features.AttachmentUpload />} />
          <Route
            path="knowledgebase"
            element={<Features.CustomerKnowledgeBase />}
          />
          <Route
            path="knowledgebase/:kbId"
            element={<Features.SingleKnowledgebase />}
          />
          <Route path="chat" element={<Features.CustomerChat />} />
          <Route path="ticket" element={<Features.CustomerTicket />} />
          <Route path="ticket/:ticketId" element={<Features.SingleTicket />} />
          <Route path="*" exact={true} element={<p>Not Found</p>} />
        </Route>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <AdminLayout />
            </PrivateRoute>
          }
        >
          <Route
            index
            element={
              role === "Admin" || permission ? (
                <Features.Dashboard />
              ) : (
                <Navigate to="/leads" replace />
              )
            }
          />
          <Route path="/leads" element={<Features.Leads />} />
          <Route path="/customers" element={<Features.Customers />} />
          <Route path="/projects" element={<Features.ProjectCustomers />} />
          {/* for expense */}
          <Route path="/expenses" element={<Features.Expense />} />
          <Route
            path="/revenue-records/invoices"
            element={<Features.Invoices />}
          />
          <Route
            path="/revenue-records/commissions"
            element={<Features.Commissions />}
          />
          <Route
            path="/revenue-records/bank-invoices"
            element={<Features.InvoiceBank />}
          />
          <Route path="/revenue-records/sales" element={<Features.Sales />} />
          <Route path="/payments" element={<Features.Payments />} />
          <Route path="/tasks" element={<Features.Tasks />} />
          <Route path="/attendance" element={<Features.Attendance />} />
          <Route path="/tickets" element={<Features.Ticket />} />
          <Route
            path="/reports/university-targets"
            element={<Features.UniversityTargetReports />}
          />
          <Route path="/support/knowledge" element={<Features.Knowledge />} />

          <Route path="/utilities/notes" element={<Features.Notes />} />
          <Route
            path="/utilities/attachments"
            element={<Features.Attachments />}
          />
          <Route path="/utilities/backup" element={<Features.Backup />} />
          <Route
            path="/utilities/activity-log"
            element={<Features.ActivityLog />}
          />
          {/* Trash */}
          <Route path="/trash/leads" element={<Features.LeadsTrash />} />
          <Route
            path="/trash/customers"
            element={<Features.CustomersTrash />}
          />
          <Route
            path="/trash/projects"
            element={<Features.ProjectCustomersTrash />}
          />
          <Route path="/trash/invoices" element={<Features.InvoicesTrash />} />
          <Route path="/trash/expenses" element={<Features.ExpenseTrash />} />
          <Route
            path="/trash/knowledge"
            element={<Features.KnowledgeTrash />}
          />
          {/* Setup routes */}
          <Route
            path="/setup/status/lead-statuses"
            element={<Features.LeadStatuses />}
          />
          <Route
            path="/setup/status/project-statuses"
            element={<Features.ProjectStatuses />}
          />
          <Route
            path="/setup/status/project-customer-statuses"
            element={<Features.ProjectCustomerStatuses />}
          />
          <Route
            path="/setup/status/admission-statuses"
            element={<Features.AdmissionStatuses />}
          />
          <Route
            path="/setup/status/bank-invoice-statuses"
            element={<Features.BankInvoiceStatuses />}
          />
          <Route
            path="/setup/status/task-statuses"
            element={<Features.TaskStatuses />}
          />

          <Route
            path="/setup/manage-project/branches"
            element={<Features.Branches />}
          />
          <Route
            path="/setup/manage-project/projects"
            element={<Features.Projects />}
          />
          <Route
            path="/setup/manage-project/intakes"
            element={<Features.Intakes />}
          />
          <Route
            path="/setup/manage-project/sponsor-professions"
            element={<Features.SponsorJobs />}
          />
          <Route
            path="/setup/manage-project/sponsor-profession-fields"
            element={<Features.SponsorJobFields />}
          />
          <Route
            path="/setup/manage-project/embassy-fields"
            element={<Features.EmbassyFields />}
          />

          <Route path="/setup/finance/fees" element={<Features.Fees />} />
          <Route
            path="/setup/finance/currencies"
            element={<Features.Currencies />}
          />
          <Route path="/setup/finance/banks" element={<Features.Banks />} />
          <Route
            path="/setup/finance/pay-methods"
            element={<Features.PaymentMethods />}
          />
          <Route
            path="/setup/finance/expense-categories"
            element={<Features.ExpenseCategories />}
          />
          <Route
            path="/setup/finance/expense-items"
            element={<Features.ExpenseItems />}
          />
          <Route
            path="/setup/finance/bank-invoice-banks"
            element={<Features.BankInvoiceBanks />}
          />

          <Route path="/setup/education/elp" element={<Features.Courses />} />
          <Route
            path="/setup/education/faculties"
            element={<Features.Faculties />}
          />
          <Route
            path="/setup/education/subjects"
            element={<Features.Subjects />}
          />
          <Route
            path="/setup/education/institutes"
            element={<Features.Institutes />}
          />
          <Route
            path="/setup/education/universities"
            element={<Features.Universities />}
          />
          <Route
            path="/setup/education/semesters"
            element={<Features.Semesters />}
          />

          <Route
            path="/setup/messaging/sms-templates"
            element={<Features.SmsTemplates />}
          />
          <Route
            path="/setup/messaging/email-templates"
            element={<Features.EmailTemplates />}
          />

          <Route
            path="/setup/university-targets"
            element={<Features.UniversityTargets />}
          />
          <Route
            path="/reports/expense-vs-income"
            element={<Features.ExpenseVsIncomeReports />}
          />
          <Route path="/setup/references" element={<Features.References />} />
          <Route path="/setup/sources" element={<Features.Sources />} />
          <Route path="/setup/holidays" element={<Features.Holidays />} />
          <Route path="/setup/notice" element={<Features.NoticeBoard />} />
          <Route path="/setup/countries" element={<Features.Countries />} />

          <Route path="/setup/workforce/roles" element={<Features.Roles />} />
          <Route
            path="/setup/workforce/departments"
            element={<Features.Departments />}
          />
          <Route
            path="/setup/workforce/designations"
            element={<Features.Designations />}
          />
          <Route path="/setup/workforce/staffs" element={<Features.Staffs />} />

          <Route
            path="/setup/other/knowledge-base-categories"
            element={<Features.KnowledgeBaseCategories />}
          />

          <Route path="/setup/settings" element={<Features.Settings />} />
          <Route path="*" exact={true} element={<p></p>} />
        </Route>
        <Route path="/info-form/:path_source" element={<Features.LeadForm />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
