import { Box, Typography } from "@mui/material";
import assets from "assets";

const NotAuthorized = ({ isModal, isSettings }) => {
  return (
    <Box
      sx={{
        height: isModal
          ? "calc(100vh - 165px)"
          : isSettings
          ? "calc(100vh - 195px)"
          : "calc(100vh - 143px)",
        overflow: "hidden",
        bgcolor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
          width: "95%",
          maxWidth: "400px",
        },
      }}
    >
      <img src={assets.unauthorized} alt="" />
      <Typography
        variant="h4"
        sx={{
          fontSize: "clamp(14px, 3vw, 18px)",
          textAlign: "center",
          p: "0 15px 20px",
          mt: "-30px",
        }}
      >
        You are not authorized to access this page
      </Typography>
    </Box>
  );
};

export default NotAuthorized;
