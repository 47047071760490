import * as yup from "yup";

const validationSchema = yup.object({
  customer_id: yup.string("").required("Customer is Required").nullable(),
  currency_id: yup.string("").required("Currency is Required").nullable(),
  assignees: yup.array().required("Assignee is Required").min(1),
  invoice_details: yup
    .array()
    .required("Invoice is Required")
    .of(
      yup.object().shape({
        fee_id: yup.string("").required("Fee is Required"),
        subtotal: yup.string("").required("Subtotal is Required"),
      })
    )
    .min(1),
});

export default validationSchema;
