import { Box, Button } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import AddEditTask from "./AddEditTask";
import TaskTable from "./TaskTable";

const Task = ({ id, route, related_to, isStaffProfile }) => {
  const [persistedData, setPersistedData] = useState({});
  const [addTask, setAddTask] = useState(false);
  const [currTask, setCurrTask] = useState({});
  const [allTask, setAllTask] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  const debouncedText = useDebounce(searchText, 1000);

  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getTasks = async () => {
      let finalPagination = { ...pagination };
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}&search=${debouncedText}`;
      const endpoint = isStaffProfile
        ? {
            method: "post",
            url: `/api/admin/task/list?${params}`,
            data: { user_id: [id] },
          }
        : {
            method: "get",
            url: `/api/admin/${route}/task/${id}?${params}`,
          };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllTask(allData || []);
        setPagination(rest);
      }
    };
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, debouncedText]);

  return (
    <Box sx={{ m: "-20px" }}>
      {addTask ? (
        <AddEditTask
          id={id}
          row_id={currTask?.id}
          allTask={allTask}
          setAllTask={setAllTask}
          persistedData={persistedData}
          setPersistedData={setPersistedData}
          setAddTask={setAddTask}
          related_to={related_to}
        />
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "15px",
            }}
          >
            <SearchBox
              handleSearch={(text) => setSearchText(text)}
              placeholder={"Search in task.."}
            />
            <Button
              startIcon={<BiPlus />}
              variant="outlined"
              onClick={() => {
                setCurrTask({});
                setAddTask(true);
              }}
            >
              Add Task
            </Button>
          </Box>
          <TaskTable
            loading={loading}
            allTask={allTask}
            pagination={pagination}
            setPagination={setPagination}
            setReload={setReload}
            setAddTask={setAddTask}
            setCurrTask={setCurrTask}
            setAllTask={setAllTask}
          />
        </Box>
      )}
    </Box>
  );
};

export default Task;
