import { Box, Button } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { BiLeftArrowAlt } from "react-icons/bi";
import * as yup from "yup";
import { createMakePayment, updateMakePayment } from "../../../api/sales";
import { useSelector } from "react-redux";
import { useEffect, useMemo } from "react";
let capitalizedString = (data) => data.charAt(0).toUpperCase() + data.slice(1);

const AddEditMakePayments = ({
  id,
  setAddPayment,
  currPayment,
  setReload,
  profile,
  setReloadMain,
  expected_payment_list,
}) => {
  let paymentMethods = useSelector(
    (state) => state?.base?.base_data?.paymentMethods
  );
  const { loading, fetchData } = useApi();

  const initialValues = {
    installation_invoice_id: profile?.id,
    payment_method_id: "",
    expected_payment_id: "",
    payment_date: null,
    total_paid: "",
    note: "",
    payble_amount: "",
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let obj = {};
      for (const key in initialValues) {
        obj[key] = data[key];
      }
      return obj;
    } else return initialValues;
  };

  const onSubmit = async ({ payble_amount, ...data }) => {
    let result = await fetchData(
      currPayment?.id
        ? updateMakePayment(data, currPayment?.id)
        : createMakePayment(data)
    );
    if (result?.success) {
      setReload((pre) => !pre);
      setReloadMain((pre) => !pre);
      setAddPayment(false);
    }
  };
  const formik = useFormik({
    initialValues: getInitialValues(currPayment),
    validationSchema: yup.object({
      total_paid: yup
        .number()
        .positive("Required Field")
        .required("Required Field"),
      payment_method_id: yup.string("").required("Required Field").nullable(),
      expected_payment_id: yup.string().required("Required Field").nullable(),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const amount = expected_payment_list.find(
      (item) => item?.id === formik.values.expected_payment_id
    )?.total_due;
    formik.setFieldValue("payble_amount", amount || "");
    !currPayment?.id && formik.setFieldValue("total_paid", amount || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.expected_payment_id]);

  let updateSalesListWithName = useMemo(() => {
    return expected_payment_list
      .filter((item) => (!currPayment?.id ? item.status !== "paid" : true))
      .map((item) => {
        const expected_payment_type =
          item?.expected_payment_type === "charge"
            ? `Subscription Fee - ${capitalizedString(item?.month)}, ${
                item?.year
              } `
            : "Installation Fee";
        return {
          id: item?.id,
          name: `${expected_payment_type} ${
            item.status === "paid"
              ? `- (${capitalizedString(item?.status)})`
              : ""
          }
          `,
        };
      });
  }, [currPayment?.id, expected_payment_list]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddPayment(false)}
        >
          View Payments
        </Button>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "15px",
          pb: "15px",
        }}
      >
        <Select
          name="expected_payment_id"
          options={updateSalesListWithName}
          label="Installment *"
          formik={formik}
          selectProps={{ id: "id", label: "name" }}
        />

        <InputField
          name="payble_amount"
          label="Payable Amount"
          type="number"
          disabled
          formik={formik}
        />
        <Select
          name="payment_method_id"
          options={paymentMethods}
          label="Select Payment Method *"
          formik={formik}
          selectProps={{ id: "id", label: "name" }}
        />

        <InputField
          name="total_paid"
          label="Amount *"
          type="number"
          formik={formik}
        />
        <DateTimePicker
          name="payment_date"
          label="Payment Date"
          type="date"
          formik={formik}
        />

        <Box sx={{ gridColumn: "span 2" }}>
          <InputField
            fullWidth
            name="note"
            label="Note"
            multiline
            rows={3}
            formik={formik}
          />
        </Box>
        <Button
          sx={{ gridColumn: "span 2" }}
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={
            (!(formik.dirty && formik.isValid) && !currPayment?.id) || loading
          }
        >
          {currPayment?.id ? "Update Payment" : "Save Payment"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddEditMakePayments;
