import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApiCall from "hooks/useApiCall";
import useBase from "hooks/useBase";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  allDepartments,
  allDesignations,
  allRoles,
  deleteStaff,
  rolePermissions,
  viewAllStaffs,
} from "../api/staffs";
import ComfirmDelete from "./ComfirmDelete";
import StaffsHeader from "./StaffsHeader";
import StaffsTable from "./StaffsTable";
import AddStaff from "./addStaff/AddStaff";
import StaffProfile from "./staffProfile/StaffProfile";

const Staffs = () => {
  const permissions = usePermissions("staff");
  const { base, updateBase } = useBase();
  const { data, loading, fetchData: fetchAllStaffs } = useApiCall();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [staffDeleteData, setStaffDeleteData] = useState({});
  const [openProfile, setOpenProfile] = useState(false);
  const [allStaffs, setAllStaffs] = useState([]);
  const [designations, setDesignations] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [currStaff, setCurrStaff] = useState({});
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  useEffect(() => {
    if (openSidebar === false && openProfile === false) {
      setCurrStaff({});
    }
  }, [openSidebar, openProfile, openConfirmDelete]);

  useEffect(() => {
    fetchAllStaffs(
      viewAllStaffs,
      {
        params: `page=${pagination?.current_page}&limit=${pagination?.per_page}&search=${search}`,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, search, pagination?.current_page, pagination?.per_page]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllStaffs(allData);
      setPagination(rest);
    }
  }, [data]);

  useEffect(() => {
    updateBase(["assignees"]);
  }, []);

  useEffect(() => {
    const getDesignations = async () => {
      const data = await allDesignations();
      setDesignations(data?.data);
    };
    const getDepartments = async () => {
      const data = await allDepartments();
      setDepartments(data?.data);
    };
    const getRoles = async () => {
      const data = await allRoles();
      setRoles(data?.data);
    };
    getRoles();
    getDepartments();
    getDesignations();
  }, []);

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenConfirmDelete(true);
    setStaffDeleteData({ ...staffDeleteData, id });
  };

  const handleDeleteConfirm = async () => {
    setOpenConfirmDelete(false);
    setOpenAlert(false);
    const result = await deleteStaff(staffDeleteData);
    if (result.success) {
      setReload((pre) => !pre);
      toast.success(result?.message);
    } else {
      toast.error(result?.message);
    }
  };

  const handleEdit = (value) => {
    setCurrStaff(value);
    setOpenSidebar(true);
  };

  const handleView = (value) => {
    setCurrStaff(value);
    setOpenProfile(true);
  };

  const [permissionModules, setPermissionModules] = useState([]);

  const getRolePermissions = async () => {
    const response = await rolePermissions();
    setPermissionModules(response?.data);
  };
  useEffect(() => {
    getRolePermissions();
  }, []);

  return (
    <Box>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Staff"
          desc={
            <Box>
              Are you sure you want to delete? This will delete the staff
              permanently! <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      {openConfirmDelete && (
        <ComfirmDelete
          {...{
            assignees: base?.assignees?.filter(
              (assignee) => assignee?.id !== staffDeleteData?.id
            ),
            setStaffDeleteData,
            staffDeleteData,
            setOpenAlert,
            setOpen: setOpenConfirmDelete,
            open: openConfirmDelete,
          }}
        />
      )}
      <StaffsHeader
        {...{
          setOpenSidebar,
          setSearch,
          setPagination,
          pagination,
          permissions,
          search,
        }}
      />
      {openSidebar && (
        <AddStaff
          {...{
            openDrawer: openSidebar,
            setOpenDrawer: setOpenSidebar,
            setReload,
            currStaff,
            designations,
            departments,
            roles,
            permissionModules,
          }}
        />
      )}
      {permissions?.list ? (
        <StaffsTable
          allStaffs={allStaffs}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
          handleView={handleView}
          setReload={setReload}
          permissions={permissions}
        />
      ) : (
        <NotAuthorized />
      )}
      {openProfile && (
        <StaffProfile
          setOpenDrawer={setOpenProfile}
          openDrawer={openProfile}
          currStaff={currStaff}
          handleEdit={handleEdit}
          permissions={permissions}
        />
      )}
    </Box>
  );
};

export default Staffs;
