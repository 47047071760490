import Edit from "@mui/icons-material/Edit";
import PersonOutlined from "@mui/icons-material/PersonOutlined";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Tab,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import UNIVERSAL from "config";
import { useFormik } from "formik";
import { saveBase } from "lib/Reducer/baseSlice";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  addNewStaff,
  createStaff,
  editStaff,
  updateStaff,
} from "../../api/staffs";
import { validationSchema } from "../../schema";
import Permissions from "./Permissions";
import useApi from "hooks/useApi";
import handleError from "utils/handleError";
import LoadingBtn from "components/ui/loadingBtn";

let weekendsList = [
  { id: "Monday", label: "Monday" },
  { id: "Tuesday", label: "Tuesday" },
  { id: "Wednesday", label: "Wednesday" },
  { id: "Thursday", label: "Thursday" },
  { id: "Friday", label: "Friday" },
  { id: "Saturday", label: "Saturday" },
  { id: "Sunday", label: "Sunday" },
];
const AddStaff = (props) => {
  const {
    openDrawer,
    setOpenDrawer,
    currStaff,
    setReload,
    designations,
    departments,
    roles,
    permissionModules,
  } = props;
  const [rolePermissions, setRolePermissions] = useState([]);
  const { fetchData, loading } = useApi();
  const { fetchData: fetchAddEdit, loading: addEditloading } = useApi();

  useEffect(() => {
    const getPermissions = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/staff/show/permission/${currStaff?.id}`,
      };
      const res = await fetchData(endpoint, false);
      if (res.success) setRolePermissions(res?.data);
    };
    getPermissions();
  }, []);

  const dispatch = useDispatch();
  const [image, setImage] = useState({
    blob: currStaff.id ? UNIVERSAL.BASEURL + currStaff.image : "",
    file: "",
  });
  const [nid, setNid] = useState({
    blob: "",
    file: "",
  });
  const [signature, setSignature] = useState({
    blob: "",
    file: "",
  });

  useEffect(() => {
    if (currStaff.id) {
      setImage({ blob: UNIVERSAL.BASEURL + currStaff.image, file: "" });
      setNid({ blob: UNIVERSAL.BASEURL + currStaff.nid, file: "" });
      setSignature({ blob: UNIVERSAL.BASEURL + currStaff.signature, file: "" });
    } else {
      setImage({ blob: "", file: "" });
      setNid({ blob: "", file: "" });
      setSignature({ blob: "", file: "" });
    }
  }, [openDrawer]);

  const initialValues = {
    name: currStaff.name || "",
    phone: currStaff.phone || "",
    email: currStaff.email || "",
    password: "",
    default_checkin: currStaff.default_checkin || "09:00:00",
    default_checkout: currStaff.default_checkout || "18:00:00",
    present_address: currStaff.present_address || "",
    permanent_address: currStaff.permanent_address || "",
    gender: currStaff.gender || "Male",
    status: currStaff.status || "Active",
    department_id: currStaff.department_id || "",
    designation_id: currStaff.designation_id || "",
    role_id: currStaff.role_id || "",
    weekends: currStaff?.id ? currStaff?.weekends : [],
    helper: 0,
    nid_no: currStaff.nid_no || "",
    skype: currStaff?.skype || "",
    linkedin: currStaff?.linkedin || "",
    twitter: currStaff?.twitter || "",
    facebook: currStaff?.facebook || "",
    instagram: currStaff?.instagram || "",
    role_permissions: currStaff?.id
      ? rolePermissions
      : permissionModules?.length
      ? permissionModules
      : [],
    reset: false,
  };

  const onSubmit = async ({ weekends, helper, ...data }) => {
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (key === "role_permissions") {
        formData.append("role_permissions", JSON.stringify(data[key]));
      } else {
        formData.append(key, data[key]);
      }
    });
    formData.append("image", image.file);
    formData.append("nid", nid.file);
    formData.append("signature", signature.file);
    formData.append("weekends", JSON.stringify(weekends));

    const result = await fetchAddEdit(
      currStaff?.id
        ? updateStaff(currStaff?.id, formData)
        : createStaff(formData)
    );

    if (result.success) {
      setReload((pre) => !pre);
      setOpenDrawer(false);
      dispatch(saveBase({ data: null, updated: true }));
      formik.resetForm();
    } else {
      handleError(result, formik);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleAddImage = (e, type) => {
    formik.setFieldValue("helper", formik.values.helper + 1);
    const data = e.target.files[0];
    let objectURL = URL.createObjectURL(data);
    if (type === "image") {
      setImage({ blob: objectURL, file: data });
    } else if (type === "nid") {
      setNid({ blob: objectURL, file: data });
    } else {
      setSignature({ blob: objectURL, file: data });
    }
  };

  const [value, setValue] = useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <RightDrawer
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      headingLeft={currStaff?.id ? "Edit Staff" : "New Staff"}
      headingRight={null}
      width="650px"
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Profile" value="1" />
            <Tab label="Permissions" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: "0px" }}>
          <Box sx={{ overflowY: "auto", height: "calc(100vh - 184px)" }}>
            <Box
              sx={{
                position: "relative",
                height: "150px",
                width: "150px",
                margin: "20px auto 20px",
              }}
            >
              <Box
                sx={{
                  margin: "0 auto",
                  borderRadius: "50%",
                  height: "100%",
                  width: "100%",
                  overflow: "hidden",
                  border: "3px solid #ddd",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  bgcolor: "#f5f5f5",
                }}
              >
                {image?.blob ? (
                  <img
                    src={image.blob}
                    alt=""
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      padding: "2px",
                      borderRadius: "50%",
                    }}
                  />
                ) : (
                  <PersonOutlined sx={{ fontSize: "80px", color: "#afafaf" }} />
                )}
              </Box>
              <IconButton
                component="label"
                sx={{
                  position: "absolute",
                  bottom: "6px",
                  right: "6px",
                  zIndex: "100",
                  color: (theme) => theme.palette.primary.main,
                  bgcolor: "#fff",
                  boxShadow: "0 0 3px #ddd",
                  "&:hover": {
                    bgcolor: "#fff",
                  },
                }}
              >
                <input
                  type="file"
                  accept="image/png, image/gif, image/jpeg"
                  hidden
                  onChange={(e) => handleAddImage(e, "image")}
                />
                <Edit fontSize="small" />
              </IconButton>
            </Box>
            <Stack sx={{ gap: "10px", px: "20px" }}>
              <TextField
                size="small"
                label="Name *"
                {...formik.getFieldProps("name")}
                error={formik.touched.name && formik.errors.name}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                size="small"
                label="Phone Number *"
                {...formik.getFieldProps("phone")}
                error={formik.touched.phone && formik.errors.phone}
                helperText={formik.touched.phone && formik.errors.phone}
              />
              <TextField
                size="small"
                label="Email *"
                {...formik.getFieldProps("email")}
                error={formik.touched.email && formik.errors.email}
                helperText={formik.touched.email && formik.errors.email}
              />

              <TextField
                size="small"
                label={currStaff?.id ? "Password" : "Password *"}
                {...formik.getFieldProps("password")}
                error={formik.touched.password && formik.errors.password}
                helperText={formik.touched.password && formik.errors.password}
              />
              <DateTimePicker
                name="default_checkin"
                label="Default Checkin"
                type="time"
                formik={formik}
              />
              <DateTimePicker
                name="default_checkout"
                label="Default Checkout"
                type="time"
                formik={formik}
              />
              <MultiSelect
                options={weekendsList}
                label="Weekend"
                name="weekends"
                value={formik?.values?.weekends}
                onChange={(value) => {
                  formik.setFieldValue("weekends", value);
                }}
                selectProps={{ id: "id", label: "label" }}
              />
              <FormControl>
                <FormLabel id="gender-radio-buttons">Gender</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="gender-radio-buttons"
                  {...formik.getFieldProps("gender")}
                  error={formik.touched.gender && formik.errors.gender}
                  helperText={formik.touched.gender && formik.errors.gender}
                >
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                </RadioGroup>
              </FormControl>
              <Select
                options={roles}
                label="Role *"
                value={formik?.values?.role_id}
                onChange={(value) => {
                  formik.setFieldValue("role_id", value);
                  if (Number(currStaff.role_id) === Number(value)) {
                    formik.setFieldValue(
                      "role_permissions",
                      currStaff.role_permissions
                    );
                  } else {
                    formik.setFieldValue(
                      "role_permissions",
                      roles.find((role) => Number(role.id) === Number(value))
                        ?.role_permissions
                    );
                  }
                }}
                selectProps={{ id: "id", label: "name" }}
              />
              <Select
                disabled={!formik.values.role_id}
                options={departments}
                label="Department *"
                value={formik?.values?.department_id}
                onChange={(value) =>
                  formik.setFieldValue("department_id", value)
                }
                selectProps={{ id: "id", label: "name" }}
              />
              <Select
                disabled={!formik.values.department_id}
                options={
                  designations?.filter(
                    (des) =>
                      des?.department_id === formik?.values?.department_id
                  ) || []
                }
                label="Designation *"
                value={formik?.values?.designation_id}
                onChange={(value) =>
                  formik.setFieldValue("designation_id", value)
                }
                selectProps={{ id: "id", label: "name" }}
              />
              <InputField
                name="present_address"
                label="Present Address"
                placeholder=""
                formik={formik}
                multiline
                rows={2}
              />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <FormControlLabel
                  label="Same as present"
                  labelPlacement="start"
                  control={
                    <Checkbox
                      onChange={(e) =>
                        formik.setFieldValue(
                          "permanent_address",
                          e.target.checked ? formik.values.present_address : ""
                        )
                      }
                    />
                  }
                />
              </Box>
              <InputField
                name="permanent_address"
                label="Permanent Address"
                placeholder=""
                formik={formik}
                multiline
                rows={2}
                InputLabelProps={{
                  shrink: formik.values.permanent_address ? true : false,
                }}
              />
              <TextField
                size="small"
                label="LinkedIn"
                {...formik.getFieldProps("linkedin")}
                error={formik.touched.linkedin && formik.errors.linkedin}
                helperText={formik.touched.linkedin && formik.errors.linkedin}
              />
              <TextField
                size="small"
                label="Skype"
                {...formik.getFieldProps("skype")}
                error={formik.touched.skype && formik.errors.skype}
                helperText={formik.touched.skype && formik.errors.skype}
              />
              <TextField
                size="small"
                label="Facebook"
                {...formik.getFieldProps("facebook")}
                error={formik.touched.facebook && formik.errors.facebook}
                helperText={formik.touched.facebook && formik.errors.facebook}
              />
              <TextField
                size="small"
                label="Instagram"
                {...formik.getFieldProps("instagram")}
                error={formik.touched.instagram && formik.errors.instagram}
                helperText={formik.touched.instagram && formik.errors.instagram}
              />
              <TextField
                size="small"
                label="Twitter"
                {...formik.getFieldProps("twitter")}
                error={formik.touched.twitter && formik.errors.twitter}
                helperText={formik.touched.twitter && formik.errors.twitter}
              />
              <Select
                options={["Active", "Inactive"]}
                label="Status *"
                value={formik?.values?.status}
                onChange={(value) => formik.setFieldValue("status", value)}
              />
              <TextField
                size="small"
                label="NID No"
                {...formik.getFieldProps("nid_no")}
                error={formik.touched.nid_no && formik.errors.nid_no}
                helperText={formik.touched.nid_no && formik.errors.nid_no}
              />
              <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
                Upload Staff's NID
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  height: "150px",
                  backgroundColor: "#ddd",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "3px",
                }}
              >
                {nid?.blob && (
                  <img
                    src={nid.blob}
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                    alt=""
                  />
                )}
                <IconButton
                  component="label"
                  sx={{
                    position: "absolute",
                    backgroundColor: (theme) => theme.palette.primary.main,
                    bottom: "3px",
                    right: "3px",
                    zIndex: "100",
                    bgcolor: "#fff",
                    boxShadow: "0 0 3px #ddd",
                  }}
                >
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                    onChange={(e) => handleAddImage(e, "nid")}
                  />
                  <Edit
                    fontSize="small"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  />
                </IconButton>
              </Box>
              <Typography sx={{ color: (theme) => theme.palette.primary.main }}>
                Upload Staff's Signature
              </Typography>
              <Box
                sx={{
                  position: "relative",
                  height: "80px",
                  backgroundColor: "#ddd",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "3px",
                }}
              >
                {signature.blob && (
                  <img
                    src={signature.blob}
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                    alt=""
                  />
                )}
                <IconButton
                  component="label"
                  sx={{
                    position: "absolute",
                    backgroundColor: (theme) => theme.palette.primary.main,
                    bottom: "3px",
                    right: "3px",
                    zIndex: "100",
                    bgcolor: "#fff",
                    boxShadow: "0 0 3px #ddd",
                  }}
                >
                  <input
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                    onChange={(e) => handleAddImage(e, "signature")}
                  />
                  <Edit
                    fontSize="small"
                    sx={{ color: (theme) => theme.palette.primary.main }}
                  />
                </IconButton>
              </Box>
            </Stack>
          </Box>
        </TabPanel>
        <TabPanel value="2" sx={{ padding: "0px" }}>
          <Box sx={{ overflowY: "auto", height: "calc(100vh - 182px)" }}>
            <Permissions
              loading={loading}
              formik={formik}
              currRolePermission={rolePermissions}
              roles={roles}
              role_id={currStaff?.role_id}
            />
          </Box>
        </TabPanel>
      </TabContext>
      <Box sx={{ p: "15px 20px" }}>
        {addEditloading ? (
          <LoadingBtn />
        ) : (
          <Button
            fullWidth
            onClick={formik.submitForm}
            disabled={!(formik.isValid && formik.dirty)}
            variant="solid"
          >
            {currStaff?.id ? "Update Staff" : "Add Staff"}
          </Button>
        )}
      </Box>
    </RightDrawer>
  );
};

export default AddStaff;
