import { TabList } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddEdit from "./AddEdit";
import Header from "./Header";
import TicketList from "./TicketList";

export const ticketStatus = [
  {
    id: "1",
    value: "",
    label: "All",
  },
  {
    id: "2",
    value: "new",
    label: "New",
  },
  {
    id: "3",
    value: "progress",
    label: "Progress",
  },

  {
    id: "4",

    value: "hold",
    label: "Hold",
  },
  {
    id: "5",

    value: "resolved",
    label: "Resolved",
  },
  {
    id: "6",

    value: "dismissed",
    label: "Dismissed",
  },
];

const CustomerTicket = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [allTickets, setAllTickets] = useState([]);
  const [statusTotalCount, seStatusTotalCount] = useState([]);
  // Helper states
  const [tabValue, setTabValue] = useState("1");
  const customer_id = useSelector((state) => state.auth.user_details.user.id);
  const { base_data } = useSelector((state) => state.base);
  const [reload, setReload] = useState(false);
  const [updateCount, setUpdateCount] = useState(false);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
    to: 0,
    from: 0,
  });
  const { loading, fetchData: listTicket } = useApi();
  const { fetchData: listTicketStatus } = useApi();
  useEffect(() => {
    const getCustomerTicket = async () => {
      const data = {
        customer_id: [customer_id],
        search,
        status: ticketStatus.find((t) => t.id === tabValue).value,
      };
      let finalPagination = { ...pagination };
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/ticket/list?${params}`,
        data,
      };
      const result = await listTicket(endpoint, false);
      if (result?.success) {
        const { data: allData, ...rest } = result.data;
        setAllTickets(allData || []);
        setPagination(rest);
      }
    };
    getCustomerTicket();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reload,
    pagination?.current_page,
    pagination?.per_page,
    search,
    tabValue,
  ]);

  useEffect(() => {
    const getCustomerTicketListStatus = async () => {
      const endpoint = {
        method: "get",
        url: `/api/ticket/status/count/${customer_id}`,
      };
      const result = await listTicketStatus(endpoint, false);
      if (result?.success) {
        seStatusTotalCount(result.data);
      }
    };
    getCustomerTicketListStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, updateCount]);

  const all_props = {
    ...base_data,
    setReload,
    pagination,
    setPagination,
    allTickets,
    setAllTickets,
    loading,
    // permissions,
  };

  let allCount = statusTotalCount.reduce((acc, item) => acc + item.count, 0);
  let showTotalCount = ticketStatus.map((item) => {
    let count = statusTotalCount.find((t) => t.status === item.value)?.count;
    return {
      ...item,
      count: item.value === "" ? allCount : count,
    };
  });

  const handleChangeTabValue = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <div>
      {openSidebar && (
        <AddEdit
          {...{
            openDrawer: openSidebar,
            setOpenDrawer: setOpenSidebar,
            customer_id,
            setTabValue,
            tabValue,
            setUpdateCount,
            ...all_props,
          }}
        />
      )}

      {!openSidebar && (
        <>
          <Header
            {...{
              setOpenSidebar,
              setSearch,
              setPagination,
              pagination,
              setUpdateCount,
              updateCount,
            }}
          />
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChangeTabValue} aria-label="Status">
                {showTotalCount.map((ts, i) => (
                  <Tab
                    key={ts.id}
                    label={`${ts.label} (${ts?.count || 0})`}
                    value={ts.id}
                  />
                ))}
              </TabList>
            </Box>
          </TabContext>
          <TicketList
            {...{
              ...all_props,
            }}
          />
        </>
      )}
    </div>
  );
};

export default CustomerTicket;
