import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};

const ticketSlice = createSlice({
  name: "ticket",
  initialState,
  reducers: {
    increamentTicket: (state) => {
      state.count += 1;
    },
    decreamentTicket: (state) => {
      state.count -= 1;
    },
    setTicketCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { increamentTicket, decreamentTicket, setTicketCount } = ticketSlice.actions;

export default ticketSlice.reducer;
