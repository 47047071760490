import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import React, { useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import MainTableRow from "./MainTableRow";
import Note from "components/common/note";

const ExpandableRow = ({ context, item: row, ...restProps }) => {
  return <MainTableRow {...restProps} {...context} key={row.id} row={row} />;
};

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: ExpandableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const MainTable = ({
  setAllSales,
  allSales,
  handleDelete,
  handleEdit,
  handleView,
  setReload,
  pagination,
  setPagination,
  loading,
  permissions,
  bankInvoiceStatuses,
}) => {
  const [openNote, setOpenNote] = useState("");
  const rows = allSales;
  const columns = [
    {
      id: "invoice_number",
      label: "Invoice Number",
      style: { minWidth: "140px" },
    },
    {
      id: "customer_name",
      label: "Customer name",
      style: { minWidth: "150px" },
    },
    {
      id: "contact_name",
      label: "Contact name",
      style: { minWidth: "150px" },
    },
    {
      id: "followup_date",
      label: "Followup Date",
      style: { minWidth: "150px" },
    },
    {
      id: "contact_number",
      label: "Contact Number",
      style: { minWidth: "150px" },
    },
    {
      id: "account_open_date",
      label: "Confirm Date",
      style: { minWidth: "120px" },
    },
    {
      id: "last_note",
      label: "Last Note",
      style: { minWidth: "190px" },
    },
    {
      id: "installation_fee",
      label: "Installation Fee",
      style: { minWidth: "130px" },
    },
    { id: "charge", label: "Subscription Fee", style: { minWidth: "130px" } },
    {
      id: "total_amount",
      label: "Payable Amount",
      style: { minWidth: "140px" },
    },
    { id: "total_paid", label: "Total Paid", style: { minWidth: "100px" } },
    { id: "total_due", label: "Due Amount", style: { minWidth: "110px" } },
    { id: "bank_invoice_status_id", label: "Status" },
    {
      id: "action",
      label: "Actions",
      style: { width: "170px" },
      align: "center",
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer sx={{ overflowX: "auto" }}>
          <TableVirtuoso
            style={{ height: "calc(100vh - 198px)" }}
            data={rows}
            components={TableComponents}
            context={{
              columns,
              handleDelete,
              handleEdit,
              handleView,
              setReload,
              allSales,
              setAllSales,
              permissions,
              bankInvoiceStatuses,
              openNote,
              setOpenNote,
            }}
            fixedHeaderContent={() => (
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 20px",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    sx={{ bgcolor: "#fff" }}
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          />
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 198px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
      {openNote && (
        <Note
          open={openNote}
          handleClose={() => setOpenNote(false)}
          id={openNote}
          related_id={openNote}
          related_to="installation_invoice"
          table_note_key="installation_invoice_notes"
          route="sales"
          allData={allSales}
          setAllData={setAllSales}
        />
      )}
    </Paper>
  );
};

export default MainTable;
