import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { viewExpenseSummary } from "../api/expense";

const Summary = ({ filters, search }) => {
  const [expenseSummary, setExpenseSummary] = useState([]);

  useEffect(() => {
    const getExpenseSummary = async () => {
      const summaryData = await viewExpenseSummary({
        body_data: { ...filters, search },
      });
      setExpenseSummary(summaryData?.data);
    };
    getExpenseSummary();
  }, [search, filters]);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
        gap: "20px",
        padding: "20px 20px",
      }}
    >
      {expenseSummary?.length > 0 &&
        expenseSummary.map((singleSummary, i) => (
          <Box
            key={i}
            sx={{
              bgcolor: "#fff",
              padding: "20px",
              borderRadius: "5px",
              boxShadow: "2px 1px 3px #e5e5e5",
            }}
          >
            <Typography>{singleSummary?.expense_category_name}</Typography>
            <Typography variant="h3">
              {singleSummary?.total_category_price}
            </Typography>
          </Box>
        ))}
    </Box>
  );
};

export default Summary;
