import Axios from "lib/Axios";

export const viewAllExpense = ({ body_data, params }) =>
  Axios.post(`/api/admin/expense/list?${params}`, body_data).then(
    (res) => res.data
  );

export const getProjects = (id) =>
  Axios.get(`/api/admin/base/project?customer_id=${id}`).then(
    (res) => res.data
  );

export const exportCustomer = ({ body_data }) =>
  Axios.post("/api/admin/customer/export", body_data).then((res) => res.data);

export const importSample = () =>
  Axios.get("/api/admin/lead/sample?type=xlsx").then((res) => res.data);

export const restoreLead = ({ id }) =>
  Axios.get(`/api/admin/lead/restore/${id}`).then((res) => res.data);

export const viewLead = ({ id }) =>
  Axios.get(`/api/admin/lead/show/${id}`).then((res) => res.data);

export const allLeadUtils = () =>
  Axios.get("/api/admin/base/lead-utils").then((res) => res.data);

export const updateExpenseStatus = ({ body_data, id }) =>
  Axios.post(`/api/admin/expense/change/status/${id}`, body_data).then(
    (res) => res.data
  );

export const viewNote = ({ id }) =>
  Axios.get(`/api/admin/expense/note/${id}`).then((res) => res.data);

export const createNote = ({ body_data }) =>
  Axios.post("/api/admin/note/create", body_data).then((res) => res.data);

export const deleteNote = ({ id }) =>
  Axios.delete(`/api/admin/note/delete/${id}`).then((res) => res.data);

export const viewAttachment = ({ id }) =>
  Axios.get(`api/admin/expense/attachment/${id}`).then((res) => res.data);

export const uploadAttachment = ({ body_data, id, setProgress }) =>
  Axios.post(`/api/admin/expense/attachment/upload/${id}`, body_data, {
    onUploadProgress: (progressEvent) => {
      let percentComplete = progressEvent.loaded / progressEvent.total;
      percentComplete = parseInt(percentComplete * 100);
      setProgress(percentComplete);
    },
  }).then((res) => res.data);

export const deleteAttachment = ({ id }) =>
  Axios.delete(`/api/admin/attachment/delete/${id}`).then((res) => res.data);

export const viewActivity = ({ id }) =>
  Axios.get(`/api/admin/expense/activity/${id}`).then((res) => res.data);

export const viewExpenseSummary = ({ body_data }) =>
  Axios.post("/api/admin/expense/summery", body_data).then((res) => res.data);

export const exportExpense = ({ body_data }) =>
  Axios.post("/api/admin/expense/export", body_data).then((res) => res.data);

export const importLeads = ({ body_data }) =>
  Axios.post("/api/admin/customer/import", body_data).then((res) => res.data);

export const invoiceBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/expense/bulk/action", body_data).then(
    (res) => res.data
  );

export const viewTasks = ({ id, params }) =>
  Axios.post(`/api/admin/expense/task/${id}?${params}`).then((res) => res.data);

export const addTask = ({ body_data }) =>
  Axios.post("/api/admin/task/create", body_data).then((res) => res.data);

export const updateTask = ({ id, body_data }) =>
  Axios.post(`/api/admin/task/update/${id}`, body_data).then((res) => res.data);

export const deleteTask = ({ id }) =>
  Axios.delete(`/api/admin/task/delete/${id}`).then((res) => res.data);

export const updateFollowupDate = ({ body_data }) =>
  Axios.post(`/api/admin/customer/followupdate`, body_data).then(
    (res) => res.data
  );

export const billingShippingAddress = ({ id, body_data }) =>
  Axios.post(`/api/admin/customer/billing-shipping/${id}`, body_data).then(
    (res) => res.data
  );

export const viewAddress = ({ id }) =>
  Axios.get(`/api/admin/base/customer-billing-shipping-address/${id}`).then(
    (res) => res.data
  );

export const showPaymentDetails = ({ body_data }) =>
  Axios.post("/api/admin/payment/list", body_data).then((res) => res.data);

export const projectsList = ({ id }) =>
  Axios.get(`/api/admin/base/project?customer_id=${id}`).then(
    (res) => res.data
  );

const endpoints = {
  createExpense: (data) => ({
    method: "post",
    url: "/api/admin/expense/create",
    data,
  }),
  updateExpense: (id, data) => ({
    method: "put",
    url: `/api/admin/expense/update/${id}`,
    data,
  }),
  deleteExpense: (id) => ({
    method: "delete",
    url: `/api/admin/expense/trash/${id}`,
  }),
};

export const { createExpense, updateExpense, deleteExpense } = endpoints;
