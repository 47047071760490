import { Box, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";

const Summary = ({ filters, search }) => {
  const [summary, setSummary] = useState({});
  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getInvoiceSummary = async () => {
      const endpoint = {
        method: "post",
        url: "api/admin/invoice/university-reference/summery",
        data: { ...filters, search },
      };
      const result = await fetchData(endpoint, false);
      setSummary(result?.data || {});
    };
    getInvoiceSummary();
  }, [search, filters]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 145px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "20px",
              padding: "20px 20px",
              borderTop: "1px solid #ddd",
            }}
          >
            {!!summary?.status_summery &&
              summary?.status_summery?.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    bgcolor: "#fff",
                    padding: "20px",
                    borderRadius: "5px",
                    boxShadow: "2px 1px 3px #e5e5e5",
                  }}
                >
                  <Typography>
                    {item?.status &&
                      item.status.charAt(0).toUpperCase() +
                        item.status.slice(1)}
                  </Typography>
                  <Typography variant="h3">{item?.total || 0}</Typography>
                </Box>
              ))}
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
              gap: "20px",
              padding: "20px 20px",
              borderTop: "1px solid #ddd",
            }}
          >
            {!!summary?.currency_summery &&
              summary?.currency_summery?.map(
                (item, index) =>
                  item?.total && (
                    <Box
                      key={index}
                      sx={{
                        bgcolor: "#fff",
                        padding: "20px",
                        borderRadius: "5px",
                        boxShadow: "2px 1px 3px #e5e5e5",
                        textTransform: "capitalize",
                        "& span": {
                          fontWeight: "500",
                          fontSize: "14px",
                        },
                      }}
                    >
                      <Typography variant="h5">
                        Status: <span>{item?.status || "-"}</span>
                      </Typography>
                      <Typography variant="h5">
                        Paid Amount: <span>{item?.paid_amount || 0}</span>
                      </Typography>
                      <Typography variant="h5">
                        Total Due: <span>{item?.total_due || 0}</span>
                      </Typography>
                      <Typography variant="h5">
                        Total: <span>{item?.total || 0}</span>
                      </Typography>
                    </Box>
                  )
              )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Summary;
