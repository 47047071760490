import { Avatar, Box, Button, Typography } from "@mui/material";
import UNIVERSAL from "config";
import moment from "moment";
import { BiLeftArrowAlt } from "react-icons/bi";

const ViewEmail = ({ setViewEmail, viewEmail }) => {
  const email = viewEmail?.data;

  return (
    <Box p="15px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setViewEmail({ data: null, open: false })}
        >
          View Emails
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          pt: "20px",
        }}
      >
        <Typography variant="h3" sx={{ fontWeight: "400", mb: "10px" }}>
          {email.subject}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px" }}>
            <Avatar src={UNIVERSAL.BASEURL + email?.user_image} />
            <Box>
              <Typography>{email?.user_name}</Typography>
              <Typography sx={{ fontSize: "13px" }}>{`to ${JSON.parse(
                email.send_to
              )}`}</Typography>
            </Box>
          </Box>
          <Typography sx={{ fontSize: "13px" }}>
            {moment(email.created_at).format("lll")}
          </Typography>
        </Box>
        <Box sx={{ padding: "15px 0 15px 50px" }}>
          <Typography
            variant="body2"
            dangerouslySetInnerHTML={{
              __html: email?.mail_body,
            }}
          ></Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewEmail;
