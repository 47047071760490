import AttachFileIcon from "@mui/icons-material/AttachFile";
import ForumIcon from "@mui/icons-material/Forum";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import { Avatar, Box, Skeleton, Typography } from "@mui/material";
import TablePagination from "components/tablePagination";
import UNIVERSAL from "config";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";

export const ticketStatus = [
  {
    id: "new",
    label: "New",
    color: "#FF0000",
  },
  {
    id: "progress",
    label: "Progress",
    color: "#FFA500",
  },

  {
    id: "hold",
    label: "Hold",
    color: "#808080",
  },
  {
    id: "resolved",
    label: "Resolved",
    color: "#008000",
  },
  {
    id: "dismissed",
    label: "Dismissed",
    color: "#000000",
  },
];

const TicketList = ({
  loading,
  allTickets,
  setReload,
  pagination,
  setPagination,
}) => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        px: 2,
      }}
    >
      {loading ? (
        <>
          {[...Array(5)].map((_, i) => (
            <Skeleton
              key={i}
              width={"100%"}
              height={150}
              animation="wave"
              variant="rounded"
              sx={{ mt: 3 }}
            />
          ))}
        </>
      ) : (
        <>
          {allTickets
            .sort((a, b) => new Date(b.created_at) - new Date(a?.created_at))
            .map((ticket) => (
              <Box
                key={ticket?.id}
                sx={{
                  p: 2.5,
                  borderRadius: 3,
                  border: "1px solid #dddddd",
                  mt: 4,
                  mb: 4,
                  cursor: "pointer",
                  "&:hover": {
                    boxShadow: "0 4px 8px rgb(112 111 111 / 13%)",
                  },
                }}
                onClick={() => {
                  navigate(`${ticket?.id}`);
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: 4,
                    mb: 2,
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: 38,
                        height: 38,
                        borderRadius: "50%",

                        backgroundColor:
                          ticketStatus?.find(
                            (singleStatus) =>
                              singleStatus?.id === ticket?.status
                          )?.color + "15",
                      }}
                    >
                      <TextSnippetIcon
                        sx={{
                          color: ticketStatus?.find(
                            (singleStatus) =>
                              singleStatus?.id === ticket?.status
                          )?.color,
                        }}
                      />
                    </Box>
                    <Typography variant="h5">
                      #Ticket {ticket?.project_name}
                    </Typography>
                  </Box>

                  <Box>
                    {ticket?.last_replay.length > 0 ? (
                      <>
                        Last Comment:{" "}
                        {moment(ticket?.last_replay[0]?.created_at).format(
                          "lll"
                        )}
                      </>
                    ) : (
                      <>Created: {moment(ticket?.created_at).format("lll")}</>
                    )}
                  </Box>
                </Box>
                <Typography variant="h4">{ticket?.title}</Typography>
                <Typography
                  variant="body1"
                  sx={{ mt: 1, mb: 3 }}
                  dangerouslySetInnerHTML={{
                    __html: ticket?.description,
                  }}
                ></Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 2,
                  }}
                >
                  {ticket?.last_replay.length > 0 && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                      >
                        {/* need fixing maybe in name 9999 */}
                        <Avatar
                          sx={{ width: 28, height: 28 }}
                          alt={
                            ticket?.last_replay[0]?.replay_customer_name ||
                            ticket?.last_replay[0]?.replay_user_name
                          }
                          src={
                            UNIVERSAL.BASEURL +
                            (ticket?.last_replay[0]?.replay_customer_image ||
                              ticket?.last_replay[0]?.replay_user_image)
                          }
                        />
                        <Typography variant="h5" sx={{ mb: 0 }}>
                          {ticket?.last_replay[0]?.replay_customer_name ||
                            ticket?.last_replay[0]?.replay_user_name}
                        </Typography>
                      </Box>
                    </>
                  )}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      ml: "auto",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        color: "#646161",
                      }}
                    >
                      <AttachFileIcon fontSize="sm" />
                      <Typography variant="span">
                        {ticket?.attachment_count || 0}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        color: "#646161",
                      }}
                    >
                      <ForumIcon fontSize="sm" />
                      <Typography variant="span">
                        {ticket?.replay_count || 0}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
        </>
      )}

      {pagination?.total > pagination?.per_page && (
        <TablePagination
          setReload={setReload}
          pagination={pagination}
          setPagination={setPagination}
        />
      )}
    </Box>
  );
};

export default TicketList;
