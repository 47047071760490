import moment from "moment";

const useTimeAgo = (full) => {
  const formatTimeAgo = (timestamp) => {
    const now = moment();
    const then = moment(timestamp);
    const yearsDiff = now.diff(then, "years");
    if (yearsDiff > 0) {
      return {
        value: full
          ? `${yearsDiff} ${yearsDiff === 1 ? "year" : "years"} ago`
          : `${yearsDiff} yr`,
        isNew: false,
      };
    }

    const monthsDiff = now.diff(then, "months");
    if (monthsDiff > 0) {
      return {
        value: full
          ? `${monthsDiff} ${monthsDiff === 1 ? "month" : "months"} ago`
          : `${monthsDiff} mth`,
        isNew: false,
      };
    }

    const daysDiff = now.diff(then, "days");
    if (daysDiff > 0) {
      return {
        value: full
          ? `${daysDiff} ${daysDiff === 1 ? "day" : "days"} ago`
          : `${daysDiff} dy`,
        isNew: false,
      };
    }

    const hoursDiff = now.diff(then, "hours");
    if (hoursDiff > 0) {
      return {
        value: full
          ? `${hoursDiff} ${hoursDiff === 1 ? "hour" : "hours"} ago`
          : `${hoursDiff} hr`,
        isNew: hoursDiff < 1,
      };
    }

    const minutesDiff = now.diff(then, "minutes");
    return {
      value: full
        ? minutesDiff < 1
          ? "Just now"
          : `${minutesDiff} ${minutesDiff === 1 ? "minute" : "minutes"} ago`
        : `${minutesDiff} min`,
      isNew: true,
    };
  };
  return formatTimeAgo;
};

export default useTimeAgo;
