import { Box } from "@mui/material";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import TimesheetHeader from "./TimesheetHeader";
import TimesheetTable from "./TimesheetTable";

const Timesheet = ({ id }) => {
  const [timesheetHistory, setTimesheetHistory] = useState([]);
  const [filters, setFilters] = useState({});
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 30,
    to: 0,
    from: 0,
    total: 0,
  });

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const { loading, fetchData } = useApi();

  const getProfileDetails = async () => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    const endpoint = {
      url: `/api/admin/staff/timesheet/history/${id}?page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
      method: "post",
      data: finalFilters,
      //  {
      //   user_id: [id],
      //   date_from: "2023-04-09",
      //   date_to: "2023-04-09",
      // },
    };
    const result = await fetchData(endpoint, false);
    if (result.success) {
      const { data, ...rest } = result?.data;
      setTimesheetHistory(data);
      setPagination(rest);
    }
  };

  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page]);

  return (
    <Box sx={{ m: "-20px" }}>
      <TimesheetHeader
        setFilters={setFilters}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
        filters={filters}
        isFiltering={isFiltering}
        id={id}
      />
      <TimesheetTable
        timesheetHistory={timesheetHistory}
        pagination={pagination}
        setPagination={setPagination}
        setReload={setReload}
        loading={loading}
      />
    </Box>
  );
};

export default Timesheet;
