import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count: 0,
};

const todoSlice = createSlice({
  name: "todo",
  initialState,
  reducers: {
    increament: (state) => {
      state.count += 1;
    },
    decreament: (state) => {
      state.count -= 1;
    },
    setTodoCount: (state, action) => {
      state.count = action.payload;
    },
  },
});

export const { increament, decreament, setTodoCount } = todoSlice.actions;

export default todoSlice.reducer;
