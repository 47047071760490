import { Visibility, VisibilityOff } from "@mui/icons-material";
import InputField from "components/ui/inputField";
import { useFormik } from "formik";
import { saveUser } from "lib/Reducer/authSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as yup from "yup";
import "./style.css";
// Mui components
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import assets from "assets";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import { toast } from "react-toastify";
import handleError from "utils/handleError";
import LoadingBtn from "components/ui/loadingBtn";

const CustomerLogin = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const siteInfo = useSelector((state) => state?.base?.site_info);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Email format is not valid")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const { fetchData, loading } = useApi();

  const onSubmit = async (data) => {
    const endpoint = {
      method: "post",
      url: "/api/login",
      data,
    };
    const result = await fetchData(endpoint);

    if (result.success) {
      formik.resetForm();
      dispatch(saveUser(result.data));
    } else {
      const errors = handleError(result);
      errors?.map((err) => toast.error(err));
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box
      sx={{
        background: "#405189",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        paddingX: "20px",
        "@media (max-width: 500px )": {
          p: "10px",
        },
      }}
    >
      <Box
        sx={{
          background: "#6e83c9",
          borderRadius: "15px",
          boxShadow: "0 0 20px #00000024",
          width: "100%",
          maxWidth: "1100px",
          overflow: "hidden",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          "@media (max-width: 1060px )": {
            gridTemplateColumns: "1fr",
          },
          margin: "0 auto",
          height: "calc(100vh - 260px)",
          maxHeight: "800px",
          minHeight: "500px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: "20px",
            "@media (max-width: 1060px )": {
              display: "none",
            },
          }}
        >
          <img
            style={{ maxWidth: "90%", margin: "0 auto" }}
            src={assets?.clientLogin}
            alt=""
          />
        </Box>
        <Box
          sx={{
            bgcolor: "#fff",
            p: "30px",
            "@media (max-width: 500px )": {
              p: "15px",
            },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              maxHeight: "150px",
              maxWidth: "300px",
              margin: "0 auto 60px",
            }}
            src={
              siteInfo?.site_logo
                ? UNIVERSAL.BASEURL + siteInfo?.site_logo
                : assets.logo
            }
            alt=""
          />
          <Typography variant="h3" sx={{ color: "#405189", mb: "20px" }}>
            Welcome Back
          </Typography>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.submitForm();
            }}
            className="login-form"
          >
            <div className="login-form-single-input">
              <InputField
                formik={formik}
                name="email"
                label="Email Address"
                size="large"
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <Box
              sx={{
                position: "relative",
                "& button": {
                  position: "absolute",
                  top: "50%",
                  right: "20px",
                  transform: "translateY(-50%)",
                },
              }}
            >
              <InputField
                sx={{ width: "100%" }}
                formik={formik}
                type={showPassword ? "text" : "password"}
                name="password"
                label="Password"
                size="large"
                InputLabelProps={{ shrink: true }}
              />
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Box>
            {loading ? (
              <LoadingBtn height="50px" />
            ) : (
              <Button
                disabled={!(formik.isValid && formik.dirty)}
                variant="solid"
                size="large"
                type="submit"
                sx={{ padding: "25px" }}
              >
                Sign in
              </Button>
            )}
          </form>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <FormControlLabel
              control={<Checkbox size="small" defaultChecked />}
              label="Remember me"
            />
            <Link to="#" style={{ color: "#405189", textDecoration: "none" }}>
              Need help?
            </Link>
          </Box>
        </Box>
      </Box>
      <Typography sx={{ color: "#d5d5d5", mt: "15px" }}>
        {siteInfo?.name} © 2022 | All rights reserved
      </Typography>
    </Box>
  );
};

export default CustomerLogin;
