import { ChatBubbleOutline } from "@mui/icons-material";
import { Badge, Box, IconButton, Menu, MenuItem } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import defaultNotficationSound from "assets/audio/ding.mp3";
import UNIVERSAL from "config";
import useTimeAgo from "hooks/useTimeAgo";
import useWindowSize from "hooks/useWindowSize";
import Axios from "lib/Axios";
import Pusher from "pusher-js";
import { useEffect, useRef, useState } from "react";
import { BiChat } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

let intPage = {
  page: 1,
  current_page: 1,
  last_page: null,
  total: 0,
};

const ClientChatList = () => {
  const { isMini } = useWindowSize();
  let user_role = useSelector((state) => state?.auth?.user_details?.role);

  let user_id = useSelector((state) => state?.auth?.user_details?.user?.id);
  const settings = useSelector((state) => state?.base?.base_data?.settings);
  const divScrollRef = useRef(null);
  const [chatList, setChatList] = useState([]);
  const [countUnread, setCountUnread] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [page, setPage] = useState(intPage);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ================= FETCH LIST

  const fetchChats = (page) =>
    Axios.get(
      `/api/customer/chat/notify/list?page=${page}&limit=15&customer_id=${user_id}`
    ).then((res) => res.data);

  let fetchChatList = async (newNotifiMsg, page) => {
    const result = await fetchChats(page);
    let { data, ...rest } = result?.data?.data;
    if (result.success) {
      if (page === 1) {
        setCountUnread(result?.data?.countChatUnSeen);
        setChatList(data);
        newNotifiMsg && toast.success(newNotifiMsg);
      } else {
        setChatList((prev) => [...prev, ...data]);
      }
      setPage(rest);
    }
  };

  useEffect(() => {
    fetchChatList("", 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ================= PUSHER
  useEffect(() => {
    const pusher = new Pusher(settings?.notification?.pusher_key || "", {
      cluster: settings?.notification?.pusher_cluster || "",
      useTLS: false,
      forceTLS: false,
    });

    try {
      const channel = pusher.subscribe("chatevent");
      channel.bind("chatevent", function (data) {
        const notifyUser = Array.isArray(data?.assignees)
          ? data?.assignees?.includes(user_id)
          : null;
        const { success, message, action } = data;

        const shouldNotify =
          action === "create" && user_role !== "Admin" && notifyUser;
        if (success) {
          fetchChatList(shouldNotify ? message : "", 1);
          if (shouldNotify) {
            audioRef.current.muted = false;
            audioRef.current?.play().catch((e) => {});
            if (divScrollRef.current) {
              divScrollRef.current.scrollTop = 0;
            }
          }
        }
      });
    } catch (error) {}
    return () => {
      pusher.unsubscribe("chatevent");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // ================= AUDIO

  const audioRef = useRef(null);
  useEffect(() => {
    const handleClick = () => {
      audioRef.current.muted = true;
      if (audioRef.current.paused) {
        audioRef.current?.play().catch((e) => {});
      }
    };
    document.addEventListener("click", handleClick, { once: true });
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <>
      {/* ================= ChatList Sound ==========*/}
      <audio ref={audioRef}>
        <source
          src={
            settings?.notification?.notification_sound
              ? UNIVERSAL.BASEURL + settings?.notification?.notification_sound
              : defaultNotficationSound
          }
          type="audio/mpeg"
        />
      </audio>

      <Tooltip title="Conversations">
        <Badge
          badgeContent={countUnread}
          max={99}
          color="red"
          sx={{
            color: "#fff",
            fontWeight: "500",
          }}
          className="ntf-badge"
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <ChatBubbleOutline color="primary" />
          </IconButton>
        </Badge>
      </Tooltip>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 5px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            ml: "14px",
            width: "100%",
            maxWidth: isMini ? "350px" : "450px",
            height: "calc(100vh - 70px)",
          },
        }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px ",
            justifyContent: "space-between",
            p: "5px 15px 10px 20px",
          }}
        >
          <Typography variant="h4">Conversations</Typography>
        </Box>
        <Box
          sx={{
            height: "calc(100vh - 168px)",
            overflow: "auto",
          }}
          id="scrollableDiv"
          ref={divScrollRef}
          className="disable_bar"
        >
          {!!chatList?.length && (
            <InfiniteScroll
              dataLength={chatList?.length}
              next={() => fetchChatList("", page?.current_page + 1)}
              hasMore={page?.current_page === page?.last_page ? false : true}
              loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {chatList?.map((item, index) => (
                <SingleChatItem
                  key={item?.id}
                  item={item}
                  index={index}
                  user_id={user_id}
                  handleClose={handleClose}
                />
              ))}
            </InfiniteScroll>
          )}
        </Box>
      </Menu>
    </>
  );
};

export default ClientChatList;

const SingleChatItem = ({ item, user_id, handleClose }) => {
  const navigate = useNavigate();
  const formatTimeAgo = useTimeAgo();
  return (
    <MenuItem
      disableRipple
      onClick={() => {
        navigate(
          `/client/chat?project_id=${item?.project?.id}&project_name=${item?.project?.name}&related_id=${item?.last_message_details?.related_id}`
        );
        handleClose();
      }}
      sx={{
        ...(!item?.last_message_details?.seenCustomer.includes(user_id) && {
          background: "rgb(153 203 255 / 15%)",
        }),
        display: "grid",
        gridTemplateColumns: "40px auto",
        alignItems: "center",
        gap: "10px",
        px: 2,
        py: 2.5,
        "&:hover": {
          background: "rgb(153 203 255 / 25%)",
        },
      }}
    >
      <Box
        sx={{
          height: "40px",
          width: "40px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "20px",
          color: "#fff",
          bgcolor: "#658CF6",
          borderRadius: "25px",
        }}
      >
        <BiChat />
      </Box>

      <Box>
        <Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Typography
              component="span"
              sx={{
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              {item?.customer?.name || "_unknown"}
            </Typography>
            <Typography
              component="span"
              sx={{
                display: "inline-block",
                backgroundColor: "#c6c1e324",
                borderRadius: "25px",
                padding: "1px 6px",
                whiteSpace: "500",
                fontSize: "12px",
                fontWeight: "400",
                border: "1px solid #cdc6f3",
              }}
            >
              {item?.project?.name}
            </Typography>
          </Box>
          <Typography
            sx={{
              whiteSpace: "break-spaces",
              fontSize: "13px",
              fontWeight: "400",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
            }}
          >
            {item?.last_message_details?.user_id === user_id ? (
              <>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  You:
                </Typography>
                <Typography component="span" sx={{ fontSize: "13px" }}>
                  {" " + item?.last_message_details?.message}
                </Typography>
              </>
            ) : item?.last_message_details?.user_id ? (
              <>
                <Typography
                  component="span"
                  sx={{
                    fontSize: "13px",
                    fontWeight: "500",
                  }}
                >
                  {item?.last_message_details?.user?.name}
                </Typography>
                <Typography component="span" sx={{ fontSize: "13px" }}>
                  {" " + item?.last_message_details?.message}
                </Typography>
              </>
            ) : (
              item?.last_message_details?.message
            )}
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "12px",
            position: "absolute",
            top: "20px",
            right: "15px",
          }}
        >
          {formatTimeAgo(item?.last_message_details?.created_at)?.value}
        </Typography>
      </Box>
    </MenuItem>
  );
};
