import { Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import useFormatter from "hooks/useFormatter";
import useQueries from "hooks/useQueries";
import { MdDelete } from "react-icons/md";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  permissions,
  ...restProps
}) => {
  const { formatDate } = useFormatter();
  const { setQueries } = useQueries();

  return (
    <TableRow
      {...restProps}
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 20px",
        },
      }}
    >
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.format && typeof value === "number" ? (
              column.format(value)
            ) : column.id === "transaction_id" ? (
              value ? (
                value
              ) : (
                "-"
              )
            ) : column.id === "payment_date" || column.id === "created_at" ? (
              formatDate(value)
            ) : column.id === "amount" ? (
              <Typography variant="body2">{row.symbol + value}</Typography>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => setQueries(`id=${row?.id}&m=0`)}
                    disabled={!permissions.update}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    disabled={!permissions.delete}
                  >
                    <MdDelete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
