/* eslint-disable import/no-anonymous-default-export */
export default {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        margin: 0,
        padding: 0,
        fontSize: "14px",
      },
      a: {
        textDecoder: "none",
      },
    },
  },
};
