import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";

const ExpenseTable = ({ customer_id }) => {
  const [allExpense, setAllExpense] = useState([]);
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 30,
    to: 0,
    from: 0,
    total: 0,
  });

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getCustomers = async () => {
      let finalPagination = { ...pagination };
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/expense/list?${params}`,
        data: customer_id ? { customer_id: [customer_id] } : null,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllExpense(allData || []);
        setPagination(rest);
      }
    };
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page]);

  const columns = [
    { id: "expense_number", label: "Expense No." },
    { id: "project_name", label: "Project" },
    { id: "expense_category_name", label: "Category" },
    { id: "payment_method_name", label: "Pay Method" },
    { id: "reference", label: "Reference" },
    { id: "expense_date", label: "Expense Date" },
    { id: "tax", label: "Tax", align: "right" },
    { id: "discount", label: "Discount", align: "right" },
    { id: "total", label: "Total", align: "right" },
  ];

  return (
    <Box sx={{ m: "-20px" }}>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        {!loading ? (
          <TableContainer
            sx={{ height: "calc(100vh - 188px)", overflowX: "auto" }}
          >
            <Table
              stickyHeader
              sx={{
                "& th:first-of-type": { pl: "20px", pr: "0px" },
                "& th:last-child": { pr: "20px" },
                "& td:first-of-type": { pl: "20px", pr: "0px" },
                "& td:last-child": { pr: "20px" },
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, ...column.style }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allExpense?.map((row) => (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : column.id === "expense_date" ? (
                            moment(value).format("ll")
                          ) : column.id === "tax" ? (
                            <Typography color="#2fb42f" variant="body2">
                              {"+ " + row.symbol + value}
                            </Typography>
                          ) : column.id === "discount" ? (
                            <Typography color="#ff3535" variant="body2">
                              {"- " + row.symbol + value}
                            </Typography>
                          ) : column.id === "total" ? (
                            // tempvalue = row.total + row.tax - row.discount,
                            <Typography color="#7171ff" variant="body2">
                              {row.symbol}
                              {value + row.tax - row.discount}
                            </Typography>
                          ) : column.id === "subtotal" ? (
                            <Typography variant="body2">
                              {row.symbol + value}
                            </Typography>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableSkeleton columns={columns} tableHeight="calc(100vh - 188px)" />
        )}
        <TablePagination
          span={columns?.length}
          setReload={setReload}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Paper>
    </Box>
  );
};

export default ExpenseTable;
