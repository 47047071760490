import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { checkin, updateCheckin } from "features/attendance/api/attendence";
import { useFormik } from "formik";
import usePermissions from "hooks/usePermissions";
import { saveAttStatus } from "lib/Reducer/attendanceSlice";
import { setLoading } from "lib/Reducer/loadingSlice";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";

const validationSchema = yup.object({
  date: yup.date().required("Date is required"),
  start_time: yup.string().required("Start time is required"),
  end_time: yup.string().nullable(),
  user_id: yup.string().required("Staff name is required"),
  note: yup.string(),
  attendance: yup.string().required("Attendance status is required"),
  leave_type: yup.string().when("attendance", {
    is: "leave",
    then: yup.string().required("Leave type is required"),
    otherwise: yup.string().nullable(),
  }),
});

let arrayAttendece = ["leave", "present", "absent"];
const Checkin = (props) => {
  const {
    openDrawer,
    setOpenDrawer,
    currAttendence,
    staffs,
    setAllAttendence,
    allAttendence,
  } = props;
  const permissions = usePermissions("attendance");
  const user = useSelector((state) => state?.auth?.user_details?.user);
  const dispatch = useDispatch();
  let att = Object.keys(currAttendence).filter(
    (item) => currAttendence[arrayAttendece.find((c) => c === item)] == 1
  )[0];

  const initialValues = {
    date: currAttendence?.date || moment().format("YYYY-MM-DD"),
    start_time: currAttendence?.start_time || moment().format("HH:mm:ss"),
    end_time: currAttendence?.end_time || null,
    user_id: currAttendence?.user_id || user?.id || "",
    note: currAttendence?.note || "",
    attendance: currAttendence?.attendance || att || "present",
    leave_type: currAttendence?.leave_type || null,
  };

  // const getInitialValues = (data) => {
  //   if (data?.id) {
  //     let obj = {};
  //     for (const key in initialValues) {
  //       obj[key] = data[key];
  //       if (key === "attendance") {
  //         obj[key] = att;
  //       }
  //     }
  //     return obj;
  //   } else return initialValues;
  // };
  const onSubmit = async ({ attendance, ...data }) => {
    let getAttVal = arrayAttendece.find((item) => item === attendance);
    const finalData = {
      ...data,
      ...{ [getAttVal]: true },
      start_time: data?.start_time,
      end_time: data?.end_time,
      leave_type: getAttVal === "leave" ? data?.leave_type : null,
    };
    dispatch(setLoading(true));
    try {
      if (currAttendence?.id) {
        const result = await updateCheckin({
          body_data: finalData,
          id: currAttendence?.id,
        });
        if (result.success) {
          toast.success(result.message);
          setAllAttendence(
            allAttendence.map((att) =>
              att.id === currAttendence.id ? result.data : att
            )
          );
        } else {
          toast.error(result.message);
        }
      } else {
        const { end_time, ...rest } = finalData;
        const result = await checkin({ body_data: rest });
        if (result.success) {
          toast.success(result.message);
          setAllAttendence([result.data, ...allAttendence]);
          if (data?.user_id === user?.id) {
            dispatch(
              saveAttStatus({ status: 2, attendance_id: result?.data?.id })
            );
          }
        } else {
          toast.error(result.message);
        }
      }
    } finally {
      dispatch(setLoading(false));
      setOpenDrawer(false);
    }
  };

  const formik = useFormik({
    // initialValues: getInitialValues(currAttendence),
    initialValues,
    validationSchema,
    onSubmit,
  });

  const isLeave = formik.values?.attendance === "leave";

  return (
    <RightDrawer
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      headingLeft={currAttendence?.id ? "Edit Attendence" : "Check In"}
      headingRight={null}
      width="450px"
    >
      <Box
        sx={{
          p: "15px 20px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <DateTimePicker
          readOnly={!permissions?.isAdmin}
          name="date"
          formik={formik}
          label="Check In Date *"
          type="date" // time
        />
        <DateTimePicker
          readOnly={!permissions?.isAdmin}
          name="start_time"
          formik={formik}
          label="Check In Time *"
          type="time"
        />
        {currAttendence?.id && (
          <DateTimePicker
            readOnly={!permissions?.isAdmin}
            name="end_time"
            formik={formik}
            label="Check Out Time"
            type="time"
          />
        )}
        {permissions?.isAdmin !== 0 && (
          <Select
            readOnly={!permissions?.isAdmin}
            options={staffs || []}
            label="Staff Name *"
            value={formik?.values?.user_id}
            onChange={(value) => formik.setFieldValue("user_id", value)}
            selectProps={{
              id: "user_id",
              label: "name",
            }}
          />
        )}
        <InputField
          name="note"
          label="Note"
          formik={formik}
          multiline
          rows={3}
          sx={{ gridColumn: "span 2" }}
        />
        <Typography variant="body1" sx={{ mb: 0 }}>
          Attendance *
        </Typography>
        <FormControl>
          <RadioGroup
            row
            aria-labelledby="attendance-radio-buttons"
            {...formik.getFieldProps("attendance")}
            error={formik.touched.attendance && formik.errors.attendance}
            helperText={formik.touched.attendance && formik.errors.attendance}
          >
            <FormControlLabel
              value="present"
              control={<Radio sx={{ py: 0 }} />}
              label="Present"
            />
            <FormControlLabel
              value="leave"
              control={<Radio sx={{ py: 0 }} />}
              label="Leave"
            />

            <FormControlLabel
              value="absent"
              control={<Radio sx={{ py: 0 }} />}
              label="Absent"
            />
          </RadioGroup>
        </FormControl>

        {/* sick,casual */}
        {!!permissions?.isAdmin && !!isLeave && (
          <Select
            options={[
              { id: "sick", label: "Sick" },
              { id: "casual", label: "Casual" },
            ]}
            label="Leave Type *"
            name="leave_type"
            formik={formik}
            sx={{ mt: "6px" }}
          />
        )}

        <Button
          fullWidth
          onClick={formik.submitForm}
          disabled={!permissions?.checkin || !(formik.dirty && formik.isValid)}
          variant="solid"
        >
          {currAttendence?.id ? "Update Attendence" : "Check In"}
        </Button>
      </Box>
    </RightDrawer>
  );
};

export default Checkin;
