const handleError = (res, formik) => {
  if (res.code === 422 && formik) {
    Object.keys(res.errors).forEach((fieldName) => {
      let nestedName;
      if (fieldName.includes(".")) {
        const splitedName = fieldName.split(".");
        splitedName.map((sn) => {
          if (isNaN(Number(sn))) {
            nestedName = nestedName ? nestedName + "." + sn : sn;
          } else {
            nestedName = nestedName ? nestedName + `[${Number(sn)}]` : sn;
          }
          return "";
        });
      }

      const errorMessage = res?.errors?.[fieldName].join(", ");
      let pattern = /\..+\./;
      let nestedMessage;
      if (pattern.test(errorMessage)) {
        const splitedName = errorMessage.split(" ");
        splitedName.map((sn) => {
          if (pattern.test(sn)) {
            nestedMessage = nestedMessage
              ? nestedMessage + " " + sn.split(".")[sn.split(".").length - 1]
              : sn;
          } else {
            nestedMessage = nestedMessage ? nestedMessage + " " + sn : sn;
          }
          return "";
        });
      }
      formik.setFieldError(
        fieldName.includes(".") ? nestedName : fieldName,
        pattern.test(errorMessage)
          ? nestedMessage?.replace("id", "")
          : errorMessage?.replace("id", "")
      );
    });
  } else if (res.code === 422) {
    return Object.keys(res.errors).map((fieldName) => {
      const errorMessage = res?.errors?.[fieldName].join(", ");
      return errorMessage;
    });
  } else {
    return [res.message];
  }
};

export default handleError;
