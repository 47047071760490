import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import RightDrawer from "components/rightDrawer/RightDrawer";
import Select from "components/ui/select";
import { exportProjectCustomer } from "features/projectCustomers/api/projectCustomer";
import { useFormik } from "formik";
import * as yup from "yup";

const Export = ({ openDrawer, setOpenDrawer, filters, search }) => {
  const initialValues = {
    type: "xlsx",
  };

  const validationSchema = yup.object({
    type: yup.string().required("Export type is required!"),
  });

  const onSubmit = async (data) => {
    const res = await exportProjectCustomer({
      body_data: { ...data, ...filters, search, trash_export: true },
    });
    if (res.success) window.open(res?.data?.file);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "420px",
        headingLeft: "Export Lead",
        headingRight: "",
      }}
    >
      <Stack gap="20px" p="20px">
        <Select
          label="Select File Type"
          name="type"
          options={[
            { id: "xlsx", label: "XLSX" },
            { id: "csv", label: "CSV" },
          ]}
          formik={formik}
        />
        <Box>
          <Button
            fullWidth
            onClick={formik.submitForm}
            disabled={!formik.isValid}
            variant="solid"
          >
            Export
          </Button>
        </Box>
      </Stack>
    </RightDrawer>
  );
};

export default Export;
