import { DownloadOutlined } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import DateRangeField from "components/ui/dateRangeField";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { useState } from "react";
import * as yup from "yup";

const getInitialvalues = (filters) => {
  return {
    date_from: filters.date_from || null,
    date_to: filters.date_to || null,
  };
};

const TimesheetHeader = ({
  setReload,
  setFilters,
  setPagination,
  pagination,
  filters,
  isFiltering,
  id,
}) => {
  const [reset, setReset] = useState(false);
  const initialValues = getInitialvalues(filters);

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    setFilters({ ...filters, ...data });
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const resetForm = () => {
    const emptyFilter = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = null;
      }
    }
    formik.setValues(emptyFilter);
    setFilters({});
    setReload((pre) => !pre);
    setReset((pre) => !pre);
  };

  const { fetchData } = useApi();

  const handleExportTimesheet = async () => {
    const data = {
      type: "xlsx",
      ...filters,
      trash_export: false,
    };
    const endpoint = {
      method: "post",
      url: `/api/admin/staff/timesheet/export/${id}`,
      data: data,
    };
    const res = await fetchData(endpoint);
    if (res.success) window.open(res?.data?.file);
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "500px 1fr",
        justifyContent: "space-between",
      }}
      p="15px"
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 110px 110px",
          gap: "10px",
        }}
      >
        <DateRangeField
          label="Created Date"
          from="date_from"
          to="date_to"
          formik={formik}
          key={`${reset}`}
        />
        <Button
          variant="solid"
          onClick={formik.submitForm}
          disabled={!formik.dirty}
        >
          Filter
        </Button>
        <Button
          variant="outlined"
          disabled={!isFiltering()}
          onClick={resetForm}
        >
          Reset Filter
        </Button>
      </Box>
      <Box ml="auto">
        <Button
          variant="outlined"
          startIcon={<DownloadOutlined />}
          onClick={() => handleExportTimesheet()}
        >
          Export
        </Button>
      </Box>
    </Box>
  );
};

export default TimesheetHeader;
