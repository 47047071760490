import assets from "assets";
import UNIVERSAL from "config";

const getFileIcon = ({ extension, file, isFileType }) => {
  switch (extension) {
    case "pdf":
      return assets.fipPdf;
    case "doc":
    case "docx":
      return assets.fipDoc;
    case "xls":
    case "xlsx":
      return assets.fipXls;
    case "jpg":
    case "jpeg":
    case "png":
    case "gif":
      return isFileType ? assets.fipImg : UNIVERSAL.BASEURL + file;
    case "mp3":
      return assets.fipMp3;
    case "ppt":
    case "pptx":
      return assets.fipPpt;
    case "avi":
    case "mp4":
    case "mov":
      return assets.fipVideo;
    case "html":
      return assets.fipHtml;
    case "apk":
      return assets.fipApk;
    default:
      return assets.fipUnknown;
  }
};

export default getFileIcon;
