import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const RangeSlider = ({ formik, from, to, minmax, type }) => {
  const handleChange = (event, newValue) => {
    formik.setFieldValue(from, newValue[0]);
    formik.setFieldValue(to, newValue[1]);
  };
  const marks = [
    {
      value: minmax[0],
      label: `${type} ${formik.values[from] || 0}`,
    },
    {
      value: minmax[1],
      label: `${type} ${formik.values[to] || 0}`,
    },
  ];

  return (
    <Box sx={{ margin: "0px 10px" }}>
      <Slider
        sx={{
          '& span[data-index="0"].MuiSlider-markLabel': {
            transform: "none",
            left: "0%!important",
          },
          '& span[data-index="1"].MuiSlider-markLabel': {
            transform: "none",
            left: "unset!important",
            right: "0!important",
          },
        }}
        value={[formik.values[from], formik.values[to]]}
        onChange={handleChange}
        valueLabelDisplay="auto"
        marks={marks}
        step={0.5}
        min={minmax[0]}
        max={minmax[1]}
        disableSwap
      />
    </Box>
  );
};

export default RangeSlider;
