import { DeleteOutline, DownloadOutlined } from "@mui/icons-material";
import { Box, Button, MenuItem, TextField, Typography } from "@mui/material";
import Alert from "components/alert";
import MultiSelect from "components/ui/multiSelect";
import { useFormik } from "formik";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { invoiceBulkAction } from "../api/invoices";

const BulkActions = ({
  selectedRows,
  setSelectedRows,
  setReload,
  sources,
  assignees,
}) => {
  const [alert, setAlert] = useState({ status: false, data: null });

  const dispatch = useDispatch();
  const handleBulkAction = async (type, data) => {
    setAlert({ status: false, data: null });
    dispatch(setLoading(true));
    const obj = { selected_ids: selectedRows, action: type };
    if (type === "export") obj.trash_export = false;
    obj[type] = data;
    try {
      const result = await invoiceBulkAction({ body_data: obj });
      if (result?.data?.file) window.open(result?.data?.file);
    } finally {
      dispatch(setLoading(false));
      setReload((pre) => !pre);
    }
  };

  const initialValues = {
    assignee: [],
  };

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    handleBulkAction("assignee", data.assignee);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const statuses = ["paid", "unpaid", "hold", "partial", "inactive"];

  return (
    <Box
      p="10px 20px 10px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "18px",
        backgroundColor: "#e3eefa",
      }}
    >
      {alert?.status && (
        <Alert
          open={alert?.status}
          handleClose={() => setAlert({ status: false, data: null })}
          title="Delete Selected Invoice"
          desc="Are you sure you want to delete? Don't worry you will find these invoices on Trash Menu"
          buttons={
            <>
              <Button
                onClick={() => {
                  setAlert({ status: false, data: null });
                  setSelectedRows([]);
                }}
              >
                No, keep it
              </Button>
              <Button
                variant="solid"
                onClick={() => handleBulkAction(alert.data)}
              >
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Typography variant="h5">{selectedRows?.length} Selected</Typography>
      <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
        <TextField
          sx={{ width: "180px" }}
          size="small"
          label="Change status"
          select
          onChange={(e) => handleBulkAction("status", e.target.value)}
        >
          {statuses?.length ? (
            statuses?.map((status) => (
              <MenuItem value={status}>{status}</MenuItem>
            ))
          ) : (
            <MenuItem disabled>No status available</MenuItem>
          )}
        </TextField>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "260px",
            position: "relative",
            "& button": { position: "absolute", right: "0", top: "0" },
          }}
        >
          <MultiSelect
            options={assignees}
            label="Assignees"
            value={formik?.values?.assignee}
            onChange={(value) => formik.setFieldValue("assignee", value)}
            selectProps={{
              id: "user_id",
              label: "name",
            }}
          />
          <Button
            variant="solid"
            sx={{ borderRadius: "none", marginTop: "1px" }}
            onClick={formik.submitForm}
          >
            Add
          </Button>
        </Box>
        <Button
          variant="solid"
          onClick={() => setAlert({ status: true, data: "trash" })}
          startIcon={<DeleteOutline />}
        >
          Delete
        </Button>
        <Button
          variant="solid"
          onClick={() => handleBulkAction("export")}
          startIcon={<DownloadOutlined />}
        >
          Export
        </Button>
      </Box>
    </Box>
  );
};

export default BulkActions;
