import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApi from "hooks/useApi";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AddEdit from "./AddEdit";
import Filter from "./Filter";
import NotesHeader from "./NotesHeader";
import MainTable from "./table/MainTable";

const Notes = () => {
  const [openSidebar, setOpenSidebar] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState();
  const { base_data } = useSelector((state) => state.base);
  const [currNote, setCurrNote] = useState({});
  const [allNote, setAllNote] = useState([]);
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  useEffect(() => {
    if (openSidebar === false) {
      setCurrNote({});
    }
  }, [openSidebar]);

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getNotes = async () => {
      let finalFilters = { ...filters };
      let finalPagination = { ...pagination };
      finalFilters.search = search;
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/note/list?${params}`,
        data: finalFilters,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllNote(allData || []);
        setPagination(rest);
      }
    };
    getNotes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, search]);

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    const endpoint = {
      method: "delete",
      url: `/api/admin/note/delete/${openAlert}`,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      setTimeout(() => {
        setReload((pre) => !pre);
      }, 2000);
    }
  };

  const handleEdit = (value) => {
    setCurrNote(value);
    setOpenSidebar(true);
  };

  const permissions = usePermissions("note");

  const all_props = {
    ...base_data,
    setReload,
    filters,
    setFilters,
    isFiltering,
    pagination,
    setPagination,
    allNote,
    setAllNote,
    permissions,
  };

  return (
    <Box>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Note"
          desc={
            <Box>
              Are you sure you want to delete this Note? This will delete the
              Note permanently! <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <NotesHeader
        {...{
          setOpenSidebar,
          allNote,
          setSearch,
          setPagination,
          pagination,
          isFiltering,
          ...all_props,
        }}
      />
      {!!(permissions["list(own)"] || permissions["list(global)"]) && (
        <Filter {...all_props} />
      )}
      {openSidebar && (
        <AddEdit
          {...{
            openDrawer: openSidebar,
            setOpenDrawer: setOpenSidebar,
            setReload,
            setAllNote,
            allNote,
            currNote,
            ...all_props,
          }}
        />
      )}
      {permissions["list(own)"] || permissions["list(global)"] ? (
        <MainTable
          allNote={allNote}
          handleDelete={handleDelete}
          handleEdit={handleEdit}
          pagination={pagination}
          setPagination={setPagination}
          loading={loading}
          setReload={setReload}
          {...all_props}
        />
      ) : (
        <NotAuthorized />
      )}
    </Box>
  );
};

export default Notes;
