import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import moment from "moment";

const TimesheetTable = ({
  loading,
  timesheetHistory,
  setReload,
  pagination,
  setPagination,
}) => {
  const columns = [
    { id: "created_at", label: "Date" },
    { id: "name", label: "Name" },
    { id: "email", label: "Email" },
    { id: "phone", label: "Phone" },
    { id: "ip_address", label: "IP Address" },
    { id: "type", label: "Type" },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 255px)", overflowX: "auto" }}
        >
          <Table
            stickyHeader
            sx={{
              "& th:first-of-type": { pl: "20px", pr: "0px" },
              "& th:last-child": { pr: "20px" },
              "& td:first-of-type": { pl: "20px", pr: "0px" },
              "& td:last-child": { pr: "20px" },
            }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {timesheetHistory?.map((row) => {
                return (
                  <TableRow hover role="checkbox" key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={column.style}
                        >
                          {column.id === "created_at"
                            ? moment(value).format("lll")
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 255px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Paper>
  );
};

export default TimesheetTable;
