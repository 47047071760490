import { Box, Button } from "@mui/material";
import useApiCall from "hooks/useApiCall";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { viewAllExpectedSales } from "../../../api/sales";
import AddEditInstallment from "./AddEditInstallment";
import InstallmentTable from "./InstallmentTable";
import Payments from "./Payments";
import ViewInstallment from "./ViewInstallment";

const Installment = ({ id, assignees, profile, setReloadMain, symbol }) => {
  const [addPayment, setAddPayment] = useState(false);
  const [currPayment, setCurrPayment] = useState({});
  const [reload, setReload] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [viewPayment, setViewPayment] = useState(false);

  const [allPayment, setAllPayment] = useState([]);
  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    fetchListData(
      viewAllExpectedSales,
      {
        id: profile?.id,
      },
      false
    );
  }, [reload]);

  useEffect(() => {
    if (data) {
      setAllPayment(data);
    }
  }, [data]);

  return addPayment ? (
    <AddEditInstallment
      setReloadMain={setReloadMain}
      profile={profile}
      setAddPayment={setAddPayment}
      id={id}
      assignees={assignees}
      setReload={setReload}
      currPayment={currPayment}
    />
  ) : makePayment ? (
    <Payments
      id={profile?.id}
      setMakePayment={setMakePayment}
      currPayment={currPayment}
      setReloadMain={setReloadMain}
      setReload={setReload}
    />
  ) : viewPayment ? (
    <ViewInstallment
      profile={profile}
      currPayment={currPayment}
      setViewPayment={setViewPayment}
      symbol={symbol}
    />
  ) : (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Button
          startIcon={<BiPlus />}
          variant="outlined"
          onClick={() => {
            setCurrPayment({});
            setAddPayment(true);
          }}
        >
          Add Installment
        </Button>
      </Box>
      <InstallmentTable
        profile={profile}
        symbol={symbol}
        loading={loading}
        setReloadMain={setReloadMain}
        setReload={setReload}
        allPayment={allPayment}
        setAddPayment={setAddPayment}
        setCurrPayment={setCurrPayment}
        setAllPayment={setAllPayment}
        setMakePayment={setMakePayment}
        setViewPayment={setViewPayment}
      />
    </Box>
  );
};

export default Installment;
