import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import MainTableRow from "./MainTableRow";

const MainTable = ({
  handleDelete,
  pagination,
  setPagination,
  loading,
  setReload,
  allPost,
  setAllPost,
  handleEdit,
  permissions,
  handleTableCheckBox,
  selectedRows,
  handleView,
  handleRestore,
}) => {
  const rows = allPost;

  const columns = [
    { id: "name", label: "Title" },
    {
      id: "description",
      label: "Description",
      style: {
        width: "190px",
      },
    },
    {
      id: "knowledge_base_category_name",
      label: "Category",
      minWidth: 150,
    },
    { id: "status", label: "Status" },
    {
      id: "created_by_image",
      label: "Created By",
      style: {
        width: 190,
      },
    },
    { id: "created_at", label: "Created At" },

    { id: "action", label: "Actions", minWidth: 70, align: "center" },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 265px)", overflowX: "auto" }}
        >
          <Table
            stickyHeader
            sx={{
              "& th:first-of-type": { pl: "20px", pr: "0px" },
              "& th:last-child": { pr: "20px" },
              "& td:first-of-type": { pl: "20px", pr: "0px" },
              "& td:last-child": { pr: "20px" },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    // disabled={!permissions["bulk action"]}
                    checked={
                      selectedRows?.length === allPost?.length &&
                      allPost?.length
                    }
                    onChange={(e) =>
                      handleTableCheckBox("all", e.target.checked)
                    }
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <MainTableRow
                  key={row.id}
                  row={row}
                  columns={columns}
                  span={columns?.length}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  handleView={handleView}
                  setReload={setReload}
                  allPost={allPost}
                  handleTableCheckBox={handleTableCheckBox}
                  selectedRows={selectedRows}
                  handleRestore={handleRestore}
                  setAllPost={setAllPost}
                  permissions={permissions}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 210px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Paper>
  );
};

export default MainTable;
