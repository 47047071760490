import { FormControlLabel, Switch } from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import * as yup from "yup";

const SponsorJobs = () => {
  const initialValues = {
    job_name: "",
    status: 1,
  };
  return (
    <SetupCC
      name="Sponsor Profession"
      baseRouteKey="PCSponsorJobs"
      moduleKey="project-customer-sponsor-job"
      columns={[
        { id: "job_name", label: "Profession Name", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "status" ? (
              row["status"] ? (
                <span>Active</span>
              ) : (
                <span>Inactive</span>
              )
            ) : (
              value
            )}
          </>
        );
      }}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        job_name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="job_name" label="Profession Name" formik={formik} />
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  formik.setFieldValue("status", e.target.checked ? 1 : 0);
                }}
                checked={Number(formik?.values?.status) ? true : false}
              />
            }
            label="Active"
          />
        </>
      )}
    ></SetupCC>
  );
};

export default SponsorJobs;
