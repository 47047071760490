import { Delete, Download, Edit, InfoOutlined } from "@mui/icons-material";
import { Box, Divider, ListItemIcon, Menu, MenuItem } from "@mui/material";
import UNIVERSAL from "config";
import React, { useEffect, useRef, useState } from "react";
import { BiCopy } from "react-icons/bi";
import { toast } from "react-toastify";

const ContextMenu = ({
  setOpenRename,
  children,
  att,
  setAttDetails,
  attDetails,
  handleDelete,
}) => {
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const clickedElementRef = useRef(null);
  const [preAnchor, setPreAnchor] = useState(null);

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (preAnchor === e.currentTarget) {
      setPreAnchor(null);
      setIsContextMenuOpen(false);
      setAnchorPosition({ top: 0, left: 0 });
    } else {
      setPreAnchor(e.currentTarget);
      setAnchorPosition({ top: e.clientY, left: e.clientX });
      setIsContextMenuOpen(true);
      clickedElementRef.current = e.currentTarget;
    }
  };

  const handleMenuItemClick = (e, type) => {
    e.stopPropagation();
    if (type === "download") {
      window.open(UNIVERSAL.BASEURL + att.file);
    } else if (type === "copy") {
      navigator.clipboard.writeText(UNIVERSAL.BASEURL + att.file).then(() => {
        toast.success("File link copied!");
      });
    } else if (type === "info") {
      setAttDetails(att);
    } else if (type === "rename") {
      setOpenRename(att);
    } else if (type === "delete") {
      handleDelete(att.id);
    }
    setIsContextMenuOpen(false);
    setPreAnchor(null);
  };

  useEffect(() => {
    const handleDocumentClick = (e) => {
      if (!clickedElementRef.current.contains(e.target)) {
        setIsContextMenuOpen(false);
        setPreAnchor(null);
      }
    };

    if (isContextMenuOpen) {
      document.addEventListener("click", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, [isContextMenuOpen]);

  const handleClose = () => {
    setIsContextMenuOpen(false);
    setPreAnchor(null);
  };

  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      handleContextMenu,
      isContextMenuOpen,
      att,
      attDetails,
    })
  );

  return (
    <Box
      onContextMenu={handleContextMenu}
      style={{ position: "relative" }}
      onClick={() =>
        !isContextMenuOpen &&
        setAttDetails((pre) => (pre && pre.id === att.id ? null : att))
      }
    >
      {childrenWithProps}
      <Menu
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        open={isContextMenuOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={(e) => handleMenuItemClick(e, "download")}>
          <ListItemIcon>
            <Download fontSize="small" />
          </ListItemIcon>
          Download
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => handleMenuItemClick(e, "rename")}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          Rename
        </MenuItem>
        <MenuItem onClick={(e) => handleMenuItemClick(e, "copy")}>
          <ListItemIcon>
            <BiCopy fontSize="19px" />
          </ListItemIcon>
          Copy Link
        </MenuItem>
        <MenuItem onClick={(e) => handleMenuItemClick(e, "info")}>
          <ListItemIcon>
            <InfoOutlined fontSize="small" />
          </ListItemIcon>
          File Information
        </MenuItem>
        <Divider />
        <MenuItem onClick={(e) => handleMenuItemClick(e, "delete")}>
          <ListItemIcon>
            <Delete fontSize="small" />
          </ListItemIcon>
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default ContextMenu;
