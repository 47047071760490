import { Send } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import Editor from "components/ui/editor/Editor";
import InputField from "components/ui/inputField";
import LoadingBtn from "components/ui/loadingBtn";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import { BiLeftArrowAlt } from "react-icons/bi";
import { toast } from "react-toastify";
import * as yup from "yup";

const AddEmail = ({
  setAddEmail,
  email_addresses,
  related_to,
  setReload,
  related_id,
}) => {
  const { base } = useBase();
  const { emailTemplateLists } = base;
  const { loading, fetchData } = useApi();

  const initialValues = {
    emails: email_addresses || [],
    subject: "",
    message: emailTemplateLists?.find((et) => et.is_default === 1)?.body || "",
    template_id:
      emailTemplateLists?.find((et) => et.is_default === 1)?.id || "",
    related_to,
    related_id,
  };

  const validationSchema = yup.object({
    subject: yup.string("").required("Subject is Required").nullable(),
    message: yup.string("").required("").nullable(),
  });

  const onSubmit = async (data) => {
    const endpoint = {
      method: "post",
      url: "/api/admin/email/send",
      data: data,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      setAddEmail(false);
      setReload((pre) => !pre);
    } else {
      toast.error(result?.message);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box p="15px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddEmail(false)}
        >
          View Emails
        </Button>
        <Box sx={{ width: "230px" }}>
          <Select
            options={emailTemplateLists}
            label={"Use Template"}
            value={formik?.values?.template_id}
            onChange={(value) => {
              formik.setFieldValue("template_id", value);
              formik.setFieldValue(
                "message",
                value
                  ? emailTemplateLists.find((item) => item.id === value).body
                  : ""
              );
            }}
            selectProps={{ id: "id", label: "title" }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          pt: "20px",
        }}
      >
        <InputField
          label="Email Address"
          formik={formik}
          name="emails"
          disabled
        />
        <InputField label="Subject" formik={formik} name="subject" autoFocus />
        <Editor formik={formik} name="message" />
        {loading ? (
          <LoadingBtn />
        ) : (
          <Button
            variant="solid"
            onClick={formik.submitForm}
            fullWidth
            disabled={!(formik.dirty && formik.isValid)}
            startIcon={<Send />}
          >
            Send
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AddEmail;
