import {
  Add,
  DownloadOutlined,
  FileUploadOutlined,
  FilterAltOutlined,
  TimelineOutlined,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Drawer, Grid, IconButton } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import useDidMountEffect from "hooks/useDidMountEffect";
import useQueries from "hooks/useQueries";
import useWindowSize from "hooks/useWindowSize";
import { useState } from "react";
import { VscSettings } from "react-icons/vsc";

const Header = ({
  activeMenu,
  setActiveMenu,
  setOpenFilter,
  setOpenImportLead,
  setSearch,
  setPagination,
  pagination,
  permissions,
  isFiltering,
  search,
  filters,
}) => {
  const { setQueries } = useQueries();
  const { isMini } = useWindowSize();
  const { loading, fetchData } = useApi();
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useDidMountEffect(() => {
    setSearch(debouncedText);
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  const handleExport = async () => {
    const res = await fetchData(
      {
        method: "post",
        url: "/api/admin/lead/export",
        data: {
          type: "xlsx",
          trash_export: false,
          search,
          ...filters,
        },
      },
      false
    );
    if (res.success) window.open(res?.data?.file);
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        {!!(permissions["list(own)"] || permissions["list(global)"]) && (
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in table.."}
          />
        )}
      </Grid>
      {!isMini ? (
        <Box sx={{ display: "flex", gap: "10px" }}>
          <Button
            variant={activeMenu === "summary" ? "solid" : "outlined"}
            startIcon={<TimelineOutlined />}
            onClick={() => {
              setActiveMenu(activeMenu === "summary" ? "" : "summary");
              toggleDrawer(false);
            }}
            disabled={!permissions?.summery}
          >
            Summary
          </Button>
          <Button
            variant={isFiltering ? "solid" : "outlined"}
            startIcon={<FilterAltOutlined />}
            onClick={() => setOpenFilter(true)}
            disabled={
              !(permissions["list(own)"] || permissions["list(global)"])
            }
          >
            Advanced Filters
          </Button>
          <LoadingButton
            loading={loading}
            loadingPosition="start"
            variant="outlined"
            startIcon={<DownloadOutlined />}
            onClick={handleExport}
            disabled={!permissions.export}
          >
            Export
          </LoadingButton>
          <Button
            variant="outlined"
            startIcon={<FileUploadOutlined />}
            onClick={() => setOpenImportLead(true)}
            disabled={!permissions.import}
          >
            Import
          </Button>
          <Button
            variant="outlined"
            startIcon={<Add />}
            onClick={() => setQueries("m=0")}
            disabled={!permissions.create}
          >
            New Lead
          </Button>
        </Box>
      ) : (
        <Box>
          <IconButton onClick={toggleDrawer(true)}>
            <VscSettings />
          </IconButton>
          <Drawer
            anchor="bottom"
            open={openDrawer}
            onClose={toggleDrawer(false)}
          >
            <Box sx={{ width: "auto" }}>
              <Box
                sx={{
                  padding: "20px",
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                  flexDirection: "column",
                  gap: "15px",
                }}
                role="presentation"
                onClick={toggleDrawer(false)}
                onKeyDown={toggleDrawer(false)}
              >
                <Button
                  variant="outlined"
                  startIcon={<Add />}
                  onClick={() => setQueries("m=0")}
                  disabled={!permissions.create}
                >
                  New Lead
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<FileUploadOutlined />}
                  onClick={() => setOpenImportLead(true)}
                  disabled={!permissions.import}
                >
                  Import
                </Button>
                <LoadingButton
                  loading={loading}
                  loadingPosition="start"
                  variant="outlined"
                  startIcon={<DownloadOutlined />}
                  onClick={handleExport}
                  disabled={!permissions.export}
                >
                  Export
                </LoadingButton>
                <Button
                  variant={isFiltering ? "solid" : "outlined"}
                  startIcon={<FilterAltOutlined />}
                  onClick={() => setOpenFilter(true)}
                  disabled={
                    !(permissions["list(own)"] || permissions["list(global)"])
                  }
                >
                  Filters
                </Button>
                <Button
                  variant={activeMenu === "summary" ? "solid" : "outlined"}
                  startIcon={<TimelineOutlined />}
                  onClick={() =>
                    setActiveMenu(activeMenu === "summary" ? "" : "summary")
                  }
                  disabled={!permissions?.summery}
                >
                  Summary
                </Button>
              </Box>
            </Box>
          </Drawer>
        </Box>
      )}
    </Grid>
  );
};

export default Header;
