import { Box } from "@mui/material";
import NotAuthorized from "components/notAuthorized";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProjectTable from "./ProjectTable";

const CustomerProject = () => {
  const permissions = usePermissions("project");
  const id = useSelector((state) => state.auth.user_details?.user?.id);
  const [allProject, setAllProject] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  const debouncedText = useDebounce(searchText, 1000);
  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getCustomerProject = async () => {
      let finalPagination = { ...pagination };
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}&search=${debouncedText}`;
      const endpoint = {
        method: "post",
        url: `/api/customer/project/list?${params}`,
        data: { customer_id: [id] },
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllProject(allData);
        setPagination(rest);
      }
    };
    getCustomerProject();
  }, [reload, pagination?.current_page, pagination?.per_page, debouncedText]);

  return (
    <Box pt="10px">
      {permissions.list ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: "10px",
            }}
          >
            <SearchBox
              handleSearch={(text) => setSearchText(text)}
              placeholder={"Search in Project.."}
            />
          </Box>
          <ProjectTable
            loading={loading}
            allProject={allProject}
            pagination={pagination}
            setPagination={setPagination}
            setReload={setReload}
            setAllProject={setAllProject}
          />
        </Box>
      ) : (
        <NotAuthorized />
      )}
    </Box>
  );
};

export default CustomerProject;
