import { Delete, UploadFileSharp } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import { useEffect, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { FcFile } from "react-icons/fc";
import * as yup from "yup";

const MakePayment = ({ setReload, currInvoice, setMakePayment }) => {
  const { base, updateBase } = useBase();
  const { invoices, paymentMethods, currencies, settings, banks } = base;
  const currency_id = settings?.app?.currency_id;

  useEffect(() => {
    updateBase(["banks"]);
  }, []);

  const [attachment, setAttachment] = useState(null);
  const { fetchData, loading } = useApi();

  const onSubmit = async (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    if (attachment) {
      formData.append("file", attachment);
    }
    const endpoint = {
      method: "post",
      url: "/api/admin/payment/create",
      data: formData,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      setMakePayment(false);
      setReload((pre) => !pre);
    }
  };

  const initialValues = {
    invoice_id: currInvoice?.id,
    payment_method_id: "",
    payment_date: null,
    currency_id: currency_id || "",
    amount: "",
    transaction_id: "",
    note: "",
    bank_id: "",
  };

  const validationSchema = yup.object({
    // customer_id: yup.string("").required("Customer is Required").nullable(),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleUplodFile = (files) => {
    setAttachment(files[0]);
  };

  return (
    <Box sx={{ m: "-10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setMakePayment(false)}
        >
          View Invoices
        </Button>
        <Button
          component="label"
          startIcon={<UploadFileSharp />}
          variant="outlined"
        >
          <input
            type="file"
            hidden
            onChange={(e) => handleUplodFile(e.target.files)}
          />
          Attach Files
        </Button>
      </Box>
      <Box py="20px">
        {attachment && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "50px auto 100px",
              alignItems: "center",
              borderBottom: "1px solid #ddd",
              pb: "10px",
              mb: "15px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FcFile style={{ fontSize: "40px" }} />
            </Box>
            <Box>
              <Typography variant="h6">{attachment?.name}</Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {(attachment?.size / 1024).toFixed(0)}KB
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
              }}
            >
              <IconButton onClick={() => setAttachment(null)}>
                <Delete />
              </IconButton>
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "15px",
            pb: "15px",
          }}
        >
          <Select
            disabled
            options={invoices}
            label="Invoice"
            value={formik?.values?.invoice_id}
            onChange={(value) => formik.setFieldValue("invoice_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />

          <InputField
            label="Transaction ID"
            name="transaction_id"
            formik={formik}
          />
          <DateTimePicker
            name="payment_date"
            label="Payment Date *"
            type="date" // time
            formik={formik}
          />
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "15px",
            }}
          >
            <Select
              options={paymentMethods}
              label="Pay Method"
              value={formik?.values?.payment_method_id}
              onChange={(value) =>
                formik.setFieldValue("payment_method_id", value)
              }
              selectProps={{ id: "id", label: "name" }}
            />
            <Select
              options={banks}
              label="Banks"
              value={formik?.values?.bank_id}
              onChange={(value) => formik.setFieldValue("bank_id", value)}
              selectProps={{ id: "id", label: "name" }}
            />
          </Box>
          <Select
            options={currencies}
            label="Currency *"
            value={formik?.values?.currency_id}
            onChange={(value) => formik.setFieldValue("currency_id", value)}
            selectProps={{ id: "id", label: "name" }}
          />
          <InputField label="Amount" name="amount" formik={formik} />
          <InputField
            sx={{ gridColumn: "span 2" }}
            name="note"
            label="Note"
            multiline
            rows={2}
            formik={formik}
          />
          <Button
            sx={{ gridColumn: "span 2" }}
            variant="solid"
            onClick={formik.submitForm}
            fullWidth
            disabled={!(formik.dirty && formik.isValid) || loading}
          >
            Save Payment
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MakePayment;
