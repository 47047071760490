import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

export function removeTagsAndWhitespace(html) {
  var cleanr = /<\/?[^>]+>|\s+/gi;
  var plainText = html.replace(cleanr, " ");
  return plainText.trim();
}

const MainTableRow = ({ row, columns, handleDelete, handleEdit }) => {
  return (
    <TableRow hover role="checkbox" key={row.id}>
      {columns.map((column, i) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.id === "body" ? (
              <Typography
                className={"clamped-text"}
                variant="body2"
                sx={{ width: "150px" }}
                dangerouslySetInnerHTML={{
                  __html: removeTagsAndWhitespace(value),
                }}
              ></Typography>
            ) : column.id === "is_default" ? (
              value === 1 ? (
                "Default"
              ) : (
                ""
              )
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Edit">
                  <IconButton onClick={() => handleEdit(row)}>
                    <Edit />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(row.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
