import Axios from "lib/Axios";

export const knowledgeBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/knowledge/bulk/action", body_data).then(
    (res) => res.data
  );
export const updateKnowledgeStatus = ({ body_data }) =>
  Axios.post("/api/admin/knowledge/status", body_data).then((res) => res.data);

export const addComment = ({ body_data }) =>
  Axios.post("/api/knowledge/comment/add", body_data).then((res) => res.data);


export const deleteKnowledge = ({ id }) =>
  Axios.delete(`/api/admin/knowledge/trash/${id}`).then((res) => res.data);

export const restoreKnowledge = ({ id }) =>
  Axios.get(`/api/admin/knowledge/restore/${id}`).then((res) => res.data);
