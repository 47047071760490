import { Box, Button } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import * as yup from "yup";

const AddEdit = (props) => {
  const {
    openDrawer,
    setOpenDrawer,
    setAllNote,
    allNote,
    currNote,
    relatedToForNotes,
  } = props;
  const [relatedToData, setRelatedToData] = useState([]);

  const initialValues = {
    related_to: currNote?.related_to || "",
    related_id: currNote?.related_id || "",
    note: currNote?.note || "",
  };

  const validationSchema = yup.object({
    related_to: yup.string("").required("This field is Required").nullable(),
    related_id: yup.string("").required("This field is Required").nullable(),
    note: yup.string("").required("Note is Required").nullable(),
  });

  const { loading, fetchData } = useApi();
  const onSubmit = async (data) => {
    let endpoint;
    if (currNote?.id) {
      endpoint = {
        method: "post",
        url: `/api/admin/note/update/${currNote.id}`,
        data,
      };
    } else {
      endpoint = {
        method: "post",
        url: "/api/admin/note/create",
        data,
      };
    }
    const result = await fetchData(endpoint);
    if (result.success) {
      if (currNote?.id) {
        setAllNote(
          allNote.map((note) => (note.id === currNote.id ? result.data : note))
        );
      } else {
        setAllNote([result?.data, ...allNote]);
      }
      setOpenDrawer(false);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const fetchRelatedToData = async (relatedTo) => {
    const endpoint = {
      method: "get",
      url: `/api/admin/base/related-module-data/${relatedTo}`,
    };
    const result = await fetchData(endpoint, false);
    if (result.success) setRelatedToData(result.data);
  };

  useEffect(() => {
    if (currNote.id) {
      fetchRelatedToData(formik.values.related_to);
    }
  }, []);

  const convertToObjectOptions = (options) => {
    return options.map((option) => {
      return {
        id: option,
        label: option.charAt(0).toUpperCase() + option.slice(1),
      };
    });
  };

  return (
    <RightDrawer
      openDrawer={openDrawer}
      setOpenDrawer={setOpenDrawer}
      headingLeft={currNote?.id ? "Edit Note" : "Add Note"}
      headingRight={null}
      width="750px"
    >
      <Box sx={{ p: "20px" }}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <Box p="20px 0">
            <Box
              sx={{
                display: "grid",
                gap: "20px",
              }}
            >
              <Select
                options={convertToObjectOptions(relatedToForNotes || [])}
                label="Related to *"
                value={formik?.values?.related_to}
                onChange={(value) => {
                  fetchRelatedToData(value);
                  formik.setFieldValue("related_to", value);
                  formik.setFieldValue("related_id", "");
                }}
              />
              <Select
                disabled={!formik.values.related_to}
                options={relatedToData}
                label={
                  formik.values.related_to
                    ? "Select " + formik.values.related_to + " *"
                    : "Related to Item *"
                }
                value={formik?.values?.related_id}
                onChange={(value) => formik.setFieldValue("related_id", value)}
                selectProps={{ id: "related_id", label: "label" }}
              />
              <InputField
                sx={{ gridColumn: "span 2" }}
                name="note"
                label="Note *"
                formik={formik}
                multiline
                rows={3}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "10px",
                mt: "20px",
              }}
            >
              <Button
                variant="solid"
                onClick={formik.submitForm}
                fullWidth
                disabled={!(formik.dirty && formik.isValid)}
              >
                {currNote?.id ? "Update Note" : "Save Note"}
              </Button>
            </Box>
          </Box>
        </LocalizationProvider>
      </Box>
    </RightDrawer>
  );
};

export default AddEdit;
