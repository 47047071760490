import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Note from "components/common/note";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import React, { useState } from "react";
import { TableVirtuoso } from "react-virtuoso";
import MainTableRow from "./MainTableRow";
import TableAdmissionList from "./TableAdmissionList";

const ExpandableRow = ({ context, item: row, ...restProps }) => {
  return <MainTableRow {...restProps} {...context} key={row.id} row={row} />;
};

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: ExpandableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const MainTable = ({
  setAllCustomers,
  allCustomers,
  handleDelete,
  setReload,
  pagination,
  setPagination,
  loading,
  handleTableCheckBox,
  selectedRows,
  permissions,
  setModal,
}) => {
  const [openNote, setOpenNote] = useState("");
  const [openAdmission, setOpenAdmission] = useState("");
  const rows = allCustomers;
  const columns = [
    { id: "customer_name", label: "Customer" },
    { id: "branch_name", label: "Branch" },
    { id: "project_name", label: "Project" },
    { id: "intake_name", label: "Intake" },
    { id: "followup_date", label: "Followup Date", style: { width: "150px" } },
    { id: "last_note", label: "Last note", style: { width: "190px" } },
    { id: "assignees", label: "Assigned", align: "center" },
    { id: "university_name", label: "University", style: { width: "190px" } },
    {
      id: "admission_status",
      label: "Update",
      style: { width: "100px" },
      align: "center",
    },
    { id: "reference_name", label: "Reference" },
    { id: "status_id", label: "Status" },
    { id: "created_at", label: "FO Date", style: { width: "130px" } },
    {
      id: "action",
      label: "Actions",
      style: { width: "170px" },
      align: "center",
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer sx={{ overflowX: "auto" }}>
          <TableVirtuoso
            style={{ height: "calc(100vh - 257px)" }}
            data={rows}
            components={TableComponents}
            context={{
              columns,
              handleDelete,
              openNote,
              setOpenNote,
              handleTableCheckBox,
              selectedRows,
              permissions,
              setReload,
              allCustomers,
              setAllCustomers,
              openAdmission,
              setOpenAdmission,
              setModal,
            }}
            fixedHeaderContent={() => (
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 16px",
                  },
                  "& .MuiTableCell-sizeMedium:first-of-type": {
                    paddingLeft: "8px",
                    paddingRight: "0px",
                  },
                }}
              >
                <TableCell padding="checkbox" sx={{ bgcolor: "#fff" }}>
                  <Checkbox
                    disabled={!permissions["bulk action"]}
                    checked={
                      selectedRows?.length === rows?.length && rows?.length
                    }
                    onChange={(e) =>
                      handleTableCheckBox("all", e.target.checked)
                    }
                  />
                </TableCell>
                {columns.map((column) => (
                  <TableCell
                    sx={{ bgcolor: "#fff" }}
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          />
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 265px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
      {openNote && (
        <Note
          open={openNote}
          handleClose={() => setOpenNote(false)}
          id={openNote}
          related_id={allCustomers.find((c) => c?.id === openNote)?.id}
          related_to="project"
          route="project-customer"
          allData={allCustomers}
          setAllData={setAllCustomers}
        />
      )}
      {openAdmission && (
        <TableAdmissionList
          open={openAdmission}
          handleClose={() => setOpenAdmission(false)}
          id={openAdmission}
        />
      )}
    </Paper>
  );
};

export default MainTable;
