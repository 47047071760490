import { Box, Button } from "@mui/material";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useBase from "hooks/useBase";
import { toast } from "react-toastify";
import * as yup from "yup";
import { updateSMSSettings } from "../api/settings";
import { useEffect } from "react";

const SMS = ({ sms }) => {
  const { updateBase } = useBase();

  const initialValues = {
    gateway_type: sms?.gateway_type || "metrotel" || "",
    user_name: sms?.user_name || "",
    password: sms?.password || "",
    source: sms?.source || "",
    api_key: sms?.api_key || "",
    base_url: sms?.base_url || "",
    balance_url: sms?.balance_url || "",
    sms_type: sms?.sms_type || "",
  };

  const validationSchema = yup.object({
    gateway_type: yup.string().required("Required Field."),
    user_name: yup.string().required("Required Field."),
    password: yup.string().required("Required Field."),
    source: yup.string().required("Required Field."),
    base_url: yup.string().required("Required Field."),
    sms_type: yup.string().required("Required Field."),
  });
  const onSubmit = async (data) => {
    const result = await updateSMSSettings({ body_data: data });
    if (result.success) {
      toast.success(result.message);
      updateBase(["settings"]);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    if (formik.values.gateway_type === "microtechbd") {
      formik.setFieldValue("api_key", "");
    } else {
      formik.setFieldValue("balance_url", "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.gateway_type]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: "650px",
          margin: "0 auto",
          border: "1px solid #efefef",
          borderRadius: "5px",
          padding: "15px",
          display: "flex",
          gap: "15px",
          flexDirection: "column",
        }}
      >
        <Select
          name="gateway_type"
          options={[
            { label: "Metrotel", id: "metrotel" },
            { label: "Microtechbd", id: "microtechbd" },
            { label: "MRAM", id: "mram" },
            { label: "GHIT", id: "ghit" },
          ]}
          label="Gateway Type *"
          formik={formik}
        />
        <InputField name="user_name" formik={formik} label="Username *" />
        <InputField name="password" formik={formik} label="Password *" />
        <InputField name="source" formik={formik} label="Source/Sender ID *" />
        <InputField
          disabled={formik.values.gateway_type === "microtechbd"}
          name="api_key"
          formik={formik}
          label="Api Key"
        />
        <InputField name="base_url" formik={formik} label="Base URL *" />
        <InputField
          disabled={formik.values.gateway_type !== "microtechbd"}
          name="balance_url"
          formik={formik}
          label="Balance URL"
        />
        <Select
          name="sms_type"
          options={[
            { label: "Masking", id: "masking" },
            { label: "Non Masking", id: "non_masking" },
          ]}
          label="SMS Type *"
          formik={formik}
        />

        <Button
        type="submit"
          variant="solid"
          fullWidth
          disabled={!(formik.dirty && formik.isValid)}
        >
          Save Changes
        </Button>
      </Box>
    </form>
  );
};

export default SMS;
