import { Box, Button } from "@mui/material";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import ContextMenu from "./ContextMenu";
import SingleAtt from "./SingleAtt";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactLoading from "react-loading";
import Alert from "components/alert";
import UploadFileModal from "./UploadFileModal";
import { BiPlus } from "react-icons/bi";
import AttRename from "./AttRename";

const AttachmentsGrid = ({
  onlyFilesScreen,
  type,
  attDetails,
  setAttDetails,
  filters,
}) => {
  const { loading, fetchData } = useApi();
  const [attachments, setAttachments] = useState([]);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [reload, setReload] = useState([]);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: type === "recents" ? 16 : 30,
    to: 0,
    from: 0,
    total: 0,
  });

  const getAttachments = async (page_to_fetch) => {
    let finalPagination = { ...pagination };
    const params = `page=${page_to_fetch}&limit=${finalPagination?.per_page}`;
    const endpoint = {
      method: "post",
      url: `/api/admin/attachment/list?${params}`,
      data: type === "recents" ? {} : filters,
    };
    const result = await fetchData(endpoint, false);
    if (result.success) {
      const { data: allData, ...rest } = result.data;
      if (page_to_fetch === 1) {
        setAttachments(allData);
      } else {
        setAttachments((pre) => [...pre, ...allData]);
      }
      setPagination(rest);
    }
  };

  useEffect(() => {
    getAttachments(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    const endpoint = {
      method: "delete",
      url: `/api/admin/attachment/delete/${openAlert}`,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      if (attachments?.length > 1) {
        setAttachments((pre) => pre.filter((att) => att?.id !== openAlert));
        setAttDetails(null);
      }
    } else {
      setReload((pre) => !pre);
    }
  };

  return (
    <>
      {openRename && (
        <AttRename
          open={openRename}
          handleClose={() => setOpenRename(false)}
          file_id={openRename?.id}
          file_name={openRename?.name}
          related_to={openRename.related_to}
          related_id={openRename.related_id}
          setAttachments={setAttachments}
        />
      )}
      {openUploadFile && (
        <UploadFileModal
          open={openUploadFile}
          handleClose={() => setOpenUploadFile(false)}
          related_to={onlyFilesScreen}
          setAttachments={setAttachments}
        />
      )}
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Attachment"
          desc={
            <Box>
              Are you sure you want to delete this Attachment? This will delete
              the Attachment permanently!
              <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      {loading && !pagination?.last_page ? (
        <Box
          sx={{
            height:
              type === "recents"
                ? "calc(100vh - 400px)"
                : "calc(100vh - 150px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box>
          {type === "recents" ? (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fill, minmax(180px, 1fr))",
                gap: "16px",
              }}
            >
              <Box
                onClick={() => setOpenUploadFile(true)}
                sx={{
                  border: "2px dashed #ddd",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  borderRadius: "8px",
                }}
              >
                <BiPlus fontSize="30px" color="#ddd" />
              </Box>
              {attachments?.map((att, i) => (
                <ContextMenu
                  key={i}
                  {...{
                    setOpenRename,
                    attDetails,
                    setAttDetails,
                    att,
                    handleDelete,
                  }}
                >
                  <SingleAtt />
                </ContextMenu>
              ))}
            </Box>
          ) : (
            <Box
              className="att-scroller"
              sx={{
                height: "calc(100vh - 150px)",
                overflowY: "auto",
                pr: "20px",
                mr: "-20px",
              }}
              id="scrollableDiv"
            >
              <InfiniteScroll
                dataLength={attachments?.length}
                next={() => getAttachments(pagination?.current_page + 1)}
                hasMore={
                  pagination?.current_page === pagination?.last_page
                    ? false
                    : true
                }
                loader={<h4 style={{ textAlign: "center" }}>Loading...</h4>}
                scrollableTarget="scrollableDiv"
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns:
                      "repeat(auto-fill, minmax(180px, 1fr))",
                    gap: "16px",
                  }}
                >
                  {![
                    "Pdf",
                    "Doc",
                    "Sheet",
                    "Image",
                    "Audio",
                    "Video",
                    "PPT",
                    "HTML",
                    "Apk",
                  ].includes(onlyFilesScreen) && (
                    <Box
                      onClick={() => setOpenUploadFile(true)}
                      sx={{
                        border: "2px dashed #ddd",
                        height: "100%",
                        minHeight: "180px",

                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        cursor: "pointer",
                        borderRadius: "8px",
                      }}
                    >
                      <BiPlus fontSize="30px" color="#ddd" />
                    </Box>
                  )}
                  {attachments?.map((att, i) => (
                    <ContextMenu
                      key={i}
                      {...{
                        setOpenRename,
                        attDetails,
                        setAttDetails,
                        att,
                        handleDelete,
                      }}
                    >
                      <SingleAtt />
                    </ContextMenu>
                  ))}
                </Box>
              </InfiniteScroll>
            </Box>
          )}
        </Box>
      )}
    </>
  );
};

export default AttachmentsGrid;
