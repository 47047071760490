import { Box, Skeleton, Typography } from "@mui/material";
import { TfiAnnouncement } from "react-icons/tfi";

const Notice = ({ notice, filters, loading, name }) => {
  return loading && filters.filterEntry.includes(name) ? (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: "3px",
        boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
        padding: "15px",
        mb: "20px",
        height: "120px",
      }}
    >
      <Box
        sx={{
          height: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <Skeleton
          variant="rectangular"
          height={"22px"}
          width={"50%"}
          sx={{
            margin: "0",
            borderRadius: "5px",
          }}
        />
        <Skeleton
          variant="rectangular"
          height={"36px"}
          width={"100%"}
          sx={{
            borderRadius: "5px",
          }}
        />
      </Box>
    </Box>
  ) : (
    !!Object.keys(notice || {})?.length && (
      <Box
        sx={{
          bgcolor: "#fff7da",
          borderRadius: "3px",
          boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
          padding: "15px",
          mb: "20px",
          maxHeight: "220px",
          overflow: "auto",
          border: "1px solid #ffd1a2",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems:"center",
            gap: "15px",
            mb: "10px",
          }}
        >
         <TfiAnnouncement color="#405189" fontSize={30}/> <Typography variant="h4">{notice?.title} </Typography>
        </Box>
        <Typography
          variant="body1"
          sx={{ "& p": { my: 0 } }}
          dangerouslySetInnerHTML={{ __html: notice?.description }}
        />
      </Box>
    )
  );
};

export default Notice;
