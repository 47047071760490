import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApi from "hooks/useApi";
import useApiCall from "hooks/useApiCall";
import useBase from "hooks/useBase";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { viewAllInvoiceBank } from "../api/invoiceBank";
import AddEdit from "./AddEdit";
import Export from "./Export";
import Header from "./Header";
import Summary from "./Summary";
import AdvanceFilter from "./filter/AdvanceFilter";
import MainScreenFilter from "./filter/MainScreenFilter";
import MainTable from "./table/MainTable";
import ViewInvoiceBank from "./viewInvoiceBank/ViewInvoiceBank";
import useQueries from "hooks/useQueries";

const Bank = () => {
  const { setQueries, openAddEdit, openView } = useQueries();
  const [persistedData, setPersistedData] = useState({});
  const { updateBase, base } = useBase();
  const [activeMenu, setActiveMenu] = useState("");
  const [openFilter, setOpenFilter] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [allInvoiceBank, setAllInvoiceBank] = useState([]);

  // Helper states
  const [openAlert, setOpenAlert] = useState(false);
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const permissions = usePermissions("bank-invoice");
  const all_props = {
    ...base,
    persistedData,
    setPersistedData,
    setReload,
    filters,
    setFilters,
    isFiltering,
    pagination,
    setPagination,
    allInvoiceBank,
    setAllInvoiceBank,
    permissions,
    openFilter,
    setOpenFilter,
  };

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const { fetchData: fetchDelete } = useApi();

  const handleDeleteConfirm = async () => {
    const oldAllInvoiceBank = JSON.parse(JSON.stringify(allInvoiceBank));
    const itemIdToDelete = openAlert;
    setOpenAlert(false);
    setAllInvoiceBank(
      allInvoiceBank?.filter((bank) => bank?.id !== itemIdToDelete)
    );
    const endpoint = {
      method: "delete",
      url: `/api/admin/bank-invoice/delete/${itemIdToDelete}`,
    };
    const result = await fetchDelete(endpoint);
    if (!result.success) {
      setAllInvoiceBank(oldAllInvoiceBank);
      toast.error(result.message);
    }
    if (allInvoiceBank?.length === 1) {
      setReload((pre) => !pre);
    }
  };

  const { data, loading, fetchData: fetchListData } = useApiCall();

  useEffect(() => {
    updateBase([
      "bankInvoiceStatuses",
      "bankInvoiceBanks",
      "paymentMethods",
      "references",
      "customers",
    ]);
  }, [reload]);

  useEffect(() => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    finalFilters.search = search;
    fetchListData(
      viewAllInvoiceBank,
      {
        body_data: finalFilters,
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, search, pagination?.current_page, pagination?.per_page]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllInvoiceBank(allData);
      setPagination(rest);
    }
  }, [data]);

  return (
    <>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Bank Invoice"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this Invoice
              Bank permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Header
        {...{
          activeMenu,
          setActiveMenu,
          setOpenExport,
          setSearch,
          setPagination,
          pagination,
          permissions,
          setOpenFilter,
        }}
      />
      {openAddEdit && (
        <AddEdit
          {...{
            openDrawer: openAddEdit,
            setOpenDrawer: () => setQueries(""),
            ...all_props,
          }}
        />
      )}
      {openView && (
        <ViewInvoiceBank
          openDrawer={openView}
          setOpenDrawer={() => setQueries("")}
          {...all_props}
        />
      )}
      {activeMenu === "summary" && <Summary {...{ filters, search }} />}
      {openExport && (
        <Export
          {...{
            setOpenDrawer: setOpenExport,
            openDrawer: openExport,
            search,
            filters,
          }}
        />
      )}
      {!activeMenu &&
        !!(permissions["list(own)"] || permissions["list(global)"]) && (
          <MainScreenFilter {...all_props} />
        )}
      {openFilter && (
        <AdvanceFilter
          openDrawer={openFilter}
          setOpenDrawer={setOpenFilter}
          {...all_props}
        />
      )}
      {!activeMenu &&
        (permissions.list ? (
          <MainTable
            handleDelete={handleDelete}
            loading={loading}
            {...all_props}
          />
        ) : (
          <NotAuthorized />
        ))}
    </>
  );
};

export default Bank;
