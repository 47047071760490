import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import Activity from "components/common/activity";
import Attachment from "components/common/attachment";
import Email from "components/common/email";
import Note from "components/common/note";
import Task from "components/common/task";
import NotAuthorized from "components/notAuthorized";
import RightDrawer from "components/rightDrawer/RightDrawer";
import useBase from "hooks/useBase";
import { useState } from "react";
import SMSList from "./SMSList";
import SMS from "./SMSsend";
import Profile from "./profile/Profile";
import useQueries from "hooks/useQueries";
import useApi from "hooks/useApi";
import { useEffect } from "react";

const ViewLead = ({
  openDrawer,
  setOpenDrawer,
  setReload,
  statuses,
  allLeads,
  setAllLeads,
  permissions,
}) => {
  const { row_id, mod_id, setQueries } = useQueries();
  const { fetchData, loading } = useApi();
  const [profile, setProfile] = useState({});
  const [value, setValue] = useState(mod_id || "1");
  const { base } = useBase();
  const activeEmailModules = base?.settings?.email?.active_modules;

  const handleChange = (event, newValue) => {
    setQueries(`id=${row_id}&m=${newValue}`);
    setValue(newValue);
  };

  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/lead/show/${row_id}`,
      };
      const { success, data } = await fetchData(endpoint, false);
      if (success) setProfile(data);
    };
    getProfile();
  }, []);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "1050px",
        headingLeft:
          "#" + row_id + `${profile?.name ? ` - ${profile?.name}` : ""}`,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", overflowX: "hidden" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab sx={{ paddingLeft: "0 " }} label="Profile" value="1" />
              <Tab label="Attachment" value="2" />
              <Tab label="Note" value="3" />
              <Tab label="Task" value="4" />
              <Tab label="SMS" value="5" />
              <Tab label="SMS List" value="6" />
              {activeEmailModules?.includes("lead") && (
                <Tab label="Email" value="7" />
              )}
              <Tab label="Activity" value="8" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1" sx={{ p: 0 }}>
              {permissions["show"] ? (
                <Profile
                  loading={loading}
                  profile={profile}
                  setReload={setReload}
                  statuses={statuses}
                  setOpenDrawer={setOpenDrawer}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="2">
              {permissions["attachment"] ? (
                <Attachment id={row_id} route="lead" />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="3">
              {permissions["note"] ? (
                <Note
                  id={row_id}
                  related_id={row_id}
                  related_to="lead"
                  route="lead"
                  allData={allLeads}
                  setAllData={setAllLeads}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel sx={{ paddingBottom: "0px" }} value="4">
              {permissions["task"] ? (
                <Task id={row_id} route="lead" related_to="lead" />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="5">
              <SMS leadId={row_id} />
            </TabPanel>
            <TabPanel value="6">
              <SMSList profile={profile} />
            </TabPanel>
            <TabPanel value="7">
              <Email
                email_addresses={[profile?.email || ""]}
                related_to="lead"
                related_id={profile?.id}
              />
            </TabPanel>
            <TabPanel value="8">
              {permissions["activity"] ? (
                <Activity
                  activityList={{
                    method: "get",
                    url: `/api/admin/lead/activity/${row_id}`,
                  }}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default ViewLead;
