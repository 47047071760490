import { Delete, Edit } from "@mui/icons-material";
import {
  Box,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import useFormatter from "hooks/useFormatter";
import moment from "moment";
import { IoLogOutSharp } from "react-icons/io5";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  handleEdit,
  permissions,
  handleCheckOut,
  ...restProps
}) => {
  const { formatDate } = useFormatter();

  return (
    <TableRow
      {...restProps}
      hover
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 16px 10px 20px",
        },
      }}
    >
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.id === "date" ? (
              formatDate(value)
            ) : column.id === "start_time" ||
              column.id === "end_time" ||
              column.id === "default_checkin" ||
              column.id === "default_checkout" ? (
              value ? (
                moment(value, "h:mm:ss").format("LT")
              ) : (
                "-"
              )
            ) : column.id === "late_hour" ||
              column.id === "overtime" ||
              column.id === "working_hour_due" ||
              column.id === "total_hour" ? (
              value ? (
                value
              ) : (
                "00:00:00"
              )
            ) : column.id === "created_by_name" ||
              column.id === "updated_by_name" ||
              column.id === "checkin_by_name" ||
              column.id === "checkout_by_name" ||
              column.id === "ip_check_in" ||
              column.id === "ip_check_out" ||
              column.id === "ip_for_create" ||
              column.id === "ip_for_update" ? (
              value ? (
                value
              ) : (
                "-"
              )
            ) : column.id === "late" ? (
              <Typography sx={{ color: value && "red" }} variant="body2">
                {value ? "Yes" : "No"}
              </Typography>
            ) : column.id === "is_holiday" || column.id === "is_weekend" ? (
              <Typography sx={{ color: value && "red" }} variant="body2">
                {value ? "Yes" : "No"}
              </Typography>
            ) : column.id === "absent" ||
              column.id === "leave" ||
              column.id === "present" ? (
              <Typography sx={{ color: value && "red" }} variant="body2">
                {value ? "Yes" : "No"}
              </Typography>
            ) : column.id === "leave_type" ? (
              <Typography sx={{ textTransform: "capitalize" }} variant="body2">
                {value ? value : "-"}
              </Typography>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                {permissions.isAdmin === 1 && (
                  <Tooltip title="Checkout">
                    <IconButton
                      disabled={row?.end_time || !permissions.checkin}
                      onClick={() => handleCheckOut(row?.id)}
                    >
                      <IoLogOutSharp />
                    </IconButton>
                  </Tooltip>
                )}

                <Tooltip title="Edit">
                  <IconButton
                    disabled={!permissions.update}
                    onClick={() => handleEdit(row)}
                  >
                    <Edit style={{ fontSize: "22px" }} />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Delete">
                  <IconButton
                    disabled={!permissions.delete}
                    onClick={() => handleDelete(row.id)}
                  >
                    <Delete style={{ fontSize: "22px" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
