const getInitialvalues = (filters) => {
  return {
    created_at_from: filters.created_at_from || null,
    created_at_to: filters.created_at_to || null,
    user_id: filters.user_id || null,
    customer_id: filters.customer_id || null,
  };
};

export default getInitialvalues;
