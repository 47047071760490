import { Box, Button } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import Editor from "components/ui/editor/Editor";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useQueries from "hooks/useQueries";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import * as yup from "yup";

const ticketPriorities = [
  { id: "low", label: "Low", color: "#008000" },
  { id: "medium", label: "Medium", color: "#FFA500" },
  { id: "high", label: "High", color: "#FF0000" },
];
const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  projects,
  setAllTickets,
  allTickets,
  customers,
}) => {
  const { row_id } = useQueries();
  const { loading, fetchData } = useApi();
  const [currTicket, setCurrTicket] = useState(row_id ? {} : persistedData);
  const isUpdating = row_id ? true : false;

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullTicket = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/ticket/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrTicket(result?.data);
    };
    row_id && getFullTicket();
  }, []);

  const initialValues = {
    customer_id: currTicket?.customer_id || "",
    project_id: currTicket?.project_id || "",
    title: currTicket?.title || "",
    description: currTicket?.description || "",
    priority: currTicket?.priority || "",
  };

  const validationSchema = yup.object({
    customer_id: yup.string("").required("This field is Required").nullable(),
    project_id: yup.string("").required("This field is Required").nullable(),
    title: yup.string("").required("Title is Required").nullable(),
    description: yup.string("").required("Description is Required").nullable(),
    priority: yup.string("").required("Priority is Required").nullable(),
  });

  const onSubmit = async (data) => {
    let endpoint;
    if (isUpdating) {
      endpoint = {
        method: "put",
        url: `/api/admin/ticket/update/${row_id}`,
        data,
      };
    } else {
      endpoint = {
        method: "post",
        url: "/api/admin/ticket/create",
        data,
      };
    }

    const result = await fetchData(endpoint);
    if (result.success) {
      if (isUpdating) {
        setAllTickets(
          allTickets.map((ticket) =>
            ticket.id === row_id ? result.data : ticket
          )
        );
      } else {
        setAllTickets([result?.data, ...allTickets]);
      }
      handleClose("reset");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });
  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: isUpdating ? "Edit Ticket" : "New Ticket",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box p="20px">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "20px",
                gridColumn: "span 2",
              }}
            >
              <Select
                options={ticketPriorities}
                label="Priority *"
                value={formik?.values?.priority}
                onChange={(value) => formik.setFieldValue("priority", value)}
              />
              <Select
                options={customers}
                label="Customer *"
                value={formik?.values?.customer_id}
                onChange={(value) => formik.setFieldValue("customer_id", value)}
                selectProps={{ id: "id", label: "name" }}
              />
              <Select
                options={projects}
                label="Project *"
                value={formik?.values?.project_id}
                onChange={(value) => formik.setFieldValue("project_id", value)}
                selectProps={{ id: "id", label: "name" }}
              />
            </Box>
            <InputField
              sx={{ gridColumn: "span 2" }}
              name="title"
              label="Ticket Title *"
              formik={formik}
            />
            <Box sx={{ gridColumn: "span 2" }}>
              <Editor formik={formik} initVal={currTicket.description} />
            </Box>
          </Box>
          <Box sx={{ mt: "20px" }}>
            <Button
              variant="solid"
              onClick={formik.submitForm}
              fullWidth
              disabled={!(formik.dirty && formik.isValid) || loading}
            >
              {isUpdating ? "Update Ticket" : "Save Ticket"}
            </Button>
          </Box>
        </Box>
      )}
    </RightDrawer>
  );
};

export default AddEdit;
