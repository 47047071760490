import { Delete, UploadFileSharp } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import LoadingBtn from "components/ui/loadingBtn";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import useQueries from "hooks/useQueries";
import moment from "moment";
import { useEffect, useState } from "react";
import { FcFile } from "react-icons/fc";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import * as yup from "yup";

const LinearProgressWithLabel = (props) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
};

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  setAllTask,
  allTask,
}) => {
  const { row_id } = useQueries();
  const [currTask, setCurrTask] = useState(row_id ? {} : persistedData);
  const isUpdating = row_id ? true : false;
  const { fetchData, loading } = useApi();
  const { fetchData: fetchDeleteAtt } = useApi();
  const { fetchData: fetchAttUp, loading: attUpLoading } = useApi();
  const { fetchData: fetchRelatedToItems, loading: relatedToItemsLoading } =
    useApi();
  const user_id = useSelector((state) => state?.auth?.user_details?.user?.id);
  const { base } = useBase();
  const { taskStatuses, relatedToTasks, assignees } = base;
  const [relatedToData, setRelatedToData] = useState([]);
  const taskpriorities = [
    { id: 1, label: "Low", color: "#008000" },
    { id: 2, label: "Medium", color: "#FFA500" },
    { id: 3, label: "High", color: "#FF0000" },
    { id: 4, label: "Urgent", color: "#800080" },
  ];

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullTask = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/task/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        setCurrTask(result?.data);
        fetchRelatedToData(result?.data.related_to);
      }
    };
    row_id && getFullTask();
  }, []);

  const initialValues = {
    related_to: currTask?.related_to || "",
    related_id: currTask?.related_id || "",
    name: currTask?.name || "",
    date_added: currTask?.date_added || moment().format("YYYY-MM-DD"),
    due_date: currTask?.due_date || "",
    date_finished: currTask?.date_finished || "",
    assignees: !row_id
      ? currTask?.assignees || [user_id]
      : currTask?.assignees?.map((assignee) => assignee?.user_id) || [user_id],
    followers: currTask?.followers?.map((follower) => follower?.user_id) || [],
    priority: currTask?.priority || 2,

    task_status_id:
      currTask?.task_status_id ||
      taskStatuses?.find((status) => status?.is_open === 1)?.id ||
      "",
    description: currTask?.description || "",
  };

  const validationSchema = yup.object({
    name: yup.string("").required("Name is Required").nullable(),
    priority: yup.string("").required("Priority is Required").nullable(),
    task_status_id: yup.string("").required("Status is Required").nullable(),
    date_added: yup.string("").required("Date added is Required").nullable(),
    assignees: yup.array().required("Assignee is Required").min(1),
    description: yup
      .string("")
      .max(250, "Description Should be less than 250 letter"),
  });

  const onSubmit = async (data) => {
    let endpoint;
    if (isUpdating) {
      endpoint = {
        method: "post",
        url: `/api/admin/task/update/${row_id}`,
        data,
      };
    } else {
      endpoint = {
        method: "post",
        url: "/api/admin/task/create",
        data,
      };
    }
    const result = await fetchData(endpoint);
    if (result.success) {
      if (currTask?.id) {
        setAllTask(
          allTask.map((task) => (task.id === row_id ? result.data : task))
        );
        handleClose("reset");
      } else {
        setAllTask([result?.data, ...allTask]);
        if (files.length) {
          uploadFile(result?.data?.id);
        } else {
          handleClose("reset");
        }
      }
    }
  };

  const fetchRelatedToData = async (relatedTo) => {
    const endpoint = {
      method: "get",
      url: `/api/admin/base/related-module-data/${relatedTo}`,
    };
    const result = await fetchRelatedToItems(endpoint, false);
    if (result.success) setRelatedToData(result.data);
  };

  const convertToObjectOptions = (options) => {
    return options.map((option) => {
      return {
        id: option,
        label: option.charAt(0).toUpperCase() + option.slice(1),
      };
    });
  };

  // ---------------------- Handle Files
  const [progress, setProgress] = useState(0);
  const [uploadingFileName, setUploadingFileName] = useState("");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const getAttachments = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/task/attachment/${row_id}?limit=500`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const data = result?.data?.data;
        setFiles(data || []);
      }
    };
    getAttachments();
  }, []);

  const handleAddFile = (new_files) => {
    if (row_id) {
      setUploadingFileName(new_files[0].name);
      uploadFile(row_id, new_files[0]);
    } else setFiles([new_files[0], ...files]);
  };

  const deleteAtt = (attId) => {
    const endpoint = {
      method: "delete",
      url: `/api/admin/attachment/delete/${attId}`,
    };
    fetchDeleteAtt(endpoint);
  };

  const handleDeleteAtt = async (attKey, attId) => {
    setFiles(files.filter((file, key) => key !== attKey));
    if (row_id) deleteAtt(attId);
  };

  const uploadFile = async (id, file) => {
    const formData = new FormData();
    formData.append("description", "");
    formData.append("file", file || files[0]);

    try {
      const endpoint = {
        method: "post",
        url: `/api/admin/task/attachment/upload/${id}`,
        data: formData,
        updateProgress: (value) => setProgress(value),
      };
      const res = row_id
        ? await fetchAttUp(endpoint, false)
        : await fetchData(endpoint, false);
      if (res.success) {
        setFiles([res.data, ...files]);
      }
    } finally {
      if (id && !row_id) handleClose("reset");
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <RightDrawer
      openDrawer={openDrawer}
      setOpenDrawer={handleClose}
      headingLeft={isUpdating ? "Edit Task" : "New Task"}
      headingRight={null}
      width="750px"
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box sx={{ px: "20px" }}>
          {!files.length || row_id ? (
            <Box
              sx={{
                pt: "20px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                component="label"
                startIcon={<UploadFileSharp />}
                variant="outlined"
              >
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleAddFile(e.target.files)}
                />
                Attach File
              </Button>
            </Box>
          ) : (
            <Box pt="10px" />
          )}
          {attUpLoading && (
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "50px auto 60px",
                alignItems: "center",
                borderBottom: "1px solid #ddd",
                padding: "15px 0",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <FcFile style={{ fontSize: "40px" }} />
              </Box>
              <Box>
                <Typography variant="h6">{uploadingFileName}</Typography>
                <LinearProgressWithLabel value={progress} />
              </Box>
            </Box>
          )}
          {!!files.length &&
            files.map((attachment, key) => {
              return (
                <Box
                  key={key}
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "50px auto 100px",
                    alignItems: "center",
                    borderBottom: "1px solid #ddd",
                    padding: "15px 0",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FcFile style={{ fontSize: "40px" }} />
                  </Box>
                  <Box>
                    <Typography variant="h6">{attachment?.name}</Typography>
                    {attachment?.size ||
                      (attachment?.file_size && (
                        <Typography sx={{ fontSize: "12px" }}>
                          {(row_id
                            ? attachment?.file_size / 1024
                            : attachment?.size / 1024
                          ).toFixed(0)}
                          KB
                        </Typography>
                      ))}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                    }}
                  >
                    <IconButton
                      onClick={() => handleDeleteAtt(key, attachment?.id)}
                    >
                      <Delete />
                    </IconButton>
                  </Box>
                </Box>
              );
            })}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box
              sx={{
                borderTop: !!files.length ? "none" : "1px solid #ddd",
                p: "15px 0 20px",
                mt: "15px",
              }}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: "15px 20px",
                  pb: "10px",
                }}
              >
                <InputField
                  variant="standard"
                  sx={{ gridColumn: "span 2" }}
                  name="name"
                  label="Task Name *"
                  formik={formik}
                />
                <InputField
                  variant="standard"
                  sx={{ gridColumn: "span 2" }}
                  name="description"
                  label="Task Description"
                  formik={formik}
                  multiline
                  rows={5}
                />
                <Select
                  variant="standard"
                  sx={{ gridColumn: "span 2" }}
                  options={taskpriorities}
                  label="Priority *"
                  value={formik?.values?.priority}
                  onChange={(value) => formik.setFieldValue("priority", value)}
                />
                <Select
                  variant="standard"
                  sx={{ gridColumn: "span 2" }}
                  options={taskStatuses}
                  label="Status *"
                  value={formik?.values?.task_status_id}
                  onChange={(value) =>
                    formik.setFieldValue("task_status_id", value)
                  }
                  selectProps={{
                    id: "id",
                    label: "name",
                  }}
                />
                <Select
                  variant="standard"
                  options={convertToObjectOptions(relatedToTasks || [])}
                  label="Related to"
                  value={formik?.values?.related_to}
                  onChange={(value) => {
                    fetchRelatedToData(value);
                    formik.setFieldValue("related_to", value);
                    formik.setFieldValue("related_id", "");
                  }}
                />
                <Box position="relative">
                  {relatedToItemsLoading && (
                    <Box
                      sx={{
                        position: "absolute",
                        height: "30px",
                        top: "10px",
                        right: "6px",
                        background: "white",
                        zIndex: "10",
                      }}
                    >
                      <ReactLoading
                        type="bubbles"
                        color="#405189"
                        height={"15px"}
                        width={"30px"}
                      />
                    </Box>
                  )}
                  <Select
                    variant="standard"
                    disabled={
                      !formik.values.related_to || relatedToItemsLoading
                    }
                    options={relatedToData}
                    label={
                      formik.values.related_to
                        ? "Select " + formik.values.related_to
                        : "Related to Item"
                    }
                    value={formik?.values?.related_id}
                    onChange={(value) =>
                      formik.setFieldValue("related_id", value)
                    }
                    selectProps={{ id: "related_id", label: "label" }}
                  />
                </Box>

                <MultiSelect
                  variant="standard"
                  options={assignees}
                  label="Assigned *"
                  value={formik?.values?.assignees}
                  onChange={(value) => formik.setFieldValue("assignees", value)}
                  selectProps={{
                    id: "user_id",
                    label: "name",
                  }}
                />
                <MultiSelect
                  variant="standard"
                  options={assignees}
                  label="Followers"
                  value={formik?.values?.followers}
                  onChange={(value) => formik.setFieldValue("followers", value)}
                  selectProps={{
                    id: "user_id",
                    label: "name",
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: "15px",
                  gridColumn: "span 2",
                }}
              >
                <DateTimePicker
                  variant="standard"
                  name="date_added"
                  label="Start Date *"
                  formik={formik}
                />
                <DateTimePicker
                  variant="standard"
                  name="due_date"
                  label="Due Date"
                  formik={formik}
                />
                <DateTimePicker
                  variant="standard"
                  name="date_finished"
                  label="Finished Date"
                  formik={formik}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "10px",
                  mt: "20px",
                }}
              >
                {loading ? (
                  <LoadingBtn />
                ) : (
                  <Button
                    variant="solid"
                    onClick={formik.submitForm}
                    fullWidth
                    disabled={!(formik.dirty && formik.isValid)}
                  >
                    {isUpdating ? "Update" : "Create Task"}
                  </Button>
                )}
              </Box>
            </Box>
          </LocalizationProvider>
        </Box>
      )}
    </RightDrawer>
  );
};

export default AddEdit;
