import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Box, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import useApi from "hooks/useApi";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Vote = ({ currKB }) => {
  let customer_id = useSelector((state) => state?.auth?.user_details?.user?.id);
  const { fetchData: addVoteApi } = useApi();
  const [hasVoted, setHasVoted] = useState(false);
  const [hasVotedDislike, setHasVotedDisLike] = useState(false);
  const handleLike = async () => {
    const endpoint = {
      method: "post",
      url: `/api/knowledge/vote/add`,
      data: {
        customer_id: [customer_id],
        knowledge_base_id: currKB?.id,
        vote: 1,
      },
    };
    const result = await addVoteApi(endpoint);
    if (result.success) {
      if (!hasVoted) {
        setHasVoted(true);
      }
    } else {
      toast.error(result.message);
    }
  };

  const handleDislike = async () => {
    const endpoint = {
      method: "post",
      url: `/api/knowledge/vote/add`,
      data: {
        customer_id: [customer_id],
        knowledge_base_id: currKB?.id,
        vote: 0,
      },
    };
    const result = await addVoteApi(endpoint);
    if (result.success) {
      setHasVotedDisLike(true);
    } else {
      toast.error(result.message);
    }
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "center",
          mt: 4,
        }}
      >
        <>
          <Typography variant="h4">Was this page helpful?</Typography>

          <div>
            <IconButton
              onClick={handleLike}
              // disabled={hasVoted}
              color={hasVoted ? "primary" : "default"}
              aria-label="Like"
            >
              <ThumbUpIcon />
            </IconButton>

            <IconButton
              onClick={handleDislike}
              // disabled={hasVoted}
              color={hasVotedDislike ? "primary" : "default"}
              aria-label="Dislike"
            >
              <ThumbDownIcon />
            </IconButton>
          </div>
        </>
      </Box>
    </>
  );
};

export default Vote;
