import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import {
  addSponsor,
  sponsorProfessions,
  sponsorProfessionsFields,
  updateSponsor,
} from "features/projectCustomers/api/projectCustomer";
import CheckDateBeforeToday from "features/projectCustomers/utils/CheckDateBeforeToday";
import { useFormik } from "formik";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useEffect, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import ProfessionFields from "./ProfessionFields";

const AddSponsor = ({
  customer_id,
  project_id,
  project_customer_id,
  setAddSponsor,
  currSponsor,
  setReload,
}) => {
  const [professionTypes, setProfessionTypes] = useState([]);
  const [professionFields, setProfessionFields] = useState([]);
  const dispatch = useDispatch();

  const initialValues = {
    name: "",
    relation: "",
    amount: "",
    source: "",
    nid: 0,
    nid_receive_date: null,
    passport: 0,
    passport_receive_date: null,
    photo: 0,
    photo_receive_date: null,
    source_of_fund_doc: 0,
    source_of_fund_receive_date: null,
    source_of_fund: "",
    tax_doc: 0,
    tax_doc_receive_date: null,
    tax_details: "",
    bank_doc: 0,
    bank_doc_receive_date: null,
    bank_details: "",
    property_doc: 0,
    property_doc_receive_date: null,
    property_details: "",
    note: "",
    selected_professions_id: [],
    profession_details: "",
    sponsor_professions: [],
    profession_note: "",

    rental_income: 0,
    rental_income_receive_date: null,
    rental_income_note: "",
  };

  const validationSchema = yup.object({
    // name: yup.string("").required("Name is Required").nullable(),
    relation: yup.string("").required("Relation is Required").nullable(),
    amount: yup.string("").required("Amount is Required").nullable(),
  });
  const onSubmit = async (data) => {
    dispatch(setLoading(true));
    data.customer_id = customer_id;
    data.project_id = project_id;
    data.project_customer_id = project_customer_id;

    try {
      let result;
      if (currSponsor?.id) {
        result = await updateSponsor({
          body_data: data,
          id: currSponsor.id,
        });
      } else {
        result = await addSponsor({
          body_data: data,
        });
      }
      if (result.success) {
        toast.success(result.message);
        setAddSponsor(false);
        setReload((pre) => !pre);
      } else {
        toast.error(result.message);
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let obj = {};
      for (const key in initialValues) {
        if (key === "currency") {
          obj[key] = data[key].id;
        } else {
          obj[key] = data[key];
        }
      }
      return obj;
    } else return initialValues;
  };

  const formik = useFormik({
    initialValues: getInitialValues(currSponsor),
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const getProfession = async () => {
      const result = await sponsorProfessions();
      result?.success && setProfessionTypes(result.data);
      const spfResult = await sponsorProfessionsFields();
      spfResult?.success && setProfessionFields(spfResult.data);
    };
    getProfession();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddSponsor(false)}
        >
          View Sponsors
        </Button>
      </Box>
      <Box p="20px 0">
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "15px",
          }}
        >
          <InputField name="name" label="Name" formik={formik} />
          <InputField name="relation" label="Relation *" formik={formik} />
          <InputField name="amount" label="Amount *" formik={formik} />

          <Select
            options={[
              { id: "own", label: "Own" },
              { id: "loan", label: "Loan" },
              { id: "both", label: "Both" },
            ]}
            label="Fund Arranged by"
            value={formik?.values?.source}
            onChange={(value) => formik.setFieldValue("source", value)}
          />

          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Source of Fund"
              checked={Number(formik?.values?.rental_income) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("rental_income", e.target.checked ? 1 : 0);
              }}
            />
            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.rental_income)
                  ? null
                  : formik?.values?.rental_income_receive_date
              }
            >
              <DateTimePicker
                name="rental_income_receive_date"
                label={
                  Number(formik?.values?.rental_income)
                    ? "Receive Date"
                    : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>

            <InputField
              sx={{ gridColumn: "span 2", mt: "15px" }}
              name="rental_income_note"
              label="Source of Fund Details"
              formik={formik}
              multiline
              rows={2}
            />
          </Stack>

          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Tax Documents"
              checked={Number(formik?.values?.tax_doc) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("tax_doc", e.target.checked ? 1 : 0);
              }}
            />
            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.tax_doc)
                  ? null
                  : formik?.values?.tax_doc_receive_date
              }
            >
              <DateTimePicker
                name="tax_doc_receive_date"
                label={
                  Number(formik?.values?.tax_doc) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>

            <InputField
              sx={{ gridColumn: "span 2", mt: "15px" }}
              name="tax_details"
              label="Tax Details"
              formik={formik}
              multiline
              rows={2}
            />
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Bank Documents"
              checked={Number(formik?.values?.bank_doc) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("bank_doc", e.target.checked ? 1 : 0);
              }}
            />
            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.bank_doc)
                  ? null
                  : formik?.values?.bank_doc_receive_date
              }
            >
              <DateTimePicker
                name="bank_doc_receive_date"
                label={
                  Number(formik?.values?.bank_doc) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>

            <InputField
              sx={{ gridColumn: "span 2", mt: "15px" }}
              name="bank_details"
              label="Bank Details"
              formik={formik}
              multiline
              rows={2}
            />
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Property Documents"
              checked={Number(formik?.values?.property_doc) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("property_doc", e.target.checked ? 1 : 0);
              }}
            />
            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.property_doc)
                  ? null
                  : formik?.values?.property_doc_receive_date
              }
            >
              <DateTimePicker
                name="property_doc_receive_date"
                label={
                  Number(formik?.values?.property_doc)
                    ? "Receive Date"
                    : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
            <InputField
              sx={{ gridColumn: "span 2", mt: "15px" }}
              name="property_details"
              label="Property Details"
              formik={formik}
              multiline
              rows={2}
            />
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Nid"
              checked={Number(formik?.values?.nid) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("nid", e.target.checked ? 1 : 0);
              }}
            />
            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.nid)
                  ? null
                  : formik?.values?.nid_receive_date
              }
            >
              <DateTimePicker
                name="nid_receive_date"
                label={
                  Number(formik?.values?.nid) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Passport"
              checked={Number(formik?.values?.passport) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("passport", e.target.checked ? 1 : 0);
              }}
            />
            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.passport)
                  ? null
                  : formik?.values?.passport_receive_date
              }
            >
              <DateTimePicker
                name="passport_receive_date"
                label={
                  Number(formik?.values?.passport) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <Stack>
            <FormControlLabel
              control={<Checkbox />}
              label="Photo"
              checked={Number(formik?.values?.photo) ? true : false}
              onChange={(e) => {
                formik.setFieldValue("photo", e.target.checked ? 1 : 0);
              }}
            />
            <CheckDateBeforeToday
              givenDate={
                Number(formik?.values?.photo)
                  ? null
                  : formik?.values?.photo_receive_date
              }
            >
              <DateTimePicker
                name="photo_receive_date"
                label={
                  Number(formik?.values?.photo) ? "Receive Date" : "Due Date"
                }
                formik={formik}
              />
            </CheckDateBeforeToday>
          </Stack>
          <InputField
            sx={{ gridColumn: "span 2" }}
            name="note"
            label="Note"
            formik={formik}
            multiline
            rows={3}
          />
          <Box sx={{ gridColumn: "span 2" }}>
            <ProfessionFields
              formik={formik}
              professionTypes={professionTypes}
              professionFields={professionFields}
            />
          </Box>
          <InputField
            sx={{ gridColumn: "span 2" }}
            name="profession_details"
            label="Income Details"
            formik={formik}
            multiline
            rows={3}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
            mt: "20px",
          }}
        >
          <Button
            variant="solid"
            onClick={formik.submitForm}
            fullWidth
            disabled={!(formik.dirty && formik.isValid)}
          >
            {currSponsor?.id ? "Update Sponsor" : "Save Sponsor"}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default AddSponsor;
