import hideIcon from "./hide-icon.svg";
import loginBg from "./login-bg.svg";
// import loginSlider1 from "./login-slider1.svg";
import logo from "./logo.svg";
import success from "./success.svg";
import unauthorized from "./unauthorized.svg";
import userPlaceholder from "./user-placeholder.jpg";
import todoBg from "./todo_bg.jpg";
import lcFiles from "./lc_files.png";
import lcOrganized from "./lc_organized.png";
import lcRealtime from "./lc_realtime.png";
import lcSupport from "./lc_support.png";
import lcTasks from "./lc_tasks.png";
import clientLogin from "./client_login.png";
import fipApk from "./file-icon-pac/apk.svg";
import fipDoc from "./file-icon-pac/doc.svg";
import fipHtml from "./file-icon-pac/html.svg";
import fipIcs from "./file-icon-pac/ics.svg";
import fipImg from "./file-icon-pac/img.svg";
import fipMp3 from "./file-icon-pac/mp3.svg";
import fipPdf from "./file-icon-pac/pdf.svg";
import fipPpt from "./file-icon-pac/ppt.svg";
import fipRar from "./file-icon-pac/rar.svg";
import fipVideo from "./file-icon-pac/video.svg";
import fipXls from "./file-icon-pac/xls.svg";
import fipZip from "./file-icon-pac/zip.svg";
import fipUnknown from "./file-icon-pac/unknown.svg";

const assets = {
  hideIcon,
  loginBg,
  logo,
  success,
  unauthorized,
  userPlaceholder,
  todoBg,
  fipApk,
  fipDoc,
  fipHtml,
  fipIcs,
  fipImg,
  fipMp3,
  fipPdf,
  fipPpt,
  fipRar,
  fipVideo,
  fipXls,
  fipZip,
  fipUnknown,
  clientLogin,
  lcFiles,
  lcOrganized,
  lcRealtime,
  lcSupport,
  lcTasks,
};

export default assets;
