import { Box, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SearchBox from "components/ui/searchBox";
import Select from "components/ui/select";
import useBase from "hooks/useBase";
import { useDebounce } from "hooks/useDebounce";
import useDidMountEffect from "hooks/useDidMountEffect";
import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";

const TodoFilter = ({ filters, setFilters }) => {
  const {
    base: { assignees },
  } = useBase();
  const user_type = useSelector((state) => state.auth.user_details?.role);
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useDidMountEffect(() => {
    setFilters({ ...filters, search: debouncedText });
  }, [debouncedText]);

  return (
    <Box
      sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "15px" }}
      alignItems="center"
      p="15px 20px"
      borderBottom="1px solid #ddd"
    >
      <SearchBox
        isFullWidth
        handleSearch={(text) => setSearchText(text)}
        placeholder={"Search in table.."}
      />
      {user_type === "Admin" ? (
        <Select
          options={assignees}
          label="Filter Staff"
          value={filters?.user_id}
          onChange={(value) => {
            setFilters({
              ...filters,
              user_id: value,
            });
          }}
          selectProps={{
            id: "user_id",
            label: "name",
          }}
        />
      ) : (
        <Box></Box>
      )}
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          label="Todo Date"
          value={filters?.date_from}
          onChange={(newValue) => {
            setFilters({
              ...filters,
              date_from: moment(newValue).isValid()
                ? moment(newValue).format("YYYY-MM-DD")
                : null,
              date_to: moment(newValue).isValid()
                ? moment(newValue).format("YYYY-MM-DD")
                : null,
            });
          }}
          renderInput={(params) => <TextField size="small" {...params} />}
          inputFormat={"DD/MM/YYYY"}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default TodoFilter;
