import { Box } from "@mui/material";
import moment from "moment";

const isGivenDateLessThanToday = (givenDate) => {
  const today = moment();
  return moment(givenDate).isBefore(today, "day");
};

const CheckDateBeforeToday = ({ givenDate = null, children }) => {
  let dateCheck = isGivenDateLessThanToday(givenDate);

  return (
    <Box
      sx={{
        background: dateCheck ? "#fff1f1" : "",
        borderRadius: "4px",
        width: "100%",
        "& > div": {
          width: "100%",
        },
      }}
      key={givenDate}
    >
      {children}
    </Box>
  );
};

export default CheckDateBeforeToday;
