import { Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import Permissions from "components/common/permissions";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";

const CustomerPermissions = ({ customer_id }) => {
  const [customerPermissions, setCustomerPermissions] = useState([]);
  const [openEdit, setOpenEdit] = useState(false);

  const { loading, fetchData } = useApi();
  const getCustomerPermissions = async () => {
    const endpoint = {
      method: "get",
      url: `/api/admin/base/customer/permission/list/${customer_id}`,
    };
    const result = await fetchData(endpoint, false);
    if (result.success) setCustomerPermissions(result?.data);
  };

  useEffect(() => {
    getCustomerPermissions();
  }, []);

  const initialValues = {
    customer_id,
    role_permissions: customerPermissions || {},
  };

  const onSubmit = async (data) => {
    const endpoint = {
      method: "post",
      url: `/api/admin/customer/role/permission`,
      data,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      setCustomerPermissions(result?.data?.role_permissions);
      setOpenEdit(false);
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box p="15px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: "15px",
        }}
      >
        {openEdit ? (
          <>
            <Button
              startIcon={<BiLeftArrowAlt />}
              onClick={() => setOpenEdit(false)}
            >
              View Permissions
            </Button>
            <Button
              disabled={!formik.dirty}
              variant="solid"
              onClick={formik.submitForm}
            >
              Update Permissions
            </Button>
          </>
        ) : (
          <>
            <Box></Box>
            <Button
              variant="outlined"
              startIcon={<Edit />}
              onClick={() => setOpenEdit(true)}
            >
              Edit Permissions
            </Button>
          </>
        )}
      </Box>
      {openEdit ? (
        <div>
          <Table
            sx={{
              border: "1px solid #ddd",
              "& td": {
                border: "1px solid #ddd",
              },
            }}
          >
            <TableBody>
              <TableRow>
                <TableCell>Features</TableCell>
                <TableCell>Permissons</TableCell>
              </TableRow>
              {formik?.values?.role_permissions?.map(
                (singleModule, firstIndex) => (
                  <TableRow key={firstIndex}>
                    <TableCell sx={{ textTransform: "capitalize" }}>
                      {singleModule?.module?.split("-")?.join(" ")}
                    </TableCell>
                    <TableCell>
                      {singleModule?.permission?.map(
                        (singlePermission, secondIndex) => (
                          <Box sx={{ m: "-5px 0" }} key={secondIndex}>
                            <FormControlLabel
                              control={<Checkbox size="small" />}
                              label={
                                singlePermission?.operation
                                  ?.charAt(0)
                                  .toUpperCase() +
                                singlePermission?.operation?.slice(1)
                              }
                              checked={
                                formik?.values?.role_permissions[firstIndex]
                                  ?.permission[secondIndex]?.is_permit
                                  ? true
                                  : false
                              }
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `role_permissions[${firstIndex}].permission[${secondIndex}].is_permit`,
                                  e.target.checked ? 1 : 0
                                );
                              }}
                            />
                          </Box>
                        )
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </div>
      ) : (
        <Permissions role_permissions={customerPermissions} loading={loading} />
      )}
    </Box>
  );
};

export default CustomerPermissions;
