import SetupCC from "components/setupCC";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import moment from "moment";
import * as yup from "yup";

const Holidays = () => {
  const initialValues = {
    title: "",
    description: "",
    date_from: "",
    date_to: "",
    year: null,
  };

  return (
    <SetupCC
      name="Holiday"
      baseRouteKey="holidays"
      moduleKey="holiday"
      columns={[
        { id: "title", label: "Title", minWidth: 100 },
        { id: "description", label: "Description", minWidth: 100 },
        { id: "date_from", label: "Date From", minWidth: 100 },
        { id: "date_to", label: "Due To", minWidth: 100 },
        { id: "year", label: "Year", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        title: yup.string().required("Title is required!"),
        date_from: yup.string("").required().nullable(),
        date_to: yup.string("").required().nullable(),
        year: yup.string("").required("Year is required!").nullable(),
      })}
      allFields={(formik) => (
        <>
          <InputField name="title" label="Title *" formik={formik} />
          <InputField
            name="description"
            label="Description"
            formik={formik}
            multiline
            rows={3}
          />
          <DateTimePicker
            name="date_from"
            label="Date From *"
            formik={formik}
          />

          <DateTimePicker
            name="date_to"
            label="Due To *"
            formik={formik}
            disabled={!formik.values.date_from}
            shouldDisableDate={(date) => {
              const selectedDateFrom = moment(formik.values.date_from);
              return moment(date).isBefore(selectedDateFrom, "day");
            }}
          />
          <DateTimePicker
            views={["year"]}
            name="year"
            label="Year *"
            formik={formik}
          />
        </>
      )}
    ></SetupCC>
  );
};

export default Holidays;
