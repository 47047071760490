import { Box, Button } from "@mui/material";
import { viewStuCom } from "features/invoices/commissions/api/commissions";
import useApiCall from "hooks/useApiCall";
import useQueries from "hooks/useQueries";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import AddEditCommission from "./AddEditCommission";
import CommissionTable from "./CommissionTable";

const Commission = ({ profile, symbol }) => {
  let { row_id } = useQueries();
  const [addCommission, setAddCommission] = useState(false);
  const [currCommission, setCurrCommission] = useState({});
  const [reload, setReload] = useState(false);

  const [allCommission, setAllCommission] = useState([]);
  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    fetchListData(
      viewStuCom,
      {
        id: row_id,
      },
      false
    );
  }, [reload]);

  useEffect(() => {
    if (data) {
      setAllCommission(data);
    }
  }, [data]);

  return addCommission ? (
    <AddEditCommission
      profile={profile}
      setAddCommission={setAddCommission}
      setReload={setReload}
      currCommission={currCommission}
      setAllCommission={setAllCommission}
      allCommission={allCommission}
    />
  ) : (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Button
          startIcon={<BiPlus />}
          variant="outlined"
          onClick={() => {
            setCurrCommission({});
            setAddCommission(true);
          }}
        >
          Add Commission
        </Button>
      </Box>
      <CommissionTable
        profile={profile}
        symbol={symbol}
        loading={loading}
        allCommission={allCommission}
        setReload={setReload}
        setAddCommission={setAddCommission}
        setCurrCommission={setCurrCommission}
        setAllCommission={setAllCommission}
      />
    </Box>
  );
};

export default Commission;
