import Axios from "lib/Axios";

export const checkin = ({ body_data }) =>
  Axios.post("/api/admin/attendance/create", body_data).then(
    (res) => res.data
  );

export const updateCheckin = ({ body_data, id }) =>
  Axios.put(`/api/admin/attendance/update/${id}`, body_data).then(
    (res) => res.data
  );

export const deleteAttendence = ({ id }) =>
  Axios.delete(`/api/admin/attendance/delete/${id}`).then((res) => res.data);

export const viewAllAttendence = ({ params, body_data }) =>
  Axios.post(`/api/admin/attendance/list?${params}`, body_data).then(
    (res) => res.data
  );

  export const exportAttendanse = ({ body_data }) =>
  Axios.post("/api/admin/attendance/list/export", body_data).then((res) => res.data);

export const checkOut = ({ id }) =>
  Axios.get(`/api/admin/attendance/checkout/${id}`).then((res) => res.data);
