import { PrintRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";

import assets from "assets";
import { processEducationForProfile } from "utils/processData";
import { useRef } from "react";
import ReactLoading from "react-loading";
import ReactToPrint from "react-to-print";

const Profile = ({ profile, loading }) => {
  const customerProfile = processEducationForProfile(profile?.customer);
  const printableContentRef = useRef();

  const getFormatedData = (key, type) => {
    if (profile[key + "_receive_date"]) {
      if (Number(profile[key]) && type === "receive") {
        return moment(profile[key + "_receive_date"]).format("ll");
      } else if (!Number(profile[key]) && type === "due") {
        return moment(profile[key + "_receive_date"]).format("ll");
      }
    }
    return "-";
  };
  const isGivenDateLessThanToday = (givenDateKey) => {
    const today = moment();
    return moment(profile[givenDateKey + "_receive_date"]).isBefore(
      today,
      "day"
    );
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <>
          <ReactToPrint
            documentTitle={`${profile?.customer_name}-${profile?.project_name}`}
            trigger={() => (
              <Button
                variant="outlined"
                startIcon={<PrintRounded />}
                sx={{
                  position: "absolute",
                  right: "3rem",
                  top: "2rem",
                }}
              >
                Print
              </Button>
            )}
            content={() => printableContentRef.current}
          />

          <Box ref={printableContentRef} p={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography variant="h4" mt="10px">
                Personal information
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 3fr",
                padding: "30px 0",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                }}
              >
                <img src={assets?.userPlaceholder} alt="" width={160} />
                <Box sx={{ fontSize: "18px", fontWeight: "500" }}>
                  {customerProfile?.name || "-"}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  padding: "0 20px",
                }}
              >
                <Stack gap="15px">
                  <Box>
                    <Typography variant="h5">Email</Typography>
                    <Typography>{customerProfile?.email || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Phone</Typography>
                    <Typography>{customerProfile?.phone || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Project</Typography>
                    <Typography>{profile?.project_name || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Reference</Typography>
                    <Typography>{profile?.reference_name || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Reference Type</Typography>
                    <Typography>{profile?.reference_type || "-"}</Typography>
                  </Box>
                </Stack>
                <Stack gap="15px">
                  <Box>
                    <Typography variant="h5">Intake</Typography>
                    <Typography>{profile?.intake_name || "-"}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="h5">Assigned</Typography>
                    {profile?.assignees?.map((assignee) => (
                      <Typography>{assignee?.name}</Typography>
                    ))}
                  </Box>
                  <Box>
                    <Typography variant="h5">Status</Typography>
                    <Box
                      sx={{
                        bgcolor: `${profile?.status_color}15`,
                        color: profile?.status_color,
                        textAlign: "center",
                        padding: "3px 5px",
                        borderRadius: "20px",
                        width: "150px",
                        mt: "5px",
                        fontSize: "13px",
                      }}
                    >
                      {profile?.status}
                    </Box>
                  </Box>
                  <Box>
                    <Typography variant="h5">Create at</Typography>
                    <Typography>
                      {profile?.created_at
                        ? moment(profile?.created_at).format("ll")
                        : "-"}
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
            <Box sx={{ pt: "15px", pb: "35px" }}>
              <Typography
                variant="h4"
                sx={{ borderBottom: "1px solid #ddd", pb: "15px", mb: "15px" }}
              >
                Additional Information
              </Typography>
              <Box>{profile?.description || "-"}</Box>
            </Box>
            {customerProfile?.educations?.length > 0 && (
              <Box mt="20px">
                <Typography variant="h4">Educational Information</Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    rowGap: "20px",
                    padding: "20px 0",
                    margin: "20px 0",
                    borderTop: "1px solid #ddd",
                    "& > div:nth-of-type(4), & > div:nth-of-type(5), & > div:nth-of-type(6)":
                      {
                        borderTop: "1px solid #ddd",
                        paddingTop: "20px",
                      },
                  }}
                >
                  {customerProfile?.educations?.map((edu) => (
                    <Stack gap="20px" sx={{ padding: "0 10px" }}>
                      {edu.hasOwnProperty("name") && (
                        <Box>
                          <Typography variant="h5">Exam Type</Typography>
                          <Typography>{edu?.name || "-"}</Typography>
                        </Box>
                      )}
                      {edu.hasOwnProperty("group") && (
                        <Box>
                          <Typography variant="h5">Group</Typography>
                          <Typography>{edu?.group || "-"}</Typography>
                        </Box>
                      )}
                      {edu.hasOwnProperty("subject_name") && (
                        <Box>
                          <Typography variant="h5">Subject</Typography>
                          <Typography>{edu?.subject_name || "-"}</Typography>
                        </Box>
                      )}
                      {edu.hasOwnProperty("passing_year") && (
                        <Box>
                          <Typography variant="h5">Examination Year</Typography>
                          <Typography>{edu?.passing_year || "-"}</Typography>
                        </Box>
                      )}
                      {edu.hasOwnProperty("gpa") && (
                        <Box>
                          <Typography variant="h5">GPA</Typography>
                          <Typography>{edu?.gpa || "-"}</Typography>
                        </Box>
                      )}
                      {edu.hasOwnProperty("grade") && (
                        <Box>
                          <Typography variant="h5">GRADE</Typography>
                          <Typography>{edu?.grade || "-"}</Typography>
                        </Box>
                      )}
                      {edu.hasOwnProperty("board") && (
                        <Box>
                          <Typography variant="h5">Board</Typography>
                          <Typography>{edu?.board || "-"}</Typography>
                        </Box>
                      )}
                      {edu.hasOwnProperty("institute_name") && (
                        <Box>
                          <Typography variant="h5">Institute</Typography>
                          <Typography>{edu?.institute_name || "-"}</Typography>
                        </Box>
                      )}
                      {edu.hasOwnProperty("university_name") && (
                        <Box>
                          <Typography variant="h5">University</Typography>
                          <Typography>{edu?.university_name || "-"}</Typography>
                        </Box>
                      )}
                    </Stack>
                  ))}
                </Box>
              </Box>
            )}
            {customerProfile?.courses?.length > 0 && (
              <Box pt="40px">
                <Typography variant="h4">English Language Course</Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    padding: "20px 0",
                    rowGap: "20px",
                    margin: "20px 0",
                    borderTop: "1px solid #ddd",
                  }}
                >
                  {customerProfile?.courses?.map((course) => (
                    <Box sx={{ padding: "0 10px" }}>
                      <Box
                        sx={{
                          "& table": {
                            borderCollapse: "collapse",
                            width: "100%",
                            marginTop: "10px",
                          },
                          "& td": { border: "1px solid #ddd", padding: "10px" },
                          "& td:first-of-type": {
                            fontWeight: "500",
                          },
                          "& tr:nth-of-type(5) td:last-child": {
                            color: "#d14a22",
                          },
                        }}
                      >
                        <Typography variant="h5" align="center">
                          {course?.course_name}
                        </Typography>
                        <table>
                          <tbody>
                            {course?.course_name === "DUOLINGO" ? (
                              <>
                                <tr>
                                  <td>Literacy</td>
                                  <td>{course?.literacy}</td>
                                </tr>
                                <tr>
                                  <td>Comprehension</td>
                                  <td>{course?.comprehension}</td>
                                </tr>
                                <tr>
                                  <td>Conversation</td>
                                  <td>{course?.conversation}</td>
                                </tr>
                                <tr>
                                  <td>Production</td>
                                  <td>{course?.production}</td>
                                </tr>
                                <tr>
                                  <td>Overall</td>
                                  <td>{course?.overall}</td>
                                </tr>
                              </>
                            ) : (
                              <>
                                <tr>
                                  <td>Listening</td>
                                  <td>{course?.listening}</td>
                                </tr>
                                <tr>
                                  <td>Writing</td>
                                  <td>{course?.writing}</td>
                                </tr>
                                <tr>
                                  <td>Reading</td>
                                  <td>{course?.reading}</td>
                                </tr>
                                <tr>
                                  <td>Speaking</td>
                                  <td>{course?.speaking}</td>
                                </tr>
                                <tr>
                                  <td>Overall</td>
                                  <td>{course?.overall}</td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
            {customerProfile?.jobs?.length > 0 && (
              <Box mt="20px">
                <Typography variant="h4">Job Information</Typography>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr",
                    rowGap: "20px",
                    padding: "20px 0",
                    margin: "20px 0",
                    borderTop: "1px solid #ddd",
                    "& > div:nth-of-type(4), & > div:nth-of-type(5), & > div:nth-of-type(6)":
                      {
                        borderTop: "1px solid #ddd",
                        paddingTop: "20px",
                      },
                  }}
                >
                  {customerProfile?.jobs?.map((job) => (
                    <Stack gap="20px" sx={{ padding: "0 10px" }}>
                      <Box>
                        <Typography variant="h5">Title</Typography>
                        <Typography>{job?.title || "-"}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">Company Name</Typography>
                        <Typography>{job?.company_name || "-"}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">Company Address</Typography>
                        <Typography>{job?.company_address || "-"}</Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">Join Date</Typography>
                        <Typography>
                          {job?.join_date
                            ? moment(job?.join_date).format("ll")
                            : "-"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">End Date</Typography>
                        <Typography>
                          {job?.end_date
                            ? moment(job?.end_date).format("ll")
                            : "-"}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="h5">Duration</Typography>
                        <Typography>{job?.duration || "-"}</Typography>
                      </Box>
                    </Stack>
                  ))}
                </Box>
              </Box>
            )}
            {customerProfile?.preferred_countries?.length > 0 && (
              <Box pt="40px">
                <Typography variant="h4">Preferred Countries</Typography>
                <Box
                  sx={{
                    padding: "20px 0",
                    margin: "20px 0",
                    borderTop: "1px solid #ddd",
                  }}
                >
                  <Box
                    gap="20px"
                    sx={{
                      display: "flex",
                      flexFlow: "wrap",
                      gap: "10px",
                      "& h6": {
                        backgroundColor: "#efefef",
                        padding: "5px 10px",
                        borderRadius: "5px",
                        fontWeight: "400",
                      },
                    }}
                  >
                    {customerProfile?.preferred_countries?.map(({ name }) => (
                      <Typography variant="h6">{name}</Typography>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
            <Box>
              <Typography
                variant="h4"
                sx={{ borderBottom: "1px solid #ddd", py: "20px", mb: "20px" }}
              >
                Documents Received
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Document Name</TableCell>
                    <TableCell>Have received?</TableCell>
                    <TableCell>Received Date</TableCell>
                    <TableCell>Due Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Academic Doc</TableCell>
                    <TableCell>
                      {Number(profile?.academic_doc) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {getFormatedData("academic_doc", "receive")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("academic_doc")
                          ? "#e35858"
                          : "",
                      }}
                    >
                      {getFormatedData("academic_doc", "due")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Passport</TableCell>
                    <TableCell>
                      {Number(profile?.passport) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {getFormatedData("passport", "receive")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("passport")
                          ? "#e35858"
                          : "",
                      }}
                    >
                      {getFormatedData("passport", "due")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>CV</TableCell>
                    <TableCell>{Number(profile?.cv) ? "Yes" : "No"}</TableCell>
                    <TableCell>{getFormatedData("cv", "receive")}</TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("cv") ? "#e35858" : "",
                      }}
                    >
                      {getFormatedData("cv", "due")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Photo</TableCell>
                    <TableCell>
                      {Number(profile?.photo) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{getFormatedData("photo", "receive")}</TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("photo")
                          ? "#e35858"
                          : "",
                      }}
                    >
                      {getFormatedData("photo", "due")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Medical Certificate</TableCell>
                    <TableCell>
                      {Number(profile?.medical_certificate) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {getFormatedData("medical_certificate", "receive")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("medical_certificate")
                          ? "#e35858"
                          : "",
                      }}
                    >
                      {getFormatedData("medical_certificate", "due")}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Police Clearance</TableCell>
                    <TableCell>
                      {Number(profile?.police_clearance) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {getFormatedData("police_clearance", "receive")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("police_clearance")
                          ? "#e35858"
                          : "",
                      }}
                    >
                      {getFormatedData("police_clearance", "due")}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Job Doc</TableCell>
                    <TableCell>
                      {Number(profile?.job_doc) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {getFormatedData("job_doc", "receive")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("job_doc")
                          ? "#e35858"
                          : "",
                      }}
                    >
                      {getFormatedData("job_doc", "due")}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Tax Doc</TableCell>
                    <TableCell>
                      {Number(profile?.tax_doc) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {getFormatedData("tax_doc", "receive")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("tax_doc")
                          ? "#e35858"
                          : "",
                      }}
                    >
                      {getFormatedData("tax_doc", "due")}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>ELP Doc</TableCell>
                    <TableCell>
                      {Number(profile?.elp_doc) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {getFormatedData("elp_doc", "receive")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("elp_doc")
                          ? "#e35858"
                          : "",
                      }}
                    >
                      {getFormatedData("elp_doc", "due")}
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>Letter of Recommendation</TableCell>
                    <TableCell>
                      {Number(profile?.letter_of_recommendation) ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>
                      {getFormatedData("letter_of_recommendation", "receive")}
                    </TableCell>
                    <TableCell
                      sx={{
                        color: isGivenDateLessThanToday("letter_of_recommendation")
                          ? "#e35858"
                          : "",
                      }}
                    >
                      {getFormatedData("letter_of_recommendation", "due")}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Profile;
