import { Box } from "@mui/material";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import LatestActivity from "./LatestActivity";
import LatestTasks from "./LatestTasks";
import LeaderBoard from "./LeaderBoard";
import Notice from "./Notice";
import QuickStats from "./QuickStats";
import CustomerSummary from "./charts/CustomerSummary";
import ExpenseAmount from "./charts/ExpenseAmount";
import ExpenseSummary from "./charts/ExpenseSummary";
import InvoiceAmount from "./charts/InvoiceAmount";
import LCPCombo from "./charts/LCPCombo";
import LeadSummary from "./charts/LeadSummary";
import PaymentAmount from "./charts/PaymentAmount";
import PaymentSummary from "./charts/PaymentSummary";
import ProjectSummary from "./charts/ProjectSummary";

const Dashboard = () => {
  const { updateBase } = useBase();
  const { width } = useWindowSize();
  const { fetchData, loading } = useApi();
  const [data, setData] = useState({});
  const [filters, setFilters] = useState({
    lead_customer_project_default_chart: "week",
    week_for_lead_customer_project: "current",
    month_for_lead_customer_project: "",
    year_for_lead_customer_project: null,
    lead_pie_chart_for: "status",
    customer_pie_chart_for: "source",
    project_pie_chart_for: "status",
    status_invoice_line_chart: null,
    invoice_line_chart_for: "week",
    week_invoice_line_chart: "",
    month_invoice_line_chart: "",
    year_invoice_line_chart: null,
    payment_methods_line_chart: null,
    payment_line_chart_for: "week",
    week_payment_line_chart: "",
    month_payment_line_chart: "",
    year_payment_line_chart: null,
    expense_payment_methods_line_chart: null,
    expense_category_line_chart: null,
    expense_line_chart_for: "week",
    week_expense_line_chart: "",
    month_expense_line_chart: "",
    year_expense_line_chart: null,
    expense_pie_chart_for: "category",

    customer_convert_start_date: "",
    customer_convert_end_date: "",
    staffs_for_leaderboard: [],

    month_project_success_rate: "",
    year_project_success_rate: null,
    staffs_for_project_success_rate: [],

    filterEntry: [
      "notice",
      "count",
      "project_board",
      "convert_board",
      "invoice_amount",
      "lcpCombo",
      "expense_amount",
      "payment_amount",
      "lead_summery",
      "customer_summery",
      "project_summery",
      "expense_summery",
    ],
  });

  useEffect(() => {
    const getDashboardData = async () => {
      const endpoint = {
        method: "post",
        url: "/api/admin/dashboard",
        data: filters,
      };
      const result = await fetchData(endpoint, false);
      if (result?.success) {
        setData(result.data);
      }
    };
    getDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    updateBase(["taskStatuses"]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const props = {
    loading,
    data,
    filters,
    setFilters,
  };
  return (
    <Box sx={{ p: "20px" }}>
      <Notice
        {...{
          name: "notice",
          loading,
          notice: data?.notice_board,
          filters,
        }}
      />

      <QuickStats {...props} />
        <LeaderBoard
          data_name="customer_convert_leaderboard"
          title="Converted Customers"
          name="convert_board"
          {...props}
          start_date="customer_convert_start_date"
          end_date="customer_convert_end_date"
          staff_filter="staffs_for_leaderboard"
          isHide={false}
        />
        <LeaderBoard
          data_name="project_customer_leaderboard"
          title="Project Success Rate"
          name="project_board"
          {...props}
          year_filter="year_project_success_rate"
          month_filter="month_project_success_rate"
          staff_filter="staffs_for_project_success_rate"
          isHide
        />
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: width < 1300 ? "1fr" : "auto 400px",
          gap: "20px",
          mb: "20px",
        }}
      >
        <Box>
          <LatestTasks {...props} />
        </Box>
        <LatestActivity {...props} />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(475px, 1fr))",
          gap: "20px",
          mb: "20px",
        }}
      >
        <LeadSummary {...props} />
        <CustomerSummary {...props} />
        <ProjectSummary {...props} />
      </Box>
      <Box sx={{ mb: "20px" }}>
        <LCPCombo {...props} />
      </Box>
      <Box sx={{ mb: "20px" }}>
        <InvoiceAmount {...props} />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: width < 1150 ? "1fr" : "auto 400px",
          gap: "20px",
          mb: "20px",
        }}
      >
        <Box>
          <PaymentAmount {...props} />
        </Box>
        <PaymentSummary {...props} />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: width < 1150 ? "1fr" : "auto 400px",
          gap: "20px",
          mb: "20px",
        }}
      >
        <Box>
          <ExpenseAmount {...props} />
        </Box>
        <ExpenseSummary {...props} />
      </Box>
    </Box>
  );
};

export default Dashboard;
