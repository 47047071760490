import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const AttachmentsSkeleton = () => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "50px auto 150px",
        alignItems: "center",
        padding: "15px 10px",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Skeleton variant="rounded" width={40} height={50} />
      </Box>
      <Box>
        <Skeleton variant="text" sx={{ fontSize: "1rem", maxWidth: "400px" }} />
        <Skeleton variant="text" sx={{ fontSize: "1rem", maxWidth: "100px" }} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "10px",
        }}
      >
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
      </Box>
    </Box>
  );
};

export default AttachmentsSkeleton;
