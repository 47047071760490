import { Box } from "@mui/material";
import UNIVERSAL from "config";

const ViewAttachment = ({ setViewAtt, file }) => {
  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: "100",
        top: "0",
        left: "0",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        backgroundColor: "#00000094",
      }}
      onClick={() => setViewAtt(false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          "& img": {
            maxHeight: "calc(100% - 40px)",
            maxWidth: "calc(100% - 40px)",
          },
        }}
      >
        <img
          src={UNIVERSAL.BASEURL + file?.image}
          alt=""
          onClick={(e) => e.stopPropagation()}
        />
      </Box>
    </Box>
  );
};

export default ViewAttachment;
