import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import useQueries from "hooks/useQueries";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";

const Header = ({ setSearch, setPagination, pagination }) => {
  const { setQueries } = useQueries();
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useEffect(() => {
    setSearch(debouncedText || "");
    setPagination({ ...pagination, current_page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in table.."}
        />
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          variant={"outlined"}
          startIcon={<BiPlus />}
          onClick={() => setQueries("m=0")}
        >
          New Knowledge
        </Button>
      </Box>
    </Grid>
  );
};

export default Header;
