import Axios from "lib/Axios";

export const knowledgeBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/knowledge/bulk/action", body_data).then(
    (res) => res.data
  );
export const updateKnowledgeStatus = ({ body_data }) =>
  Axios.post("/api/admin/knowledge/status", body_data).then((res) => res.data);

export const addComment = ({ body_data }) =>
  Axios.post("/api/knowledge/comment/add", body_data).then((res) => res.data);
