import { Box, TableCell, TableRow } from "@mui/material";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  selectedRows,
  handleTableCheckBox,
  permissions,
  ...restProps
}) => {
  return (
    <TableRow
      {...restProps}
      hover
      role="checkbox"
      key={row.id}
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 20px",
        },
      }}
    >
      {columns.map((column, i) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.id === "bonus_status" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: `${value === "No" ? "#f90606" : "#11c327"}15`,
                    color: `${value === "No" ? "#f90606" : "#11c327"}`,
                    textAlign: "center",
                    padding: "3px 5px",
                    borderRadius: "20px",
                    width: "100px",
                    mt: "5px",
                    fontSize: "13px",
                  }}
                >
                  {value}
                </Box>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
