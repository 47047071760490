import { Checkbox, FormControlLabel } from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useSelector } from "react-redux";
import * as yup from "yup";

const EmbassyFields = () => {
  const projects = useSelector((state) => state?.base?.base_data?.projects);
  const initialValues = {
    project_id: "",
    field_name: "",
    is_require: 0,
  };

  return (
    <SetupCC
      name="Embassy Field"
      baseRouteKey="embassyFields"
      moduleKey="project-customer-embassy-field"
      columns={[
        { id: "field_name", label: "Field Name", minWidth: 100 },
        { id: "project_name", label: "Project", minWidth: 100 },
        { id: "is_require", label: "Is Require?", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "is_require" ? (value === 1 ? "Yes" : "") : value}
          </>
        );
      }}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        field_name: yup.string().required("Field name is required!"),
        project_id: yup.string().required("Job type is required!"),
      })}
      allFields={(formik) => (
        <>
          <Select
            label="Select Project *"
            name="project_id"
            options={projects}
            selectProps={{ id: "id", label: "name" }}
            formik={formik}
          />
          <InputField name="field_name" label="Field Name *" formik={formik} />
          <FormControlLabel
            control={<Checkbox />}
            label="Is require?"
            checked={formik.values.is_require}
            onChange={(e) =>
              formik.setFieldValue("is_require", e.target.checked)
            }
          />
        </>
      )}
    ></SetupCC>
  );
};

export default EmbassyFields;
