import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { memo } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";

const Permissions = ({
  formik,
  currRolePermission,
  roles,
  role_id,
  loading,
}) => {
  const isAdmin = useSelector((state) => state.auth.user_details.role);

  const item = formik?.values?.role_permissions;

  return loading ? (
    <Box
      sx={{
        height: "calc(100vh - 143px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <div>
      <Box
        sx={{
          my: "24px",
          display: "flex",
          gap: "30px",
          justifyContent: "end",
          px: "10px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            justifyContent: "flex-end",
          }}
        >
          {isAdmin?.toLowerCase() === "admin" && (
            <>
              <Button
                onClick={() => {
                  formik.setFieldValue("reset", true);
                  formik.setFieldValue(
                    "role_permissions",
                    roles.find((role) => Number(role.id) === Number(role_id))
                      ?.role_permissions
                  );
                }}
                variant="outlined"
              >
                Reset Permissions
              </Button>

              {formik.values.reset && (
                <Button
                  onClick={() => {
                    formik.setFieldValue("reset", false);
                    formik.setFieldValue(
                      "role_permissions",
                      currRolePermission
                    );
                  }}
                  variant="outlined"
                >
                  Cancel Reset
                </Button>
              )}
            </>
          )}
        </Box>
      </Box>
      <TableOpt item={item} formik={formik} />
    </div>
  );
};

export default Permissions;

const TableOpt = memo(({ item, formik }) => {
  return (
    <Table
      sx={{
        border: "1px solid #ddd",
        "& td": {
          border: "1px solid #ddd",
        },
      }}
    >
      <TableBody>
        <TableRow>
          <TableCell>Features</TableCell>
          <TableCell>Permissons</TableCell>
        </TableRow>
        {item?.map((singleModule, firstIndex) => (
          <TableRow key={singleModule?.module}>
            <TableCell sx={{ textTransform: "capitalize" }}>
              {singleModule?.module?.split("-")?.join(" ")}
            </TableCell>
            <TableCell>
              {singleModule?.permission?.map(
                (singlePermission, secondIndex) => (
                  <Box sx={{ m: "-5px 0" }}>
                    <FormControlLabel
                      control={<Checkbox size="small" />}
                      label={
                        singlePermission?.operation?.charAt(0).toUpperCase() +
                        singlePermission?.operation?.slice(1)
                      }
                      checked={
                        formik?.values?.role_permissions[firstIndex]
                          ?.permission[secondIndex]?.is_permit
                          ? true
                          : false
                      }
                      onChange={(e) => {
                        formik.setFieldValue(
                          `role_permissions[${firstIndex}].permission[${secondIndex}].is_permit`,
                          e.target.checked ? 1 : 0
                        );
                      }}
                    />
                  </Box>
                )
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});
