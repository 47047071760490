import { Box, Typography } from "@mui/material";
import getFileIcon from "../utils/getFileIcon";

const FileTypes = ({ setOnlyFilesScreen, setFilters }) => {
  const fileTypes = [
    { iconKey: "pdf", name: "Pdf", extension: ["pdf"] },
    { iconKey: "doc", name: "Doc", extension: ["doc", "docx"] },
    { iconKey: "xls", name: "Sheet", extension: ["xls", "xlsx"] },
    {
      iconKey: "jpg",
      name: "Image",
      extension: ["jpg", "png", "webp", "jpeg", "gif"],
    },
    { iconKey: "mp3", name: "Audio", extension: ["mp3"] },
    { iconKey: "mp4", name: "Video", extension: ["avi", "mp4", "mov"] },
    { iconKey: "ppt", name: "PPT", extension: ["ppt", "pptx"] },
    { iconKey: "html", name: "HTML", extension: ["html"] },
    { iconKey: "apk", name: "Apk", extension: ["apk"] },
  ];

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, 120px)",
        gap: "15px",
      }}
    >
      {fileTypes?.map((ft) => (
        <Box
          onClick={() => {
            setFilters({ extension: ft.extension });
            setOnlyFilesScreen(ft.name);
          }}
          key={ft?.name}
          sx={{
            bgcolor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 5px #efefef",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "35px",
              py: "10px",
              "& img": {
                maxHeight: "80px",
                maxWidth: "100%",
              },
            }}
          >
            <img
              src={getFileIcon({ extension: ft?.iconKey, isFileType: true })}
              alt=""
            />
          </Box>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontWeight: "500",
              color: "#666",
              textAlign: "center",
            }}
          >
            {ft?.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default FileTypes;
