import { LinearProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import { TbUsers } from "react-icons/tb";
import ReactLoading from "react-loading";

const Summary = ({ filters, search }) => {
  const { loading, fetchData } = useApi();
  const [customerSummary, setCustomerSummary] = useState([]);

  useEffect(() => {
    const getCustomerSummary = async () => {
      const endpoint = {
        method: "post",
        url: "/api/admin/customer/summery",
        data: { ...filters, search },
      };
      const result = await fetchData(endpoint, false);
      setCustomerSummary(result?.data || {});
    };
    getCustomerSummary();
  }, [search, filters]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 145px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box
          sx={{
            p: "10px 20px",
            "& > div": {
              width: "100%",
              maxWidth: "500px",
              bgcolor: "#fff",
              borderRadius: "3px",
              boxShadow: "0 1px 15px 1px rgba(90,90,90,.08)",
              p: "15px",
              "& > div": {
                display: "grid",
                gridTemplateColumns: "30px auto 100px",
                alignItems: "center",
                gap: "5px",
                mb: "10px",
                "& > p:first-of-type": {
                  backgroundColor: "#efefef",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "26px",
                  width: "26px",
                  borderRadius: "3px",
                  fontSize: "17px",
                  color: "#666",
                },
                "& > p:nth-of-type(3)": {
                  textAlign: "right",
                },
              },
            },
          }}
        >
          <Box>
            <Box>
              <Typography>
                <TbUsers />
              </Typography>
              <Typography>Active customers</Typography>
              <Typography>
                {customerSummary?.active_customer +
                  "/" +
                  customerSummary?.total_customer}
              </Typography>
            </Box>
            <LinearProgress
              color="primary"
              value={
                (customerSummary?.active_customer /
                  customerSummary?.total_customer) *
                100
              }
              variant="determinate"
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Summary;
