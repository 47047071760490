import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import React from "react";
import { useSelector } from "react-redux";
import { TableVirtuoso } from "react-virtuoso";
import MainTableRow from "./MainTableRow";

const ExpandableRow = ({ context, item: row, ...restProps }) => {
  return <MainTableRow {...restProps} {...context} key={row.id} row={row} />;
};

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: ExpandableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const MainTable = ({
  allAttendence,
  handleDelete,
  handleEdit,
  pagination,
  setPagination,
  loading,
  handleCheckOut,
  setReload,
  permissions,
}) => {
  const role = useSelector((state) => state?.auth?.user_details.role);
  const rows = allAttendence;
  const data = [
    { id: "date", label: "Date", style: { minWidth: "100px" } },
    { id: "name", label: "Name" },
    { id: "start_time", label: "In Time" },
    { id: "end_time", label: "Out Time" },
    { id: "default_checkin", label: "Default Checkin" },
    { id: "default_checkout", label: "Default checkout" },
    { id: "present", label: "Present" },
    { id: "leave", label: "Leave" },
    { id: "leave_type", label: "Leave Type" },
    { id: "absent", label: "Absent" },
    { id: "is_holiday", label: "Holiday" },
    { id: "is_weekend", label: "Weekend" },
    { id: "late", label: "Late" },
    { id: "late_hour", label: "Late Hour" },
    { id: "overtime", label: "Overtime Hour" },
    { id: "working_hour_due", label: "Working Hour Due" },
    { id: "total_hour", label: "Total Hour" },
    { id: "ip_check_in", label: "Check In IP" },
    { id: "ip_check_out", label: "Check Out IP" },
    { id: "ip_for_create", label: "Created IP" },
    { id: "ip_for_update", label: "Updated IP" },
    { id: "created_by_name", label: "Created By" },
    { id: "updated_by_name", label: "Updated By" },
    { id: "checkin_by_name", label: "Check In By" },
    { id: "checkout_by_name", label: "Check Out By" },

    { id: "note", label: "Note", style: { width: "220px" } },
    {
      id: "action",
      label: "Actions",
      style: { width: "170px" },
      align: "center",
    },
  ];
  let columns;

  if (role === "Admin") {
    columns = data;
  } else {
    const objectsToRemove = [
      "ip_check_in",
      "ip_check_out",
      "ip_for_update",
      "ip_for_create",
      "working_hour_due",
      "created_by_name",
      "updated_by_name",
      "checkin_by_name",
      "checkout_by_name",
    ];
    columns = data.filter((obj) => !objectsToRemove.includes(obj.id));
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {!loading ? (
        <TableContainer sx={{ overflowX: "auto" }}>
          <TableVirtuoso
            style={{ height: "calc(100vh - 198px)" }}
            data={rows}
            components={TableComponents}
            context={{
              columns,
              handleDelete,
              handleEdit,
              permissions,
              handleCheckOut,
            }}
            fixedHeaderContent={() => (
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 16px 10px 20px",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    sx={{ bgcolor: "#fff" }}
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          />
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 198px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Paper>
  );
};

export default MainTable;
