import { Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import useQueries from "hooks/useQueries";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import { BsTable } from "react-icons/bs";
import { TbLayoutKanban } from "react-icons/tb";

const TasksHeader = ({
  setSearch,
  setPagination,
  pagination,
  kanban,
  setKanban,
  permissions,
}) => {
  const { setQueries } = useQueries();
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useEffect(() => {
    setSearch(debouncedText || "");
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        {!kanban &&
          !!(permissions["list(own)"] || permissions["list(global)"]) && (
            <SearchBox
              handleSearch={(text) => setSearchText(text)}
              placeholder={"Search in table.."}
            />
          )}
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          variant={"outlined"}
          startIcon={
            !kanban ? (
              <TbLayoutKanban
                style={{
                  fontSize: "18px",
                }}
              />
            ) : (
              <BsTable
                style={{
                  fontSize: "16px",
                }}
              />
            )
          }
          onClick={() => setKanban((prev) => !prev)}
        >
          {!kanban ? "Kanban View" : "Table View"}
        </Button>
        {!kanban && (
          <Button
            variant={"outlined"}
            startIcon={<BiPlus />}
            onClick={() => setQueries("m=0")}
            disabled={!permissions.create}
          >
            New Task
          </Button>
        )}
      </Box>
    </Grid>
  );
};

export default TasksHeader;
