const checkFiltering = (filters) => {
  for (const key in filters) {
    if (Array.isArray(filters[key])) {
      if (filters[key].length > 0) return true;
    } else {
      if (filters[key]) return true;
    }
  }
  return false;
};

export default checkFiltering;
