import { Box, Button, Checkbox, FormControlLabel, Stack } from "@mui/material";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import LoadingBtn from "components/ui/loadingBtn";
import Select from "components/ui/select";
import { viewEmbassyDetails } from "features/projectCustomers/api/projectCustomer";
import CheckDateBeforeToday from "features/projectCustomers/utils/CheckDateBeforeToday";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import { useEffect } from "react";
import { toast } from "react-toastify";
import * as yup from "yup";
import ReactLoading from "react-loading";

const Embassy = ({ customer_id, project_id, project_customer_id }) => {
  const { base } = useBase();
  const { fetchData: fetchEmbassy, loading: loadingFetchEmbassy } = useApi();
  const { fetchData, loading } = useApi();
  const embassyFields = base?.embassyFields
    ?.filter((field) => field?.project_id === project_id)
    ?.reverse();

  const initialValues = {
    apply_with: "",
    note: "",
    field_values:
      embassyFields?.map((ef) => {
        return {
          is_require: ef?.is_require,
          field_name: ef.field_name,
          p_c_embassy_field_id: ef.id,
          value_bool: 0,
          value_date: null,
        };
      }) || [],
  };

  useEffect(() => {
    const getEmbassyDetails = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/project-customer/embassy/details/${project_customer_id}`,
      };
      const result = await fetchEmbassy(endpoint, false);
      if (result.success) {
        const data = getInitialValues(result?.data || {});
        formik.setValues(data);
      }
    };
    getEmbassyDetails();
  }, []);

  const validationSchema = yup.object().shape({
    apply_with: yup.string().required("This field is Required"),
  });

  const getInitialValues = (data) => {
    if (data?.id) {
      let obj = {};
      for (const key in initialValues) {
        if (key === "field_values") {
          if (data[key]?.length) {
            obj[key] = data[key]?.map((ef) => {
              return {
                field_name: ef.field_name,
                is_require: ef?.is_require,
                p_c_embassy_field_id: ef.p_c_embassy_field_id,
                value_bool: ef.value_bool !== null ? ef.value_bool : 0,
                value_date: ef.value_date,
              };
            });
          } else {
            obj[key] =
              embassyFields?.map((ef) => {
                return {
                  field_name: ef.field_name,
                  is_require: ef?.is_require,
                  p_c_embassy_field_id: ef.id,
                  value_bool: 0,
                  value_date: null,
                };
              }) || [];
          }
        } else {
          obj[key] = data[key];
        }
      }
      return obj;
    } else return initialValues;
  };

  const onSubmit = async (data) => {
    data.customer_id = customer_id;
    data.project_id = project_id;
    data.project_customer_id = project_customer_id;

    const endpoint = {
      method: "post",
      url: "/api/admin/project-customer/embassy",
      data,
    };
    const result = await fetchData(endpoint);
    if (!result.success) toast.error(result?.message);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const checkValidation = () => {
    const obj = formik.values;
    if (!formik.values.apply_with) return false;
    for (const field of obj.field_values) {
      if (field.is_require === 1 && field.value_date === null) {
        return false;
      }
    }
    return true;
  };

  return loadingFetchEmbassy ? (
    <Box
      sx={{
        height: "calc(100vh - 143px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <Box>
      <Box
        sx={{
          height: "calc(100vh - 210px)",
          overflowY: "auto",
          mx: "-20px",
          px: "20px",
          mb: "10px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "15px",
            py: "5px",
          }}
        >
          <Select
            sx={{ gridColumn: "span 2" }}
            options={[
              { id: "single", label: "Single" },
              { id: "spouse", label: "Spouse" },
              { id: "kid", label: "Kid" },
              { id: "spouse and kid", label: "Spouse and Kid" },
              { id: "spouse and kid 2", label: "Spouse and Kid 2" },
              { id: "spouse and kid 3", label: "Spouse and Kid 3" },
              { id: "spouse and kid 4", label: "Spouse and Kid 4" },
            ]}
            label="Apply with *"
            value={formik?.values?.apply_with}
            onChange={(value) => formik.setFieldValue("apply_with", value)}
          />
          <InputField
            sx={{ gridColumn: "span 2" }}
            name="note"
            label="Note"
            formik={formik}
            multiline
            rows={3}
            InputLabelProps={{ shrink: formik.values.note ? true : false }}
          />
          {formik.values?.field_values?.length > 0 &&
            formik.values?.field_values?.map((fv, index) => (
              <Stack>
                <FormControlLabel
                  control={<Checkbox />}
                  label={`${fv?.field_name} ${fv?.is_require ? "*" : ""}`}
                  checked={
                    Number(formik?.values?.field_values[index]?.value_bool)
                      ? true
                      : false
                  }
                  onChange={(e) => {
                    formik.setFieldValue(
                      `field_values[${index}].value_bool`,
                      e.target.checked ? 1 : 0
                    );
                  }}
                />
                <CheckDateBeforeToday
                  givenDate={
                    !Number(formik?.values?.field_values[index]?.value_bool)
                      ? formik?.values?.field_values[index]?.value_date
                      : null
                  }
                >
                  <DateTimePicker
                    isComplex={true}
                    name={`field_values[${index}].value_date`}
                    label={
                      Number(formik?.values?.field_values[index]?.value_bool)
                        ? "Receive Date"
                        : "Due Date"
                    }
                    formik={formik}
                  />
                </CheckDateBeforeToday>
              </Stack>
            ))}
        </Box>
      </Box>
      {loading ? (
        <LoadingBtn />
      ) : (
        <Button
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={!checkValidation()}
        >
          Save Informations
        </Button>
      )}
    </Box>
  );
};

export default Embassy;
