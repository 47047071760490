import { Badge, IconButton, Menu } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import useWindowSize from "hooks/useWindowSize";
import { useState } from "react";
import { GoTasklist } from "react-icons/go";
import { useSelector } from "react-redux";
import Todo from "./todo";
import { setTodoCount } from "lib/Reducer/features/todo/todoSlice";
import { useDispatch } from "react-redux";
import useApi from "hooks/useApi";
import { useEffect } from "react";

const HeaderTodo = () => {
  const { isMini } = useWindowSize();
  let count = useSelector((state) => state?.todo?.count);
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let dispatch = useDispatch();
  let { fetchData } = useApi();
  useEffect(() => {
    async function callApi() {
      let endpoint = {
        method: "get",
        url: "/api/admin/todo/unfinished",
      };
      let result = await fetchData(endpoint, false);
      if (result?.success) {
        dispatch(setTodoCount(result?.data?.totalUnfinished));
      }
    }
    callApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Tooltip title="Todo">
        <Badge
          badgeContent={count}
          max={99}
          color="red"
          sx={{
            color: "#fff",
            fontWeight: "500",
          }}
          className="ntf-badge"
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={handleClick}
          >
            <GoTasklist color="#405189" />
          </IconButton>
        </Badge>
      </Tooltip>

      <Menu
        sx={{ "& ul": { p: 0 } }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            filter: "drop-shadow(0px 5px 8px rgba(0,0,0,0.1))",
            mt: 1.5,
            ml: "11px",
            width: "100%",
            maxWidth: isMini ? "400px" : "550px",
            height: "calc(100vh - 70px)",
          },
        }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Todo />
      </Menu>
    </>
  );
};

export default HeaderTodo;
