import { Box, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import AddSponsor from "./AddSponsor";
import SponsorTable from "./SponsorTable";
import useApi from "hooks/useApi";

const Sponsor = ({
  project_customer_id,
  assignees,
  customer_id,
  project_id,
}) => {
  const [addSponsor, setAddSponsor] = useState(false);
  const [currSponsor, setCurrSponsor] = useState({});
  const [allSponsor, setAllSponsor] = useState([]);
  const [reload, setReload] = useState(false);

  const { loading, fetchData } = useApi();

  useEffect(() => {
    (async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/project-customer/sponsor/list/${project_customer_id}`,
      };
      const res = await fetchData(endpoint, false);
      if (res.success) setAllSponsor(res?.data);
    })();
  }, [reload]);

  return addSponsor ? (
    <AddSponsor
      setAddSponsor={setAddSponsor}
      project_customer_id={project_customer_id}
      assignees={assignees}
      setReload={setReload}
      currSponsor={currSponsor}
      customer_id={customer_id}
      project_id={project_id}
    />
  ) : (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        <Button
          fullWidth
          startIcon={<BiPlus />}
          variant="outlined"
          onClick={() => {
            setCurrSponsor({});
            setAddSponsor(true);
          }}
        >
          New Sponsor
        </Button>
      </Box>
      <SponsorTable
        loading={loading}
        allSponsor={allSponsor}
        setReload={setReload}
        setAddSponsor={setAddSponsor}
        setCurrSponsor={setCurrSponsor}
        setAllSponsor={setAllSponsor}
      />
    </Box>
  );
};

export default Sponsor;
