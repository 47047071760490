import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import store from "lib/store";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import theme from "theme";

function AppProviders({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {children}
        <CssBaseline />
        <ToastContainer
          position="bottom-left"
          autoClose={3000}
          // limit={3}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      </Provider>
    </ThemeProvider>
  );
}

export default AppProviders;
