import { FormControlLabel, Switch } from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import * as yup from "yup";

const SponsorJobFields = () => {
  const [jobTypes, setJobTypes] = useState([]);
  const { fetchData } = useApi();

  const initialValues = {
    pc_sponsor_job_id: "",
    field_name: "",
    status: 1,
  };

  useEffect(() => {
    (async () => {
      const { success, data } = await fetchData(
        {
          method: "get",
          url: "/api/admin/base/project-customer-sponsor-job",
        },
        false
      );
      if (success) setJobTypes(data);
    })();
  }, []);

  return (
    <SetupCC
      name="Sponsor Profession Field"
      baseRouteKey="PCSJobFields"
      moduleKey="project-customer-sponsor-job-field"
      columns={[
        { id: "field_name", label: "Field Name", minWidth: 100 },
        { id: "job_name", label: "Profession Type", minWidth: 100 },
        { id: "status", label: "Status", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "status" ? (
              row["status"] ? (
                <span>Active</span>
              ) : (
                <span>Inactive</span>
              )
            ) : (
              value
            )}
          </>
        );
      }}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        field_name: yup.string().required("Field name is required!"),
        pc_sponsor_job_id: yup
          .string()
          .required("Profession type is required!"),
      })}
      allFields={(formik) => (
        <>
          <Select
            label="Select Profession Type"
            name="pc_sponsor_job_id"
            options={jobTypes}
            selectProps={{ id: "id", label: "job_name" }}
            formik={formik}
          />
          <InputField name="field_name" label="Field Name" formik={formik} />
          <FormControlLabel
            control={
              <Switch
                onChange={(e) => {
                  formik.setFieldValue("status", e.target.checked ? 1 : 0);
                }}
                checked={Number(formik?.values?.status) ? true : false}
              />
            }
            label="Active"
          />
        </>
      )}
    ></SetupCC>
  );
};

export default SponsorJobFields;
