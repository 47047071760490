import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import UNIVERSAL from "config";
import moment from "moment";
import ReactLoading from "react-loading";

const View = ({ post, loading }) => {
  return loading ? (
    <Box
      sx={{
        height: "calc(100vh - 143px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Stack spacing={1} direction={"row"} alignItems={"center"}>
          <Avatar
            alt={post?.created_by_name}
            src={UNIVERSAL.BASEURL + post?.created_by_image}
          />

          <Stack>
            <Typography variant="span">{post?.created_by_name}</Typography>
            <Typography variant="span">
              {moment(post?.created_at).format("ll")}
            </Typography>
          </Stack>
        </Stack>

        <Box>
          <Typography
            variant="span"
            sx={{
              color: post?.status === 1 ? "#0db30d" : "#ff564a",
            }}
          >
            {post?.status === 1 ? "Active" : "Inactive"}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h2">{post?.name}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Category: {post?.knowledge_base_category_name}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: post?.description,
          }}
        ></Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <ThumbUpIcon color="primary" /> <span>{post?.vote_yes}</span>
          </Box>{" "}
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
            }}
          >
            <ThumbDownIcon color="primary" /> <span>{post?.vote_no}</span>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default View;
