import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApiCall from "hooks/useApiCall";
import useNotificationQuery from "hooks/useNotificationQuery";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { deleteTicket, viewAllTickets } from "../api/ticket";
import AddEdit from "./AddEdit";
import BulkActions from "./BulkActions";
import Header from "./Header";
import MainScreenFilter from "./filter/MainScreenFilter";
import MainTable from "./table/MainTable";
import ViewTicket from "./viewTicket/ViewTicket";
import useQueries from "hooks/useQueries";

const Ticket = () => {
  const { setQueries, openAddEdit, openView } = useQueries();
  const [persistedData, setPersistedData] = useState({});
  const permissions = usePermissions("ticket");
  const [allTickets, setAllTickets] = useState([]);
  // Helper states
  const { base_data } = useSelector((state) => state.base);
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    if (allTickets?.length > 1) {
      setAllTickets(
        allTickets.filter((customer) => customer?.id !== openAlert)
      );
      setOpenAlert(false);
      try {
        const result = await deleteTicket({ id: openAlert });
        if (result.success) {
          toast.success(result?.message);
        } else {
          toast.error(result?.message);
        }
      } catch (error) {}
    } else {
      setOpenAlert(false);
      const result = await deleteTicket({ id: openAlert });
      if (result.success) {
        toast.success(result?.message);
      } else {
        toast.error(result?.message);
      }
      setReload((pre) => !pre);
    }
    setSelectedRows([]);
  };

  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    finalFilters.search = search;
    fetchListData(
      viewAllTickets,
      {
        body_data: finalFilters,
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, search, pagination?.current_page, pagination?.per_page]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllTickets(allData);
      setPagination(rest);
      setSelectedRows([]);
    }
  }, [data]);

  // Bulk options
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTableCheckBox = (id, checked) => {
    if (id === "all") {
      if (checked) {
        setSelectedRows(allTickets?.map((ticket) => ticket?.id));
      } else {
        setSelectedRows([]);
      }
    } else {
      if (checked) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows(selectedRows?.filter((ticket) => ticket !== id));
      }
    }
  };

  const all_props = {
    ...base_data,
    persistedData,
    setPersistedData,
    setReload,
    filters,
    setFilters,
    pagination,
    setPagination,
    allTickets,
    setAllTickets,
    isFiltering,
    permissions,
  };

  return (
    <div>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Ticket"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this ticket
              permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Header
        {...{
          setSearch,
          setPagination,
          pagination,
          permissions,
        }}
      />
      {openAddEdit && (
        <AddEdit
          {...{
            openDrawer: openAddEdit,
            setOpenDrawer: () => setQueries(""),
            ...all_props,
          }}
        />
      )}
      {openView && (
        <ViewTicket
          openDrawer={openView}
          setOpenDrawer={() => setQueries("")}
          {...all_props}
        />
      )}
      {!selectedRows?.length > 0 &&
        !!(permissions["list(own)"] || permissions["list(global)"]) && (
          <MainScreenFilter {...all_props} />
        )}

      {selectedRows?.length > 0 && (
        <BulkActions
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          {...all_props}
        />
      )}
      {permissions["list(global)"] || permissions["list(own)"] ? (
        <MainTable
          handleDelete={handleDelete}
          loading={loading}
          handleTableCheckBox={handleTableCheckBox}
          selectedRows={selectedRows}
          {...all_props}
        />
      ) : (
        <NotAuthorized />
      )}
    </div>
  );
};

export default Ticket;
