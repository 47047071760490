import UNIVERSAL from "config";
import moment from "moment";
import { AiOutlineSkype } from "react-icons/ai";
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import { HiOutlineEnvelope } from "react-icons/hi2";
import { MdOutlineLocalPhone } from "react-icons/md";
import { RiMapPin2Line } from "react-icons/ri";
import { RxHome } from "react-icons/rx";
// MUI
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ReactLoading from "react-loading";
const attnTimeFormat = (time) => moment(time, "HH:mm").format("h:mm A");

const ProfileDetails = ({ profile, handleEdit, setOpenDrawer, loading }) => {
  return loading ? (
    <Box
      sx={{
        height: "calc(100vh - 143px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <div>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "150px auto 120px",
          gap: "10px",
        }}
      >
        <Stack justifyContent="center" alignItems="center">
          <Avatar
            sx={{ height: "130px", width: "130px" }}
            src={UNIVERSAL.BASEURL + profile?.image}
          />
        </Stack>
        <Stack gap="5px" justifyContent="center">
          <Stack direction="row" gap="10px">
            <Typography variant="h4">{profile?.name}</Typography>
            {/* <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "3px",
                color: "#35a935",
              }}
            >
              <CircleRounded sx={{ fontSize: "14px" }} />
              <span>Online</span>
            </Typography> */}
          </Stack>
          <Typography sx={{ mb: "15px" }}>
            {profile?.designation_name}
          </Typography>
          <Typography
            sx={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <MdOutlineLocalPhone fontSize="18px" /> {profile?.phone}
          </Typography>
          <Typography
            sx={{ display: "flex", alignItems: "center", gap: "5px" }}
          >
            <HiOutlineEnvelope fontSize="18px" /> {profile?.email}
          </Typography>
          {/* <Typography sx={{ fontSize: "14px", mt: "10px" }}>
            Added on {moment(profile?.created_at).format("ll")}
          </Typography> */}
        </Stack>
        {/* <Button
          disabled
          variant="outlined"
          startIcon={<Edit />}
          onClick={() => {
            setOpenDrawer(false);
            handleEdit(profile);
          }}
        >
          Edit Staff
        </Button> */}
      </Box>
      <Typography
        variant="h4"
        sx={{ p: "40px 0 10px", borderBottom: "1px solid #ddd" }}
      >
        Personal Informations
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          padding: "20px 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography variant="h5">Department</Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.department_name}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5">Access Role</Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.role}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5">Joining Date</Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {moment(profile?.created_at).format("ll")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography variant="h5">Status</Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              Full time
            </Typography>
          </Box>

          <Box>
            <Typography variant="h5">Shift</Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {attnTimeFormat(profile?.default_checkin)} -
              {attnTimeFormat(profile?.default_checkout)}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5">Reporting Manager</Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>-</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography variant="h5">Created at</Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {moment(profile?.created_at).format("ll")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h5">Weekends</Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.weekends?.length ? profile?.weekends?.join(", ") : "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography
        variant="h4"
        sx={{ p: "40px 0 10px", borderBottom: "1px solid #ddd" }}
      >
        Addresses
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          padding: "20px 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              variant="h5"
            >
              <RiMapPin2Line fontSize="18px" />
              Present address
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.present_address || "-"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              variant="h5"
            >
              <RxHome fontSize="18px" />
              Permanent address
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.permanent_address || "-"}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Typography
        variant="h4"
        sx={{ p: "40px 0 10px", borderBottom: "1px solid #ddd" }}
      >
        Social Media Accounts
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          padding: "20px 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              variant="h5"
            >
              <FaLinkedin fontSize="18px" />
              Linkedin
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.linkedin || "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              variant="h5"
            >
              <FaFacebook fontSize="18px" />
              Facebook
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.facebook || "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              variant="h5"
            >
              <FaInstagram fontSize="18px" />
              Instagram
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.instagram || "-"}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              variant="h5"
            >
              <FaTwitter fontSize="18px" />
              Twitter
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.twitter || "-"}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{ display: "flex", alignItems: "center", gap: "5px" }}
              variant="h5"
            >
              <AiOutlineSkype fontSize="18px" />
              Skype
            </Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.skype || "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography
        variant="h4"
        sx={{ p: "40px 0 10px", borderBottom: "1px solid #ddd" }}
      >
        Signature
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          padding: "20px 0",
        }}
      >
        {profile?.signature ? (
          <img
            src={UNIVERSAL.BASEURL + profile?.signature}
            alt=""
            style={{ height: "50px", objectFit: "cover" }}
          />
        ) : (
          <Typography>-</Typography>
        )}
      </Box>
      <Typography
        variant="h4"
        sx={{ p: "40px 0 10px", borderBottom: "1px solid #ddd" }}
      >
        KYC Details
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          padding: "20px 0",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography variant="h5">NID Image</Typography>
            {profile?.nid ? (
              <img
                src={UNIVERSAL.BASEURL + profile?.nid}
                alt=""
                style={{ height: "200px", objectFit: "cover" }}
              />
            ) : (
              <Typography>-</Typography>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box>
            <Typography variant="h5">NID Number</Typography>
            <Typography sx={{ fontSize: "14px", mt: "5px" }}>
              {profile?.nid_no || "-"}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ProfileDetails;
