import { Box, Button } from "@mui/material";
import { viewAdmissions } from "features/projectCustomers/api/projectCustomer";
import useApiCall from "hooks/useApiCall";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import AddAdmission from "./AddAdmission";
import AdmissionTable from "./AdmissionTable";

const Admission = ({
  project_customer_id,
  assignees,
  customer_id,
  project_id,
}) => {
  const [addAdmission, setAddAdmission] = useState(false);
  const [currAdmission, setCurrAdmission] = useState({});
  const [allAdmission, setAllAdmission] = useState([]);
  const [reload, setReload] = useState(false);

  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    fetchListData(
      viewAdmissions,
      {
        id: project_customer_id,
      },
      false
    );
  }, [reload]);

  useEffect(() => {
    if (data) {
      setAllAdmission(data);
    }
  }, [data]);

  return addAdmission ? (
    <AddAdmission
      setAddAdmission={setAddAdmission}
      project_customer_id={project_customer_id}
      assignees={assignees}
      setReload={setReload}
      currAdmission={currAdmission}
      customer_id={customer_id}
      project_id={project_id}
    />
  ) : (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "15px",
        }}
      >
        {/* <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in task.."}
        /> */}
        <Button
          fullWidth
          startIcon={<BiPlus />}
          variant="outlined"
          onClick={() => {
            setCurrAdmission({});
            setAddAdmission(true);
          }}
        >
          New Admission Details
        </Button>
      </Box>
      <AdmissionTable
        loading={loading}
        allAdmission={allAdmission}
        setReload={setReload}
        setAddAdmission={setAddAdmission}
        setCurrAdmission={setCurrAdmission}
        setAllAdmission={setAllAdmission}
      />
    </Box>
  );
};

export default Admission;
