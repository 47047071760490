import { toast } from "react-toastify";

const call = async (api, data) => {
  try {
    const result = await api(data);
    return result.data;
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.message);
      return error?.response?.data;
    }
  }
};

export default call;
