import { useSearchParams } from "react-router-dom";
import useDidMountEffect from "./useDidMountEffect";

const useNotificationQuery = (reload) => {
  let [queryParms, setSearchParams] = useSearchParams();
  let getParamsId = queryParms.get("id");

  useDidMountEffect(() => {
    if (getParamsId) {
      queryParms.delete("id");
      setSearchParams(queryParms);
    }
  }, [reload]);

  return getParamsId;
};

export default useNotificationQuery;
