import { DownloadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";

const ActivityHeader = ({
  setSearch,
  setPagination,
  pagination,
  permissions,
  search,
  filters,
}) => {
  const { loading, fetchData } = useApi();
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);

  useEffect(() => {
    setSearch(debouncedText || "");
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  const handleExport = async () => {
    const res = await fetchData(
      {
        method: "post",
        url: "/api/admin/report/expense-vs-income/export",
        data: { type: "xlsx", trash_export: false, search, ...filters },
      },
      false
    );
    if (res.success) window.open(res?.data?.file);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      <Grid item>
        {!!permissions["University Target Report"] && (
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in table.."}
          />
        )}
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          startIcon={<DownloadOutlined />}
          onClick={handleExport}
          disabled={!permissions["University Target Report Export"]}
        >
          Export
        </LoadingButton>
      </Box>
    </Grid>
  );
};

export default ActivityHeader;
