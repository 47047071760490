import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import useApi from "hooks/useApi";
import useNotificationQuery from "hooks/useNotificationQuery";
import usePermissions from "hooks/usePermissions";
import useQueries from "hooks/useQueries";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MainScreenFilter from "../components/filter/MainScreenFilter";
import AddEdit from "./AddEdit";
import BulkActions from "./BulkActions";
import Header from "./Header";
import MainTable from "./table/MainTable";
import ViewKnowledge from "./viewKnowledge/ViewKnowledge";

const KnowledgeBaseCategory = () => {
  const { setQueries, openAddEdit, openView } = useQueries();
  const [persistedData, setPersistedData] = useState({});

  const { base_data } = useSelector((state) => state.base);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState();
  const [allPost, setAllPost] = useState([]);
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getPost = async () => {
      let finalFilters = { ...filters };
      let finalPagination = { ...pagination };
      finalFilters.search = search;

      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/knowledge/list?${params}`,
        data: finalFilters,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllPost(allData || []);
        setPagination(rest);
      }
    };
    getPost();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, search]);

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    const endpoint = {
      method: "delete",
      url: `/api/admin/knowledge/delete/${openAlert}`,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      if (allPost?.length > 1) {
        setAllPost(allPost.filter((post) => post?.id !== openAlert));
      }
    } else {
      setReload((pre) => !pre);
    }
  };

  const permissions = usePermissions("knowledge");
  const all_props = {
    ...base_data,
    persistedData,
    setPersistedData,
    setReload,
    filters,
    setFilters,
    isFiltering,
    pagination,
    setPagination,
    allPost,
    setAllPost,
    permissions,
  };

  // Bulk options
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTableCheckBox = (id, checked) => {
    if (id === "all") {
      if (checked) {
        setSelectedRows(allPost?.map((post) => post?.id));
      } else {
        setSelectedRows([]);
      }
    } else {
      if (checked) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows(selectedRows?.filter((post) => post !== id));
      }
    }
  };

  return (
    <Box>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Post"
          desc={
            <Box>
              Are you sure you want to delete this Post? This will delete the
              Post permanently! <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Header
        setSearch={setSearch}
        setPagination={setPagination}
        pagination={pagination}
      />
      {openAddEdit && (
        <AddEdit
          {...{
            openDrawer: openAddEdit,
            setOpenDrawer: () => setQueries(""),
            ...all_props,
          }}
        />
      )}
      {openView && (
        <ViewKnowledge
          openDrawer={openView}
          setOpenDrawer={() => setQueries("")}
          {...all_props}
        />
      )}

      {selectedRows?.length > 0 && (
        <BulkActions
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          {...all_props}
        />
      )}

      {!selectedRows?.length > 0 && <MainScreenFilter {...all_props} />}

      <MainTable
        allPost={allPost}
        handleDelete={handleDelete}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setReload={setReload}
        handleTableCheckBox={handleTableCheckBox}
        selectedRows={selectedRows}
        {...all_props}
      />
    </Box>
  );
};

export default KnowledgeBaseCategory;
