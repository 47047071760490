import { Close } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import UNIVERSAL from "config";
import moment from "moment";
import bytesFormat from "../utils/bytesFormat";
import getFileIcon from "../utils/getFileIcon";

const AttDetails = ({ attDetails, setAttDetails }) => {
  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderLeft: "1px solid #EDEFF2",
        height: "calc(100vh - 65px)",
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          borderBottom: "1px solid #EDEFF2",
          padding: "10px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          onClick={() => setAttDetails(false)}
          sx={{
            border: "1px solid #ddd",
            cursor: "pointer",
            height: "30px",
            width: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Close style={{ fontSize: "20px" }} />
        </Box>
      </Box>
      <Box
        sx={{
          height: "200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          "& img": {
            maxHeight: ["jpg", "jpeg", "png", "gif"].includes(
              attDetails.extension
            )
              ? "90%"
              : "70%",
            maxWidth: "100%",
          },
        }}
      >
        <img src={getFileIcon(attDetails)} alt="" />
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 250px",
          gap: "10px",
          padding: "15px",
        }}
      >
        <Typography fontWeight={600}>File Name</Typography>
        <Typography sx={{ overflowWrap: "break-word" }}>
          {attDetails?.name}
        </Typography>
        <Typography fontWeight={600}>Description</Typography>
        <Typography sx={{ overflowWrap: "break-word" }}>
          {attDetails?.description || "-"}
        </Typography>
        <Typography fontWeight={600}>Related to</Typography>
        <Typography
          sx={{ overflowWrap: "break-word", textTransform: "capitalize" }}
        >
          {attDetails?.related_to || "-"}
        </Typography>
        <Typography fontWeight={600}>Size</Typography>
        <Typography sx={{ overflowWrap: "break-word" }}>
          {bytesFormat(attDetails?.file_size) || "-"}
        </Typography>
        <Typography fontWeight={600}>Modified</Typography>
        <Typography sx={{ overflowWrap: "break-word" }}>
          {moment(attDetails?.updated_at).format("lll") || "-"}
        </Typography>
        <Typography fontWeight={600}>Created</Typography>
        <Typography sx={{ overflowWrap: "break-word" }}>
          {moment(attDetails?.created_at).format("lll") || "-"}
        </Typography>
      </Box>
      <Box
        sx={{
          border: "1px solid #ddd",
          borderRadius: "3px",
          padding: "5px",
          m: "15px",
        }}
      >
        <Typography sx={{ color: "#555", fontSize: "13px", mb: "5px" }}>
          Link for download
        </Typography>
        <Typography
          onClick={() => window.open(UNIVERSAL.BASEURL + attDetails.file)}
          sx={{
            overflowWrap: "break-word",
            color: "#5996e1",
            fontSize: "13px",
            cursor: "pointer",
          }}
        >
          {UNIVERSAL.BASEURL + attDetails.file}
        </Typography>
      </Box>
    </Box>
  );
};

export default AttDetails;
