import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import Board from "./Board";
import Modal from "./Modal";
let modalList = {
  createTask: "createTask",
  updateTask: "updateTask",
  deleteTask: "deleteTask",
  viewTask: "viewTask",
  createSection: "createSection",
  deleteSection: "deleteSection",
  taskComments: "taskComments",
};

const Kanban = ({ permissions }) => {
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);
  const { loading, fetchData } = useApi();
  const [openModal, setOpenModal] = useState({
    modalName: null,
    data: null,
  });
  const handleClose = () => {
    setOpenModal({
      modalName: null,
      data: {},
    });
  };

  useEffect(() => {
    const getTasks = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/task/kanban/list`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData } = result;
        setData(allData || []);
      }
    };
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  const { fetchData: kanbanPosApi } = useApi();

  const onDragEnd = async ({ source, destination }) => {
    if (!destination) return;
    const sourceColIndex = data.findIndex(
      (e) => e.id.toString() === source.droppableId
    );
    const destinationColIndex = data.findIndex(
      (e) => e.id.toString() === destination.droppableId
    );
    const sourceCol = data[sourceColIndex];
    const destinationCol = data[destinationColIndex];

    const sourceTasks = [...sourceCol.tasks];
    const destinationTasks = [...destinationCol.tasks];

    if (source.droppableId !== destination.droppableId) {
      const [removed] = sourceTasks.splice(source.index, 1);
      destinationTasks.splice(destination.index, 0, removed);
      data[sourceColIndex].tasks = sourceTasks;
      data[destinationColIndex].tasks = destinationTasks;
    } else {
      const [removed] = destinationTasks.splice(source.index, 1);
      destinationTasks.splice(destination.index, 0, removed);
      data[destinationColIndex].tasks = destinationTasks;
    }
    const prevIndex = destination.index - 1;
    const nextIndex = destination.index + 1;
    const currIndex = destination.index;
    const currTaskPos = destinationTasks[currIndex] || null;
    const topTaskPos = destinationTasks[prevIndex] || null;
    const bottomTask = destinationTasks[nextIndex] || null;

    const endpoint = {
      method: "post",
      url: `/api/admin/task/kanban/position/change`,
      data: {
        id: currTaskPos?.id,
        task_status_id: destinationCol?.id,
        top_position: topTaskPos?.position || null,
        bottom_position: bottomTask?.position || null,
      },
    };
    const result = await kanbanPosApi(endpoint, false);
    if (result.success) {
      setData(data);
    }
  };

  const onUpdateTask = (task, sectionId) => {
    setOpenModal({
      data: { task, sectionId },
      modalName: modalList.updateTask,
    });
  };

  const createSection = () => {
    setOpenModal({
      data: {},
      modalName: modalList.createSection,
    });
  };

  const createTask = (sectionId) => {
    setOpenModal({
      data: { sectionId },
      modalName: modalList.createTask,
    });
  };

  const deleteSection = (sectionId) => {
    setOpenModal({
      data: { sectionId },
      modalName: modalList.deleteSection,
    });
  };

  const onDeleteTask = (task, sectionId) => {
    setOpenModal({
      data: { taskId: task?.id, sectionId },
      modalName: modalList.deleteTask,
    });
  };
  const onViewTask = (task) => {
    setOpenModal({
      data: task,
      modalName: modalList.viewTask,
    });
  };
  const onTaskComment = (task) => {
    setOpenModal({
      data: task,
      modalName: modalList.taskComments,
    });
  };
  return (
    <>
      <Modal
        {...{
          handleClose,
          openModal,
          modalList,
          setReload,
          setData,
          permissions,
        }}
      />
      <Board
        {...{
          loading,
          onDragEnd,
          data,
          createTask,
          deleteSection,
          onUpdateTask,
          onDeleteTask,
          onViewTask,
          onTaskComment,
          createSection,
          setOpenModal,
          permissions,
        }}
      />
    </>
  );
};

export default Kanban;
