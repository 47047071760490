import { Box, Button } from "@mui/material";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import { BiLeftArrowAlt } from "react-icons/bi";
import * as yup from "yup";

import DateTimePicker from "components/ui/dateTimePicker";
import useApi from "hooks/useApi";
import { toast } from "react-toastify";
import { createExpectedSales, updateExpectedSales } from "../../../api/sales";
import { monthsInformat } from "../../AddEdit";
import { useEffect } from "react";
import MultiSelect from "components/ui/multiSelect";

const AddEditInstallment = ({
  id,
  setAddPayment,
  currPayment,
  setReload,
  profile,
  setReloadMain,
}) => {
  const { loading, fetchData } = useApi();

  const initialValues = {
    installation_invoice_id: profile?.id,
    expected_payment_type: currPayment?.expected_payment_type || "",
    amount: currPayment?.amount || "",
    month: currPayment.id ? currPayment?.month || "" : [],
    year: currPayment?.year || "",
  };

  const onSubmit = async (data) => {
    if (currPayment?.id && data.month.length ) {
      data.month = [data.month];
    }
    let result = await fetchData(
      currPayment?.id
        ? updateExpectedSales(data, currPayment?.id)
        : createExpectedSales(data)
    );
    if (result?.success) {
      setReload((pre) => !pre);
      setReloadMain((pre) => !pre);
      setAddPayment(false);
    } else {
      if (result?.code === 403) {
        toast.error(result?.message);
      }
      if (currPayment?.id) {
        data.month = currPayment.month;
      }
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema: yup.object({
      expected_payment_type: yup.string().required("Required Field").nullable(),
      amount: yup.number().required("Required Field"),

      ...(currPayment?.id
        ? {
            month: yup.mixed().when(["expected_payment_type"], {
              is: "charge",
              then: yup.mixed().required("Required Field").nullable(),
              otherwise: yup.mixed().nullable(),
            }),
          }
        : {
            month: yup.array().when(["expected_payment_type"], {
              is: "charge",
              then: yup.array().required("Required Field").min(1).nullable(),
              otherwise: yup.array().nullable(),
            }),
          }),

      year: yup.string().when(["expected_payment_type"], {
        is: "charge",
        then: yup.string().required("Required Field").nullable(),
        otherwise: yup.string().nullable(),
      }),
    }),
    onSubmit,
    enableReinitialize: true,
  });
  useEffect(() => {
    // if (formik.values.expected_payment_type === "charge") {
    //   formik.setFieldValue("year", currPayment?.year|| "");
    //   formik.setFieldValue("month", currPayment?.month);
    // }
    !currPayment?.id &&
      formik.setFieldValue(
        "amount",
        formik.values.expected_payment_type === "charge"
          ? profile?.charge
          : formik.values.expected_payment_type === "installation_fee"
          ? profile?.installation_fee
          : ""
      );
  }, [formik.values.expected_payment_type]);
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddPayment(false)}
        >
          View Installment
        </Button>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "15px",
          pb: "15px",
        }}
      >
        <Select
          name="expected_payment_type"
          options={[
            {
              id: "installation_fee",
              name: "Installation Fee",
            },
            {
              id: "charge",
              name: "Subscription Fee",
            },
          ]}
          label="Installment *"
          formik={formik}
          selectProps={{ id: "id", label: "name" }}
        />

        <InputField
          name="amount"
          label="Expected Amount *"
          type="number"
          formik={formik}
        />

        {formik.values.expected_payment_type === "charge" && (
          <>
            {currPayment?.id ? (
              <Select
                name="month"
                options={monthsInformat}
                label="Select Months *"
                formik={formik}
                selectProps={{ id: "id", label: "name" }}
              />
            ) : (
              <MultiSelect
                options={monthsInformat}
                label="Select Months *"
                value={formik?.values?.month}
                onChange={(value) => formik.setFieldValue("month", value)}
                selectProps={{ id: "id", label: "name" }}
              />
            )}

            <DateTimePicker
              views={["year"]}
              name="year"
              label="Year *"
              formik={formik}
            />
          </>
        )}

        <Button
          sx={{ gridColumn: "span 2" }}
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={
            (!(formik.dirty && formik.isValid) && !currPayment?.id) || loading
          }
        >
          {currPayment?.id ? "Update Installment" : "Save Installment"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddEditInstallment;
