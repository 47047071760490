import { Box, Button } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { createPayment, updatePayment } from "features/payments/api/payments";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import useQueries from "hooks/useQueries";
import moment from "moment";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import handleError from "utils/handleError";
import validationSchema from "../../schema";

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  setAllPayments,
  allPayments,
}) => {
  const { row_id } = useQueries();
  const isUpdating = row_id ? true : false;
  const [currPayment, setCurrPayment] = useState(row_id ? {} : persistedData);
  const { base, updateBase } = useBase();

  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullPayment = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/payment/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrPayment(result?.data);
    };
    row_id && getFullPayment();
  }, []);

  const {
    unpaidInvoices,
    invoices,
    paymentMethods,
    currencies,
    settings,
    banks,
  } = base;
  const currency_id = settings?.app?.currency_id;

  useEffect(() => {
    updateBase(isUpdating ? ["invoices"] : ["unpaidInvoices"]);
  }, []);

  const { loading, fetchData } = useApi();

  const onSubmit = async (data) => {
    const result = await fetchData(
      isUpdating ? updatePayment(currPayment?.id, data) : createPayment(data)
    );
    if (result?.success) {
      if (isUpdating) {
        setAllPayments(
          allPayments.map((payment) =>
            payment.id === currPayment.id
              ? { ...currPayment, ...result.data }
              : payment
          )
        );
      } else {
        setAllPayments([result.data, ...allPayments]);
      }
      handleClose("reset");
    } else {
      handleError(result, formik);
    }
  };

  const initialValues = {
    invoice_id: currPayment?.invoice_id || "",
    payment_method_id:
      currPayment?.payment_method_id ||
      paymentMethods?.find((item) => item?.name === "Cash")?.id ||
      "",
    payment_date: currPayment?.payment_date || moment().format("YYYY-MM-DD"),
    due_date: currPayment?.due_date || null,
    currency_id: currPayment?.currency_id || currency_id || "",
    amount: currPayment?.amount || "",
    transaction_id: currPayment?.transaction_id || "",
    note: currPayment?.note || "",
    bank_id: currPayment?.bank_id || "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: currPayment?.id ? "Edit Payment" : "New Payment",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box p="20px">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "15px",
              pb: "15px",
            }}
          >
            <Select
              options={isUpdating ? invoices : unpaidInvoices}
              label="Invoice *"
              name="invoice_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />

            <InputField
              label="Transaction ID"
              name="transaction_id"
              formik={formik}
            />
            <DateTimePicker
              name="payment_date"
              label="Payment Date *"
              formik={formik}
            />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "15px",
              }}
            >
              <Select
                options={paymentMethods}
                label="Pay Method *"
                name="payment_method_id"
                selectProps={{ id: "id", label: "name" }}
                formik={formik}
              />
              <Select
                options={banks}
                label="Banks"
                value={formik?.values?.bank_id}
                onChange={(value) => formik.setFieldValue("bank_id", value)}
                selectProps={{ id: "id", label: "name" }}
              />
            </Box>
            <Box
              sx={{
                gridColumn: "span 2",
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                gap: "15px",
              }}
            >
              <Select
                options={currencies}
                label="Currency *"
                name="currency_id"
                selectProps={{ id: "id", label: "name" }}
                formik={formik}
              />
              <InputField label="Amount *" name="amount" formik={formik} />
              <DateTimePicker
                name="due_date"
                label="Due Date"
                formik={formik}
              />
            </Box>
            <InputField
              sx={{ gridColumn: "span 2" }}
              name="note"
              label="Note"
              multiline
              rows={2}
              formik={formik}
            />
            <Button
              sx={{ gridColumn: "span 2" }}
              variant="solid"
              onClick={formik.submitForm}
              fullWidth
              disabled={!(formik.dirty && formik.isValid)}
            >
              {currPayment?.id ? "Update Payment" : "Save Payment"}
            </Button>
          </Box>
        </Box>
      )}
    </RightDrawer>
  );
};

export default AddEdit;
