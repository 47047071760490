import { Box, Typography } from "@mui/material";
import assets from "assets";
import useWindowSize from "hooks/useWindowSize";
import Carousel from "react-material-ui-carousel";

const LoginSlider = () => {
  const { height } = useWindowSize();
  const sliderData = [
    {
      title: "Simplify Your Business",
      desc: "Emphasize how your CRM keeps everything organized, from client data to projects and tasks, reducing chaos and improving productivity.",
      img: assets?.lcOrganized,
    },
    {
      title: "Streamline Your Workflow",
      desc: "This slide can emphasize how your CRM boosts efficiency in managing leads, clients, and projects, helping businesses save time and resources.",
      img: assets?.lcTasks,
    },
    {
      title: "Effortless File Handling",
      desc: "Highlight the CRM's robust file management capabilities, making it easy to store, access, and manage important documents and resources.",
      img: assets?.lcFiles,
    },
    {
      title: "Data-Driven Decision Making",
      desc: "Showcase the power of real-time data analytics and reporting, empowering users to make informed decisions for their consultancy business.",
      img: assets?.lcRealtime,
    },
    {
      title: "Dedicated Customer Assistance",
      desc: "Highlight your CRM's exceptional customer support and assistance services, reassuring users of reliable help when they need it.",
      img: assets?.lcSupport,
    },
  ];
  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-end",
        padding: "70px",
        "@media (max-height: 750px)": {
          padding: "25px",
        },
        "@media (max-width: 1300px )": {
          padding: "33px",
        },
      }}
    >
      <Box sx={{ width: "100%" }}>
        <Carousel
          interval={2200}
          height={height > 750 ? height - 170 : height - 85}
          indicatorIconButtonProps={{
            style: {
              height: "13px",
              width: "13px",
              color: "transparent",
              border: "2px solid #fff",
              cursor: "pointer",
              marginRight: "10px",
            },
          }}
          activeIndicatorIconButtonProps={{
            style: {
              backgroundColor: "#fff",
            },
          }}
          indicatorContainerProps={{
            style: {
              textAlign: "left",
            },
          }}
          navButtonsAlwaysInvisible
        >
          {sliderData?.map(({ img, title, desc }) => (
            <Box
              key={title}
              sx={{
                height: "100%",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height:
                    height < 650 ? "calc(100% - 25px)" : "calc(100% - 125px)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={img}
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: height < 650 ? "400px" : "650px",
                    margin: "0 auto",
                    display: "block",
                  }}
                />
              </Box>
              <Box sx={{ position: "absolute", bottom: "20px", left: "0px" }}>
                <Typography
                  variant="h2"
                  sx={{ color: "#fff", fontWeight: "400" }}
                >
                  {title}
                </Typography>
                <Typography
                  sx={{
                    color: "#e9e9e9",
                    fontWeight: "300",
                    pt: "15px",
                    fontSize: "14px",
                    letterSpacing: ".7px",
                    maxWidth: "600px",
                    display: height < 550 && "none",
                  }}
                >
                  {desc}
                </Typography>
              </Box>
            </Box>
          ))}
        </Carousel>
      </Box>
    </Box>
  );
};

export default LoginSlider;
