import Axios from "lib/Axios";

export const viewAllInvoiceBank = ({ body_data, params }) =>
  Axios.post(`/api/admin/bank-invoice/list?${params}`, body_data).then(
    (res) => res.data
  );

export const viewAllPayment = ({ id }) =>
  Axios.post(`/api/admin/bank-invoice/payment/list/${id}`).then(
    (res) => res.data
  );
  export const viewAllMakePayment = ({ id }) =>
  Axios.get(`/api/admin/bank-invoice/payment/paid/list/${id}`).then(
    (res) => res.data
  );
  

  export const viewSinglePayment = ({ id }) =>
  Axios.get(`/api/admin/sales/expected/show/${id}`).then(
    (res) => res.data
  );
export const updateInvoiceBankStatus = ({ body_data }) =>
  Axios.post("/api/admin/bank-invoice/change-status", body_data).then(
    (res) => res.data
  );

export const updateFollowupDate = ({ body_data }) =>
  Axios.post(`api/admin/bank-invoice/followupdate`, body_data).then(
    (res) => res.data
  );
export const exportBankInvoice = ({ body_data }) =>
  Axios.post("/api/admin/bank-invoice/export", body_data).then(
    (res) => res.data
  );



const endpoints = {
  createPayment: (data) => ({
    method: "post",
    url: "/api/admin/bank-invoice/payment/create",
    data,
  }),
  updatePayment: (data, id) => ({
    method: "post",
    url: `/api/admin/bank-invoice/payment/update/${id}`,
    data,
  }),

  createMakePayment: (data) => ({
    method: "post",
    url: "/api/admin/bank-invoice/payment/paid",
    data,
  }),
  updateMakePayment: (data, id) => ({
    method: "put",
    url: `/api/admin/bank-invoice/payment/paid/update/${id}`,
    data,
  }),
};

export const deleteMakePayment = (id) =>
  Axios.delete(`/api/admin/bank-invoice/payment/paid/delete/${id}`).then(
    (res) => res.data
  );

export const deletePayment = (id) =>
  Axios.delete(`/api/admin/bank-invoice/payment/delete/${id}`).then(
    (res) => res.data
  );

export const {
  createPayment,
  updatePayment,
  createMakePayment,
  updateMakePayment,
} = endpoints;
