import { Box, Button } from "@mui/material";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import Select from "components/ui/select";
import { useFormik } from "formik";
import useBase from "hooks/useBase";
import { toast } from "react-toastify";
import * as yup from "yup";
import { updateEmailSettings } from "../api/settings";

const Email = ({ email }) => {
  const { updateBase } = useBase();
  const modules = [
    { label: "Lead", id: "lead" },
    { label: "Customer", id: "customer" },
    { label: "Project", id: "project" },
  ];
  const initialValues = {
    driver: email?.driver || "",
    mail_path: email?.mail_path || "",
    mailgun_domain: email?.mailgun_domain || "",
    mailgun_secret: email?.mailgun_secret || "",
    host: email?.host || "",
    port: email?.port || "",
    username: email?.username || "",
    password: email?.password || "",
    encryption: email?.encryption || "",
    from_address: email?.from_address || "",
    from_name: email?.from_name || "",
    active_modules: email?.active_modules || [],
  };

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    const result = await updateEmailSettings({ body_data: data });
    if (result.success) {
      toast.success(result.message);
      updateBase(["settings"]);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box
      sx={{
        width: "650px",
        margin: "0 auto",
        border: "1px solid #efefef",
        borderRadius: "5px",
        padding: "15px",
        display: "flex",
        gap: "15px",
        flexDirection: "column",
      }}
    >
      <Select
        options={[
          { label: "SMTP", id: "smtp" },
          { label: "SendMail", id: "send-mail" },
          { label: "Mailgun", id: "mailgun" },
        ]}
        label="Email Driver"
        value={formik?.values?.driver}
        onChange={(value) => formik.setFieldValue("driver", value)}
      />
      {formik.values.driver === "send-mail" ? (
        <>
          <InputField name="mail_path" formik={formik} label="Mail Path" />
        </>
      ) : (
        <>
          {formik.values.driver === "mailgun" && (
            <>
              <InputField
                name="mailgun_domain"
                formik={formik}
                label="Mailgun Domain"
              />
              <InputField
                name="mailgun_secret"
                formik={formik}
                label="Mailgun Secret"
              />
            </>
          )}
          <Select
            options={[
              { label: "TLS", id: "tls" },
              { label: "SSL", id: "ssl" },
            ]}
            label="Email Encryption"
            value={formik?.values?.encryption}
            onChange={(value) => {
              formik.setFieldValue("encryption", value);
            }}
          />
          <InputField name="host" formik={formik} label="Host" />
          <InputField name="port" formik={formik} label="Port" />
          <InputField name="username" formik={formik} label="Username" />
          <InputField name="password" formik={formik} label="Password" />
          <InputField
            name="from_address"
            formik={formik}
            label="From Address"
            placeholder="jhondoe@mail.com"
          />
          <InputField
            name="from_name"
            formik={formik}
            label="From Name"
            placeholder="Jhon Doe"
          />
          <MultiSelect
            name="active_modules"
            label="Active Modules"
            options={modules}
            formik={formik}
          />
        </>
      )}
      <Button
        variant="solid"
        onClick={formik.submitForm}
        fullWidth
        disabled={!(formik.dirty && formik.isValid)}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default Email;
