import { Box, Typography } from "@mui/material";
import useApi from "hooks/useApi";
import useBase from "hooks/useBase";
import { useEffect, useState } from "react";
import { FcFolder } from "react-icons/fc";

const AttFolders = ({ setOnlyFilesScreen, setFilters }) => {
  const { fetchData } = useApi();
  const [attCounts, setAttCounts] = useState(null);
  const {
    base: { relatedToAttachments },
  } = useBase();
  useEffect(() => {
    const getAttCounts = async () => {
      const endpoint = {
        method: "get",
        url: "/api/admin/attachment/count",
      };
      const result = await fetchData(endpoint, false);
      setAttCounts(result?.data || []);
    };
    getAttCounts();
  }, []);

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
        gap: "15px",
      }}
    >
      {relatedToAttachments?.map((folder) => (
        <Box
          onClick={() => {
            setFilters({ related_to: folder });
            setOnlyFilesScreen(folder);
          }}
          key={folder}
          sx={{
            bgcolor: "#fff",
            padding: "10px",
            borderRadius: "5px",
            boxShadow: "0 0 5px #efefef",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "35px",
              }}
            >
              <FcFolder />
            </Box>
            <Typography
              sx={{
                textTransform: "capitalize",
                fontWeight: "500",
                color: "#666",
              }}
            >
              {folder}
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "500",
              color: "#888",
              pr: "5px",
            }}
          >
            {attCounts?.find((count) => count?.related_to === folder)?.count ||
              ""}
          </Typography>
        </Box>
      ))}
      <Box
        onClick={() => {
          setFilters({ related_to: "others" });
          setOnlyFilesScreen("Other");
        }}
        sx={{
          bgcolor: "#fff",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0 0 5px #efefef",
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "35px",
            }}
          >
            <FcFolder />
          </Box>
          <Typography
            sx={{
              textTransform: "capitalize",
              fontWeight: "500",
              color: "#666",
            }}
          >
            Others
          </Typography>
        </Box>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: "500",
            color: "#888",
            pr: "5px",
          }}
        >
          {attCounts?.find((count) => !count?.related_to)?.count || ""}
        </Typography>
      </Box>
    </Box>
  );
};

export default AttFolders;
