import { Alert, Box, Button, Grid } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { useDebounce } from "hooks/useDebounce";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useEffect, useState } from "react";
import { CgTrashEmpty } from "react-icons/cg";
import { MdRestore } from "react-icons/md";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { knowledgeBulkAction } from "../api/knowledge";
const Header = ({
  setOpenSidebar,
  setSearch,
  setPagination,
  pagination,
  setReload,
  allPost,
}) => {
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setSearch(debouncedText || "");
    setPagination({ ...pagination, current_page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText]);

  const handleBulkAction = async (type, data) => {
    dispatch(setLoading(true));
    const obj = { selected_ids: [], action: type };
    obj[type] = data;
    try {
      const result = await knowledgeBulkAction({ body_data: obj });
      if (result?.success) toast.success(result?.message);
      else toast.error(result?.message);
    } finally {
      setReload((pre) => !pre);
      dispatch(setLoading(false));
    }
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    dispatch(setLoading(true));
    const obj = { selected_ids: [], action: openAlert };
    try {
      const result = await knowledgeBulkAction({ body_data: obj });
      if (result?.success) toast.success(result?.message);
      else toast.error(result?.message);
    } finally {
      setReload((pre) => !pre);
      dispatch(setLoading(false));
    }
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center" p="20px">
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Empty Project Knowledge Trash"
          desc={
            <Box>
              Are you sure you want to delete all the customers permanently?
              This will delete this all the customer permanently.
              <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, Empty Trash
              </Button>
            </>
          }
        />
      )}
      <Grid item>
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in table.."}
        />
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Button
          disabled={!allPost?.length}
          variant="outlined"
          startIcon={<CgTrashEmpty />}
          onClick={() => setOpenAlert("delete-all")}
        >
          Empty Trash
        </Button>
        <Button
          disabled={!allPost?.length}
          variant="outlined"
          startIcon={<MdRestore />}
          onClick={() => handleBulkAction("restore-all")}
        >
          Restore All
        </Button>
      </Box>
    </Grid>
  );
};

export default Header;
