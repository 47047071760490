import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import Attachment from "components/common/attachment";
import RightDrawer from "components/rightDrawer/RightDrawer";
import { useState } from "react";
import { useSelector } from "react-redux";
import Details from "./Details";
import Payments from "./payments/Payments";
import Note from "components/common/note";
import MakePayments from "./makePayment/MakePayments";
import useQueries from "hooks/useQueries";
import useApi from "hooks/useApi";
import { useEffect } from "react";

const ViewInvoiceBank = ({
  openDrawer,
  setOpenDrawer,
  currInvoiceBank,
  setReload,
  allInvoiceBank,
  setAllInvoiceBank,
}) => {
  const { row_id, mod_id, setQueries } = useQueries();
  const { fetchData, loading } = useApi();
  const [profile, setProfile] = useState({});
  const [value, setValue] = useState(mod_id || "1");
  const currency_id = useSelector(
    (state) => state?.base?.base_data?.settings?.app?.currency_id
  );
  const currencies = useSelector((state) => state?.base?.base_data?.currencies);
  const symbol = currency_id
    ? currencies?.find((c) => c.id === currency_id)?.symbol
    : "";

  const handleChange = (event, newValue) => {
    setQueries(`id=${row_id}&m=${newValue}`);
    setValue(newValue);
  };
  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/bank-invoice/show/${row_id}`,
      };
      const { success, data } = await fetchData(endpoint, false);
      if (success) setProfile(data);
    };
    getProfile();
  }, []);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "900px",
        headingLeft:
          "#" +
          row_id +
          `${profile?.applicant_name ? ` - ${profile?.applicant_name}` : ""}`,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", overflowX: "hidden" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="View Details" value="1" />
              <Tab label="Invoices" value="2" />
              <Tab label="Payments" value="3" />
              <Tab label="Note" value="4" />
              <Tab label="Attachment" value="5" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1">
              <Details profile={profile} symbol={symbol} loading={loading} />
            </TabPanel>
            <TabPanel value="2">
              <Payments
                profile={profile}
                setReloadMain={setReload}
                symbol={symbol}
              />
            </TabPanel>
            <TabPanel value="3">
              <MakePayments
                profile={profile}
                setReloadMain={setReload}
                symbol={symbol}
              />
            </TabPanel>
            <TabPanel value="4">
              <Note
                id={row_id}
                related_id={row_id}
                related_to="bank_invoice"
                route="bank-invoice"
                allData={allInvoiceBank}
                setAllData={setAllInvoiceBank}
              />
            </TabPanel>
            <TabPanel value="5">
              <Attachment id={row_id} route="bank-invoice" />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default ViewInvoiceBank;
