import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import NoteSkeleton from "components/skeletons/NoteSkeleton";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import moment from "moment";
import { useState } from "react";
import ViewAttachment from "./ViewCommentAttachment";

const ReplyList = ({ setReplys, replys, loading }) => {
  const [viewAtt, setViewAtt] = useState(false);
  const [currFile, setCurrFile] = useState({});
  const handleFileView = (reply) => {
    if (
      reply?.ticket_reply_attachment?.attachment?.mime_type
        .split("/")
        .includes("image")
    ) {
      setCurrFile(reply);
      setViewAtt(true);
    } else {
      window.open(
        UNIVERSAL.BASEURL + reply?.ticket_reply_attachment?.attachment?.file,
        { replace: true }
      );
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {loading ? (
        <NoteSkeleton />
      ) : (
        <>
          {replys
            .sort((a, b) => new Date(b.created_at) - new Date(a?.created_at))
            .map((reply) => (
              <Box
                key={reply?.id}
                sx={{
                  display: "grid",
                  gridTemplateColumns: "50px auto 30px",
                  padding: "10px",
                  borderTop: "1px dashed #ddd",
                  mt: 2,
                }}
              >
                <Box>
                  <Avatar
                    alt={reply?.user?.name || reply?.customer?.name}
                    src={
                      UNIVERSAL.BASEURL +
                      (reply?.user?.image || reply?.customer?.image)
                    }
                  />
                </Box>

                <Box sx={{ paddingBottom: "15px" }}>
                  <Typography variant="h6">
                    {reply?.user?.name || reply?.customer?.name}
                  </Typography>
                  <Box sx={{ fontSize: "12px" }}>
                    {moment(reply?.created_at).format("lll")}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "14px",
                      paddingTop: "10px",
                      paddingBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{ m: 0 }}
                      dangerouslySetInnerHTML={{ __html: reply?.text }}
                    ></Typography>
                  </Box>
                  {reply?.ticket_reply_attachment?.attachment?.mime_type
                    .split("/")
                    .includes("image") ? (
                    <Button onClick={() => handleFileView(reply)}>
                      <img
                        style={{
                          width: "10rem",
                        }}
                        alt=""
                        src={
                          UNIVERSAL.BASEURL +
                          reply?.ticket_reply_attachment?.attachment?.file
                        }
                      />
                    </Button>
                  ) : (
                    reply?.ticket_reply_attachment?.attachment.file && (
                      <Chip
                        sx={{ mb: "15px" }}
                        label={reply?.ticket_reply_attachment?.attachment?.name}
                        component="a"
                        href={
                          UNIVERSAL?.BASEURL +
                          reply?.ticket_reply_attachment?.attachment?.file
                        }
                        target="_blank"
                        variant="outlined"
                        clickable
                      />
                    )
                  )}
                </Box>

                <Box>
                  <Action reply={reply} setReplys={setReplys} />
                </Box>
              </Box>
            ))}
          {viewAtt && (
            <ViewAttachment setViewAtt={setViewAtt} file={currFile} />
          )}
        </>
      )}
    </Box>
  );
};
function Action({ reply, setReplys }) {
  const { fetchData: deleteReplys } = useApi();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  let handleDelete = async () => {
    const endpoint = {
      method: "delete",
      url: `/api/ticket/reply/delete/${reply?.id}`,
    };
    const result = await deleteReplys(endpoint, false);
    if (result.success) {
      setReplys((prev) => prev.filter((r) => r.id !== reply?.id));
    }
  };
  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleDelete();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
export default ReplyList;
