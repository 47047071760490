import { Edit } from "@mui/icons-material";
import {
  Checkbox,
  IconButton,
  MenuItem,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { Box } from "@mui/system";
import PopoverAG from "components/ui/popoverAG";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { TbListDetails } from "react-icons/tb";
import { updateTicketPriority, updateTicketStatus } from "../../api/ticket";
import useQueries from "hooks/useQueries";
import { useDispatch } from "react-redux";
import {
  decreamentTicket,
  increamentTicket,
} from "lib/Reducer/features/ticket/ticketSlice";
import { useState } from "react";
export function removeTagsAndWhitespace(html) {
  var cleanr = /<\/?[^>]+>|\s+/gi;
  var plainText = html.replace(cleanr, " ");
  return plainText.trim();
}
export const ticketPriorities = [
  { id: "low", label: "Low", color: "#008000" },
  { id: "medium", label: "Medium", color: "#FFA500" },
  { id: "high", label: "High", color: "#FF0000" },
];
export const ticketStatus = [
  {
    id: "new",
    label: "New",
    color: "#FF0000",
  },
  {
    id: "progress",
    label: "Progress",
    color: "#FFA500",
  },

  {
    id: "hold",
    label: "Hold",
    color: "#808080",
  },
  {
    id: "resolved",
    label: "Resolved",
    color: "#008000",
  },
  {
    id: "dismissed",
    label: "Dismissed",
    color: "#000",
  },
];
const MainTableRow = ({
  row,
  columns,
  handleDelete,
  handleTableCheckBox,
  selectedRows,
  allTickets,
  setAllTickets,
  permissions,
}) => {
  let [preState, setPrestate] = useState(false);
  let dispacth = useDispatch();
  const { setQueries } = useQueries();
  const handleChangeStatus = async (value) => {
    try {
      let res = await updateTicketStatus({
        body_data: { id: row?.id, status: value },
      });
      if (res?.success) {
        setAllTickets(
          allTickets.map((ticket) =>
            ticket.id === row.id ? { ...row, status: value } : ticket
          )
        );
        if (value === "resolved" && !preState) {
          dispacth(decreamentTicket());
          setPrestate(true);
        } else if (value !== "resolved" && preState) {
          dispacth(increamentTicket());
          setPrestate(false);
        }else if (row?.status === "resolved"){
          dispacth(increamentTicket());

        }
      }
    } catch (error) {}
  };
  const handleChangePriority = async (value) => {
    try {
      await updateTicketPriority({
        body_data: { id: row?.id, priority: value },
      });
      setAllTickets(
        allTickets.map((ticket) =>
          ticket.id === row.id ? { ...row, priority: value } : ticket
        )
      );
    } catch (error) {}
  };

  return (
    <TableRow
      hover
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 16px",
        },
        "& .MuiTableCell-sizeMedium:first-of-type": {
          paddingLeft: "8px",
          paddingRight: "0px",
        },
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRows?.includes(row.id)}
          onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
          disabled={!permissions["bulk action"]}
        />
      </TableCell>
      {columns.map((column) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align} style={column.style}>
            {column.id === "priority" ? (
              <TextField
                disabled={!permissions.priority}
                size="small"
                value={value}
                select
                sx={{
                  width: "100px",
                  color: ticketPriorities?.find(
                    (singleStatus) => singleStatus?.id === value
                  )?.color,
                  textAlign: "left",
                  "& > div:before": { content: "none" },
                  "& div": {
                    color: ticketPriorities?.find(
                      (singleStatus) => singleStatus?.id === value
                    )?.color,
                  },
                }}
                variant="standard"
                onChange={(e) => handleChangePriority(e.target.value)}
              >
                {ticketPriorities?.length > 0 &&
                  ticketPriorities?.map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {status?.label}
                    </MenuItem>
                  ))}
              </TextField>
            ) : column.id === "status" ? (
              <TextField
                disabled={!permissions?.status}
                size="small"
                value={value}
                select
                sx={{
                  width: "100px",
                  color: ticketStatus?.find(
                    (singleStatus) => singleStatus?.id === value
                  )?.color,
                  textAlign: "left",
                  "& > div:before": { content: "none" },
                  "& div": {
                    color: ticketStatus?.find(
                      (singleStatus) => singleStatus?.id === value
                    )?.color,
                  },
                }}
                variant="standard"
                onChange={(e) => handleChangeStatus(e.target.value)}
              >
                {ticketStatus?.length > 0 &&
                  ticketStatus?.map((status) => (
                    <MenuItem value={status?.id} key={status?.id}>
                      {status?.label}
                    </MenuItem>
                  ))}
              </TextField>
            ) : column.id === "created_at" ? (
              moment(value).format("ll")
            ) : column.id === "assignees" ? (
              <PopoverAG items={value} />
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "5px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Details">
                  <IconButton onClick={() => setQueries(`id=${row?.id}&m=1`)}>
                    <TbListDetails />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Edit">
                  <IconButton
                    onClick={() => setQueries(`id=${row?.id}&m=0`)}
                    disabled={!permissions.update}
                  >
                    <Edit />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <IconButton
                    onClick={() => handleDelete(row.id)}
                    disabled={!permissions.delete}
                  >
                    <MdDelete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
