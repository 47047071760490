import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import NotAuthorized from "components/notAuthorized";
import useBase from "hooks/useBase";
import usePermissions from "hooks/usePermissions";
import { useEffect, useState } from "react";
import AppSettings from "./AppSettings";
import Email from "./Email";
import General from "./General";
import Notification from "./Notification";
import SMS from "./SMS";

const Settings = () => {
  const permissions = usePermissions("settings");
  const { base, updateBase } = useBase();
  useEffect(() => {
    updateBase(["settings"]);
  }, []);

  const settings = base?.settings;
  const timezones = base?.timezones;
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const chekcSMSExistInApp = !(
  //   settings?.app?.mram_username &&
  //   settings?.app?.mram_password &&
  //   settings?.app?.mram_sender_id
  // );
  return (
    <Box
      sx={{
        width: "calc(100% - 30px)",
        height: "calc(100% - 30px)",
        backgroundColor: "#fff",
        typography: "body1",
        overflowX: "hidden",
        margin: "15px",
      }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="General" value="1" />
            <Tab label="App" value="2" />
            <Tab label="Email" value="3" />
            <Tab label="Notifications" value="4" />
            <Tab label="SMS" value="5" />
          </TabList>
        </Box>
        <Box
          sx={{
            position: "relative",
            height: "calc(100% - 60px)",
          }}
        >
          <TabPanel value="1">
            {permissions.general ? (
              <General general={settings?.general} />
            ) : (
              <NotAuthorized isSettings />
            )}
          </TabPanel>
          <TabPanel value="2">
            {permissions.app ? (
              <AppSettings app={settings?.app} timezones={timezones} />
            ) : (
              <NotAuthorized isSettings />
            )}
          </TabPanel>

          <TabPanel value="3">
            {permissions.email ? (
              <Email email={settings?.email} />
            ) : (
              <NotAuthorized isSettings />
            )}
          </TabPanel>

          <TabPanel value="4">
            {permissions.notification ? (
              <Notification puserCreds={settings?.notification} />
            ) : (
              <NotAuthorized isSettings />
            )}
          </TabPanel>
            <TabPanel value="5">
              {permissions["Masking or Non Masking SMS"] ? (
                <SMS sms={settings?.maskingSms} />
              ) : (
                <NotAuthorized isSettings />
              )}
            </TabPanel>
        </Box>
      </TabContext>
    </Box>
  );
};

export default Settings;
