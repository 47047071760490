import { Delete } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import UNIVERSAL from "config";
import moment from "moment";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  selectedRows,
  handleTableCheckBox,
  permissions,
  ...restProps
}) => {
  return (
    <TableRow
      {...restProps}
      hover
      role="checkbox"
      key={row.id}
      sx={{
        "& .MuiTableCell-sizeMedium": {
          padding: "10px 0px 10px 16px",
        },
        "& .MuiTableCell-sizeMedium:first-of-type": {
          paddingLeft: "8px",
          paddingRight: "0px",
        },
      }}
    >
      <TableCell padding="checkbox">
        <Checkbox
          checked={selectedRows?.includes(row.id)}
          onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
          disabled={!permissions["bulk action"]}
        />
      </TableCell>
      {columns.map((column, i) => {
        const value = row[column.id];
        return (
          <TableCell key={column.id} align={column.align}>
            {column.id === "created_at" ? (
              moment(value).format("lll")
            ) : column.id === "created_by_name" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "20px",
                  alignItems: "center",
                }}
              >
                {value ? (
                  <>
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      src={UNIVERSAL.BASEURL + row?.user_image}
                      alt={value}
                    ></Avatar>
                    <Typography
                      sx={{
                        flexShrink: 0,
                      }}
                    >
                      {value}
                    </Typography>
                  </>
                ) : (
                  "-"
                )}
              </Box>
            ) : column.id === "created_by_customer_name" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                {value ? (
                  <>
                    <Avatar
                      sx={{ width: 32, height: 32 }}
                      src={UNIVERSAL.BASEURL + row?.customer_image}
                      alt={value}
                    ></Avatar>
                    <Typography>{value}</Typography>
                  </>
                ) : (
                  "-"
                )}
              </Box>
            ) : column.id === "action" ? (
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Delete">
                  <IconButton onClick={() => handleDelete(row.id)}>
                    <Delete />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default MainTableRow;
