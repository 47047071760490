import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import ProjectTable from "components/common/ProjectTable";
import Invoice from "components/common/invoice";
import Permissions from "components/common/permissions";
import Task from "components/common/task";
import Timesheet from "components/common/timesheet/Timesheet";
import NotAuthorized from "components/notAuthorized";
import RightDrawer from "components/rightDrawer/RightDrawer";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import ActivityTable from "./ActivityTable";
import AttendanceTable from "./AttendanceTable";
import CustomerTable from "./CustomerTable";
import LeadTable from "./LeadTable";
import NoteTable from "./NoteTable";
import ProfileDetails from "./ProfileDetails";
import StaffSummary from "./StaffSummary";

const StaffProfile = ({
  openDrawer,
  setOpenDrawer,
  currStaff,
  permissions,
}) => {
  const user = currStaff;
  const { loading, fetchData } = useApi();
  const [profile, setProfile] = useState({});
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getProfileDetails = async () => {
    const endpoint = {
      url: `/api/admin/staff/show/${user?.id}`,
      method: "get",
    };
    const result = await fetchData(endpoint, false);
    if (result.success) setProfile(result?.data);
  };

  useEffect(() => {
    getProfileDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);
  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "1200px",
        headingLeft: profile.user_id
          ? "#" + profile.user_id + " - " + profile?.name
          : "",
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="lab API tabs example"
            >
              <Tab label="Profile" value="1" />
              <Tab label="Permissions" value="2" />
              <Tab label="Summary" value="12" />
              <Tab label="Attendance" value="8" />
              <Tab label="Timesheet" value="3" />
              <Tab label="Lead" value="9" />
              <Tab label="Customer" value="10" />
              <Tab label="Project" value="11" />
              <Tab label="Invoice" value="7" />
              <Tab label="Task" value="5" />
              <Tab label="Note" value="4" />
              <Tab label="Activity" value="6" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1">
              {permissions.show ? (
                <ProfileDetails
                  profile={profile}
                  setOpenDrawer={setOpenDrawer}
                  loading={loading}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="2">
              {permissions["role permission"] ? (
                <Permissions
                  user_id={profile?.user_id}
                  role_permissions={profile?.role_permissions}
                  isAdmin={
                    profile?.role?.toLowerCase() === "admin" ? true : false
                  }
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="3">
              {permissions["timesheet"] ? (
                <Timesheet id={user?.user_id} />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="4">
              {permissions["note"] ? (
                <NoteTable user_id={user?.user_id} />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="5">
              <Task
                id={user?.user_id}
                route="staff"
                related_to="staff"
                isStaffProfile={true}
              />
            </TabPanel>
            <TabPanel value="6">
              {permissions["activity"] ? (
                <ActivityTable user_id={user?.user_id} />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="7">
              <Invoice user_id={user?.user_id} />
            </TabPanel>
            <TabPanel value="8">
              <AttendanceTable user_id={user?.user_id} />
            </TabPanel>
            <TabPanel value="9">
              <LeadTable user_id={user?.user_id} />
            </TabPanel>
            <TabPanel value="10">
              <CustomerTable user_id={user?.user_id} />
            </TabPanel>
            <TabPanel value="11">
              <ProjectTable user_id={user?.user_id} />
            </TabPanel>
            <TabPanel value="12">
              {permissions["summary"] ? (
                <StaffSummary user_id={user?.user_id} />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default StaffProfile;
