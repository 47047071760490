import Axios from "lib/Axios";

export const viewAllCustomers = ({ body_data, params }) =>
  Axios.post(`/api/admin/customer/list?${params}`, body_data).then(
    (res) => res.data
  );

export const deleteCustomer = ({ id }) =>
  Axios.delete(`/api/admin/customer/trash/${id}`).then((res) => res.data);

export const exportCustomer = ({ body_data }) =>
  Axios.post("/api/admin/customer/export", body_data).then((res) => res.data);

export const importSample = () =>
  Axios.get("/api/admin/lead/sample?type=xlsx").then((res) => res.data);

export const restoreLead = ({ id }) =>
  Axios.get(`/api/admin/lead/restore/${id}`).then((res) => res.data);

export const updateCustomerStatus = ({ body_data }) =>
  Axios.post("/api/admin/customer/change/status", body_data).then(
    (res) => res.data
  );

export const viewCustomerSummary = ({ body_data }) =>
  Axios.post("/api/admin/customer/summery", body_data).then((res) => res.data);

export const importLeads = ({ body_data }) =>
  Axios.post("/api/admin/customer/import", body_data).then((res) => res.data);

export const customerBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/customer/bulk/action", body_data).then(
    (res) => res.data
  );

export const updateFollowupDate = ({ body_data }) =>
  Axios.post(`/api/admin/lead/followupdate`, body_data).then((res) => res.data);

export const billingShippingAddress = ({ id, body_data }) =>
  Axios.post(`/api/admin/customer/billing-shipping/${id}`, body_data).then(
    (res) => res.data
  );

const endpoints = {
  createCustomer: (data) => ({
    method: "post",
    url: "/api/admin/customer/create",
    data,
  }),
  updateCustomer: (id, data) => ({
    method: "post",
    url: `/api/admin/customer/update/${id}`,
    data,
  }),
  deleteCustomerNew: (id) => ({
    method: "delete",
    url: `/api/admin/project-customer/trash/${id}`,
  }),
};

export const { createCustomer, updateCustomer, deleteCustomerNew } = endpoints;
