const getInitialvalues = (filters) => {
  return {
    customer_id: filters.customer_id || [],
    reference_id: filters.reference_id || [],
    bank_id: filters.bank_id || [],
    followup_date_from: filters.followup_date_from || null,
    followup_date_to: filters.followup_date_to || null,

    bank_invoice_status_id: filters.bank_invoice_status_id || [],
    created_at_from: filters.created_at_from || null,
    created_at_to: filters.created_at_to || null,
    payment_date_from: filters.payment_date_from || null,
    payment_date_to: filters.payment_date_to || null,

    year: filters.year|| "",
    month: filters.month|| "",
    expected_payment_type:  filters.expected_payment_type|| "", 
    payment_method_id: filters.payment_method_id|| [],
  };
};

export default getInitialvalues;
