import { ArrowBack } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import React from "react";
import AttachmentsGrid from "./AttachmentsGrid";

const OnlyFilesScreen = ({
  onlyFilesScreen,
  setOnlyFilesScreen,
  attDetails,
  setAttDetails,
  filters,
  setFilters,
}) => {
  return (
    <Box>
      <Typography
        onClick={() => setOnlyFilesScreen(false)}
        sx={{
          width: "fit-content",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          mb: "20px",
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <ArrowBack />{" "}
        <span style={{ fontWeight: "500", textTransform: "capitalize" }}>
          {onlyFilesScreen} files
        </span>
      </Typography>
      <AttachmentsGrid
        {...{ onlyFilesScreen, attDetails, setAttDetails, filters, setFilters }}
      />
    </Box>
  );
};

export default OnlyFilesScreen;
