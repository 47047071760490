import { DownloadOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, Grid } from "@mui/material";
import Alert from "components/alert";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useEffect, useState } from "react";
import { CgTrashEmpty } from "react-icons/cg";
import { MdRestore } from "react-icons/md";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { leadBulkAction } from "../api/leads";
import useDidMountEffect from "hooks/useDidMountEffect";

const LeadsHeader = ({
  setSearch,
  setPagination,
  pagination,
  setReload,
  allLeads,
  permissions,
  search,
  filters,
}) => {
  const { loading, fetchData } = useApi();
  const [searchText, setSearchText] = useState();
  const debouncedText = useDebounce(searchText, 1000);
  const selectedRows = [];
  const [openAlert, setOpenAlert] = useState(false);

  useDidMountEffect(() => {
    setSearch(debouncedText);
    setPagination({ ...pagination, current_page: 1 });
  }, [debouncedText]);

  const dispatch = useDispatch();
  const handleBulkAction = async (type, data) => {
    dispatch(setLoading(true));
    const obj = { selected_ids: selectedRows, action: type };
    obj[type] = data;
    try {
      const result = await leadBulkAction({ body_data: obj });
      if (result?.success) toast.success(result?.message);
      else toast.error(result?.message);
    } finally {
      setReload((pre) => !pre);
      dispatch(setLoading(false));
    }
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    dispatch(setLoading(true));
    const obj = { selected_ids: selectedRows, action: openAlert };
    try {
      const result = await leadBulkAction({ body_data: obj });
      if (result?.success) toast.success(result?.message);
      else toast.error(result?.message);
    } finally {
      setReload((pre) => !pre);
      dispatch(setLoading(false));
    }
  };

  const handleExport = async () => {
    const res = await fetchData(
      {
        method: "post",
        url: "/api/admin/lead/export",
        data: { type: "xlsx", trash_export: true, search, ...filters },
      },
      false
    );
    if (res.success) window.open(res?.data?.file);
  };

  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      p="15px 20px"
    >
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Empty Lead Trash"
          desc={
            <Box>
              Are you sure you want to delete all the leads permanently? This
              will delete this all the lead permanently.{" "}
              <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, Empty Trash
              </Button>
            </>
          }
        />
      )}
      <Grid item>
        {!!(
          permissions["trash list(own)"] || permissions["trash list(global)"]
        ) && (
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in table.."}
          />
        )}
      </Grid>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <LoadingButton
          loading={loading}
          loadingPosition="start"
          variant="outlined"
          startIcon={<DownloadOutlined />}
          onClick={handleExport}
          disabled={!permissions.export}
        >
          Export
        </LoadingButton>
        <Button
          disabled={!allLeads?.length || !permissions["bulk action"]}
          variant="outlined"
          startIcon={<CgTrashEmpty />}
          onClick={() => setOpenAlert("delete-all")}
        >
          Empty Trash
        </Button>
        <Button
          disabled={!allLeads?.length || !permissions["bulk action"]}
          variant="outlined"
          startIcon={<MdRestore />}
          onClick={() => handleBulkAction("restore-all")}
        >
          Restore All
        </Button>
      </Box>
    </Grid>
  );
};

export default LeadsHeader;
