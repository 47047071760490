import Box from "@mui/material/Box";
import ClientAppBar from "components/appBar/ClientAppBar";
import { Outlet } from "react-router-dom";

const ClientLayout = () => {
  return (
    <Box>
      <ClientAppBar />
      <Box
        sx={{
          height: "calc(100vh - 65px)",
          overflowY: "auto",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "1200px", margin: "0 auto" }}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default ClientLayout;
