import { ArrowBack, Visibility, VisibilityOff } from "@mui/icons-material";
import InputField from "components/ui/inputField";
import { useFormik } from "formik";
import { saveUser } from "lib/Reducer/authSlice";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import "./style.css";
// Mui components
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import assets from "assets";
import LoadingBtn from "components/ui/loadingBtn";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import handleError from "utils/handleError";
import LoginSlider from "./LoginSlider";

const AdminLogin = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [openFP, setOpenFP] = useState(false);
  const [otp, setOtp] = useState("");
  const [forgotEmail, setForgotEmail] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmNewPass, setConfirmNewPass] = useState("");
  const siteInfo = useSelector((state) => state?.base?.site_info);
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Email format is not valid")
      .required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const { fetchData, loading } = useApi();

  const onSubmit = async (data) => {
    const endpoint = {
      method: "post",
      url: "/api/admin/login",
      data,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      formik.resetForm();
      dispatch(saveUser(result.data));
    } else {
      const errors = handleError(result);
      errors?.map((err) => toast.error(err));
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const handleSendOTP = async () => {
    const endpoint = {
      method: "post",
      url: "/api/admin/forget-password",
      data: { email: forgotEmail },
    };
    const result = await fetchData(endpoint);
    if (result.success) setOpenFP(2);
    else toast.error(result.message);
  };

  const handleCheckOTP = async () => {
    const endpoint = {
      method: "post",
      url: "/api/admin/check-otp",
      data: { email: forgotEmail, otp },
    };
    const result = await fetchData(endpoint);
    if (result.success) setOpenFP(3);
    else toast.error(result.message);
  };

  const handlePassChange = async () => {
    const endpoint = {
      method: "post",
      url: "/api/admin/reset-password",
      data: { email: forgotEmail, password: newPass },
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      formik.resetForm();
      setOpenFP(false);
    } else toast.error(result.message);
  };

  return (
    <Box
      sx={{
        height: "100dvh",
        display: "grid",
        gridTemplateColumns: "auto 650px",
        "@media (max-width: 1300px)": {
          gridTemplateColumns: "auto 550px",
        },
        "@media (max-width: 1150px)": {
          gridTemplateColumns: "auto 450px",
        },
        "@media (max-width: 1050px)": {
          gridTemplateColumns: "1fr",
        },
      }}
    >
      <Box
        sx={{
          bgcolor: (theme) => theme.palette.primary.main,
          "@media (max-width: 1050px)": {
            display: "none",
          },
        }}
      >
        <LoginSlider />
      </Box>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          display: "flex",
          height: "100dvh",
          alignItems: "center",
          padding: "0 30px",
        }}
      >
        <Box sx={{ width: "100%", maxWidth: "480px", margin: "0 auto" }}>
          <img
            style={{
              maxWidth: "260px",
              maxHeight: "120px",
              margin: "0 auto 70px",
              display: "block",
              borderRadius: "5px",
            }}
            src={
              siteInfo?.site_logo
                ? UNIVERSAL.BASEURL + siteInfo?.site_logo
                : assets.logo
            }
            alt=""
          />
          {openFP === 1 ? (
            <Box sx={{ height: "350px" }}>
              <Typography
                onClick={() => setOpenFP(false)}
                variant="h2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  width: "260px",
                }}
              >
                <ArrowBack
                  style={{
                    fontSize: "24px",
                  }}
                />
                Forgot password
              </Typography>
              <Typography sx={{ pt: "10px", pb: "30px" }}>
                Enter your E-mail we will send 6 digits verification code
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSendOTP();
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "25px",
                    flexDirection: "column",
                    justifyContent: "center",
                    mb: "20px",
                  }}
                >
                  <TextField
                    label="Enter your email"
                    onChange={(e) => setForgotEmail(e.target.value)}
                  />
                </Box>
                {loading ? (
                  <LoadingBtn height="50px" />
                ) : (
                  <Button
                    fullWidth
                    disabled={!emailPattern.test(forgotEmail)}
                    variant="solid"
                    size="large"
                    type="submit"
                    sx={{ padding: "25px" }}
                  >
                    Continue
                  </Button>
                )}
              </form>
            </Box>
          ) : openFP === 2 ? (
            <Box sx={{ height: "350px" }}>
              <Typography
                onClick={() => setOpenFP(1)}
                variant="h2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  width: "360px",
                }}
              >
                <ArrowBack
                  style={{
                    fontSize: "24px",
                  }}
                />
                Enter your OTP code
              </Typography>
              <Typography sx={{ pt: "10px", pb: "30px" }}>
                Enter the 6 digits code sent to you at{" "}
                <span style={{ color: "#405189" }}>{forgotEmail}</span>
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleCheckOTP();
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "25px",
                    flexDirection: "column",
                    justifyContent: "center",
                    mb: "20px",
                    "& > div": {
                      justifyContent: "center",
                      "& input": {
                        height: "46px",
                        width: "40px!important",
                        fontSize: "18px",
                        border: "1px solid #ddd",
                        borderRadius: "3px",
                        "&:focus-visible": {
                          border: "1px solid #666",
                          outline: "none",
                        },
                      },
                    },
                  }}
                >
                  <OTPInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    renderSeparator={
                      <span
                        style={{ display: "inline-block", width: "10px" }}
                      ></span>
                    }
                    renderInput={(props) => <input {...props} />}
                  />
                </Box>
                {loading ? (
                  <LoadingBtn height="50px" />
                ) : (
                  <Button
                    fullWidth
                    disabled={otp.length < 6}
                    variant="solid"
                    size="large"
                    type="submit"
                    sx={{ padding: "25px" }}
                  >
                    Verify
                  </Button>
                )}
              </form>
            </Box>
          ) : openFP === 3 ? (
            <Box sx={{ height: "350px" }}>
              <Typography
                onClick={() => setOpenFP(1)}
                variant="h2"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  cursor: "pointer",
                  width: "360px",
                }}
              >
                <ArrowBack
                  style={{
                    fontSize: "24px",
                  }}
                />
                Reset Password
              </Typography>
              <Typography sx={{ pt: "10px", pb: "10px" }}>
                Set the new password for your account so you can login and
                access all the features
              </Typography>
              <Typography
                sx={{
                  pb: "20px",
                  fontStyle: "italic",
                }}
              >
                Your password must be at least 6 characters
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handlePassChange();
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    flexDirection: "column",
                    justifyContent: "center",
                    mb: "20px",
                  }}
                >
                  <TextField
                    label="Enter New Password"
                    onChange={(e) => setNewPass(e.target.value)}
                  />
                  <TextField
                    onPaste={(e) => {
                      e.preventDefault();
                      return false;
                    }}
                    label="Confirm Password"
                    onChange={(e) => setConfirmNewPass(e.target.value)}
                  />
                </Box>
                {loading ? (
                  <LoadingBtn height="50px" />
                ) : (
                  <Button
                    fullWidth
                    disabled={
                      !(newPass.length > 5 && newPass === confirmNewPass)
                    }
                    variant="solid"
                    size="large"
                    type="submit"
                    sx={{ padding: "25px" }}
                  >
                    Submit
                  </Button>
                )}
              </form>
            </Box>
          ) : (
            <Box sx={{ height: "350px" }}>
              <Typography variant="h2">Log In</Typography>
              <Typography sx={{ pt: "10px", pb: "30px" }}>
                Use a registered account and enjoy the features
              </Typography>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.submitForm();
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "25px",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <InputField
                    formik={formik}
                    name="email"
                    label="Email Address"
                    size="large"
                    InputLabelProps={{ shrink: true }}
                  />
                  <Box
                    sx={{
                      position: "relative",
                      "& button": {
                        position: "absolute",
                        top: "50%",
                        right: "20px",
                        transform: "translateY(-50%)",
                      },
                    }}
                  >
                    <InputField
                      sx={{ width: "100%" }}
                      formik={formik}
                      type={showPassword ? "text" : "password"}
                      name="password"
                      label="Password"
                      size="large"
                      InputLabelProps={{ shrink: true }}
                    />
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    my: "10px",
                  }}
                >
                  <FormControlLabel
                    control={<Checkbox size="small" defaultChecked />}
                    label="Remember me"
                  />
                  <Typography
                    style={{
                      color: "#405189",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpenFP(1)}
                  >
                    Forgot password?
                  </Typography>
                </Box>
                {loading ? (
                  <LoadingBtn height="50px" />
                ) : (
                  <Button
                    type="submit"
                    fullWidth
                    disabled={!(formik.isValid && formik.dirty)}
                    variant="solid"
                    size="large"
                    sx={{ padding: "25px" }}
                  >
                    Sign in
                  </Button>
                )}
              </form>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default AdminLogin;
