import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import React from "react";
import { MdDelete, MdRestore } from "react-icons/md";

const MainTableRow = ({
  row,
  columns,
  handleDelete,
  handleTableCheckBox,
  selectedRows,
  permissions,
  handleRestore,
}) => {
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& .MuiTableCell-sizeMedium": {
            padding: "10px 0px 10px 16px",
          },
          "& .MuiTableCell-sizeMedium:first-of-type": {
            paddingLeft: "8px",
            paddingRight: "0px",
          },
        }}
      >
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectedRows?.includes(row.id)}
            onChange={(e) => handleTableCheckBox(row.id, e.target.checked)}
            disabled={!permissions["bulk action"]}
          />
        </TableCell>
        {columns.map((column) => {
          const value = row[column.id];
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={column.style}
            >
              {column.format && typeof value === "number" ? (
                column.format(value)
              ) : column.id === "due_date" ? (
                moment(value).format("ll")
              ) : column.id === "tax" ? (
                <Typography color="#2fb42f" variant="body2">
                  {"+ " + row.symbol + value}
                </Typography>
              ) : column.id === "discount" ? (
                <Typography color="#ff3535" variant="body2">
                  {"- " + row.symbol + value}
                </Typography>
              ) : column.id === "total" ? (
                <Typography color="#7171ff" variant="body2">
                  {row.symbol}
                  {value + row.tax - row.discount}
                </Typography>
              ) : column.id === "subtotal" ? (
                <Typography variant="body2">{row.symbol + value}</Typography>
              ) : column.id === "action" ? (
                <Box
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "center",
                  }}
                >
                  <Tooltip title="Resotre">
                    <IconButton
                      disabled={!permissions.restore}
                      onClick={() => handleRestore(row.id)}
                    >
                      <MdRestore />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Delete permanently">
                    <IconButton
                      disabled={!permissions.delete}
                      onClick={() => handleDelete(row.id)}
                    >
                      <MdDelete />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                value
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </React.Fragment>
  );
};

export default MainTableRow;
