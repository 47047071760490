import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import React from "react";
import { AiOutlineProfile } from "react-icons/ai";
// mui
import Delete from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { HiOutlineDuplicate } from "react-icons/hi";

const SetupCCTable = ({
  name,
  allData,
  handleDelete,
  handleDuplicate,
  handleEdit,
  handleView,
  columns,
  conditionalColumns,
  actions,
  pagination,
  setPagination,
  loading,
  setReload,
  permissions,
}) => {
  const rows = allData;

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 198px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 20px",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row, i) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={i}
                    sx={{
                      "& .MuiTableCell-sizeMedium": {
                        padding: "10px 0px 10px 20px",
                      },
                    }}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={column.style}
                        >
                          {conditionalColumns
                            ? conditionalColumns(row, column, value)
                            : value}
                          {column.id === "action" && (
                            <>
                              {actions?.length > 0 && (
                                <Box
                                  sx={{
                                    display: "flex",
                                    gap: "10px",
                                    justifyContent: "center",
                                  }}
                                >
                                  {actions?.map((action, i) => (
                                    <React.Fragment key={i}>
                                      {action === "view" && (
                                        <Tooltip
                                          title="Details"
                                          disabled={!permissions?.show}
                                        >
                                          <IconButton
                                            onClick={() => handleView(row)}
                                          >
                                            <AiOutlineProfile />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {action === "duplicate" && (
                                        <Tooltip
                                          title="Duplicate"
                                          disabled={!permissions?.duplicate}
                                        >
                                          <IconButton
                                            onClick={() => handleDuplicate(row)}
                                          >
                                            <HiOutlineDuplicate
                                              style={{ fontSize: "22px" }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {action === "edit" && (
                                        <Tooltip
                                          title="Edit"
                                          disabled={
                                            (name === "Role" &&
                                              row.name === "Admin") ||
                                            !permissions?.update
                                          }
                                        >
                                          <IconButton
                                            onClick={() => handleEdit(row)}
                                          >
                                            <Edit
                                              style={{ fontSize: "22px" }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                      {action === "delete" && (
                                        <Tooltip
                                          title="Delete"
                                          disabled={
                                            (name === "Role" &&
                                              row.name === "Admin") ||
                                            !permissions?.delete
                                          }
                                        >
                                          <IconButton
                                            onClick={() => handleDelete(row.id)}
                                          >
                                            <Delete
                                              style={{ fontSize: "22px" }}
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </React.Fragment>
                                  ))}
                                </Box>
                              )}
                            </>
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 198px)" />
      )}

      <TablePagination
        pagination={pagination}
        setPagination={setPagination}
        setReload={setReload}
      />
    </Paper>
  );
};

export default SetupCCTable;
