import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import moment from "moment";
import removeHtml from "utils/removeHtml";

const EmailTable = ({
  loading,
  allEmail,
  pagination,
  setPagination,
  setReload,
  setViewEmail,
}) => {
  const rows = allEmail;
  const columns = [
    { id: "user_name", label: "User", minWidth: 130 },
    { id: "email_details", label: "Email Details", minWidth: 130 },
    { id: "created_at", label: "Sent Time" },
  ];

  return (
    <>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 255px)", overflowX: "auto" }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    key={row.id}
                    sx={{ cursor: "pointer" }}
                    onClick={() => setViewEmail({ data: row, open: true })}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "email_details" ? (
                            <Box>
                              <Typography>
                                <span style={{ fontWeight: "500" }}>
                                  {row?.subject.length > 25
                                    ? row?.subject.slice(0, 25) + "..."
                                    : row?.subject}
                                </span>{" "}
                                {removeHtml(row?.mail_body, 40)}
                              </Typography>
                            </Box>
                          ) : column.id === "created_at" ? (
                            <Box> {moment(value).format("lll")}</Box>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 255px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </>
  );
};

export default EmailTable;
