import { Box, Skeleton } from "@mui/material";

const EmailValueskeleton = ({ loading }) => {
  return (
    <>
      {loading && (
        <>
          {[...Array(5)].map((_, i) => (
            <Box
              key={i}
              sx={{
                paddingTop: "10px",
                marginTop: ".5rem",
              }}
            >
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", maxWidth: "100%" }}
              />
            </Box>
          ))}
        </>
      )}
    </>
  );
};

export default EmailValueskeleton;
