export const processEducationForm = (source) => {
  const processedArray = [];
  source?.forEach((sourceSingleData) => {
    if (sourceSingleData?.name === "SSC" || sourceSingleData?.name === "HSC")
      processedArray.push({
        name: sourceSingleData?.name,
        board: sourceSingleData?.board,
        group: sourceSingleData?.group,
        gpa: sourceSingleData?.gpa,
        passing_year: sourceSingleData?.passing_year,
      });
    if (
      sourceSingleData?.name === "Bachelor" ||
      sourceSingleData?.name === "Master"
    )
      processedArray.push({
        name: sourceSingleData?.name,
        university_id: sourceSingleData.university_id,
        subject_id: sourceSingleData.subject_id,
        gpa: sourceSingleData?.gpa,
        passing_year: sourceSingleData?.passing_year,
      });
    if (
      sourceSingleData?.name === "O Level" ||
      sourceSingleData?.name === "A Level"
    )
      processedArray.push({
        name: sourceSingleData?.name,
        institute_id: sourceSingleData?.institute_id,
        grade: sourceSingleData?.grade,
        passing_year: sourceSingleData?.passing_year,
      });
  });
  return processedArray;
};

export const processEducationForProfile = (profile) => {
  const { educations, ...rest } = profile;
  const processedEdu = [];
  educations?.forEach((education) => {
    if (education?.name === "SSC" || education?.name === "HSC")
      processedEdu.push({
        name: education?.name,
        board: education?.board,
        group: education?.group,
        gpa: education?.gpa,
        passing_year: education?.passing_year,
      });
    if (education?.name === "Bachelor" || education?.name === "Master")
      processedEdu.push({
        name: education?.name,
        university_name: education.university_name,
        subject_name: education.subject_name,
        gpa: education?.gpa,
        passing_year: education?.passing_year,
      });
    if (education?.name === "O Level" || education?.name === "A Level")
      processedEdu.push({
        name: education?.name,
        institute_name: education?.institute_name,
        grade: education?.grade,
        passing_year: education?.passing_year,
      });
  });
  return { ...rest, educations: processedEdu };
};
