import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import ExpenseTable from "components/common/ExpenseTable";
import Activity from "components/common/activity";
import Attachment from "components/common/attachment";
import Email from "components/common/email";
import Invoice from "components/common/invoice";
import Note from "components/common/note";
import Project from "components/common/project";
import Task from "components/common/task";
import NotAuthorized from "components/notAuthorized";
import RightDrawer from "components/rightDrawer/RightDrawer";
import useBase from "hooks/useBase";
import { useEffect, useState } from "react";
import CustomerPermissions from "./CustomerPermissions";
import Profile from "./profile/Profile";
import useQueries from "hooks/useQueries";
import useApi from "hooks/useApi";

const ViewCustomer = ({
  openDrawer,
  setOpenDrawer,
  setReload,
  allCustomers,
  setAllCustomers,
  permissions,
}) => {
  const { row_id, mod_id, setQueries } = useQueries();
  const { fetchData, loading } = useApi();
  const [profile, setProfile] = useState({});
  const [value, setValue] = useState(mod_id || "1");
  const { base } = useBase();
  const activeEmailModules = base?.settings?.email?.active_modules;

  const handleChange = (event, newValue) => {
    setQueries(`id=${row_id}&m=${newValue}`);
    setValue(newValue);
  };
  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/customer/show/${row_id}`,
      };
      const { success, data } = await fetchData(endpoint, false);
      if (success) setProfile(data);
    };
    getProfile();
  }, []);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "1000px",
        headingLeft:
          "#" + row_id + `${profile?.name ? ` - ${profile?.name}` : ""}`,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", overflowX: "hidden" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              variant="scrollable"
              allowScrollButtonsMobile
            >
              <Tab sx={{ paddingLeft: "0 " }} label="Profile" value="1" />
              <Tab label="Permissions" value="2" />
              <Tab label="Attachment" value="3" />
              <Tab label="Note" value="4" />
              {activeEmailModules?.includes("customer") && (
                <Tab label="Email" value="10" />
              )}
              <Tab label="Task" value="5" />
              <Tab label="Project" value="6" />
              <Tab label="Invoice" value="7" />
              <Tab label="Expense" value="8" />
              <Tab label="Activity" value="9" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1" sx={{ p: 0 }}>
              {permissions["show"] ? (
                <Profile
                  profile={profile}
                  setReload={setReload}
                  loading={loading}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>

            <TabPanel value="2" sx={{ p: 0 }}>
              <CustomerPermissions customer_id={row_id} />
            </TabPanel>
            <TabPanel value="3">
              {permissions["attachment"] ? (
                <Attachment
                  id={
                    allCustomers?.find((customer) => customer?.id === row_id)
                      ?.lead_id
                  }
                  route="customer"
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="4">
              {permissions["note"] ? (
                <Note
                  id={row_id}
                  related_id={
                    allCustomers?.find((customer) => customer?.id === row_id)
                      ?.lead_id
                  }
                  related_to="lead"
                  table_note_key="notes"
                  route="customer"
                  allData={allCustomers}
                  setAllData={setAllCustomers}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel sx={{ paddingBottom: "0px" }} value="5">
              {permissions["task"] ? (
                <Task
                  id={
                    allCustomers?.find((customer) => customer?.id === row_id)
                      ?.lead_id
                  }
                  related_to="lead"
                  route="customer"
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="6">
              <Project customer_id={row_id} />
            </TabPanel>
            <TabPanel value="7">
              <Invoice customer_id={row_id} />
            </TabPanel>
            <TabPanel value="8">
              <ExpenseTable customer_id={row_id} />
            </TabPanel>
            <TabPanel value="9">
              {permissions["activity"] ? (
                <Activity
                  activityList={{
                    method: "get",
                    url: `/api/admin/customer/activity/${row_id}`,
                  }}
                />
              ) : (
                <NotAuthorized isModal />
              )}
            </TabPanel>
            <TabPanel value="10">
              <Email
                email_addresses={[profile?.email || ""]}
                related_to="customer"
                related_id={profile?.id}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default ViewCustomer;
