import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Box, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import useCopy from "hooks/useCopy";
import * as yup from "yup";
import QRCode from "react-qr-code";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { DownloadOutlined } from "@mui/icons-material";
import html2canvas from "html2canvas";

const CopyToClipboard = ({ path }) => {
  const [copied, handleCopy] = useCopy(path);
  return (
    <>
      <Box
        sx={{
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "10px",
          border: "1px solid #ddd",
          borderRadius: "50px",
          p: "2px 10px",
          pr: "2px",
        }}
      >
        <Typography
          sx={{
            fontSize: "12px",
          }}
        >
          {path}
        </Typography>
        <Tooltip title={copied ? "Copied" : "Copy"}>
          <IconButton
            size="small"
            onClick={() => {
              handleCopy();
            }}
          >
            <ContentCopyIcon
              sx={{
                fontSize: "20px",
                cursor: "pointer",
                fill: "#00000040",
                "&:hover": {
                  fill: "#00000066",
                },
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
};

const Sources = () => {
  const [openModal, setOpenModal] = useState(false);
  const domain = window?.location?.origin;

  const initialValues = {
    name: "",
  };

  const downloadQR = () => {
    const qrContainer = document.querySelector(".qrcode"); // Get the chart's container
    const image = new Image();

    html2canvas(qrContainer).then((canvas) => {
      image.src = canvas.toDataURL("image/png");

      const link = document.createElement("a");
      link.href = image.src;
      link.download = `${openModal}.png`;
      link.click();
    });
  };

  return (
    <>
      {openModal && (
        <Dialog open={openModal} onClose={() => setOpenModal("")} maxWidth="md">
          <Box
            sx={{
              width: "580px",
              margin: "0 auto",
              padding: "10px",
            }}
          >
            <Box className="qrcode" sx={{ p: "10px 10px 5px" }}>
              <QRCode
                size={400}
                style={{
                  height: "auto",
                  maxWidth: "100%",
                  width: "100%",
                }}
                value={`${domain}/info-form/${openModal
                  .toLowerCase()
                  .split(" ")
                  .join("_")}`}
                viewBox={`0 0 400 400`}
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", pt: "5px" }}
            >
              <LoadingButton
                loading={false}
                loadingPosition="start"
                variant="text"
                startIcon={<DownloadOutlined />}
                onClick={downloadQR}
              >
                Download QR-Code
              </LoadingButton>
            </Box>
          </Box>
        </Dialog>
      )}
      <SetupCC
        name="Source"
        baseRouteKey="sources"
        moduleKey="source"
        columns={[
          { id: "name", label: "Source Name", minWidth: 100 },
          { id: "form_path", label: "Source Form", minWidth: 100 },
          {
            id: "qr",
            label: "Download QR-Code",
            minWidth: 100,
            align: "center",
          },
          {
            id: "action",
            label: "Actions",
            style: { width: "150px" },
            align: "center",
          },
        ]}
        conditionalColumns={(row, column, value) => {
          return (
            <>
              {column?.id === "form_path" ? (
                <CopyToClipboard
                  path={`${domain}/info-form/${row?.name
                    .toLowerCase()
                    .split(" ")
                    .join("_")}`}
                />
              ) : column?.id === "qr" ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => setOpenModal(row?.name)}
                  >
                    <QRCode
                      size={44}
                      style={{
                        height: "50px",
                        maxWidth: "100%",
                        width: "100%",
                      }}
                      value={`${domain}/info-form/${row?.name
                        .toLowerCase()
                        .split(" ")
                        .join("_")}`}
                      viewBox={`0 0 44 44`}
                    />
                  </Box>
                </Box>
              ) : (
                value
              )}
            </>
          );
        }}
        actions={["edit", "delete"]}
        getInitialValues={(data) => {
          if (data?.id) {
            let obj = {};
            for (const key in initialValues) {
              obj[key] = data[key];
            }
            return obj;
          } else return initialValues;
        }}
        validationSchema={yup.object({
          // name: yup.string().required("Name is required!"),
        })}
        allFields={(formik) => (
          <>
            <InputField name="name" label="Source Name" formik={formik} />
          </>
        )}
      ></SetupCC>
    </>
  );
};

export default Sources;
