import { Box, Checkbox, FormControlLabel } from "@mui/material";
import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import * as yup from "yup";

const TaskStatuses = () => {
  const initialValues = {
    name: "",
    color: "#000000",
    is_finished: false,
    is_open: false,
  };
  return (
    <SetupCC
      shouldReload
      name="Task Status"
      baseRouteKey="taskStatuses"
      moduleKey="task-status"
      columns={[
        { id: "name", label: "Name", minWidth: 100 },
        { id: "color", label: "Status Color", align: "center" },
        { id: "is_finished", label: "Finised", align: "center" },
        { id: "is_open", label: "Default", align: "center" },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      conditionalColumns={(row, column, value) => {
        return (
          <>
            {column?.id === "color" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box
                  sx={{
                    bgcolor: value,
                    height: "20px",
                    width: "20px",
                    borderRadius: "5px",
                  }}
                ></Box>
              </Box>
            ) : column?.id === "is_finished" ? (
              <>{value ? "Yes" : "No"}</>
            ) : column?.id === "is_open" ? (
              <>{value ? "Yes" : "No"}</>
            ) : (
              value
            )}
          </>
        );
      }}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }

          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Name" formik={formik} />
          <InputField
            label="Color"
            name="color"
            type="color"
            formik={formik}
            InputLabelProps={{ shrink: true }}
          />
          <div
            style={{
              display: "flex",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="is_finished"
                  checked={formik.values.is_finished}
                  onChange={(event) => {
                    formik.setFieldValue("is_finished", event.target.checked);
                    if (event.target.checked) {
                      formik.setFieldValue("is_open", false);
                    }
                  }}
                />
              }
              label="Finised"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="is_open"
                  checked={formik.values.is_open}
                  onChange={(event) => {
                    formik.setFieldValue("is_open", event.target.checked);
                    if (event.target.checked) {
                      formik.setFieldValue("is_finished", false);
                    }
                  }}
                />
              }
              label="Default"
            />
          </div>
        </>
      )}
    ></SetupCC>
  );
};

export default TaskStatuses;
