import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import useApi from "hooks/useApi";

const Summary = ({ filters, search }) => {
  const [invoiceBankSummary, setInvoiceBankSummary] = useState([]);
  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getInvoiceBankSummary = async () => {
      const endpoint = {
        method: "post",
        url: "api/admin/bank-invoice/summery",
        data: { ...filters, search },
      };
      const result = await fetchData(endpoint, false);
      setInvoiceBankSummary(result?.data || {});
    };
    getInvoiceBankSummary();
  }, [search, filters]);

  return loading ? (
    <Box
      sx={{
        height: "calc(100vh - 145px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
        gap: "20px",
        padding: "20px 20px",
      }}
    >
      {Object.keys(invoiceBankSummary).map((singleSummary, i) => (
        <Box
          key={i}
          sx={{
            bgcolor: "#fff",
            padding: "20px",
            borderRadius: "5px",
            boxShadow: "2px 1px 3px #e5e5e5",
          }}
        >
          <Typography
            sx={{
              textTransform: "capitalize",
            }}
          >
            {singleSummary.split("_").join(" ")}
          </Typography>
          <Typography variant="h3">
            {invoiceBankSummary?.[singleSummary]}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default Summary;
