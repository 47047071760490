import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import MainTableRow from "./MainTableRow";

const MainTable = ({
  handleDelete,
  pagination,
  setPagination,
  loading,
  setReload,
  allTemplate,
  setAllTemplate,
  handleEdit,
  permissions,
}) => {
  const rows = allTemplate;
  const columns = [
    { id: "title", label: "Title" },
    { id: "body", label: "Message" },
    { id: "is_default", label: "Default" },
    { id: "user_name", label: "User Name" },
    {
      id: "action",
      label: "Actions",
      style: { width: "100px" },
      align: "center",
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      {!loading ? (
        <TableContainer
          sx={{ height: "calc(100vh - 198px)", overflowX: "auto" }}
        >
          <Table
            stickyHeader
            sx={{
              "& th:first-of-type": { pl: "20px", pr: "0px" },
              "& th:last-child": { pr: "20px" },
              "& td:first-of-type": { pl: "20px", pr: "0px" },
              "& td:last-child": { pr: "20px" },
            }}
          >
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows?.map((row) => (
                <MainTableRow
                  key={row.id}
                  row={row}
                  columns={columns}
                  span={columns?.length}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  setReload={setReload}
                  allTemplate={allTemplate}
                  setAllTemplate={setAllTemplate}
                  permissions={permissions}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 198px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Paper>
  );
};

export default MainTable;
