import { TabList, TabPanel } from "@mui/lab";
import TabContext from "@mui/lab/TabContext";
import { Box, Tab } from "@mui/material";
import Note from "components/common/note";
import RightDrawer from "components/rightDrawer/RightDrawer";
import { useState } from "react";
import { useSelector } from "react-redux";
import Details from "./Details";
import Installments from "./installment/Installments";
import MakePayments from "./payment/MakePayments";
import { useEffect } from "react";
import useQueries from "hooks/useQueries";
import useApi from "hooks/useApi";

const ViewSales = ({
  openDrawer,
  setOpenDrawer,
  setReload,
  allSales,
  setAllSales,
}) => {
  const { row_id, mod_id, setQueries } = useQueries();
  const { fetchData, loading } = useApi();
  const [profile, setProfile] = useState({});
  const [value, setValue] = useState(mod_id || "1");

  const handleChange = (event, newValue) => {
    setQueries(`id=${row_id}&m=${newValue}`);
    setValue(newValue);
  };

  const currency_id = useSelector(
    (state) => state?.base?.base_data?.settings?.app?.currency_id
  );

  const currencies = useSelector((state) => state?.base?.base_data?.currencies);

  const symbol = currency_id
    ? currencies?.find((c) => c.id === currency_id)?.symbol
    : "";

  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/sales/show/${row_id}`,
      };
      const { success, data } = await fetchData(endpoint, false);
      if (success) setProfile(data);
    };
    getProfile();
  }, []);

  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer,
        width: "1020px",
        headingLeft:
          "#" +
          row_id +
          `${profile?.invoice_number ? ` - ${profile?.invoice_number}` : ""}`,
        headingRight: "",
      }}
    >
      <Box sx={{ width: "100%", typography: "body1", overflowX: "hidden" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange}>
              <Tab label="View Details" value="1" />
              <Tab label="Installment" value="2" />
              <Tab label="Payments" value="3" />
              <Tab label="Note" value="4" />
            </TabList>
          </Box>
          <Box
            sx={{
              position: "relative",
              height: "calc(100vh - 114px)",
            }}
          >
            <TabPanel value="1">
              <Details profile={profile} symbol={symbol} loading={loading} />
            </TabPanel>
            <TabPanel value="2">
              <Installments
                profile={profile}
                setReloadMain={setReload}
                symbol={symbol}
              />
            </TabPanel>
            <TabPanel value="3">
              <MakePayments
                profile={profile}
                setReloadMain={setReload}
                symbol={symbol}
              />
            </TabPanel>
            <TabPanel value="4">
              <Note
                id={row_id}
                related_id={row_id}
                related_to="installation_invoice"
                route="sales"
                allData={allSales}
                setAllData={setAllSales}
              />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </RightDrawer>
  );
};

export default ViewSales;
