import { Box, InputBase } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import { increament } from "lib/Reducer/features/todo/todoSlice";
import moment from "moment";
import { useDispatch } from "react-redux";

const AddTodo = ({
  setActiveMenu,
  activeMenu,
  setTodos,
  setFilters,
  initialFIlters,
}) => {
  let dispatch = useDispatch();
  const initialValues = {
    text: "",
    date: moment().format("YYYY-MM-DD"),
  };

  const { fetchData } = useApi();
  const onSubmit = async (data) => {
    let endpoint = {
      method: "post",
      url: "/api/admin/todo/create",
      data,
    };
    const result = await fetchData(endpoint, false);
    if (result.success) {
      if (activeMenu === "Completed") {
        setActiveMenu("Active");
        setFilters({ ...initialFIlters, is_finished: false });
      } else {
        setTodos((oldTodos) => [result?.data, ...oldTodos]);
      }
      formik.resetForm();
      dispatch(increament());
    }
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
  });

  return (
    <Box
      sx={{
        background: "#fff",
        padding: "10px 10px 10px 15px",
        mb: "20px",
        display: "grid",
        gridTemplateColumns: "auto 135px",
        gap: "15px",
      }}
    >
      <InputBase
        placeholder="Create a new todo..."
        autoFocus
        sx={{ color: "#555" }}
        value={formik.values.text}
        onChange={(e) => formik.setFieldValue("text", e.target.value)}
        onKeyDown={(e) => e.key === "Enter" && formik.submitForm()}
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <span>{moment(formik.values.date).format("ll")}</span>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            label="Custom input"
            value={formik.values.date}
            onChange={(newValue) => {
              formik.setFieldValue(
                "date",
                moment(newValue).format("YYYY-MM-DD")
              );
            }}
            renderInput={({ inputRef, inputProps, InputProps }) => (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                {InputProps?.endAdornment}
                <input
                  style={{
                    width: "0px",
                    height: "0px",
                    opacity: 0,
                  }}
                  ref={inputRef}
                  {...inputProps}
                />
              </Box>
            )}
          />
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default AddTodo;
