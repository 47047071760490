import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";

const CustomerTable = ({ user_id }) => {
  const [allCustomer, setAllCustomer] = useState([]);
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 30,
    to: 0,
    from: 0,
    total: 0,
  });

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getCustomers = async () => {
      let finalPagination = { ...pagination };
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/customer/list?${params}`,
        data: { user_id: [user_id] },
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllCustomer(allData || []);
        setPagination(rest);
      }
    };
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page]);

  const columns = [
    { id: "name", label: "Customer Name", style: { width: "150px" } },
    { id: "phone", label: "Phone" },
    { id: "followup_date", label: "Followup Date", style: { width: "150px" } },
    { id: "email", label: "Email" },
    {
      id: "preferred_countries",
      label: "Preferred Countries",
      style: { width: "170px" },
    },
    {
      id: "status",
      label: "Status",
      align: "center",
      style: { width: "140px" },
    },
    { id: "source_name", label: "Source" },
  ];

  return (
    <Box sx={{ m: "-20px" }}>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        {!loading ? (
          <TableContainer
            sx={{ height: "calc(100vh - 188px)", overflowX: "auto" }}
          >
            <Table
              stickyHeader
              sx={{
                "& th:first-of-type": { pl: "20px", pr: "0px" },
                "& th:last-child": { pr: "20px" },
                "& td:first-of-type": { pl: "20px", pr: "0px" },
                "& td:last-child": { pr: "20px" },
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, ...column.style }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allCustomer?.map((row) => (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === "number" ? (
                            column.format(value)
                          ) : column.id === "live_note" ? (
                            <textarea
                              name=""
                              id=""
                              cols="30"
                              rows="10"
                            ></textarea>
                          ) : column.id === "preferred_countries" ? (
                            value.map((cn) => cn.name).join(", ") || "-"
                          ) : column.id === "lead_status" ? (
                            value
                          ) : column.id === "followup_date" ? (
                            value ? (
                              moment(value).format("ll")
                            ) : (
                              "-"
                            )
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableSkeleton columns={columns} tableHeight="calc(100vh - 188px)" />
        )}
        <TablePagination
          span={columns?.length}
          setReload={setReload}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Paper>
    </Box>
  );
};

export default CustomerTable;
