import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Comments from "./comment/Comments";
import Vote from "./vote/Vote";
const SingleKnowledgebase = () => {
  let [singleKnowledgebase, setSingleKnowledgebase] = useState({});
  let { kbId } = useParams();
  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getSingleKnowledge = async () => {
      const endpoint = {
        method: "get",
        url: `/api/knowledge/details/${kbId}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        setSingleKnowledgebase(result.data);
      }
    };
    getSingleKnowledge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Box
      sx={{
        mt: 3,
        px: 3,
      }}
    >
      {loading ? (
        <>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              alignItems: "center",
              mb: 2,
            }}
          >
            <Skeleton
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
            <div
              style={{
                flexGrow: 1,
              }}
            >
              <Skeleton
                animation="wave"
                height={10}
                width="100%"
                style={{ marginBottom: 6 }}
              />{" "}
              <Skeleton
                animation="wave"
                height={10}
                width="80%"
                style={{ marginBottom: 6 }}
              />
            </div>
          </Box>
          {[...Array(10)].map((_, i) => (
            <Skeleton
              key={i}
              animation="wave"
              height={10}
              style={{ marginBottom: 6 }}
            />
          ))}{" "}
        </>
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Stack spacing={1} direction={"row"} alignItems={"center"}>
                <Avatar
                  alt={singleKnowledgebase?.created_by_name}
                  src={
                    UNIVERSAL.BASEURL + singleKnowledgebase?.created_by_image
                  }
                />

                <Stack>
                  <Typography variant="span">
                    {singleKnowledgebase?.created_by_name}
                  </Typography>
                  <Typography variant="span">
                    {moment(singleKnowledgebase?.created_at).format("ll")}
                  </Typography>
                </Stack>
              </Stack>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h2">{singleKnowledgebase?.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                Category: {singleKnowledgebase?.knowledge_base_category_name}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: singleKnowledgebase?.description,
                }}
              ></Typography>
            </Grid>
          </Grid>

          <Vote currKB={singleKnowledgebase} />

          <Comments post={singleKnowledgebase} isClient />
        </>
      )}
    </Box>
  );
};

export default SingleKnowledgebase;
