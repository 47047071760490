import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Avatar,
  Box,
  Button,
  Chip,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import NoteSkeleton from "components/skeletons/NoteSkeleton";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import CommentForm from "./CommentForm";
import ViewAttachment from "./ViewCommentAttachment";

function Action({ comment, handleDelete, handleEdit }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleDelete(comment);
          }}
        >
          Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            handleEdit(comment);
          }}
        >
          Edit
        </MenuItem>
      </Menu>
    </div>
  );
}

const Comment = ({
  comment,
  handleDelete,
  editComment,
  setEditComment,
  reply,
  setReply,
  postId,
  setCommnets,
  parent_id,
}) => {
  const [viewAtt, setViewAtt] = useState(false);
  const [currFile, setCurrFile] = useState({});
  const { loading: editLoading, fetchData: editCommentApi } = useApi();

  const handleFileView = (comment) => {
    if (comment?.mime_type.split("/").includes("image")) {
      setCurrFile(comment);
      setViewAtt(true);
    } else {
      window.open(UNIVERSAL.BASEURL + comment?.image, { replace: true });
    }
  };
  let handleEdit = (commentEdit) => {
    if (commentEdit?.id === editComment) {
      setEditComment("");
    } else {
      setReply("");
      setEditComment(commentEdit?.id);
    }
  };
  let handleReply = () => {
    if (reply === comment.id) {
      setReply("");
    } else {
      setReply(comment.id);
      setEditComment("");
    }
  };
  let onSubmitEdit = async (values) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    formData.append("id", comment.id);
    let endpoint = {
      method: "post",
      url: "/api/knowledge/comment/update",
      data: formData,
    };

    let result = await editCommentApi(endpoint, false);
    if (result.success) {
      if (comment.parent_id === 0) {
        let { id, ...newdata } = result.data;
        setCommnets((prev) =>
          prev.map((item) => {
            if (item.id === comment.id) {
              return {
                ...item,
                ...newdata,
              };
            } else {
              return item;
            }
          })
        );
      } else {
        setCommnets((prev) =>
          prev.map((item) => {
            if (item.id === result.data.id) {
              return {
                ...item,
                comments: result.data.comments,
              };
            } else {
              return item;
            }
          })
        );
      }
    }
    setEditComment("");
  };
  return (
    <>
      <Box
        key={comment?.id}
        sx={{
          display: "grid",
          gridTemplateColumns: "50px auto 30px",
          padding: "10px",
          borderTop: "1px dashed #ddd",
        }}
      >
        <Box>
          <Avatar
            alt={comment?.customer_name || comment?.name}
            src={
              UNIVERSAL.BASEURL +
              (comment?.customer_image || comment?.user_image)
            }
          />
        </Box>

        <Box sx={{ paddingBottom: "15px" }}>
          <Typography variant="h6">
            {comment?.customer_name || comment?.name}
          </Typography>
          <Box sx={{ fontSize: "12px" }}>
            {moment(comment?.created_at).format("lll")}
          </Box>
          <Box
            sx={{ fontSize: "14px", paddingTop: "10px", paddingBottom: "10px" }}
          >
            {comment?.note}
          </Box>
          {comment?.mime_type?.split("/").includes("image") ? (
            <Button onClick={() => handleFileView(comment)}>
              <img
                style={{
                  width: "10rem",
                }}
                alt=""
                src={UNIVERSAL.BASEURL + comment?.image}
              />
            </Button>
          ) : (
            comment.image && (
              <Chip
                sx={{ mb: "15px" }}
                label={comment?.image_name}
                component="a"
                href={UNIVERSAL?.BASEURL + comment.image}
                target="_blank"
                variant="outlined"
                clickable
              />
            )
          )}
        </Box>

        <Box>
          <Action
            comment={comment}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
          />
        </Box>
      </Box>
      <Box sx={{ paddingBottom: "10px", position: "absolute", bottom: "-5px" }}>
        {comment.parent_id === 0 && (
          <Button onClick={handleReply}>Reply</Button>
        )}
      </Box>

      {editComment === comment?.id && (
        <Box p={2}>
          <CommentForm
            data={comment}
            parent_id={parent_id}
            postId={postId}
            placeholder={"Edit Comment"}
            btnText={"Edit Comment"}
            onSubmit={onSubmitEdit}
            loading={editLoading}
          />
        </Box>
      )}
      {viewAtt && <ViewAttachment setViewAtt={setViewAtt} file={currFile} />}
    </>
  );
};

const Comments = ({ post }) => {
  const [reply, setReply] = useState("");
  const [editComment, setEditComment] = useState("");

  const { loading, fetchData } = useApi();

  const { fetchData: deleteComment } = useApi();
  const { loading: addCmntloading, fetchData: addComment } = useApi();
  let [commnets, setCommnets] = useState([]);
  useEffect(() => {
    async function allCommnts() {
      const endpoint = {
        method: "get",
        url: `/api/knowledge/comment/${post?.id}`,
      };
      const result = await fetchData(endpoint, false);
      setCommnets(result.data);
    }
    allCommnts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let handleDelete = async (comment) => {
    const endpoint = {
      method: "delete",
      url: `/api/knowledge/comment/delete/${comment.id}`,
    };
    const result = await deleteComment(endpoint, false);
    if (result.success) {
      comment.parent_id === 0
        ? setCommnets((prev) => prev.filter((c) => c.id !== comment?.id))
        : setCommnets((prev) =>
            prev.map((item) => {
              return {
                ...item,
                comments: item.comments.filter((c) => c.id !== comment?.id),
              };
            })
          );
    }
  };
  const onSubmit = async (values, action) => {
    const formData = new FormData();
    Object.keys(values).forEach((key) => formData.append(key, values[key]));
    let endpoint = {
      method: "post",
      url: "/api/knowledge/comment/add",
      data: formData,
    };
    let result = await addComment(endpoint, false);
    if (result.success) {
      if (result.data.comments.length > 0) {
        setCommnets((prev) =>
          prev.map((item) => {
            if (item.id === result.data.id) {
              return { ...item, comments: result.data.comments };
            } else {
              return item;
            }
          })
        );
      } else {
        setCommnets((prev) => [...prev, result.data]);
      }
    }
    action.resetForm();
    setReply("");
  };

  return (
    <Box>
      <CommentForm
        postId={post.id}
        placeholder={"Add Comment"}
        btnText={"Comment"}
        onSubmit={onSubmit}
        loading={addCmntloading}
      />
      <Box sx={{ height: "calc(100vh - 324px)", overflowY: "auto" }}>
        {loading ? (
          <>
            <NoteSkeleton />
            <NoteSkeleton />
            <NoteSkeleton />
          </>
        ) : (
          <>
            {commnets
              .sort((a, b) => new Date(b.created_at) - new Date(a?.created_at))
              .map((comment) => (
                <div
                  key={comment.id}
                  style={{
                    position: "relative",
                  }}
                >
                  <Comment
                    comment={comment}
                    handleDelete={handleDelete}
                    editComment={editComment}
                    setEditComment={setEditComment}
                    setReply={setReply}
                    reply={reply}
                    onSubmit={onSubmit}
                    postId={post?.id}
                    setCommnets={setCommnets}
                    addCmntloading={addCmntloading}
                  />
                  <Box sx={{ marginLeft: "50px" }}>
                    {comment?.comments.map((rp) => (
                      <Fragment key={rp.id}>
                        <Comment
                          parent_id={comment.id}
                          postId={post.id}
                          comment={rp}
                          handleDelete={handleDelete}
                          setCommnets={setCommnets}
                          editComment={editComment}
                          setReply={setReply}
                          setEditComment={setEditComment}
                        />
                      </Fragment>
                    ))}
                  </Box>
                  {/* Replay  Comment */}
                  {reply === comment.id && (
                    <Box p={2}>
                      <CommentForm
                        placeholder={"Reply Comment"}
                        btnText={"Reply"}
                        parent_id={comment.id}
                        onSubmit={onSubmit}
                        postId={post?.id}
                        loading={addCmntloading}
                      />
                    </Box>
                  )}
                </div>
              ))}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Comments;
