import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import MultiSelect from "components/ui/multiSelect";
import * as yup from "yup";

const Courses = () => {
  const initialValues = {
    name: "",
    fields: [],
    description: "",
  };

  const allFileds = [
    { label: "Overall", id: "Overall" },
    { label: "Listening", id: "Listening" },
    { label: "Writing", id: "Writing" },
    { label: "Speaking", id: "Speaking" },
    { label: "Reading", id: "Reading" },
    { label: "Literacy", id: "Literacy" },
    { label: "Comprehension", id: "Comprehension" },
    { label: "Conversation", id: "Conversation" },
    { label: "Production", id: "Production" },
  ];

  return (
    <SetupCC
      name="Course"
      baseRouteKey="courses"
      moduleKey="course"
      columns={[
        { id: "name", label: "Course Name", minWidth: 100 },
        { id: "fields", label: "Fields", minWidth: 200 },
        { id: "description", label: "Description", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      conditionalColumns={(row, column, value) => {
        return (
          <>{column?.id === "fields" ? row["fields"].join(", ") : value}</>
        );
      }}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Course Name" formik={formik} />
          <MultiSelect
            options={allFileds}
            label="Select Fileds"
            value={formik?.values?.fields}
            onChange={(value) => formik.setFieldValue("fields", value)}
          />
          <InputField
            name="description"
            label="Description"
            formik={formik}
            rows={2}
            multiline
          />
        </>
      )}
    ></SetupCC>
  );
};

export default Courses;
