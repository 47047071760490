import { PrintRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import Note from "components/common/note";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { BiLeftArrowAlt } from "react-icons/bi";
import { MdEditNote } from "react-icons/md";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";

const ViewInvoice = ({ invoice_id, setViewInvoice }) => {
  const [viewNote, setViewNote] = useState(false);
  const { fetchData, loading } = useApi();
  const [invoice, setInvoice] = useState({});
  const site_logo = useSelector((state) => state?.base?.site_info?.site_logo);

  useEffect(() => {
    const getProfile = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/invoice/show/${invoice_id}`,
      };
      const { success, data } = await fetchData(endpoint, false);
      if (success) setInvoice(data);
    };
    getProfile();
  }, []);

  const columns = [
    { id: "item", label: "Item", minWidth: 100 },
    { id: "subtotal", label: "Subtotal", minWidth: 100 },
    { id: "tax", label: "Tax", minWidth: 100 },
    { id: "discount", label: "Discount", minWidth: 100 },
    { id: "total", label: "Total" },
  ];
  const payColumns = [
    { id: "transaction_id", label: "TrxID" },
    { id: "payment_method_name", label: "Pay Method" },
    { id: "payment_date", label: "Pay Date" },
    { id: "note", label: "Note", minWidth: 100 },
    { id: "amount", label: "Amount" },
  ];
  const rows = invoice?.invoice_details || [];
  const payRows = invoice?.invoice_payments || [];
  const symbol = invoice?.symbol;

  const printableContentRef = useRef();

  return loading ? (
    <Box
      sx={{
        height: "calc(100vh - 162px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <Box sx={{ m: "-10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() =>
            viewNote ? setViewNote(false) : setViewInvoice(false)
          }
        >
          {viewNote ? "View Invoice" : "View Invoice Table"}
        </Button>
        {!viewNote && (
          <Box>
            <Button
              variant="outlined"
              startIcon={<MdEditNote />}
              sx={{
                position: "absolute",
                right: "120px",
                top: "20px",
              }}
              onClick={() => setViewNote(true)}
            >
              Note
            </Button>
            <ReactToPrint
              documentTitle={invoice?.invoice_number}
              trigger={() => (
                <Button
                  variant="outlined"
                  startIcon={<PrintRounded />}
                  sx={{
                    position: "absolute",
                    right: "20px",
                    top: "20px",
                  }}
                >
                  Print
                </Button>
              )}
              content={() => printableContentRef.current}
            />
          </Box>
        )}
      </Box>
      {viewNote ? (
        <Box mt="20px">
          <Note
            id={invoice_id}
            related_id={invoice_id}
            related_to="invoice"
            table_note_key="notes"
            route="invoice"
            isInvoiceNote
          />
        </Box>
      ) : (
        <Box ref={printableContentRef} p={3}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mt: "10px",
              mb: "35px",
            }}
          >
            <img
              src={UNIVERSAL.BASEURL + site_logo}
              alt=""
              style={{
                maxHeight: "100px",
                maxWidth: "240px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "50px",
            }}
          >
            <Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                <Typography
                  textTransform="uppercase"
                  sx={{ fontWeight: "500" }}
                >
                  Branch:
                </Typography>
                <Typography sx={{ fontWeight: "500" }}>
                  {" "}
                  {invoice?.branch_name || "-"}
                </Typography>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                <Typography textTransform="uppercase">
                  Customer Name:
                </Typography>
                <Typography> {invoice?.customer_name}</Typography>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                <Typography textTransform="uppercase">Project Name:</Typography>
                <Typography>
                  {invoice?.project_name ? invoice?.project_name : "-"}
                </Typography>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                <Typography textTransform="uppercase">
                  Invoice Number:
                </Typography>
                <Typography>{invoice?.invoice_number}</Typography>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                <Typography textTransform="uppercase">Status:</Typography>
                <Typography textTransform="capitalize">
                  {invoice?.status}
                </Typography>
              </Box>
            </Box>
            <Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                <Typography textTransform="uppercase">Invoice Date:</Typography>
                <Typography>
                  {moment(invoice?.created_at).format("ll")}
                </Typography>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                <Typography textTransform="uppercase">Due Date:</Typography>
                <Typography
                  sx={{
                    color:
                      moment(invoice?.due_date).format("YYYY-MM-DD") <
                      moment().format("YYYY-MM-DD")
                        ? "red"
                        : "#222",
                  }}
                >
                  {moment(invoice?.due_date).format("ll")}
                </Typography>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                <Typography textTransform="uppercase">Paid Amount:</Typography>
                <Typography textTransform="capitalize">
                  {invoice?.paid_amount
                    ? `${symbol}${invoice?.paid_amount}`
                    : "-"}
                </Typography>
              </Box>
              <Box sx={{ display: "grid", gridTemplateColumns: "150px 1fr" }}>
                <Typography textTransform="uppercase">Due Amount:</Typography>
                <Typography textTransform="capitalize">
                  {invoice?.paid_amount
                    ? `${invoice?.symbol}${
                        invoice?.total - invoice?.paid_amount
                      }`
                    : invoice?.symbol + invoice?.total}
                </Typography>
              </Box>
            </Box>
          </Box>
          <TableContainer sx={{ mt: "20px" }}>
            <Table stickyHeader>
              <TableBody>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ fontWeight: "500" }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={
                        column.id === "discount" && "hide-on-print-text"
                      }
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
                {rows?.map((row) => {
                  return (
                    <TableRow hover role="checkbox" key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "item" ? (
                              value
                            ) : column.id === "tax" ? (
                              <Typography color="#2fb42f" variant="body2">
                                {"+ " + symbol + value}
                              </Typography>
                            ) : column.id === "discount" ? (
                              <Typography
                                color="#ff3535"
                                variant="body2"
                                className={
                                  column.id === "discount" &&
                                  "hide-on-print-text"
                                }
                              >
                                {"- " + symbol + value}
                              </Typography>
                            ) : column.id === "total" ? (
                              <Typography color="#7171ff" variant="body2">
                                {symbol + value}
                              </Typography>
                            ) : column.id === "subtotal" ? (
                              <Typography variant="body2">
                                {symbol + value}
                              </Typography>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>

          <Typography
            sx={{
              mt: "25px",
              pt: "25px",
              pb: "10px",
              borderBottom: "1px solid #efefef",
            }}
          >
            Payments History
          </Typography>
          {invoice?.invoice_payments?.length > 0 ? (
            <TableContainer sx={{ mt: "20px" }}>
              <Table stickyHeader>
                <TableBody>
                  <TableRow>
                    {payColumns.map((column) => (
                      <TableCell
                        sx={{ fontWeight: "500" }}
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                  {payRows?.map((row) => {
                    return (
                      <TableRow hover role="checkbox" key={row.id}>
                        {payColumns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === "fee" ? (
                                value.name
                              ) : column.id === "amount" ? (
                                <Typography variant="body2">
                                  {symbol + value}
                                </Typography>
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography
              sx={{
                textAlign: "center",
                py: "40px",
                background: "#fbfbfb",
                mt: "10px",
              }}
            >
              No payments happened yet!
            </Typography>
          )}
          <Box
            sx={{
              margin: "20px 0 0 auto",
              padding: "20px 30px",
              border: "1px solid #caddff",
              bgcolor: "#fbfcff",
              width: "300px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "100px auto",
              }}
            >
              <Typography align="right" fontSize="16px">
                Total
              </Typography>
              <Typography align="right" fontSize="16px">
                {symbol + invoice?.total || ""}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ViewInvoice;
