import { Box } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import KnowledgeBaseTable from "./KnowledgeBaseTable";

const CustomerKnowledgeBase = () => {
  const [allKnowLedgeBase, setAllKnowLedgeBase] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 30,
    to: 0,
    from: 0,
    total: 0,
  });
  const debouncedText = useDebounce(searchText, 1000);
  const { loading, fetchData } = useApi();

  useEffect(() => {
    const getCustomerKnowledge = async () => {
      let finalPagination = { ...pagination };
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}&search=${debouncedText}`;
      const endpoint = {
        method: "post",
        url: `/api/knowledge/list?${params}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData } = result;
        setAllKnowLedgeBase(allData.data);
        setPagination(allData);
      }
    };
    getCustomerKnowledge();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, debouncedText]);
  return (
    <Box pt="10px">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          p: "10px",
        }}
      >
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in Knowledge Base.."}
        />
      </Box>
      <KnowledgeBaseTable
        loading={loading}
        allKnowLedgeBase={allKnowLedgeBase}
        pagination={pagination}
        setPagination={setPagination}
        setReload={setReload}
        setAllKnowLedgeBase={setAllKnowLedgeBase}
      />
    </Box>
  );
};

export default CustomerKnowledgeBase;
