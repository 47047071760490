import { PrintRounded } from "@mui/icons-material";
import { Box, Button, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import UNIVERSAL from "config";
import { processEducationForProfile } from "utils/processData";
import moment from "moment";
import { useRef } from "react";
import { useSelector } from "react-redux";
import ReactToPrint from "react-to-print";
import ReactLoading from "react-loading";

const Profile = ({ profile: preProfile, loading }) => {
  const printableContentRef = useRef();
  const profile = processEducationForProfile(preProfile);
  const site_logo = useSelector((state) => state?.base?.site_info?.site_logo);
  const symbol = profile?.symbol;
  const columns = [
    { id: "expense_item_name", label: "Item", minWidth: 100 },
    { id: "subtotal", label: "Subtotal", minWidth: 100 },
    { id: "tax", label: "Tax", minWidth: 100 },
    { id: "discount", label: "Discount", minWidth: 100 },
    { id: "total", label: "Total" },
  ];

  const rows = profile?.expense_details || [];
  return (
    <>
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <>
          <ReactToPrint
            documentTitle={profile?.expense_number}
            trigger={() => (
              <Button
                variant="outlined"
                startIcon={<PrintRounded />}
                sx={{
                  position: "absolute",
                  right: "20px",
                  top: "20px",
                }}
              >
                Print
              </Button>
            )}
            content={() => printableContentRef.current}
          />
          <Box ref={printableContentRef} p={3}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "10px",
                mb: "40px",
              }}
            >
              <img
                src={UNIVERSAL.BASEURL + site_logo}
                alt=""
                style={{
                  maxHeight: "100px",
                  maxWidth: "240px",
                }}
              />
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography textTransform="uppercase">
                    Expense Number:
                  </Typography>
                  <Typography sx={{ textAlign: "end" }}>
                    {profile?.expense_number}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography textTransform="uppercase">
                    Customer Name:
                  </Typography>
                  <Typography sx={{ textAlign: "end" }}>
                    {profile?.customer_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography textTransform="uppercase">
                    Project Name:
                  </Typography>
                  <Typography sx={{ textAlign: "end" }}>
                    {profile?.project_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography textTransform="uppercase">
                    Expense Category Name:
                  </Typography>
                  <Typography sx={{ textAlign: "end" }}>
                    {profile?.expense_category_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography textTransform="uppercase">Name:</Typography>
                  <Typography sx={{ textAlign: "end" }}>
                    {profile?.name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography textTransform="uppercase">Reference:</Typography>
                  <Typography sx={{ textAlign: "end" }}>
                    {profile?.reference_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography textTransform="uppercase">
                    Payment Method:
                  </Typography>
                  <Typography sx={{ textAlign: "end" }}>
                    {profile?.payment_method_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography textTransform="uppercase">
                    Expense Date:
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        moment(profile?.expense_date).format("YYYY-MM-DD") <
                        moment().format("YYYY-MM-DD")
                          ? "red"
                          : "#222",
                      textAlign: "end",
                    }}
                  >
                    {moment(profile?.expense_date).format("ll")}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <TableContainer sx={{ mt: "20px" }}>
            <Table stickyHeader>
              <TableBody>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      sx={{ fontWeight: "500" }}
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                      className={
                        column.id === "discount" && "hide-on-print-text"
                      }
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
                {rows?.map((row) => {
                  return (
                    <TableRow hover role="checkbox" key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {column.id === "item" ? (
                              value
                            ) : column.id === "tax" ? (
                              <Typography color="#2fb42f" variant="body2">
                                {"+ " + symbol + value}
                              </Typography>
                            ) : column.id === "discount" ? (
                              <Typography
                                color="#ff3535"
                                variant="body2"
                                className={
                                  column.id === "discount" &&
                                  "hide-on-print-text"
                                }
                              >
                                {"- " + symbol + value}
                              </Typography>
                            ) : column.id === "total" ? (
                              <Typography color="#7171ff" variant="body2">
                                {symbol + value}
                              </Typography>
                            ) : column.id === "subtotal" ? (
                              <Typography variant="body2">
                                {symbol + value}
                              </Typography>
                            ) : (
                              value
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
            <Box
              sx={{
                display: "grid",
                justifyContent: "end",
              }}
            >
              <Box
                sx={{
                  margin: "20px 0 0 20px",
                  padding: "20px 30px",
                  bgcolor: "#f7f7f7",
                  width: "500px",
                }}
              >
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "100px auto",
                  }}
                >
                  <Typography align="right">Sub Total</Typography>
                  <Typography align="right">
                    {symbol + profile.item_total_subtotal}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "100px auto",
                  }}
                >
                  <Typography align="right">Tax</Typography>
                  <Typography align="right">
                    + {symbol + profile.item_total_tax}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "100px auto",
                  }}
                >
                  <Typography align="right">Discount</Typography>
                  <Typography align="right">
                    - {symbol + profile.item_total_discount}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    borderTop: "1px solid #cbcbcb",
                    display: "grid",
                    gridTemplateColumns: "100px auto",
                    pt: "5px",
                    mt: "5px",
                  }}
                >
                  <Typography align="right" fontSize="16px">
                    Total
                  </Typography>
                  <Typography align="right" fontSize="16px">
                    {symbol + profile.total}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
         
        </>
      )}
    </>
  );
};

export default Profile;
