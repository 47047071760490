import { Box, Button } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import { viewAllPayment } from "features/invoices/invoices/api/invoices";
import useApiCall from "hooks/useApiCall";
import { useDebounce } from "hooks/useDebounce";
import { useEffect, useState } from "react";
import { BiPlus } from "react-icons/bi";
import AddPayment from "./AddPayment";
import PaymentTable from "./PaymentTable";

const Payment = ({ id, currency_id }) => {
  const [addPayment, setAddPayment] = useState(false);
  const [currPayment, setCurrPayment] = useState({});
  const [allPayment, setAllPayment] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  const debouncedText = useDebounce(searchText, 1000);

  const { data, loading, fetchData: fetchListData } = useApiCall();
  useEffect(() => {
    let finalPagination = { ...pagination };
    fetchListData(
      viewAllPayment,
      {
        body_data: { invoice_id: id },
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}&search=${debouncedText}`,
      },
      false
    );
  }, [reload, pagination?.current_page, pagination?.per_page, debouncedText]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllPayment(allData);
      setPagination(rest);
    }
  }, [data]);

  return addPayment ? (
    <AddPayment
      setAddPayment={setAddPayment}
      id={id}
      currency_id={currency_id}
      setReload={setReload}
      currPayment={currPayment}
    />
  ) : (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <SearchBox
          handleSearch={(text) => setSearchText(text)}
          placeholder={"Search in table.."}
        />
        <Button
          startIcon={<BiPlus />}
          variant="outlined"
          onClick={() => {
            setCurrPayment({});
            setAddPayment(true);
          }}
        >
          New Payment
        </Button>
      </Box>
      <PaymentTable
        loading={loading}
        allPayment={allPayment}
        pagination={pagination}
        setPagination={setPagination}
        setReload={setReload}
        setAddPayment={setAddPayment}
        setCurrPayment={setCurrPayment}
        setAllPayment={setAllPayment}
      />
    </Box>
  );
};

export default Payment;
