const getInitialvalues = (filters) => {
  return {
    user_id: filters.user_id || [],
    customer_id: filters.customer_id || [],
    project_id: filters.project_id || [],
    tag_id: filters.tag_id || [],
    intake_id: filters.intake_id || [],
    created_at_from: filters.created_at_from || null,
    created_at_to: filters.created_at_to || null,
    due_date_from: filters.due_date_from || null,
    due_date_to: filters.due_date_to || null,
  };
};

export default getInitialvalues;
