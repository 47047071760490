import AdminLogin from "components/login/AdminLogin";
import CustomerLogin from "components/login/CustomerLogin";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const isClientRoute = location.pathname.split("/")[1] === "client";
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const companyName = useSelector((state) => state?.base?.site_info?.name);
  const { pathname } = useLocation();
  const paths = pathname?.split("/");
  const path = paths[paths.length - 1];

  const formatTitle = (title) => {
    const makeCapital = (text) => {
      return text.charAt(0).toUpperCase() + text.slice(1);
    };
    let modifiedTitle = "";
    title
      ?.split("-")
      .map((st) =>
        modifiedTitle
          ? (modifiedTitle += " " + makeCapital(st))
          : (modifiedTitle += makeCapital(st))
      );
    return modifiedTitle;
  };

  const formatCompanyName = (name) => {
    const nameArray = name?.split(" ");
    if (nameArray?.length) {
      return nameArray?.length > 2
        ? nameArray.map((str) => str.charAt(0).toUpperCase()).join("")
        : name;
    } else {
      return "CRM";
    }
  };

  useEffect(() => {
    document.title = `${formatCompanyName(companyName)} ${
      path && " | "
    } ${formatTitle(path)}`;
  }, [path, companyName]);

  if (auth?.user_details?.token) {
    if (auth?.user_details?.role !== "Customer") {
      if (isClientRoute) navigate("../");
      else return children;
    } else {
      if (isClientRoute) return children;
      else navigate("client");
    }
  } else {
    if (isClientRoute) return <CustomerLogin />;
    else return <AdminLogin />;
  }
};

export default PrivateRoute;
