import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import React from "react";
import { TableVirtuoso } from "react-virtuoso";
import MainTableRow from "./MainTableRow";

const ExpandableRow = ({ context, item: row, ...restProps }) => {
  return <MainTableRow {...restProps} {...context} key={row.id} row={row} />;
};

const TableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => <Table {...props} style={{ borderCollapse: "separate" }} />,
  TableHead: TableHead,
  TableRow: ExpandableRow,
  TableBody: React.forwardRef((props, ref) => (
    <TableBody {...props} ref={ref} />
  )),
};

const MainTable = ({
  handleDelete,
  pagination,
  setPagination,
  loading,
  setReload,
  allTask,
  setAllTask,
  handleEdit,
  handleView,
  permissions,
}) => {
  const rows = allTask;
  const columns = [
    { id: "name", label: "Task Name", minWidth: 150 },
    { id: "related_to", label: "Related", style: { width: "120px" } },
    { id: "created_by_name", label: "Created By", style: { width: "140px" } },
    {
      id: "assignees",
      label: "Assigness",
      style: { width: "120px" },
      align: "center",
    },
    {
      id: "followers",
      label: "Followers",
      style: { width: "120px" },
      align: "center",
    },
    {
      id: "date_added",
      label: "Start Date",
      style: { width: "120px" },
      minWidth: 100,
    },
    { id: "due_date", label: "Due Date", style: { width: "120px" } },
    {
      id: "priority",
      label: "Priority",
      style: { width: "120px" },
      align: "center",
    },
    {
      id: "status",
      label: "Status",
      style: { width: "150px" },
      align: "center",
    },
    {
      id: "action",
      label: "Actions",
      style: { width: "170px" },
      align: "center",
    },
  ];

  return (
    <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
      {!loading ? (
        <TableContainer sx={{ overflowX: "auto" }}>
          <TableVirtuoso
            style={{ height: "calc(100vh - 257px)" }}
            data={rows}
            components={TableComponents}
            context={{
              columns,
              handleView,
              handleDelete,
              handleEdit,
              permissions,
              allTask,
              setAllTask,
              setReload,
            }}
            fixedHeaderContent={() => (
              <TableRow
                sx={{
                  "& .MuiTableCell-sizeMedium": {
                    padding: "10px 0px 10px 20px",
                  },
                }}
              >
                {columns.map((column) => (
                  <TableCell
                    sx={{ bgcolor: "#fff" }}
                    key={column.id}
                    align={column.align}
                    style={column.style}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            )}
          />
        </TableContainer>
      ) : (
        <TableSkeleton columns={columns} tableHeight="calc(100vh - 257px)" />
      )}
      <TablePagination
        span={columns?.length}
        setReload={setReload}
        pagination={pagination}
        setPagination={setPagination}
      />
    </Paper>
  );
};

export default MainTable;
