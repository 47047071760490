const getInitialvalues = (filters) => {
  return {
    invoice_id: filters.project_id || "",
    created_at_from: filters.created_at_from || null,
    created_at_to: filters.created_at_to || null,
    payment_date_from: filters.payment_date_from || null,
    payment_date_to: filters.payment_date_to || null,
    bank_id: filters.bank_id || "",
    amount_from: filters.amount_from || "",
    amount_to: filters.amount_to || "",
  };
};

export default getInitialvalues;
