import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import assets from "assets";
import UNIVERSAL from "config";
import { useDate } from "hooks/useDate";
import usePermissions from "hooks/usePermissions";
import useWindowSize from "hooks/useWindowSize";
import { useState } from "react";
import { FiUser } from "react-icons/fi";
import { useSelector } from "react-redux";
import Menu from "./Menu";
import "./appDrawer.css";
import mainMenu from "./mainMenu";
import setupMenu from "./setupMenu";
import useBase from "hooks/useBase";

const AppDrawer = ({ toggleNavbar, isDashboardPath }) => {
  const {
    base: { featureLists },
  } = useBase();
  const [activeMenuType, setActiveMenuType] = useState("main");
  const { wish } = useDate();
  const { isMini } = useWindowSize();
  const user_details = useSelector((state) => state.auth.user_details);
  const role = useSelector((state) => state.auth.user_details.role);
  const user = user_details?.user;
  const siteInfo = useSelector((state) => state?.base?.site_info);

  let permissions = usePermissions("dashboard");
  let mainMenuCheck =
    role === "Admin" || permissions?.dashboard
      ? mainMenu(featureLists)
      : mainMenu(featureLists).filter((item) => item.label !== "Dashboard");

  return (
    <Box
      sx={{
        position: "fixed",
        top: isMini ? "65px" : 0,
        left: 0,
        transform: toggleNavbar ? "translateX(-100%)" : "translateX(0)",
        transition: isDashboardPath ? "unset" : "transform 0.5s ease ",
        borderRight: isMini ? "none" : "1px solid #EDEFF2",
        boxShadow: isMini ? "3px 12px 15px 1px #7a7a7a4a" : "none",
        color: (theme) => theme.palette.white.main,
        width: "250px",
        height: isMini ? "calc(100vh - 65px)" : "100vh",
        overflow: "hidden",
        backgroundColor: "#fff",
        zIndex: isMini && 100000,
      }}
    >
      {!isMini && (
        <Box
          sx={{
            height: "65px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            p: "5px",
          }}
        >
          <img
            src={
              siteInfo?.site_logo
                ? UNIVERSAL.BASEURL + siteInfo?.site_logo
                : assets.logo
            }
            style={{ maxHeight: "100%", maxWidth: "100%" }}
            alt="logo"
          />
        </Box>
      )}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "60px 1fr",
          borderBottom: "1px solid #efefef",
          borderTop: isMini ? "1px solid #EDEFF2" : "none",
          padding: "9px 15px",
        }}
      >
        <Box>
          <Box
            sx={{
              color: "#666",
              height: "50px",
              width: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#efefef",
              borderRadius: "50%",
              fontSize: "24px",
            }}
          >
            <FiUser />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography sx={{ color: "#444", fontSize: "14px" }}>
            {wish}
          </Typography>
          <Typography sx={{ color: "#444" }}>{user?.name}</Typography>
        </Box>
      </Box>
      <Box className="sidebar_menu">
        <Menu
          menuList={activeMenuType === "main" ? mainMenuCheck : setupMenu}
          activeMenuType={activeMenuType}
          setActiveMenuType={setActiveMenuType}
        />
      </Box>
    </Box>
  );
};

export default AppDrawer;
