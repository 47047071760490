import { Clear } from "@mui/icons-material";
import { Box, Button, TextField, Typography } from "@mui/material";
import Select from "components/ui/select";
import { BiPlus } from "react-icons/bi";

const InvoicePriceTable = ({ formik, fees }) => {
  const handleTotalPriceUpdate = (index, field, value) => {
    if (field === "subtotal") {
      const oldSubtotal = Number(formik.values.invoice_details[index].subtotal);
      // set total tax
      formik.setFieldValue(
        "subtotal",
        Number(formik.values.subtotal) + Number(value) - oldSubtotal
      );
      formik.setFieldValue(
        "total",
        Number(formik.values.total) + Number(value) - oldSubtotal
      );
      formik.setFieldValue(
        `invoice_details[${index}].total`,
        Number(formik.values.invoice_details[index].total) +
          Number(value) -
          oldSubtotal
      );
    } else if (field === "tax") {
      const oldTax = Number(formik.values.invoice_details[index].tax);
      // set total tax
      formik.setFieldValue(
        "tax",
        Number(formik.values.tax) + Number(value) - oldTax
      );
      formik.setFieldValue(
        "total",
        Number(formik.values.total) + Number(value) - oldTax
      );
      formik.setFieldValue(
        `invoice_details[${index}].total`,
        Number(formik.values.invoice_details[index].total) +
          Number(value) -
          oldTax
      );
    } else if (field === "discount") {
      const oldDiscount = Number(formik.values.invoice_details[index].discount);

      // set total discount
      formik.setFieldValue(
        "discount",
        Number(formik.values.discount) + Number(value) - oldDiscount
      );
      formik.setFieldValue(
        "total",
        Number(formik.values.total) - (Number(value) - oldDiscount)
      );
      formik.setFieldValue(
        `invoice_details[${index}].total`,
        Number(formik.values.invoice_details[index].total) -
          (Number(value) - oldDiscount)
      );
    } else if (field === "delete_item") {
      const oldSubtotal = Number(formik.values.invoice_details[index].subtotal);
      const oldTax = Number(formik.values.invoice_details[index].tax);
      const oldDiscount = Number(formik.values.invoice_details[index].discount);
      const oldTotal = Number(formik.values.invoice_details[index].total);

      // Remove tax and dicount
      formik.setFieldValue(
        "subtotal",
        Number(formik.values.subtotal) - oldSubtotal
      );
      formik.setFieldValue("tax", Number(formik.values.tax) - oldTax);
      formik.setFieldValue(
        "discount",
        Number(formik.values.discount) - oldDiscount
      );
      formik.setFieldValue("total", Number(formik.values.total) - oldTotal);

      formik.setFieldValue(
        "invoice_details",
        [...formik.values.invoice_details].filter((invoice, i) => i !== index)
      );
    }
  };

  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(5, 1fr) 30px",
            gap: "10px",
            bgcolor: (theme) => theme.palette.primary.main,
            padding: "10px 20px",
            color: "#fff",
          }}
        >
          <Typography>Item</Typography>
          <Typography align="center">Amount</Typography>
          <Typography align="center">Tax</Typography>
          <Typography align="center">Discount</Typography>
          <Typography align="center">Item Total</Typography>
          <Box></Box>
        </Box>
        {formik?.values?.invoice_details?.map((singleItem, i) => (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(5, 1fr) 30px",
              gap: "10px",
              padding: "5px 20px",
              borderBottom: "1px solid #ddd",
            }}
          >
            <Select
              options={fees}
              value={formik?.values?.invoice_details[i]?.fee_id}
              onChange={(value) =>
                formik.setFieldValue(`invoice_details[${i}].fee_id`, value)
              }
              selectProps={{ id: "id", label: "name" }}
            />
            <TextField
              size="small"
              className="numberInput"
              type="number"
              value={formik.values.invoice_details[i].subtotal}
              onChange={(e) => {
                formik.setFieldValue(
                  `invoice_details[${i}].subtotal`,
                  Number(e.target.value)
                );
                handleTotalPriceUpdate(i, "subtotal", e.target.value);
              }}
              InputProps={{
                inputProps: {
                  style: { textAlign: "right" },
                },
              }}
            />
            <TextField
              size="small"
              type="number"
              className="numberInput"
              value={formik.values.invoice_details[i].tax}
              onChange={(e) => {
                formik.setFieldValue(
                  `invoice_details[${i}].tax`,
                  Number(e.target.value)
                );
                handleTotalPriceUpdate(i, "tax", e.target.value);
              }}
              InputProps={{
                inputProps: {
                  style: { textAlign: "right" },
                },
              }}
            />
            <TextField
              className="numberInput"
              type="number"
              InputProps={{
                inputProps: {
                  style: { textAlign: "right" },
                },
              }}
              size="small"
              value={formik.values.invoice_details[i].discount}
              onChange={(e) => {
                formik.setFieldValue(
                  `invoice_details[${i}].discount`,
                  Number(e.target.value)
                );
                handleTotalPriceUpdate(i, "discount", e.target.value);
              }}
            />
            <TextField
              InputProps={{
                readOnly: true,
                inputProps: {
                  style: { textAlign: "right" },
                },
              }}
              size="small"
              value={formik.values.invoice_details[i].total}
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleTotalPriceUpdate(i, "delete_item")}
            >
              <Clear />
            </Box>
          </Box>
        ))}
        <Box
          sx={{
            padding: "10px 20px",
          }}
        >
          <Button
            startIcon={<BiPlus />}
            variant="outlined"
            onClick={() =>
              formik.setFieldValue("invoice_details", [
                ...formik.values.invoice_details,
                {
                  fee_id: "",
                  tax: 0,
                  discount: 0,
                  subtotal: 0,
                  total: 0,
                },
              ])
            }
          >
            Add
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoicePriceTable;
