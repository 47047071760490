const getInitialvalues = (filters) => {
  return {
    user_id: filters.user_id || [],
    status: filters.status || "",
    priority: filters.priority || "",
    related_to: filters.related_to || "",
  };
};

export default getInitialvalues;
