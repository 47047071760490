import { Box, Button, Typography } from "@mui/material";
import RightDrawer from "components/rightDrawer/RightDrawer";
import DateTimePicker from "components/ui/dateTimePicker";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import {
  createExpense,
  projectsList,
  updateExpense,
} from "features/expense/api/expense";
import { useFormik } from "formik";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import handleError from "utils/handleError";
import validationSchema from "../../schema";
import useQueries from "hooks/useQueries";
import ReactLoading from "react-loading";
import ExpencePriceTable from "./ExpencePriceTable";
import useBase from "hooks/useBase";

const AddEdit = ({
  persistedData,
  setPersistedData,
  openDrawer,
  setOpenDrawer,
  setAllExpense,
  allExpense,
  customers,
  currencies,
  expenseCategories,
  paymentMethods,
  references,
}) => {
  const { row_id } = useQueries();
  const isUpdating = row_id ? true : false;
  const [currExpense, setCurrExpense] = useState(row_id ? {} : persistedData);
  const [projects, setProjects] = useState([]);
  const { loading, fetchData } = useApi();
  const currency_id = useSelector(
    (state) => state?.base?.base_data?.settings?.app?.currency_id
  );
  const { base } = useBase();
  const { expenseItems } = base;

  const currency_symbol = currency_id
    ? currencies.find((c) => c.id === currency_id).symbol
    : "";
  const handleClose = (reset) => {
    setPersistedData(row_id ? {} : reset ? {} : formik?.values);
    setOpenDrawer();
  };

  useEffect(() => {
    const getFullExpense = async () => {
      const endpoint = {
        method: "get",
        url: `/api/admin/expense/show/${row_id}`,
      };
      const result = await fetchData(endpoint, false);
      result.success && setCurrExpense(result?.data);
    };
    row_id && getFullExpense();
  }, []);

  const onSubmit = async (data) => {
    data.expense_details.forEach((detail) => {
      detail.amount = detail.subtotal;
    });

    const result = await fetchData(
      isUpdating ? updateExpense(row_id, data) : createExpense(data)
    );
    if (result?.success) {
      if (isUpdating) {
        setAllExpense(
          allExpense.map((expense) =>
            expense.id === currExpense.id
              ? { ...currExpense, ...result.data }
              : expense
          )
        );
      } else {
        setAllExpense([result.data, ...allExpense]);
      }
      handleClose("reset");
    } else {
      handleError(result, formik);
    }
  };
  const copyMatchingData = (schemaObj, sourceObj) => {
    const obj = {};
    for (const key in schemaObj) {
      if (sourceObj.hasOwnProperty(key)) {
        obj[key] = sourceObj[key];
      }
    }
    return obj;
  };
  // initial values depending on edit mode or create mode
  const initialValues = {
    currency_id: currExpense?.currency_id || currency_id || "",
    expense_category_id: currExpense?.expense_category_id || "",
    payment_method_id: currExpense?.payment_method_id || "",
    customer_id: currExpense?.customer_id || "",
    project_id: currExpense?.project_id || "",
    name: currExpense?.name || "",
    reference_id: currExpense?.reference_id || "",
    expense_date: currExpense?.expense_date || moment().format("YYYY-MM-DD"),
    tax: currExpense?.tax || 0,
    discount: currExpense?.discount || 0,
    total: currExpense?.total || 0,
    subtotal: currExpense?.subtotal || 0,
    note: currExpense?.note || "",
    expense_details:
      currExpense?.expense_details?.map((expense) =>
        copyMatchingData(
          {
            expense_item_id: "",
            tax: 0,
            discount: 0,
            subtotal: 0,
            total: 0,
          },
          expense
        )
      ) || [],
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const getProjects = async () => {
      const result = await projectsList({
        id: formik.values.customer_id || "",
      });
      if (result.success) setProjects(result.data);
    };
    getProjects();
  }, [formik.values.customer_id]);
  return (
    <RightDrawer
      {...{
        openDrawer,
        setOpenDrawer: handleClose,
        width: "850px",
        headingLeft: row_id ? "Edit Expense" : "New Expense",
        headingRight: "",
      }}
    >
      {loading ? (
        <Box
          sx={{
            height: "calc(100vh - 143px)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ReactLoading
            type="cylon"
            color="#405189"
            height={"85px"}
            width={"100px"}
          />
        </Box>
      ) : (
        <Box p="20px">
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "10px",
              pb: "15px",
            }}
          >
            <Select
              options={customers}
              label="Customer"
              name="customer_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <Select
              disabled={!formik.values.customer_id}
              options={projects}
              label="Project"
              name="project_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <InputField
              name="name"
              label="Expense Name *"
              multiline
              formik={formik}
            />
            <Select
              options={references}
              label="Reference"
              name="reference_id"
              formik={formik}
              selectProps={{ id: "id", label: "name" }}
            />
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              gap: "10px",
              pb: "15px",
            }}
          >
            <Select
              options={expenseCategories}
              label="Expense Category *"
              name="expense_category_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <DateTimePicker
              name="expense_date"
              label="Expense Date *"
              formik={formik}
            />
            <Select
              options={paymentMethods}
              label="Payment Method *"
              name="payment_method_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "10px",
              }}
            >
              <InputField
                disabled
                name="tax"
                label="Tax"
                type="number"
                formik={formik}
              />
              <InputField
                disabled
                name="discount"
                label="Discount"
                type="number"
                formik={formik}
              />
            </Box>
            <InputField
              disabled
              name="total"
              label="Total"
              type="number"
              formik={formik}
            />
            <Select
              options={currencies}
              label="Currency *"
              name="currency_id"
              selectProps={{ id: "id", label: "name" }}
              formik={formik}
            />
            <InputField
              sx={{ gridColumn: "span 3" }}
              name="note"
              label="Note"
              multiline
              rows={3}
              formik={formik}
            />
            <Box
              sx={{
                gridColumn: "1/-1",
              }}
            >
              <Box
                sx={{
                  borderTop: "1px dashed #ddd",
                  borderBottom: "1px dashed #ddd",
                  mt: "15px",
                  py: "15px",
                  mx: "-20px",
                }}
              >
                <ExpencePriceTable
                  formik={formik}
                  expenseItems={expenseItems}
                />
              </Box>
              <Box>
                <Box
                  sx={{
                    width: "300px",
                    marginLeft: "auto",
                    mt: "5px",
                  }}
                >
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "100px auto",
                    }}
                  >
                    <Typography align="right">Sub Total</Typography>
                    <Typography align="right" sx={{ fontWeight: 700 }}>
                      {currency_symbol}
                      {formik.values.subtotal}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "100px auto",
                    }}
                  >
                    <Typography align="right">Tax</Typography>
                    <Typography align="right" sx={{ fontWeight: 700 }}>
                      {currency_symbol}
                      {formik.values.tax}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "100px auto",
                    }}
                  >
                    <Typography align="right">Discount</Typography>
                    <Typography align="right" sx={{ fontWeight: 700 }}>
                      -{currency_symbol}
                      {formik.values.discount}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      borderTop: "1px solid #cbcbcb",
                      display: "grid",
                      gridTemplateColumns: "100px auto",
                      pt: "5px",
                      mt: "5px",
                    }}
                  >
                    <Typography align="right" fontSize="16px">
                      Total
                    </Typography>
                    <Typography
                      align="right"
                      fontSize="16px"
                      sx={{ fontWeight: 700 }}
                    >
                      {currency_symbol}
                      {formik.values.total}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Button
              variant="solid"
              onClick={formik.submitForm}
              fullWidth
              disabled={!(formik.dirty && formik.isValid) || loading}
              sx={{ gridColumn: "span 3" }}
            >
              {row_id ? "Update Expense" : "Save Expense"}
            </Button>
          </Box>
        </Box>
      )}
    </RightDrawer>
  );
};

export default AddEdit;
