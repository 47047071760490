import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import NotAuthorized from "components/notAuthorized";
import useApiCall from "hooks/useApiCall";
import useNotificationQuery from "hooks/useNotificationQuery";
import usePermissions from "hooks/usePermissions";
import { setLoading } from "lib/Reducer/loadingSlice";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteInvoice,
  restoreInvoice,
  viewAllExpense,
} from "../api/expensetrash";
import BulkActions from "./BulkActions";
import Header from "./Header";
import MainScreenFilter from "./filter/MainScreenFilter";
import Export from "./importExport/Export";
import MainTable from "./table/MainTable";

const ExpenseTrash = () => {
  const [activeMenu, setActiveMenu] = useState("");
  const [openSidebar, setOpenSidebar] = useState(false);
  const [openExportInvoice, setOpenExportInvoice] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  // Helper states
  const { base_data } = useSelector((state) => state.base);
  const [reload, setReload] = useState(false);
  const [filters, setFilters] = useState({});
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });

  const isFiltering = () => {
    let filtering = false;
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        if (filters[key].length > 0) filtering = true;
      } else {
        if (filters[key]) filtering = true;
      }
    }
    return filtering;
  };

  const { data, loading, fetchData: fetchListData } = useApiCall();
  let getParamsId = useNotificationQuery(reload);

  useEffect(() => {
    let finalFilters = { ...filters };
    let finalPagination = { ...pagination };
    finalFilters.search = search;
    finalFilters.id = getParamsId;
    fetchListData(
      viewAllExpense,
      {
        body_data: finalFilters,
        params: `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`,
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    reload,
    search,
    pagination?.current_page,
    pagination?.per_page,
    getParamsId,
  ]);

  useEffect(() => {
    if (data) {
      const { data: allData, ...rest } = data;
      setAllInvoices(allData);
      setPagination(rest);
      setSelectedRows([]);
    }
  }, [data]);

  const [openAlert, setOpenAlert] = useState(false);

  const handleDelete = async (id) => {
    setOpenAlert(id);
  };

  const handleDeleteConfirm = async () => {
    setOpenAlert(false);
    setSelectedRows([]);
    try {
      const result = await deleteInvoice({ id: openAlert });
      if (result.success) {
        if (allInvoices?.length > 1) {
          setAllInvoices(
            allInvoices.filter((customer) => customer?.id !== openAlert)
          );
          toast.success(result?.message);
        } else {
          // setReload((pre) => !pre);
          toast.error(result?.message);
        }
      } else {
        toast.error(result?.message);
      }
    } catch (error) {}
    setReload((pre) => !pre);
  };

  const permissions = usePermissions("expense");

  const all_props = {
    ...base_data,
    setReload,
    filters,
    setFilters,
    isFiltering,
    pagination,
    setPagination,
    allInvoices,
    setAllInvoices,
    permissions,
  };

  // Bulk options
  const [selectedRows, setSelectedRows] = useState([]);

  const handleTableCheckBox = (id, checked) => {
    if (id === "all") {
      if (checked) {
        setSelectedRows(allInvoices?.map((customer) => customer?.id));
      } else {
        setSelectedRows([]);
      }
    } else {
      if (checked) {
        setSelectedRows([...selectedRows, id]);
      } else {
        setSelectedRows(selectedRows?.filter((customer) => customer !== id));
      }
    }
  };
  const dispatch = useDispatch();

  const handleRestore = async (id) => {
    dispatch(setLoading(true));
    try {
      const result = await restoreInvoice({ id });
      if (result.success) {
        toast.success(result?.message);
      } else {
        toast.error(result?.message);
      }
      setReload((pre) => !pre);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setLoading(false));
    }
  };

  return (
    <div>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Expense"
          desc={
            <Box>
              Are you sure you want to delete? This will delete this expense
              permanently. <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}
      <Header
        {...{
          activeMenu,
          setActiveMenu,
          openSidebar,
          setOpenSidebar,
          setOpenExportInvoice,
          setSearch,
          isFiltering,
          setPagination,
          pagination,
          permissions,
          allInvoices,
          setReload,
          search,
          filters,
        }}
      />
      {openExportInvoice && (
        <Export
          {...{
            setOpenDrawer: setOpenExportInvoice,
            openDrawer: openExportInvoice,
            filters,
            search,
          }}
        />
      )}
      {!activeMenu &&
        !selectedRows?.length > 0 &&
        !!permissions["trash list"] && <MainScreenFilter {...all_props} />}
      {selectedRows?.length > 0 && (
        <BulkActions
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          {...all_props}
        />
      )}
      {!activeMenu && permissions["trash list"] ? (
        <MainTable
          handleDelete={handleDelete}
          loading={loading}
          handleTableCheckBox={handleTableCheckBox}
          selectedRows={selectedRows}
          handleRestore={handleRestore}
          {...all_props}
        />
      ) : (
        <NotAuthorized />
      )}
    </div>
  );
};

export default ExpenseTrash;
