import Axios from "lib/Axios";

export const viewAllSales = ({ body_data, params }) =>
  Axios.post(`/api/admin/sales/list?${params}`, body_data).then(
    (res) => res.data
  );

export const viewAllExpectedSales = ({ id }) =>
  Axios.get(`/api/admin/sales/expected/list/${id}`).then((res) => res.data);
export const viewAllMakePayment = ({ id }) =>
  Axios.get(`/api/admin/sales/payment/list/${id}`).then((res) => res.data);

export const callAllExpectedPayment = ({ id }) =>
  Axios.get(`/api/admin/expected/payment/list/${id}`).then((res) => res.data);

export const updateSales = ({ body_data }) =>
  Axios.post("/api/admin/sales/change-status", body_data).then(
    (res) => res.data
  );

export const updateFollowupDate = ({ body_data }) =>
  Axios.post(`api/admin/sales/followupdate`, body_data).then((res) => res.data);

const endpoints = {
  createExpectedSales: (data) => ({
    method: "post",
    url: "/api/admin/sales/expected/store",
    data,
  }),
  updateExpectedSales: (data, id) => ({
    method: "put",
    url: `/api/admin/sales/expected/update/${id}`,
    data,
  }),

  createMakePayment: (data) => ({
    method: "post",
    url: "/api/admin/sales/payment/store",
    data,
  }),
  updateMakePayment: (data, id) => ({
    method: "put",
    url: `/api/admin/sales/payment/update/${id}`,
    data,
  }),
};

export const deleteMakePayment = (id) =>
  Axios.delete(`/api/admin/sales/payment/delete/${id}`).then((res) => res.data);

export const deleteExpectedSales = (id) =>
  Axios.delete(`/api/admin/sales/expected/delete/${id}`).then(
    (res) => res.data
  );

export const {
  createExpectedSales,
  updateExpectedSales,
  createMakePayment,
  updateMakePayment,
} = endpoints;
