import * as yup from "yup";

export const validationSchema = yup.object({
  id: yup.string(),
  name: yup.string().required("Name Required"),
  phone: yup.string().required("Phone Required"),
  email: yup
    .string()
    .email("Invalid email format!")
    .required("Email Address Required"),
  gender: yup.string(),
  password: yup.string().when("id", {
    is: (id) => id,
    then: yup.string().required("Password Required!"),
  }),
  status: yup.string().required(),
});
