import { Box, Button } from "@mui/material";
import DateRangeField from "components/ui/dateRangeField";
import MultiSelect from "components/ui/multiSelect";
import getInitialvalues from "features/leads/utils/filterInitialValues";
import { useFormik } from "formik";
import { useState } from "react";
import * as yup from "yup";

const MainScreenFilter = ({
  assignees,
  leadStatuses,
  sources,
  setReload,
  setFilters,
  setPagination,
  pagination,
  filters,
  isFiltering,
}) => {
  const [reset, setReset] = useState(false);
  const initialValues = getInitialvalues(filters);

  const validationSchema = yup.object({});
  const onSubmit = async (data) => {
    setFilters({ ...filters, ...data });
    setPagination({ ...pagination, current_page: 1 });
    setReload((pre) => !pre);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true,
  });

  const resetForm = () => {
    const emptyFilter = {};
    for (const key in filters) {
      if (Array.isArray(filters[key])) {
        emptyFilter[key] = [];
      } else {
        emptyFilter[key] = null;
      }
    }
    formik.setValues(emptyFilter);
    setFilters({});
    setReload((pre) => !pre);
    setReset((pre) => !pre);
  };

  return (
    <Box
      p="15px 20px 5px"
      sx={{
        borderTop: "1px solid #efefef",
        bgcolor: "#fff",
        display: "grid",
        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
        gap: "18px",
      }}
    >
      <MultiSelect
        options={leadStatuses}
        label="Statuses"
        value={formik?.values?.lead_status_id}
        onChange={(value) => formik.setFieldValue("lead_status_id", value)}
        selectProps={{
          id: "id",
          label: "name",
        }}
      />
      <MultiSelect
        options={sources}
        label="Sources"
        value={formik?.values?.source_id}
        onChange={(value) => formik.setFieldValue("source_id", value)}
        selectProps={{
          id: "id",
          label: "name",
        }}
      />
      <MultiSelect
        options={assignees}
        label="Assignees"
        value={formik?.values?.user_id}
        onChange={(value) => formik.setFieldValue("user_id", value)}
        selectProps={{
          id: "user_id",
          label: "name",
        }}
      />
      <DateRangeField
        label="Followup Date"
        from="followup_date_from"
        to="followup_date_to"
        formik={formik}
        key={`${reset}`}
      />
      <Box
        sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: "10px" }}
      >
        <Button
          variant="solid"
          onClick={formik.submitForm}
          disabled={!formik.dirty}
        >
          Filter
        </Button>
        <Button variant="outlined" disabled={!isFiltering} onClick={resetForm}>
          Reset Filter
        </Button>
      </Box>
    </Box>
  );
};

export default MainScreenFilter;
