import { Box, Button } from "@mui/material";
import InputField from "components/ui/inputField";
import Select from "components/ui/select";
import { useFormik } from "formik";
import { BiLeftArrowAlt } from "react-icons/bi";
import * as yup from "yup";

import useApi from "hooks/useApi";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { createPayment, updatePayment } from "../../../api/invoiceBank";

export function makeInMillion({ amount, loan_amount }) {
  return amount * Number(loan_amount);
}
export let months = [
  {
    id: 1,
    name: "1st",
  },
  {
    id: 2,
    name: "2nd",
  },
  {
    id: 3,
    name: "3rd",
  },
  {
    id: 4,
    name: "4th",
  },
  {
    id: 5,
    name: "5th",
  },
  {
    id: 6,
    name: "6th",
  },
  {
    id: 7,
    name: "7th",
  },
  {
    id: 8,
    name: "8th",
  },
  {
    id: 9,
    name: "9th",
  },
  {
    id: 10,
    name: "10th",
  },
  {
    id: 11,
    name: "11th",
  },
  {
    id: 12,
    name: "12th",
  },
];
const AddPayment = ({
  id,
  setAddPayment,
  currPayment,
  setReload,
  profile,
  setReloadMain,
}) => {
  const { loading, fetchData } = useApi();

  const initialValues = {
    interest_amount: "",
    month: null,
    charge: "",
    bank_invoice_id: profile?.id,
  };

  const getInitialValues = (data) => {
    if (data?.id) {
      let obj = {};
      for (const key in initialValues) {
        obj[key] = data[key];
      }
      return obj;
    } else return initialValues;
  };

  const onSubmit = async (data) => {
    let result = await fetchData(
      currPayment?.id
        ? updatePayment(data, currPayment?.id)
        : createPayment(data)
    );
    if (result?.success) {
      setReload((pre) => !pre);
      // setReloadMain((pre) => !pre);
      setAddPayment(false);
    } else {
      if (result?.errors?.month[0]) {
        toast.error(result?.errors?.month[0]);
      }
    }
  };
  const formik = useFormik({
    initialValues: getInitialValues(currPayment),
    validationSchema: yup.object({
      interest_amount: yup.number().required("Required Field"),
      month: yup.string("").required().nullable(),
    }),
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    formik.setFieldValue(
      "charge",
      makeInMillion({
        amount: formik.values.interest_amount,
        loan_amount: profile?.loan_amount,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.interest_amount]);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: "20px",
        }}
      >
        <Button
          startIcon={<BiLeftArrowAlt />}
          onClick={() => setAddPayment(false)}
        >
          View Invoices
        </Button>
      </Box>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "15px",
          pb: "15px",
        }}
      >
        <Select
          name="month"
          options={months}
          label="Select Months *"
          value={formik?.values?.month}
          formik={formik}
          selectProps={{ id: "id", label: "name" }}
        />

        <InputField
          name="interest_amount"
          label="Interest Rate *"
          type="number"
          formik={formik}
        />

        <InputField
          name="charge"
          label="Total Amount"
          type="number"
          formik={formik}
          disabled
        />

        <Button
          sx={{ gridColumn: "span 2" }}
          variant="solid"
          onClick={formik.submitForm}
          fullWidth
          disabled={
            (!(formik.dirty && formik.isValid) && !currPayment?.id) || loading
          }
        >
          {currPayment?.id ? "Update Invoice" : "Save Invoice"}
        </Button>
      </Box>
    </Box>
  );
};

export default AddPayment;
