import SetupCC from "components/setupCC";
import InputField from "components/ui/inputField";
import * as yup from "yup";

const Countries = () => {
  const initialValues = {
    name: "",
    short_name: "",
    code: "",
  };
  return (
    <SetupCC
      name="Country"
      baseRouteKey="countries"
      moduleKey="country"
      columns={[
        { id: "name", label: "Name", minWidth: 100 },
        { id: "short_name", label: "Short Name", minWidth: 100 },
        { id: "code", label: "Code", minWidth: 100 },
        {
          id: "action",
          label: "Actions",
          style: { width: "150px" },
          align: "center",
        },
      ]}
      actions={["edit", "delete"]}
      getInitialValues={(data) => {
        if (data?.id) {
          let obj = {};
          for (const key in initialValues) {
            obj[key] = data[key];
          }
          return obj;
        } else return initialValues;
      }}
      validationSchema={yup.object({
        name: yup.string().required("Name is required!"),
      })}
      allFields={(formik) => (
        <>
          <InputField name="name" label="Name" formik={formik} />
          <InputField name="short_name" label="Short Name" formik={formik} />
          <InputField name="code" label="Code" formik={formik} />
        </>
      )}
    ></SetupCC>
  );
};

export default Countries;
