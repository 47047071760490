import ArrowBack from "@mui/icons-material/ArrowBack";
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import useApi from "hooks/useApi";
import { setTicketCount } from "lib/Reducer/features/ticket/ticketSlice";
import React, { useEffect, useState } from "react";
import { HiChevronRight } from "react-icons/hi";
import { LuSettings2 } from "react-icons/lu";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    padding: " 4px",
  },
}));
const Menu = ({ menuList, activeMenuType, setActiveMenuType }) => {
  const ticketCount = useSelector((state) => state?.ticket?.count);
  let dispatch = useDispatch();
  let { fetchData } = useApi();
  const { pathname } = useLocation();
  const extract_path = (path, position) => {
    const first_path = path.split("/")[position];
    return first_path;
  };
  const [activeMenu, setActiveMenu] = useState(
    `/${extract_path(pathname, 1)}${
      extract_path(pathname, 1) === "setup"
        ? `/${extract_path(pathname, 2)}`
        : ""
    }`
  );
  const handleDropdown = (type) => {
    if (activeMenu === type) {
      setActiveMenu("");
    } else {
      setActiveMenu(type);
    }
  };
  useEffect(() => {
    if (extract_path(pathname, 1) === "setup") {
      setActiveMenuType("setupMenu");
    }
  }, [extract_path(pathname, 1)]);
  useEffect(() => {
    async function getTicketCount() {
      const endpoint = {
        method: "get",
        url: "/api/admin/ticket/unresolved/count",
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        dispatch(setTicketCount(result?.data?.total_unresolved));
      }
    }
    getTicketCount();
  }, []);

  return (
    <>
      {activeMenuType !== "main" && (
        <Link to={""} onClick={() => setActiveMenuType("main")}>
          <ArrowBack />
          <span>Return to main menu</span>
        </Link>
      )}
      {menuList.map((singleMenu, i) => (
        <React.Fragment key={i}>
          {singleMenu.child ? (
            <>
              <div
                className={`drop-down-menu ${activeMenu === singleMenu.path}`}
                onClick={() => handleDropdown(singleMenu.path)}
              >
                <div>
                  {singleMenu.icon}
                  <span>{singleMenu.label}</span>
                </div>
                <span>
                  <HiChevronRight />
                </span>
              </div>
              <div className={`dd-items ${activeMenu === singleMenu.path}`}>
                {singleMenu?.child?.map((smc) => (
                  <NavLink to={smc.path} key={smc.path}>
                    {smc.label}
                  </NavLink>
                ))}
              </div>
            </>
          ) : (
            <NavLink
              to={singleMenu.path}
              onClick={() => handleDropdown(extract_path(singleMenu.path, 1))}
            >
              {singleMenu.icon}

              {singleMenu.label === "Tickets" ? (
                <StyledBadge badgeContent={ticketCount} color="error">
                  <span>{singleMenu.label}</span>
                </StyledBadge>
              ) : (
                <span>{singleMenu.label}</span>
              )}
            </NavLink>
          )}
        </React.Fragment>
      ))}
      {activeMenuType === "main" && (
        <Link to={"setup"} onClick={() => setActiveMenuType("setupMenu")}>
          <LuSettings2 />
          <span>Setup</span>
        </Link>
      )}
      <CopyrightNotice />
    </>
  );
};
const CopyrightNotice = () => {
  const currentYear = new Date().getFullYear();
  const companyName = "Soft Valley";

  return (
    <footer
      style={{
        position: "sticky",
        top: "99%",
      }}
    >
      <p
        style={{
          marginTop: "auto",
          color: "#a5a5a5",
          fontSize: "12px",
          textAlign: "center",
          whiteSpace: "nowrap",
        }}
      >
        © {currentYear}{" "}
        {!!companyName && (
          <a
            href="https://softvalley.net/"
            style={{
              color: "#006cec",
              padding: 0,
              margin: 0,
            }}
            rel="noreferrer"
            target="_blank"
          >
            {companyName}
          </a>
        )}
        . All rights reserved.
      </p>
    </footer>
  );
};
export default Menu;
