import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Box, IconButton, Typography } from "@mui/material";
import { Draggable, Droppable } from "react-beautiful-dnd";
import BoardItem from "./BoardItem";
import { FixedSizeList as List, areEqual } from "react-window";
import { memo } from "react";
let mas = 8;
const Row = memo(({ data, index, style, dd }) => {
  const {
    items,
    user_id,
    section,
    onUpdateTask,
    onDeleteTask,
    onViewTask,
    onTaskComment,
    permissions,
  } = data;

  const item = items[index];
  if (!item) {
    return null;
  }

  // Faking some nice spacing around the items
  const patchedStyle = {
    ...style,
    left: style.left + mas,
    top: style.top + mas,
    width: `calc(${style.width} - ${mas * 2}px)`,
    height: style.height - mas,
  };
  return (
    <Draggable draggableId={item.id.toString()} index={index} key={item.id}>
      {(provided, snapshot) => (
        <BoardItem
          provided={provided}
          isDragging={snapshot.isDragging}
          item={item}
          style={patchedStyle}
          {...{
            snapshot,
            user_id,
            section,
            onUpdateTask,
            onDeleteTask,
            onViewTask,
            onTaskComment,
            permissions,
          }}
        />
      )}
    </Draggable>
  );
}, areEqual);

const BoardSection = ({
  user_id,
  section,
  createTask,
  deleteSection,
  onUpdateTask,
  onDeleteTask,
  onViewTask,
  onTaskComment,
  permissions,
}) => {
  return (
    <Droppable
      droppableId={section?.id.toString()}
      mode="virtual"
      renderClone={(provided, snapshot, rubric) => (
        <BoardItem
          provided={provided}
          isDragging={snapshot.isDragging}
          item={section.tasks[rubric.source.index]}
          style={{ margin: 0 }}
          {...{
            snapshot,
            user_id,
            section,
            onUpdateTask,
            onDeleteTask,
            onViewTask,
            onTaskComment,
            permissions,
          }}
        />
      )}
    >
      {(droppableProvided, snapshot) => {
        const itemCount = snapshot.isUsingPlaceholder
          ? section.tasks.length + 1
          : section.tasks.length;

        return (
          <div
            style={{
              background: snapshot.isDraggingOver
                ? "#e9e9e9"
                : "rgb(238 238 238)",
              transition: "background 0.5s ease",
              boxShadow: "0px 1px 2px 0px #dddddd",
              paddingBottom: "10px",
              borderRadius: "10px",
            }}
          >
            <Box
              py={1.5}
              px={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                zIndex: 1,
                background: snapshot.isDraggingOver ? "#e9e9e9" : "#F4F5F7",
                transition: "background 0.5s ease",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    background: section?.color,
                    width: "14px",
                    height: "14px",
                    borderRadius: "100px",
                  }}
                />

                <Typography variant="h4" fontSize="16px">
                  {section?.name}
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <IconButton
                  disabled={!permissions.create}
                  size="small"
                  onClick={() => createTask(section?.id)}
                >
                  <AddIcon />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => deleteSection(section?.id)}
                >
                  <DeleteOutlineIcon />
                </IconButton>
              </Box>
            </Box>

            <List
              height={600}
              itemCount={itemCount}
              itemSize={110}
              width={"100%"}
              outerRef={droppableProvided.innerRef}
              style={{
                padding: 8,
              }}
              itemData={{
                items: section.tasks,
                user_id,
                section,
                onUpdateTask,
                onDeleteTask,
                onViewTask,
                onTaskComment,
                permissions,
              }}
            >
              {Row}
            </List>
          </div>
        );
      }}
    </Droppable>
  );
};

export default BoardSection;
