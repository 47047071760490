import { Check } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Table, TableBody, TableCell, TableRow } from "@mui/material";
import SearchBox from "components/ui/searchBox";
import useApi from "hooks/useApi";
import { useDebounce } from "hooks/useDebounce";
import useDidMountEffect from "hooks/useDidMountEffect";
import { saveBase } from "lib/Reducer/baseSlice";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { TfiClose } from "react-icons/tfi";

const Permissions = ({
  role_permissions,
  isAdmin,
  user_id,
  loading: profile_loading,
}) => {
  const dispatch = useDispatch();

  const { loading, fetchData } = useApi();
  const permissionsReset = async () => {
    const endpoint = {
      method: "get",
      url: `/api/admin/role/permission/reset/${user_id}`,
    };
    const result = await fetchData(endpoint);

    if (result.success) {
      dispatch(saveBase({ data: null, updated: true }));
      toast.success(result?.message);
    }
  };

  const [searchText, setSearchText] = useState();
  const [search, setSearch] = useState("");
  const debouncedText = useDebounce(searchText, 1000);

  useDidMountEffect(() => {
    setSearch(debouncedText || "");
  }, [debouncedText]);
  const filteredItems = role_permissions?.filter((item) =>
    item?.module?.toLowerCase()?.includes(search.toLowerCase())
  );

  return profile_loading ? (
    <Box
      sx={{
        height: "calc(100vh - 203px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    <>
      <Box
        sx={{
          mb: "24px",
          display: "flex",
          gap: "30px",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <SearchBox
            handleSearch={(text) => setSearchText(text)}
            placeholder={"Search in permission..."}
          />
        </Box>
        <Box>
          {isAdmin && (
            <LoadingButton
              onClick={permissionsReset}
              loading={loading}
              variant="outlined"
              sx={{
                display: "flex",
              }}
            >
              Reset Permissions
            </LoadingButton>
          )}
        </Box>
      </Box>
      <Table
        sx={{
          border: "1px solid #ddd",
          "& td": {
            border: "1px solid #ddd",
          },
        }}
      >
        <TableBody>
          <TableRow>
            <TableCell>Features</TableCell>
            <TableCell>Oparations</TableCell>
            <TableCell>Permissons</TableCell>
          </TableRow>
          {filteredItems?.map((singleModule, firstIndex) => (
            <>
              {singleModule?.permission?.map(
                (singlePermission, secondIndex) => (
                  <TableRow>
                    {secondIndex === 0 && (
                      <TableCell
                        rowSpan={singleModule?.permission?.length}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {singleModule?.module?.split("-")?.join(" ")}
                      </TableCell>
                    )}
                    <TableCell sx={{ textTransform: "capitalize" }}>
                      {singlePermission?.operation}{" "}
                    </TableCell>
                    <TableCell align="center">
                      {singlePermission?.is_permit || isAdmin ? (
                        <Check sx={{ color: "green" }} />
                      ) : (
                        <TfiClose sx={{ color: "red" }} />
                      )}
                    </TableCell>
                  </TableRow>
                )
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </>
  );
};

export default Permissions;
