import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

const NoteSkeleton = () => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "50px auto 30px",
        padding: "20px",
        borderTop: "1px dashed #ddd",
        height: "250px",
      }}
    >
      <Box>
        <Skeleton variant="circular" width={40} height={40} />
      </Box>
      <Box>
        <Skeleton
          variant="text"
          sx={{ fontSize: "1.2rem", maxWidth: "200px" }}
        />
        <Box sx={{ fontSize: "12px" }}>
          <Skeleton
            variant="text"
            sx={{ fontSize: ".8rem", maxWidth: "150px" }}
          />
        </Box>
        <Box sx={{ fontSize: "14px", paddingTop: "10px" }}>
          <Skeleton variant="text" sx={{ fontSize: "1rem", maxWidth: "90%" }} />
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "7px" }}>
        <Skeleton variant="circular" width={30} height={30} />
        <Skeleton variant="circular" width={30} height={30} />
      </Box>
    </Box>
  );
};

export default NoteSkeleton;
