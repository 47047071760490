import { Box, Button } from "@mui/material";
import Alert from "components/alert";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import AddSMSValue from "./AddSMSValue";
import AddTemplate from "./AddTemplate";
import Header from "./Header";
import MainTable from "./table/MainTable";

const SmsTemplates = () => {
  const [openSMSvalue, setOpenSMSvalue] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);
  const [search, setSearch] = useState();
  const [currTemplate, setCurrTemplate] = useState({});
  const [allTemplate, setAllTemplate] = useState([]);
  const [reload, setReload] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 10,
  });
  const handleDelete = (id) => {
    setOpenAlert(id);
  };

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getTasks = async () => {
      let finalPagination = { ...pagination };
      const params = `page=${finalPagination?.current_page}&limit=${
        finalPagination?.per_page
      }${search ? `&search=${search}` : ""}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/sms-template/list?${params}`,
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllTemplate(allData || []);
        setPagination(rest);
      }
    };
    getTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page, search]);

  const handleDeleteConfirm = async () => {
    const endpoint = {
      method: "delete",
      url: `/api/admin/sms-template/destroy/${openAlert}`,
    };
    const result = await fetchData(endpoint);
    if (result.success) {
      if (allTemplate?.length > 0) {
        setAllTemplate(allTemplate.filter((tmlpt) => tmlpt?.id !== openAlert));
      }
    } else {
      setReload((pre) => !pre);
    }
    setOpenAlert(false);
  };
  const handleEdit = (value) => {
    setCurrTemplate(value);
    setOpenTemplate(true);
  };
  useEffect(() => {
    if (openTemplate === false) {
      setCurrTemplate({});
    }
  }, [openTemplate]);

  return (
    <>
      {openAlert && (
        <Alert
          open={openAlert}
          handleClose={setOpenAlert}
          title="Delete Template"
          desc={
            <Box>
              Are you sure you want to delete this Template? This will delete
              the Template permanently!{" "}
              <strong>You can't undo this action</strong>
            </Box>
          }
          buttons={
            <>
              <Button onClick={() => setOpenAlert(false)}>No, keep it</Button>
              <Button variant="solid" onClick={handleDeleteConfirm}>
                Yes, delete it
              </Button>
            </>
          }
        />
      )}

      <Header
        {...{
          setOpenSMSvalue,
          setOpenTemplate,
          setSearch,
          setPagination,
          pagination,
        }}
      />
      {openSMSvalue && (
        <AddSMSValue
          openDrawer={openSMSvalue}
          setOpenDrawer={setOpenSMSvalue}
        />
      )}
      {openTemplate && (
        <AddTemplate
          openDrawer={openTemplate}
          setOpenDrawer={setOpenTemplate}
          allTemplate={allTemplate}
          setAllTemplate={setAllTemplate}
          currTemplate={currTemplate}
        />
      )}

      <MainTable
        allTemplate={allTemplate}
        setAllTemplate={setAllTemplate}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        pagination={pagination}
        setPagination={setPagination}
        loading={loading}
        setReload={setReload}
      />
    </>
  );
};

export default SmsTemplates;
