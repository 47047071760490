import Axios from "lib/Axios";

export const viewAllTickets = ({ body_data, params }) =>
  Axios.post(`/api/admin/ticket/list?${params}`, body_data).then(
    (res) => res.data
  );

export const deleteTicket = ({ id }) =>
  Axios.delete(`/api/admin/ticket/delete/${id}`).then((res) => res.data);

export const updateTicketStatus = ({ body_data }) =>
  Axios.post("/api/admin/ticket/status", body_data).then((res) => res.data);

export const updateTicketPriority = ({ body_data }) =>
  Axios.put("/api/admin/ticket/priority", body_data).then((res) => res.data);

export const ticketBulkAction = ({ body_data }) =>
  Axios.post("/api/admin/ticket/bulk/action", body_data).then(
    (res) => res.data
  );
