const getInitialvalues = (filters) => {
  return {
    customer_id: filters.customer_id || [],
    reference_id: filters.reference_id || [],
    expense_category_id: filters.expense_category_id || [],
    project_id: filters.project_id || [],
    payment_method_id: filters.payment_method_id || [],
    expense_from: filters.expense_from || null,
    expense_to: filters.expense_to || null,
  };
};

export default getInitialvalues;
