import { Box, Typography } from "@mui/material";
import assets from "assets";
import UNIVERSAL from "config";
import useApi from "hooks/useApi";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Form from "./form/Form";

const LeadForm = () => {
  const base = useSelector((state) => state?.base);
  const [dropdownData, setDropdownData] = useState({});
  const [isSourceExist, setIsSourceExist] = useState(true);
  let { path_source } = useParams();
  const source = path_source.split("_").join(" ");
  const { loading: ddLoading, fetchData: getDropdownDataApi } = useApi();
  useEffect(() => {
    async function getDropdownDataCall() {
      const endpoint = {
        method: "get",
        url: `/base-filter`,
      };
      const result = await getDropdownDataApi(endpoint, false);
      if (result.success) {
        setDropdownData(result?.data);

        if (
          !result?.data?.sources?.some(
            (item) => item?.name.toLowerCase() === source.toLowerCase()
          )
        ) {
          setIsSourceExist(false);
        }
      }
    }
    try {
      getDropdownDataCall();
    } catch (e) {}
  }, []);
  return ddLoading ? (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type="cylon"
        color="#405189"
        height={"85px"}
        width={"100px"}
      />
    </Box>
  ) : (
    !!Object?.keys(dropdownData)?.length && (
      <Box
        sx={{
          maxWidth: "1092px",
          mx: "auto",
          p: "20px",
        }}
      >
        <Box
          sx={{
            margin: "0 auto",
            maxWidth: "15rem",
            mt: "10px",
          }}
        >
          <a href="/client">
            <img
              src={
                base.site_info?.site_logo
                  ? UNIVERSAL.BASEURL + base.site_info?.site_logo
                  : assets.logo
              }
              style={{
                maxWidth: "100%",
              }}
              alt=""
            />
          </a>
        </Box>
        {isSourceExist ? (
          <>
            <Typography variant="h2" my={4}>
              Please enter your information:
            </Typography>
            <Form {...{ dropdownData, source }} />
          </>
        ) : (
          <Typography variant="h2" my={4} textAlign={"center"}>
            System does not found any valid source!
          </Typography>
        )}
      </Box>
    )
  );
};

export default LeadForm;
