import Select from "components/ui/select";
import { useState } from "react";
// MUI ICONS
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Refresh from "@mui/icons-material/Refresh";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
// MUI COMPONENTS
import { MoreVert } from "@mui/icons-material";
import { Drawer } from "@mui/material";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import useWindowSize from "hooks/useWindowSize";

const TablePagination = (props) => {
  const { pagination, setPagination, setReload } = props;
  const { isMini } = useWindowSize();
  const { from, to, current_page, last_page, total, per_page } =
    pagination || {};
  const handlePagination = (type) => {
    switch (type) {
      case "first":
        setPagination({ ...pagination, current_page: 1 });
        break;
      case "pre":
        setPagination({ ...pagination, current_page: current_page - 1 });
        break;
      case "next":
        setPagination({ ...pagination, current_page: current_page + 1 });
        break;
      case "last":
        setPagination({ ...pagination, current_page: last_page });
        break;
      default:
        break;
    }
  };

  const handleRefresh = () => {
    setReload((pre) => !pre);
  };

  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  return (
    <>
      {isMini ? (
        <Grid
          container
          justifyContent={"space-between"}
          alignItems={"center"}
          gap="5px"
          sx={{
            width: "100%",
            padding: "10px",
            borderTop: "1px solid #ddd",
            mt: "5px",
          }}
        >
          <Box>
            <IconButton onClick={toggleDrawer(true)}>
              <MoreVert />
            </IconButton>
            <Drawer
              anchor="bottom"
              open={openDrawer}
              onClose={toggleDrawer(false)}
            >
              <Box sx={{ width: "auto" }} role="presentation">
                <Box
                  sx={{
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "15px",
                  }}
                >
                  <Typography
                    sx={{ color: "#555", margin: "0 10px", textAlign: "right" }}
                  >
                    {`Showing ${from || 0}-${to || 0} of ${total || 0}`}
                  </Typography>
                  <TextField
                    fullWidth
                    size="small"
                    label="Page Size"
                    select
                    defaultValue={per_page}
                    onChange={(e) =>
                      setPagination({
                        ...pagination,
                        per_page: e.target.value,
                        current_page: 1,
                      })
                    }
                  >
                    {[10, 30, 100, 500].map((size) => (
                      <MenuItem value={size} key={size}>
                        {size}
                      </MenuItem>
                    ))}
                  </TextField>
                  <Select
                    disableClearable
                    options={Array.from({ length: last_page }, (_, i) =>
                      (i + 1).toString()
                    )}
                    label="Jump To"
                    value={current_page?.toString()}
                    onChange={(value) =>
                      setPagination({
                        ...pagination,
                        current_page: Number(value),
                      })
                    }
                    selectProps={{ id: "id", label: "name" }}
                  />
                </Box>
              </Box>
            </Drawer>
          </Box>
          <Box>
            <IconButton sx={{ marginRight: "10px" }} onClick={handleRefresh}>
              <Refresh />
            </IconButton>
            <IconButton
              onClick={() => handlePagination("first")}
              disabled={current_page === 1}
              aria-label="first page"
            >
              <FirstPageIcon />
            </IconButton>
            <IconButton
              onClick={() => handlePagination("pre")}
              disabled={current_page === 1}
              aria-label="previous page"
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              onClick={() => handlePagination("next")}
              disabled={current_page === last_page || !last_page}
              aria-label="next page"
            >
              <KeyboardArrowRight />
            </IconButton>
            <IconButton
              onClick={() => handlePagination("last")}
              disabled={current_page === last_page || !last_page}
              aria-label="last page"
            >
              <LastPageIcon />
            </IconButton>
          </Box>
        </Grid>
      ) : (
        <Grid
          container
          justifyContent={"flex-end"}
          alignItems={"center"}
          gap="5px"
          sx={{
            width: "100%",
            padding: "10px 20px",
            borderTop: "1px solid #ddd",
            mt: "5px",
          }}
        >
          <IconButton sx={{ marginRight: "10px" }} onClick={handleRefresh}>
            <Refresh />
          </IconButton>
          <TextField
            size="small"
            label="Page Size"
            sx={{ width: "90px" }}
            select
            defaultValue={per_page}
            onChange={(e) =>
              setPagination({
                ...pagination,
                per_page: e.target.value,
                current_page: 1,
              })
            }
          >
            {[10, 30, 100, 500].map((size) => (
              <MenuItem value={size} key={size}>
                {size}
              </MenuItem>
            ))}
          </TextField>
          <Typography sx={{ color: "#555", margin: "0 10px" }}>
            {`Showing ${from || 0}-${to || 0} of ${total || 0}`}
          </Typography>
          <IconButton
            onClick={() => handlePagination("first")}
            disabled={current_page === 1}
            aria-label="first page"
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            onClick={() => handlePagination("pre")}
            disabled={current_page === 1}
            aria-label="previous page"
          >
            <KeyboardArrowLeft />
          </IconButton>
          <Box sx={{ width: "80px" }}>
            <Select
              disableClearable
              options={Array.from({ length: last_page }, (_, i) =>
                (i + 1).toString()
              )}
              label="Jump To"
              value={current_page?.toString()}
              onChange={(value) =>
                setPagination({ ...pagination, current_page: Number(value) })
              }
              selectProps={{ id: "id", label: "name" }}
            />
          </Box>
          <IconButton
            onClick={() => handlePagination("next")}
            disabled={current_page === last_page || !last_page}
            aria-label="next page"
          >
            <KeyboardArrowRight />
          </IconButton>
          <IconButton
            onClick={() => handlePagination("last")}
            disabled={current_page === last_page || !last_page}
            aria-label="last page"
          >
            <LastPageIcon />
          </IconButton>
        </Grid>
      )}
    </>
  );
};

export default TablePagination;
