import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import TableSkeleton from "components/skeletons/TableSkeleton";
import TablePagination from "components/tablePagination";
import useApi from "hooks/useApi";
import moment from "moment";
import { useEffect, useState } from "react";

const AttendanceTable = ({ user_id }) => {
  const [allAttendance, setAllAttendance] = useState([]);
  const [reload, setReload] = useState(false);
  const [pagination, setPagination] = useState({
    current_page: 1,
    per_page: 30,
    to: 0,
    from: 0,
    total: 0,
  });

  const { loading, fetchData } = useApi();
  useEffect(() => {
    const getAttendances = async () => {
      let finalPagination = { ...pagination };
      const params = `page=${finalPagination?.current_page}&limit=${finalPagination?.per_page}`;
      const endpoint = {
        method: "post",
        url: `/api/admin/attendance/list?${params}`,
        data: { user_id: [user_id] },
      };
      const result = await fetchData(endpoint, false);
      if (result.success) {
        const { data: allData, ...rest } = result.data;
        setAllAttendance(allData || []);
        setPagination(rest);
      }
    };
    getAttendances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, pagination?.current_page, pagination?.per_page]);

  const columns = [
    { id: "date", label: "Date" },
    { id: "name", label: "Staff Name" },
    { id: "start_time", label: "In Time" },
    { id: "end_time", label: "Out Time" },
    { id: "ip_check_in", label: "Check In IP" },
    { id: "ip_check_out", label: "Check Out IP" },
    { id: "late", label: "Late" },
    { id: "note", label: "Note", style: { width: "100px" } },
  ];

  return (
    <Box sx={{ m: "-20px" }}>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        {!loading ? (
          <TableContainer
            sx={{ height: "calc(100vh - 188px)", overflowX: "auto" }}
          >
            <Table
              stickyHeader
              sx={{
                "& th:first-of-type": { pl: "20px", pr: "0px" },
                "& th:last-child": { pr: "20px" },
                "& td:first-of-type": { pl: "20px", pr: "0px" },
                "& td:last-child": { pr: "20px" },
              }}
            >
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth, ...column.style }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allAttendance?.map((row) => (
                  <TableRow hover role="checkbox" key={row.id}>
                    {columns.map((column, i) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.id === "date" ? (
                            moment(value).format("ll")
                          ) : column.id === "start_time" ||
                            column.id === "end_time" ? (
                            value ? (
                              moment(value, "h:mm:ss").format("LT")
                            ) : (
                              "-"
                            )
                          ) : column.id === "late" ? (
                            <Typography
                              sx={{ color: value && "red" }}
                              variant="body2"
                            >
                              {value ? "Yes" : "No"}
                            </Typography>
                          ) : (
                            value
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <TableSkeleton columns={columns} tableHeight="calc(100vh - 188px)" />
        )}
        <TablePagination
          span={columns?.length}
          setReload={setReload}
          pagination={pagination}
          setPagination={setPagination}
        />
      </Paper>
    </Box>
  );
};

export default AttendanceTable;
